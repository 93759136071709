import {Formik} from 'formik'
import * as Yup from 'yup'
import {useState} from 'react'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import TermsAndConditionsModal from './termsAndCondition'
import {
  usePostVerifyOTP,
  usePostVerifyOTPCorporate,
} from 'src/app/queries/new-cif-new-account-queries/usePostVerifyOtp'
import {toast} from 'react-toastify'

interface VerificationFormProps {
  accountType?: string
  variant: 'email_verification' | 'mobile_verification' | 'both'
  setIsVerificationModalOpen?: (value: boolean) => void
  uniqueId?: string
  loading?: boolean
  openVerificationModal?: (type: string) => void
  setIsEmailVerified?: (value: boolean) => void
  setIsNumberVerified?: (value: boolean) => void
  setIsBothVerified?: (value: boolean) => void
  caseType?: string
  setVerificationStatus?: any
}

const VerificationForm = ({
  accountType,
  variant,
  setIsVerificationModalOpen,
  uniqueId,
  loading,
  openVerificationModal,
  setIsEmailVerified,
  setIsNumberVerified,
  setIsBothVerified,
  caseType,
  setVerificationStatus,
}: VerificationFormProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleLoadingChange = (loading: boolean) => {
    setIsLoading(loading)
  }

  const {mutate, isLoading: otpLoading} = usePostVerifyOTP()
  const {mutate: corporateVerify, isLoading: otpLoadingCorporate} = usePostVerifyOTPCorporate()
  const verifyOTP = (values: any) => {
    if (accountType !== 'Corporate') {
      mutate(
        {newCIFNewAccount: uniqueId ?? '', code: values.code ?? '', caseType: caseType ?? ''},
        {
          onSuccess: (resp: any) => {
            if (resp?.data?.resDesc == 'OTP is verified') {
              if (variant === 'email_verification') {
                setIsEmailVerified && setIsEmailVerified(true)
              } else if (variant === 'mobile_verification') {
                setIsNumberVerified && setIsNumberVerified(true)
              } else {
                setIsBothVerified && setIsBothVerified(true)
              }
              setIsVerificationModalOpen && setIsVerificationModalOpen(false)
              toast.success('OTP verified successfully')
              setVerificationStatus(true)
            } else {
              setVerificationStatus(false)
              toast.error('OTP verification failed')
            }
          },
        }
      )
    } else {
      corporateVerify(
        {newCIFNewAccount: uniqueId ?? '', code: values.code ?? '', caseType: caseType ?? ''},
        {
          onSuccess: (resp: any) => {
            if (resp?.data?.resDesc == 'OTP is verified') {
              if (variant === 'email_verification') {
                setIsEmailVerified && setIsEmailVerified(true)
              } else if (variant === 'mobile_verification') {
                setIsNumberVerified && setIsNumberVerified(true)
              } else {
                setIsBothVerified && setIsBothVerified(true)
              }
              setIsVerificationModalOpen && setIsVerificationModalOpen(false)
              toast.success('OTP verified successfully')
              setVerificationStatus(true)
            } else {
              setVerificationStatus(false)
              toast.error('OTP verification failed')
            }
          },
        }
      )
    }
  }

  return (
    <div>
      <h1 className='heading__component__title fw-bolder' style={{textAlign: 'center'}}>
        {variant === 'mobile_verification'
          ? 'Mobile Number Verification'
          : variant === 'email_verification'
          ? 'Email Verification'
          : 'Both Email and Mobile Verification'}
      </h1>
      <div className='mt-10 mx-auto'>
        <p>
          Please enter the 6 digit{' '}
          <strong>{variant === 'mobile_verification' ? 'Mobile Number' : 'Email'}</strong>{' '}
          Verification Code sent in the{' '}
          {variant === 'mobile_verification' ? 'Mobile Number' : 'Email'}. If the verification code
          has not been received by the customer, click on resend code button.
        </p>
        <Formik
          initialValues={{code: ''}}
          onSubmit={(values) => {
            verifyOTP(values)
          }}
        >
          {({handleChange, touched, errors, values, handleSubmit}) => (
            <>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-body pt-9 pb-9'>
                  <div className='row g-4 align-items-center'>
                    <div className='col-12 col-md-6'>
                      <div className='col-md-12'>
                        <FormTextBox
                          name='code'
                          onChange={handleChange}
                          containerClassName='col-12'
                          mainClassName='col-12'
                          placeholder='ENTER CODE HERE'
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='d-flex mb-1' style={{gap: '0.5rem'}}>
                        <button
                          type='button'
                          className='btn-sm btn-warning'
                          onClick={() => {
                            handleSubmit()
                          }}
                          disabled={loading || isLoading}
                        >
                          {isLoading ? 'Validating...' : 'Validate'}
                        </button>
                        <button
                          onClick={() => {
                            openVerificationModal && openVerificationModal(variant)
                          }}
                          disabled={loading || isLoading}
                          className='dt-btn dt-btn-outline-primary dt-btn-sm'
                        >
                          Resend Code
                        </button>
                        {/* <button
                          style={{color: 'white'}}
                          className='btn-sm btn-danger btn-font-weight-bold'
                          onClick={() =>
                            setIsVerificationModalOpen && setIsVerificationModalOpen(false)
                          }
                        >
                          Skip Validation
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
      {loading && (
        <div className='loading-overlay'>
          <div className='loading-message'>OTP is generating...</div>
        </div>
      )}
      <style>
        {`
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
        .loading-message {
          color: white;
          font-size: 1.5rem;
        }
        `}
      </style>
    </div>
  )
}

export default VerificationForm
