import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {IParams} from 'src/app/modules/linkedEntities/common/interface/Interfaces'
import useGetLinkedEntitiesDetailsById from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesById'
import {useGetLinkedEntitesData} from 'src/app/queries/linkedEntitiesQuery/get/useGetLinkedEntitiesData'
import CorporateDetailsView from '../../detailsView/corporateDetailsView'
import ToBeLinkedEntitiesCorporateRevertCase from './entitiesToBeLinkedCorporate'
import {Button, Spinner} from 'react-bootstrap-v5'
import usePostCheckAll from '../../../../../../../queries/apiMS/usePostCheckAllAndGeneratePdf'
import {BASE_APP_URL} from '../../../../../../../../cms/helpers/constants/common'

const CorporateRevertedCaseForm = () => {
  const {id} = useParams<IParams>()
  const {data: linkedEntitiesDatas} = useGetLinkedEntitiesDetailsById(id)
  const {primary_account_number, is_corporate} = linkedEntitiesDatas?.data || {}

  const [parameters, setParameters] = useState({
    account_number: '',
    is_corporate: false,
  })

  useEffect(() => {
    if (primary_account_number && is_corporate !== undefined) {
      setParameters({
        account_number: primary_account_number,
        is_corporate: is_corporate,
      })
    }
  }, [primary_account_number, is_corporate])

  const {data: onSubmitCIFDatas} = useGetLinkedEntitesData(parameters, {
    enabled: Boolean(parameters?.account_number),
  })
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const handleScreening = async (values: any) => {
    setScreeningLoading(true)

    update.current = 'default'

    // Prepare payload based on corporate or individual
    const checkAllPayload: any = {
      customer_name: linkedEntitiesDatas?.data?.primary_customer_name,
      // pan_number: parameters?.is_corporate ? values?.pan_number : '',
      // citizenship: !parameters?.is_corporate ? '' : values?.citizenship,
      model_name: 'LinkedEntities',
    }

    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data: any) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error: any) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleSubmit = (values: any) => {
    handleScreening(values) // Pass the form values to the screening function
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <p className='heading__component__title fw-bolder text-uppercase'>Customer Details</p>
        <div className='row mb-4'>
          <div className='col-md-3'>
            <CorporateDetailsView
              label={'Primary CIF Number'}
              value={linkedEntitiesDatas?.data?.primary_cif_number || '-'}
            />
            <CorporateDetailsView
              label={'Branch Code'}
              value={linkedEntitiesDatas?.data?.branch_code || '-'}
            />
            <CorporateDetailsView
              label={'Customer Code'}
              value={linkedEntitiesDatas?.data?.customer_code || '-'}
            />
            <CorporateDetailsView
              label={'Customer Name'}
              value={linkedEntitiesDatas?.data?.primary_customer_name || '-'}
            />
            <CorporateDetailsView
              label={'Account Number'}
              value={linkedEntitiesDatas?.data?.primary_account_number || '-'}
            />
          </div>
        </div>
        <div className='col-md-2 d-flex align-items-center'>
          {screeningPerformed ? (
            checkAllData && (
              <Button
                type='button'
                onClick={handleOpenPdf}
                // className='col-md-3 col-sm-6 col-xs-12'
              >
                View Details
              </Button>
            )
          ) : screeningLoading ? (
            <div>
              <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Button
              type='submit'
              onClick={handleSubmit}
              // className='col-md-3 col-sm-6 col-xs-12'
            >
              Perform Screening
            </Button>
          )}
        </div>
        <div className='my-7'>
          <h3>Screening Files</h3>
          {linkedEntitiesDatas?.data?.screening_files?.screening_files?.length > 0 ? (
            linkedEntitiesDatas?.data?.screening_files?.screening_files?.map(
              (file: any, index: any) => (
                <>
                  <button
                    key={index}
                    type='button'

                    onClick={() => handlePdfFileClick(file.file_path)}
                    style={{display: 'block', marginBottom: '10px'}}
                  >
                    {file.file_name}
                  </button>
                </>
              )
            )
          ) : (
            <p>No files available</p>
          )}
        </div>
        <div>
          <ToBeLinkedEntitiesCorporateRevertCase
            otherData={linkedEntitiesDatas}
            onSubmitValues={parameters}
            screeningPerformed={screeningPerformed}
            accumulatedFilePaths={accumulatedFilePaths}
            setAccumulatedFilePaths={setAccumulatedFilePaths}
          />
        </div>
      </div>
    </div>
  )
}

export default CorporateRevertedCaseForm
