import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useGetIndividualJointMinorNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetBranchCheckerLists'
import {useGetCorporateNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporatePreliminariesAccountList'
import IndividualRejectedBC from './individual/individualRejectedBC'
import IndividualRejectedQA from './individual/IndividualRejectedQA'
import CorporateRejectedBranchChecker from './corporate/corporateRejectedBC'
import CorporateRejectedQA from './corporate/corporateRejectedQA'
import {useGetOnlineAccountOpening} from 'src/app/queries/onlineAccountOpening'
import IndividualOnlineRejectedQA from './individual/individulOnlineRejectQA'

const AccountsRejectedLists = () => {
  const [activeKey, setActiveKey] = useState('individualJointMinorRejectedBC')
  const roleName = useSelector((state: any) => state.auth?.rolePermissions?.name)
  const [params, setParams] = useState({
    user_type: roleName?.toLowerCase()?.replace(/\s+/g, '_')?.replace(/_two/g, ''),
    cif_number: '',
    account_status: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    keyword: '',
    orderBy: 'updated_at',
    order: 'desc',
  })
  const {data: qaIndividualRejectedData, isLoading: individualLoadingQA} =
    useGetIndividualJointMinorNewCIFandAccountList({...params, account_status: 'reject_by_qa'})

  const {data: branchCheckerIndividualRejectedData, isLoading: individualLoadingBranch} =
    useGetIndividualJointMinorNewCIFandAccountList({
      ...params,
      account_status: 'reject_by_branch_checker',
    })

  // Fetch data for corporate accounts
  const {data: qaCorporateRejectedData, isLoading: corporateLoadingQA} =
    useGetCorporateNewCIFandAccountList({...params, account_status: 'reject_by_qa'})

  const {data: branchCheckerCorporateRejectedData, isLoading: corporateLoadingBranch} =
    useGetCorporateNewCIFandAccountList({...params, account_status: 'reject_by_branch_checker'})

  const {data: onlineAccountOpeningRejected, isLoading: isOnlineAccountOpeningRejectedLoading} =
    useGetOnlineAccountOpening({...params, account_status: 'reject_by_qa'})
  return (
    <div>
      <div className='modal-container shadow rounded' data-cy='rejected-account-list-modal'>
        <div className='bg-white p-5'>
          <div className='row g-0 border-bottom align-items-center mb-3 py-3'>
            <div className='col-lg-6'>
              <div className='heading__component__title fw-bolder'>Rejected Account Lists</div>
            </div>
          </div>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k ?? 'individualJointMinorRejectedBC')}
            id='rejected-account-tabs'
            className='mb-5'
            data-cy='rejected-account-tabs'
          >
            <Tab
              eventKey='individualJointMinorRejectedBC'
              title='Individual/Joint/Minor(Rejected By Branch Checker)'
            >
              <IndividualRejectedBC
                newCIFandAccountListData={branchCheckerIndividualRejectedData}
                setParams={setParams}
                params={params}
                loading={individualLoadingBranch}
                roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
              />
            </Tab>
            <Tab eventKey='corporateBankBC' title='Corporate (Rejected By Branch Checker)'>
              <CorporateRejectedBranchChecker
                newCorporateCIFandAccountListData={branchCheckerCorporateRejectedData}
                setParams={setParams}
                params={params}
                loading={corporateLoadingBranch}
                roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
              />
            </Tab>
            <Tab
              eventKey='individualJointMinorRejectedQA'
              title='Individual/Joint/Minor (Rejected By QA)'
            >
              <IndividualRejectedQA
                newCIFandAccountListData={qaIndividualRejectedData}
                setParams={setParams}
                params={params}
                loading={individualLoadingQA}
                roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
              />
            </Tab>
            <Tab eventKey='corporateBankQA' title='Corporate (Rejected By QA)'>
              <CorporateRejectedQA
                newCorporateCIFandAccountListData={qaCorporateRejectedData}
                setParams={setParams}
                params={params}
                loading={corporateLoadingQA}
                roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
              />
            </Tab>
            <Tab eventKey='individualRejectedQA' title='Individual Account (Rejected By QA)'>
              <IndividualOnlineRejectedQA
                onlineRejectListData={onlineAccountOpeningRejected}
                setParams={setParams}
                params={params}
                loading={isOnlineAccountOpeningRejectedLoading}
                roleName={roleName?.toLowerCase()?.replace(/\s+/g, '_')}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default AccountsRejectedLists
