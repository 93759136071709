import axios from 'axios'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQueryClient,
} from 'react-query'
import {toast} from 'react-toastify'
import {masterData} from 'src/app/queries/api/apiEndPoints/EndPoints'

const postImport = async (file: File, table_name: string) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('table_name', table_name)
  try {
    const response = await axios.post(`${masterData}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostImport = (
  table_name: string,
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
) => {
  const queryClient = useQueryClient()

  return useMutation((file: File) => postImport(file, table_name), {
    onSuccess: async (response) => {
      if (response?.resCod == 200) {
        if (table_name === 'md_account_purposes') {
          queryClient.invalidateQueries('getAccountPurpose')
          refetch()
        } else if (table_name === 'md_account_schemas') {
          queryClient.invalidateQueries('getAccountSchema')
          refetch()
        } else if (table_name === 'md_relationships') {
          queryClient.invalidateQueries('getRelationship')
          refetch()
        } else if (table_name === 'md_salutations') {
          queryClient.invalidateQueries('getSalutationsList')
          refetch()
        } else if (table_name === 'md_id_types') {
          queryClient.invalidateQueries('getIdTypeLists')
          refetch()
        } else if (table_name === 'md_tax_categories') {
          queryClient.invalidateQueries('getTaxCategoryList')
          refetch()
        } else if (table_name === 'md_individual_unique_ids') {
          queryClient.invalidateQueries('getIndividualIDS')
          refetch()
        } else if (table_name === 'md_annual_transactions') {
          queryClient.invalidateQueries('getAnnualTransactionList')
          refetch()
        } else if (table_name === 'md_annual_incomes') {
          queryClient.invalidateQueries('getAnnualIncomeList')
          refetch()
        } else if (table_name === 'md_sector') {
          queryClient.invalidateQueries('getAllSectorList')
          refetch()
        } else if (table_name === 'md_business_natures') {
          queryClient.invalidateQueries('getBusinessNature')
          refetch()
        } else if (table_name === 'md_registration_with') {
          queryClient.invalidateQueries('getRegistration')
          refetch()
        } else if (table_name === 'genders') {
          queryClient.invalidateQueries('getGenderList')
          refetch()
        } else if (table_name === 'md_education_qualifications') {
          queryClient.invalidateQueries('getEducation')
          refetch()
        } else if (table_name === 'md_religions') {
          queryClient.invalidateQueries('getReligion')
          refetch()
        } else if (table_name === 'md_marital_status') {
          queryClient.invalidateQueries('getMaritialStatusesList')
          refetch()
        } else if (table_name === 'md_risk_categories') {
          queryClient.invalidateQueries('getRiskCategoriesList')
          refetch()
        } else if (table_name === 'md_occupations') {
          queryClient.invalidateQueries('getoccupation')
          refetch()
        } else if (table_name === 'md_risk_sub_categories') {
          queryClient.invalidateQueries('getRiskSubCategory')
          refetch()
        } else if (table_name === 'md_pep_sub_categories') {
          queryClient.invalidateQueries('getPepSubCategory')
          refetch()
        } else if (table_name === 'md_pep_categories') {
          queryClient.invalidateQueries('getPepCategory')
          refetch()
        } else {
          queryClient.invalidateQueries('getIncomeSource')
          refetch()
        }
        toast.success(response.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostImport
