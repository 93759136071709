export const OFFLINE_ACCOUNT_OPENING_MODULES_TITLE = 'Offline Account Opening Modules'

export const OFFLINE_ACCOUNT_OPENING_MODULES = [
  {name: 'New CIF New Account', path: '/account/offline-account/new-cif-new-account'},
  {name: 'Existing CIF New Account', path: '/existing-cif-new-account'},
  {name: 'KYC Update', path: '/kyc-update'},
  {name: 'Bulk Account Opening', path: '/bulk-account-opening'},
  {name: 'Linked Entities & New CIF Only', path: '/linked-entities-new-cif-only'},
  {name: 'New CIF', path: '/account/offline-account/new-cif'},
]

export const OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS = {
  titlePP: 'PP Size Photo (1 MB)',
  titleSecondayPhoto: ' Secondary Photo (1 MB)',
  titleAOF: 'AOF (10 MB)',
  titleSignature: 'Signature (1 MB)',
  titleKYC: 'KYC Form (5 MB)',
  titleCtz: 'Citizenship (5 MB)',
  titlePassport: 'Passport (5 MB',
  titleLicense: 'Driving License (5 MB)',
  titleVoterID: " Voter's ID (5 MB)",
  titleRegistrationID: 'Registration IDs (5 MB)',
  titleMandate: ' Mandate Form',
  titleCIFFORM: ' CIF Form (5 MB)',
  titleIdentificationCertificate: 'Identification Certificate (5 MB)',
  titleGuardianIdentification: 'Guardian Identification Certificate',
  titlePOASignature: 'POA Signature (5 MB)',
  titlePOADocument: 'POA Document (5 MB)',
  titleAOADocument: 'AOA Document (5 MB)',
  titleMOADocument: 'MOA Document (5 MB)',
  titleOtherDocuments: 'Other Documents (5 MB)',
  titleAccountVerify: 'Address Verifying Documents (5MB)',
  titleMapDocuments: 'Map Document (5MB)',
}

export const OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE = 'Preliminary Form'

export const COLOR = {
  PRIMARY_COLOR: '#d9214e',
  SECONDARY_COLOR: '',
  TERTIARY_COLOR: '',
  ACCENT_COLOR: '',
  BACKGROUND_COLOR: '',
  TEXT_COLOR: '',
  HOVER_COLOR: '',
  FOCUS_COLOR: '',
  DISABLED_COLOR: '',
  ERROR_COLOR: '',
  WARNING_COLOR: '',
}
