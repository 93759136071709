import React, {useState} from 'react'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Modal, Table, Button} from 'rsuite'
import {ellipsis} from 'polished'
import {useGetUserActivity} from 'src/app/queries/new-cif-new-account-queries/getUserActivity'

const Cell = Table.Cell

const AccountStatusLog = ({data}: any) => {
  console.log(data)
  const BadgeCell = ({rowData, dataKey, color, ...props}: any) => {
    return (
      <Cell {...props}>
        <span className={`badge badge-${color}`}>{rowData[dataKey]}</span>
      </Cell>
    )
  }

  const SnCell = ({rowIndex, ...props}: any) => {
    return <Cell {...props}>{rowIndex + 1}</Cell>
  }

  const columns = [
    {
      label: 'SN',
      width: 60,
      cell: <SnCell />,
    },
    {
      label: 'Current Account Status',
      dataKey: 'current_status',
      cell: <BadgeCell dataKey='current_status' color='primary' />,
      width: 200,
      ellipsis: true,
    },
    {
      label: 'Previous Account Status',
      dataKey: 'previous_status',
      cell: <BadgeCell dataKey='previous_status' color='secondary' />,
      width: 200,
      ellipsis: true,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      width: 250,
      ellipsis: true,
    },
    {
      label: 'Updated Date',
      dataKey: 'updated_date',
      cell: <BadgeCell dataKey='updated_date' color='success' />,
      width: 240,
      ellipsis: true,
    },
    {
      label: 'Updated By',
      dataKey: 'updated_by',
      cell: <BadgeCell dataKey='updated_by' color='warning' />,
      width: 220,
      ellipsis: true,
    },
  ]
  return (
    <div className='shadow p-5 m-2 bg-white rounded'>
      <div className='g-0 border-bottom heading__component__title fw-bolder mb-4 text-center'>
        ACCOUNT HISTORY FOR THIS CUSTOMER
      </div>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data?.status_logs?.status_logs} showLoading={false} />
      </div>
    </div>
  )
}

export {AccountStatusLog}
