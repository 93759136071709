import React, {useEffect, useState} from 'react'
import {Upload, Button, Col} from 'antd'
import {UploadChangeParam, RcFile} from 'antd/lib/upload'
import {PlusCircleOutlined, DeleteOutlined} from '@ant-design/icons'
import {toast} from 'react-toastify'
import {UploadRequestOption} from 'rc-upload/lib/interface'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'
import axios from 'axios'
import {
  useRemoveDocumentNewCif,
  useRemoveDocumentNewCifNewAccount,
} from 'src/hooks/usePostKYCUploadDoc/usePostKYCRemoveDoc/useRemoveDoc'

interface FileUploadProps {
  documentType: string
  maxSize: number
  onChange: (file: RcFile, documentType: string) => void
  documentTypeId?: any
  uniqueID?: string
  resetUploads?: boolean
  setResetUploads?: (resetUploads: boolean) => void
  setUploadDocumentsTypeId?: any
  uploadDocumentsTypeId?: {id: string}[]
  isMissing?: boolean
  initialFile?: any
  fileName?: string
  accountType?: string
  existingFilesid?: any
  otherFilesId?: any
  cifFilesIds?: any
  setCifFilesIds?: any
  validateRequiredDocuments?: any
  setExistingFiles?: any
  setUploadedFiles?: any
  setCifFormFiles?: any
}

const FileUploadComponent: React.FC<FileUploadProps> = ({
  documentType,
  maxSize,
  onChange,
  documentTypeId,
  uniqueID,
  resetUploads = false,
  setResetUploads,
  setUploadDocumentsTypeId,
  isMissing = false,
  initialFile,
  fileName,
  accountType,
  existingFilesid,
  otherFilesId,
  cifFilesIds,
  setCifFilesIds,
  validateRequiredDocuments,
  setExistingFiles,
  setUploadedFiles,
  setCifFormFiles,
}) => {
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState<Record<string, string>>({})
  // const queryClient = useQueryClient()
  const [fileList, setFileList] = useState<any[]>(initialFile)
  const uploadDocument = async (file: RcFile) => {
    if (accountType === 'Individual' || accountType === 'Minor') {
      const formData = new FormData()
      formData.append('documentTypeId', documentTypeId)
      formData.append('image', file)

      try {
        const response = await axios.post(
          `${BASE_API_URL}/new-cif/${uniqueID}/documents`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        return response.data
      } catch (error) {
        toast.error('File upload failed.')
        throw error
      }
    } else if (accountType === 'Joint') {
      const formData = new FormData()
      formData.append('documentTypeId', documentTypeId)
      formData.append('image', file)
      const url =
        documentType === 'Other Documents' || documentType === 'CIF Form'
          ? `${BASE_API_URL}/new-cif/${uniqueID}/additional-documents`
          : `${BASE_API_URL}/new-cif/${uniqueID}/documents`

      try {
        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return response.data
      } catch (error) {
        toast.error('File upload failed.')
        throw error
      }
    }
  }

  const {mutate: removeDocument} = useRemoveDocumentNewCif()
  useEffect(() => {
    if (fileName) {
      setUploadDocumentsTypeId((prev: string) => [...prev, documentTypeId])
    }
  }, [fileName, documentTypeId, setUploadDocumentsTypeId])

  const customRequest = async (options: UploadRequestOption) => {
    const {file, onSuccess, onError} = options

    if (file instanceof File || file instanceof Blob) {
      try {
        // Remove old document if re-uploading for the same document type
        if (
          (documentType === 'Other Documents' || documentType === 'Other Documents') &&
          initialFile &&
          uploadedDocumentIds[documentTypeId]
        ) {
          await removeDocument({id: uniqueID, document_id: uploadedDocumentIds[documentTypeId]})
        }

        const response = await uploadDocument(file as RcFile) // Upload document
        console.log(response)
        const newDocumentId = response?.data?.id // Assuming API returns the uploaded document's ID
        setCifFilesIds((prev: any) => {
          const validFiles = prev.filter(
            (docfile: any) => docfile.id !== '' || docfile.file !== null
          ) // Remove invalid files
          const updatedFiles = [
            ...validFiles,
            {id: newDocumentId, doc_id: response?.data?.documentType?.id},
          ]
          console.log('Updated CIF Files IDs:', updatedFiles) // Log the updated state
          return updatedFiles
        })
        setUploadedDocumentIds((prev) => ({
          ...prev,
          [documentTypeId]: newDocumentId,
        }))

        if (onSuccess) {
          onSuccess('ok')
        }

        onChange(file as RcFile, documentType)
        if (setUploadDocumentsTypeId) {
          setUploadDocumentsTypeId((prev: any) => [...prev, {id: documentTypeId}])
        }
      } catch (error: any) {
        if (onError) {
          onError(error)
        }
      }
    } else {
      toast.error('Invalid file type: ' + typeof file)
    }
  }

  useEffect(() => {
    if (resetUploads) {
      setFileList([])
      if (setResetUploads) setResetUploads(false)
    }
  }, [resetUploads, setResetUploads])

  const handleRemove = async (file: any) => {
    // Check if the document exists in recently uploaded documents
    try {
      await removeDocument({
        id: uniqueID,
        document_id: file.id || uploadedDocumentIds[documentTypeId],
      })
      toast.success('File removal in process.')
    } catch (error) {
      toast.error('Failed to remove file.')
    }
    setFileList([])
    if (setUploadDocumentsTypeId) {
      setUploadDocumentsTypeId((prev: any) =>
        prev.filter((item: any) => item.id !== documentTypeId)
      )
    }
    setUploadedFiles((prev: any) => {
      const filteredFiles = prev.filter((file: any) => {
        return file.id !== documentTypeId
      })
      return filteredFiles
    })
    // Update existingFiles by filtering out the removed document
    setExistingFiles((prev: any) => {
      const filteredFiles = prev.filter((file: any) => {
        return file.id !== documentTypeId
      })
      return filteredFiles
    })
    setCifFilesIds &&
      setCifFilesIds((prev: any) => {
        console.log('Previous CIF Files:', prev) // Log the previous state before filtering
        console.log('File to Remove:', file) // Log the file that needs to be removed

        // Filter out invalid files and remove the matched file
        const filteredFiles = prev
          .filter((docfile: any) => docfile.id !== '' || docfile.file !== null) // Remove invalid files
          .filter((docfile: any) => {
            const shouldKeep = docfile.id !== uploadedDocumentIds[documentTypeId] // Remove matched file
            console.log(
              `Checking File ID: ${docfile.id}, Target ID: ${uploadedDocumentIds[documentTypeId]} - Keep: ${shouldKeep}`
            ) // Log each comparison
            return shouldKeep
          })

        console.log('Filtered CIF Files:', filteredFiles) // Log the filtered files
        return filteredFiles
      })
    validateRequiredDocuments()
  }
  return (
    <Col md={8} sm={12} xs={24}>
      <Upload
        customRequest={customRequest}
        maxCount={1}
        accept='.jpg,.jpeg,.png,.pdf'
        fileList={fileList || initialFile}
        onChange={(info) => {
          const {file} = info
          setFileList(info.fileList)
          if (file.status === 'done') {
            onChange(file.originFileObj as RcFile, documentType)
          }
        }}
        onRemove={handleRemove}
        showUploadList={{
          showRemoveIcon: true,
          removeIcon: <DeleteOutlined style={{color: 'red'}} />,
        }}
      >
        <div
          style={{
            color: 'red',
            marginBottom: '4px',
            fontSize: '12px',
            visibility: isMissing ? 'visible' : 'hidden',
          }}
        >
          {documentType} is required
          {/* Document Required. */}
        </div>
        <Button
          style={{
            width: '20vw',
            maxWidth: '100%',
            minWidth: '260px',
            fontSize: '12px',
            minHeight: '44px',
            whiteSpace: 'normal',
            height: 'auto',
            padding: '8px 15px',
            lineHeight: '1.2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
          icon={<PlusCircleOutlined />}
        >
          {documentType}
        </Button>
      </Upload>
    </Col>
  )
}

export default FileUploadComponent
