  import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const postScreeningCheck = async (payload: any) => {
  try {
    const response = await axios.post(`${BASE_API_URL}/screening-check`, payload)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostScreeningCheck = (setComplianceData: any) => {
  return useMutation(postScreeningCheck, {
    onSuccess: (response) => {
      if (response) {
        setComplianceData(response?.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.warning(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostScreeningCheck


