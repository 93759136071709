import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

type typePayload = {
  id: string
  data: {
    action?: string
    remarks?: string
  }
}

const postCibScreeningCheck = async (payload: typePayload) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}/cib-screening-check
    `,
      payload
    )
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCibScreeningCheck = (setCibScreeningData: any) => {
  return useMutation(postCibScreeningCheck, {
    onSuccess: (response) => {
      if (response) {
        setCibScreeningData(response?.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.warning(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCibScreeningCheck
