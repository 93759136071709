import axios from 'axios'
import {useQuery} from 'react-query'
import {ONLINE_ACCOUNT_OPENING} from 'src/app/queries/api/apiEndPoints/EndPoints'

const QUERY_KEY = 'OnlineAccountOpening'

export const useGetOnlineAccountOpeningDataByCustomerId = (customerId: string) => {
  return useQuery([QUERY_KEY, customerId], async () => {
    const {data} = await axios.get(`${ONLINE_ACCOUNT_OPENING}/${customerId}`)
    return data
  })
}
