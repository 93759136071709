import {adToBs, bsToAd} from '@sbmdkl/nepali-date-converter'
import axios from 'axios'
import {ErrorMessage, FieldArray, Form, Formik} from 'formik'
import moment from 'moment'
import {nanoid} from 'nanoid'
import {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE} from 'src/app/modules/new-cif-new-account/bankMaker/createNewAccountCorporate/constants/Contants'
import CIFCheck from 'src/app/pages/dashboard/component.tsx/CIFCheck'
import usePostCustomerEnquiryInfo from 'src/app/queries/apiMS/usePostCUstomerEnquiryInfo'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import {usePostNewCIFDetails} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostPreliminariesDetails'
import {usePostToGenerateCode} from 'src/app/queries/new-cif-new-account-queries/usePostGenerateOtp'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {BASE_API_URL, BASE_APP_URL} from 'src/cms/helpers/constants/common'

import {useGetAllRiskCategoriesList} from 'src/app/queries/getMasterData/riskCategories/get/useGetAllRiskCategoriesList'
import {
  SaveAndContinueValidation,
  SaveAndContinueValidationMinor,
} from '../../utils/validations/ValidationSchemas'
import {COLOR} from '../../constants/Contants'
import {RiskType} from 'src/cms/helpers/Types/Type'
import {RootState} from 'src/setup'
import {Spin} from 'antd'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import useGetMasterDataFilter from 'src/app/queries/getMasterData/getAllMasterData'
import {Modal, Table} from 'rsuite'

const {Column, HeaderCell, Cell} = Table

const NormalIndividualJointMinorFormCIF = () => {
  const {mutate: postPreliminariesDetails, isLoading: postLoading} = usePostNewCIFDetails()
  const history = useHistory()
  // const dispatch = useDispatch()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code
  const [validationCheck, setValidationCheck] = useState('')
  // const {mutate: postAddtionalPartner} = usePostKYCAdditionalDetailsCIF()
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setcheckAllDataJoint] = useState<any>([])
  const [flag, setFlag] = useState<string>('default')
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [masterFilterData, setMasterFilterData] = useState<any>(null)

  const {mutate: masterDataFilter, isLoading: masterFilterLoading} =
    useGetMasterDataFilter(setMasterFilterData)

  useEffect(() => {
    let individualData = allMasterDataOptions?.account_types?.find(
      (type: any) => type?.cbs_code === 'Individual'
    )?.id
    if (individualData) {
      masterDataFilter(individualData)
    }
  }, [])

  const update = useRef<any>('default')
  const {mutate: mutateCheckAll, isLoading: pdfLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setcheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false)
  const [isBlacklistingModalOpen, setIsBlacklistingModalOpen] = useState(false)
  const [verificationType, setVerificationType] = useState(null)
  const [verificationStatus, setVerificationStatus] = useState<any>(false)
  const [verificationDetails, setVerificationDetails] = useState({
    email: '',
    mobile: '',
  })
  const [uniqueID, setUniqueID] = useState('')
  const [regenrateID, setRegenrateID] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isNumberVerified, setIsNumberVerified] = useState(false)
  const [isBothVerified, setIsBothVerified] = useState(false)
  const [isDisabled, setISDisabled] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>()
  const [errormessage, setErrormessage] = useState<any>()
  const [datas, setDatas] = useState<any>()
  const [isSubmitting, setSubmitting] = useState<any>()
  const [screeningId, setScreeningId] = useState<any>()

  interface RouteParams {
    id: string
  }

  const {id} = useParams<RouteParams>()
  const [btnFlag, setBtnFlag] = useState('')
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  const {zoneOptions, provinceOptions, districtOptions, isLoading, municipalitiesOptions} =
    usePlaceMasterData({
      allMasterData: false,
      constitutions: true,
      corporateRegistrations: true,
      countries: true,
      zones: true,
      provinces: true,
      districts: true,
      municipalities: true,
    })
  const {data: zoneData} = useGetAllZoneData()
  const [zoneCode, setZoneCode] = useState<string | null>(null)
  const [districtData, setDistrictData] = useState<any>(null)

  // useEffect(() => {
  //   let individualData = allMasterDataOptions?.account_types?.find(
  //     (type: any) => type?.cbs_code === updatePreliminariesDetails?.data?.account_type_id?.cbs_code
  //   )?.id
  //   if (individualData && !masterLoading && !isLoading) {
  //     masterDataFilter(individualData)
  //   }
  // }, [masterLoading, isLoading])

  const generateScreeningId = () => {
    const ScreeningId = Math.floor(10000 + Math.random() * 90000)
    setScreeningId(ScreeningId)
  }

  useEffect(() => {
    if (zoneCode) {
      const fetchDistrictData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/district`, {
              params: {zoneCode},
            })
            .then((res: any) => {
              setDistrictData(res?.data)
            })
          // setDistrictData(data) // Store the district data in state
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchDistrictData()
    }
  }, [zoneCode])
  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(generatedID)
  }, [regenrateID])

  const {mutate: generateOtp, isLoading: generateOtpLoading} = usePostToGenerateCode()
  const {data: riskData} = useGetAllRiskCategoriesList()
  const [riskCode, setRiskCode] = useState<string | null>(null)
  const [riskSubCat, setRiskSubCat] = useState<any>(null)
  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)
  const [forcedContinue, setForcedContinue] = useState<any>(false)

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)
  useEffect(() => {
    if (riskCode) {
      fetchRiskData()
    }
  }, [riskCode])
  const fetchRiskData = async () => {
    try {
      await axios
        .get(`${BASE_API_URL}/risk-sub-categories`, {
          params: {risk_category_id: riskCode},
        })
        .then((res: any) => {
          setRiskSubCat(res?.data)
        })
    } catch (error) {
      console.error('Error fetching district data:', error)
    }
  }

  const getSaluationData = (id: string) => {
    masterDataFilter(id)
  }

  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('form_addresses[0].permanent_outside_country_post_box', '')
      setFieldValue('form_addresses[0].permanent_outside_country_full_address', '')
      setFieldValue('form_addresses[0].permanent_mobile_number', '')
      setFieldValue('form_addresses[0].permanent_email', '')
      setTouched({
        'form_addresses[0].permanent_outside_country_post_box': false,
        'form_addresses[0].permanent_outside_country_full_address': false,
        'form_addresses[0].permanent_mobile_number': false,
        'form_addresses[0].permanent_email': false,
      })
    } else {
      setFieldValue('form_addresses[0].permanent_province', '')
      setFieldValue('form_addresses[0].permanent_zone', '')
      setFieldValue('form_addresses[0].permanent_district', '')
      setFieldValue('form_addresses[0].permanent_municipality', '')
      setFieldValue('form_addresses[0].permanent_city', '')

      setFieldValue('form_addresses[0].permanent_ward_number', '')
      setFieldValue('form_addresses[0].permanent_street_name', '')
      setFieldValue('form_addresses[0].permanent_house_number', '')
      setFieldValue('form_addresses[0].permanent_fax_number', '')

      setFieldValue('form_addresses[0].permanent_mobile_number', '')
      setFieldValue('form_addresses[0].permanent_email', '')
      setTouched({
        'form_addresses[0].permanent_province': false,
        'form_addresses[0].permanent_zone': false,
        'form_addresses[0].permanent_district': false,
        'form_addresses[0].permanent_municipality': false,
        'form_addresses[0].permanent_city': false,

        'form_addresses[0].permanent_ward_number': false,
        'form_addresses[0].permanent_street_name': false,
        'form_addresses[0].permanent_house_number': false,
        'form_addresses[0].permanent_mobile_number': false,
        'form_addresses[0].permanent_fax_number': false,

        'form_addresses[0].permanent_email': false,
      })
    }
  }
  const onMarriageChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    setFieldValue('spouse_name', '')
    setFieldValue('father_in_law', '')
    setTouched({
      spouse_name: false,
      father_in_law: false,
    })
  }

  const {district, province, zone} = useSelector((state: RootState) => state.place)

  const initialValues = {
    opening_mode: 'normal',
    action: '',
    account_type_id: allMasterDataOptions?.account_types?.find(
      (item: any) => item.cbs_code === 'Individual'
    )?.id,
    branch_code: userBranchCode,
    full_name: '',
    short_name: '',
    first_name: '',
    middle_name: '',
    last_name: '',

    id_type_id: '',
    identification_number: '',
    account_category_id: allMasterDataOptions?.account_categories?.find(
      (accCat: any) => accCat?.cbs_code === 'CAA'
    )?.id,
    account_scheme_id: '',
    screening_id: '',
    forced_continue: false,

    form_addresses: [
      {
        permanent_mobile_number: '',
        permanent_email: '',
        is_present_address_different: false,
      },
    ],
  }

  // State to hold the selected ID type
  const [enquiryData, setEnquiryData] = useState<any>()
  const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)
  // const transformValues = (values: any) => {
  //   const isJointAccount =
  //     values?.account_type_id &&
  //     allMasterDataOptions?.account_types?.some(
  //       (type: {id: string; title: string}) =>
  //         type.id === values.account_type_id && type.title === 'Joint'
  //     )

  //   return {
  //     account_holders: values?.account_holders.map((holder: any) => ({
  //       ...holder,
  //     })),
  //   }
  // }

  const handleFormSubmit = (values: any, setSubmitting: any) => {
    setDatas(values)

    const presentEmail = values?.form_addresses?.[0]?.permanent_email
    const presentMobile = values?.form_addresses?.[0]?.permanent_mobile_number

    // if (btnFlag === 'save_and_continue') {
    //   if (
    //     !verificationStatus &&
    //     (!(presentEmail && presentEmail.includes('.')) ||
    //       !(presentMobile && presentMobile.includes('.')))
    //   ) {
    //     toast.error('Please verify the account before submitting.')
    //     setSubmitting(false)
    //     return // Stop the function from continuing
    //   }
    // }

    const fullName = [values.first_name, values.middle_name, values.last_name]
      .filter(Boolean) // Remove empty strings
      .join(' ')
    const userFullName = fullName
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, ' ')
      .trim()
    const generatedShortName = userFullName?.slice(0, 25)

    const capitalizeName = (name: string) => {
      return name.replace(/\b\w/g, (char: string) => char.toUpperCase())
    }

    const payload = {
      ...values,
      screening_id: screeningId?.toString(),
      verification_type: verificationType,
      verification_status: verificationStatus,
      full_name: userFullName?.toUpperCase() || '',
      short_name: generatedShortName?.toUpperCase() || '',
      first_name: values?.first_name?.toUpperCase() || '',
      middle_name: values?.middle_name ? values?.middle_name?.toUpperCase() : '',
      last_name: values?.last_name?.toUpperCase() || '',

      form_addresses: {...values?.form_addresses?.[0]},
      action: btnFlag === 'save' ? 'save' : 'save_and_continue',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths, // Use accumulated file paths here
    }

    // const additionalFormPayload: any = transformValues(values)

    postPreliminariesDetails(payload, {
      onSuccess: (response) => {
        const accountId = response?.data?.id
        localStorage.setItem('accountId', accountId)
        toast.success(response?.data?.resDesc)
        setSubmitting(false)
        history.push('/new-cif-only')
        // const checkAccountType = allMasterDataOptions?.account_types?.find(
        //   (type: {title: string; id: string}) => type.id === values.account_type_id
        // )
        // if (checkAccountType?.title === 'Joint') {
        //   const id: any = localStorage.getItem('accountId')
        //   postAddtionalPartner({id: id, additionalFormPayload})
        // }
      },
      onError: (error, response) => {
        setErrors(error?.errors?.flat())
        setErrormessage(error?.message)

        setSubmitting(false)
        toast.error(error?.message)
      },
    })

    setSubmitting(false)
  }
  const handleButtonClick = (flag: string) => {
    setBtnFlag(flag)
  }

  const getMunicipilities = (id: string, setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue('form_addresses[0].permanent_district', response?.data?.district?.id)
        setFieldValue('form_addresses[0].permanent_zone', response?.data?.district?.zone?.id)
        setFieldValue(
          'form_addresses[0].permanent_province',
          response?.data?.district?.province?.id
        )
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }
  const allValidationSchema = () => {
    switch (validationCheck) {
      case allMasterDataOptions?.account_types?.find((item: any) => item?.cbs_code === 'Individual')
        ?.id: //checking individual
        return btnFlag === 'save'
          ? undefined
          : SaveAndContinueValidation(allMasterDataOptions, true)
      case allMasterDataOptions?.account_types?.find((item: any) => item?.cbs_code === 'Minor')?.id: //Checking Minor
        // return btnFlag === 'save' ? SaveAndContinueValidationMinor : saveValidationsMinor
        return btnFlag === 'save'
          ? undefined
          : SaveAndContinueValidationMinor(allMasterDataOptions, true)
      default:
        // return btnFlag !== 'save' ? SaveAndContinueValidation : saveValidations
        return btnFlag === 'save'
          ? undefined
          : SaveAndContinueValidation(allMasterDataOptions, true)
    }
  }

  const [showCIFCheck, setShowCIFCheck] = useState(false)

  const handleScreeningClick = () => {
    setShowCIFCheck((prevState) => !prevState)
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }

  const getZoneCode = (e: any, values: any) => {
    const presentZone = e
    const zoneList = zoneData?.data?.zone
    const matchingZone = zoneList?.find((zone: any) => zone?.id === presentZone)
    if (matchingZone) {
      setZoneCode(matchingZone?.code)
    } else {
      console.log('No matching zone found.')
    }
  }

  const getSubRiskCode = (id: string, setFieldValue: any) => {
    const selectedSubCategory = allMasterDataOptions.risk_sub_categories?.find(
      (option: any) => option?.id === id
    )
    const riskCategoryId = selectedSubCategory?.risk_category?.id || ''
    // const riskCategoryTitle = selectedSubCategory?.risk_category?.title || 'null'
    const riskCategoryTitle = selectedSubCategory?.title || 'null'

    setFieldValue('aml_risk_rating', riskCategoryId)
    if (riskCategoryTitle == 'Others') {
      setISDisabled(false)
    } else if (riskCategoryTitle == 'null') {
      setISDisabled(false)
    } else {
      setISDisabled(true)
    }
  }

  const handleReset = (setFieldValue: (field: string, value: any) => void) => {
    setcheckAllDataMinor(undefined) // Reset the checkAllData state
    setFieldValue('guardian_cif_number', '') // Clear the guardian_cif_number field
    setFieldValue('guardian_first_name', '') // Optionally clear other guardian fields
    setFieldValue('guardian_middle_name', '') // Optionally clear other guardian fields
    setFieldValue('guardian_last_name', '') // Optionally clear other guardian fields
    setFieldValue('guardian_cif_number', '') // Optionally clear other guardian fields
    setFieldValue('guardian_address', '') // Optionally clear other guardian fields
  }
  return (
    <>
      <div>
        {isLoading ? (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body text-center '>
              <h3>
                <Spinner animation='border' variant='danger' className='mb-3' />
                <br />
                Please Wait...
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className=''>
              {/* <div className='text-end'>
                <Button onClick={handleScreeningClick} className='mb-3'>
                  {showCIFCheck ? 'Hide CIF Check' : 'Show CIF Check'}
                </Button>
              </div> */}
              {/* {showCIFCheck && ( */}
              <CIFCheck
                mutateCheckAll={mutateCheckAll}
                model_name='NewCIF'
                checkAllData={checkAllData}
                setCheckAllData={setCheckAllData}
                handleOpenPdf={handleOpenPdf}
                setScreeningPerformed={setScreeningPerformed}
                generateScreeningId={generateScreeningId}
              />
              {/* )} */}
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                handleFormSubmit(values, setSubmitting)
              }}
              validationSchema={SaveAndContinueValidation(allMasterDataOptions)}
              validateOnChange
            >
              {({values, errors, handleChange, touched, setFieldValue, setTouched}) => {
                const otpVerification = (type: any) => {
                  setVerificationType(type)
                  setIsVerificationModalOpen(true)
                  if (type === 'email_verification') {
                    generateOtp({
                      payload: {
                        type: 'email_verification',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: values?.form_addresses[0].permanent_email,
                        mobile_number: '',
                      },
                    })
                  } else if (type === 'mobile_verification') {
                    generateOtp({
                      payload: {
                        type: 'mobile_verification',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: '',
                        mobile_number: values?.form_addresses[0].permanent_mobile_number,
                      },
                    })
                  } else {
                    generateOtp({
                      payload: {
                        type: 'both',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: values?.form_addresses[0].permanent_email,
                        mobile_number: values?.form_addresses[0].permanent_mobile_number,
                      },
                    })
                  }
                }
                // useEffect(() => {
                //   if (enquiryData) {
                //     const data: any = enquiryData

                //     const checkAllPayload: any = {
                //       customer_name: data?.data?.generalDetails[0]?.CUST_NAME,
                //       date_of_birth_bs: data?.data?.generalDetails[0]?.DATE_OF_BIRTH,
                //       unique_id_no: data?.data?.generalDetails[0]?.CUST_ID,
                //       citizenship: data?.data?.generalDetails[0]?.IDNO,
                //       gender: data?.data?.generalDetails[0]?.CUST_SEX,
                //       model_name: 'NewCIF',
                //     }

                //     if (update.current === 'minor') {
                //       mutateCheckAll(checkAllPayload, {
                //         onSuccess: (response) => {
                //           update.current = 'default' // Reset after success
                //         },
                //         onError: (error) => {
                //           update.current = 'default' // Reset after error
                //         },
                //       })
                //     }
                //     const fullName = data?.data?.generalDetails[0]?.CUST_NAME || ''

                //     const nameParts = fullName.split(' ')

                //     const first_name = nameParts[0] || '' // First part as first name
                //     const middle_name = nameParts.length > 2 ? nameParts.slice(1, -1).join(' ') : '' // Middle parts as middle name
                //     const last_name = nameParts[nameParts.length - 1] || '' // Last part as last name

                //     setFieldValue(`guardian_first_name`, first_name || '')
                //     setFieldValue(`guardian_middle_name`, middle_name || '')
                //     setFieldValue(`guardian_last_name`, last_name || '')

                //     const enteredCifNumber = values.guardian_cif_number

                //     setFieldValue(`guardian_cif_number`, enteredCifNumber)

                //     const idType = data?.data?.generalDetails[0]?.IDTYPE
                //     const matchedOption = allMasterDataOptions?.id_types?.find(
                //       (option: any) => option.code === idType
                //     )
                //     if (matchedOption) {
                //       setFieldValue(`id_type_id`, matchedOption.id)
                //     }

                //     setFieldValue(`guardian_id_number`, data?.data?.generalDetails[0]?.IDNO || '')
                //     setFieldValue(
                //       `guardian_address`,
                //       data?.data?.addressInfo?.permanentAddress?.ADDRESS1 || ''
                //     )
                //   }
                // }, [enquiryData, setFieldValue])
                // console.log(errors)
                // const handleEnquiry = (values: any) => {
                //   if (values) {
                //     customerEnquiry({values})
                //   }
                //   update.current = 'minor'
                //   setScreeningPerformed(true)
                // }

                const prevEmailRef = useRef(values.form_addresses[0].permanent_email)
                const prevMobileRef = useRef(values.form_addresses[0].permanent_mobile_number)

                useEffect(() => {
                  // Check if email or mobile number actually changed
                  if (
                    values.form_addresses[0].permanent_email !== prevEmailRef.current ||
                    values.form_addresses[0].permanent_mobile_number !== prevMobileRef.current
                  ) {
                    setVerificationStatus(false)
                    setIsEmailVerified(false)
                    setIsNumberVerified(false)
                    setIsBothVerified(false)

                    // Update refs with the new values
                    prevEmailRef.current = values.form_addresses[0].permanent_email
                    prevMobileRef.current = values.form_addresses[0].permanent_mobile_number
                  }
                }, [
                  values.form_addresses[0].permanent_email,
                  values.form_addresses[0].permanent_mobile_number,
                ])
                return (
                  <Spin spinning={masterFilterLoading}>
                    <Form className='bg-white'>
                      <div className='card mb-5 mb-xl-10'>
                        <div className='card-body border-top '>
                          <div className='d-flex border-bottom mb-4 pb-2'>
                            <h1 className=' heading__component__title fw-bolder text-uppercase'>
                              {OFFLINE_ACCOUNT_OPENING_PRELIMINARY_INDIVIDUAL_TITLE}
                            </h1>
                            <span>{'Individual'}</span>
                          </div>
                          <div className=''>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Branch Code'
                                  name='branch_code'
                                  label='Branch Code'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  disabled
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Screening Id'
                                  name='screening_id'
                                  label='Screening Id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={6}
                                  value={screeningId}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <section className='py-3'>
                            <div className='row'>
                              <div className='col-md-6 col-xs-12 mb-5'>
                                <h1 className=' heading__component__title fw-bolder text-uppercase'>
                                  Select Account Type
                                </h1>
                                <div className='d-flex'>
                                  {isLoading ? (
                                    <Spinner animation={'border'} />
                                  ) : (
                                    allMasterDataOptions?.account_types
                                      ?.filter((type: {title: string}) =>
                                        ['Individual']?.includes(type.title)
                                      )
                                      ?.map(({id, title}: any) => (
                                        <FormRadio
                                          key={id}
                                          containerClassName=''
                                          label='Account Type'
                                          name='account_type_id'
                                          type='radio'
                                          onChange={(e) => {
                                            handleChange(e)
                                            getSaluationData(e.target.value)
                                            setValidationCheck(e.target.value)

                                            if (title === 'Minor') {
                                              // Find the correct ID for 'UNMARRIED' status
                                              const unmarriedStatus =
                                                allMasterDataOptions?.marital_statuses?.find(
                                                  (status: any) =>
                                                    status.title === 'UNMARRIED' ||
                                                    status.label === 'UNMARRIED'
                                                )
                                              const birthStatus =
                                                allMasterDataOptions?.id_types?.find(
                                                  (status: any) =>
                                                    status.title === 'BIRTH CERTIFICATE' ||
                                                    status.label === 'BIRTH CERTIFICATE'
                                                )
                                              if (unmarriedStatus && birthStatus) {
                                                setFieldValue(
                                                  'marital_status',
                                                  unmarriedStatus.id || unmarriedStatus.value
                                                )
                                                setFieldValue(
                                                  'id_type_id',
                                                  birthStatus.id || birthStatus.value
                                                )
                                              }
                                            }
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          required
                                          value={id}
                                          checkBoxText={title}
                                          checked={values.account_type_id === id}
                                        />
                                      ))
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                          <hr />

                          <section className='py-3'>
                            <h1 className=' heading__component__title fw-bolder text-uppercase'>
                              A. Personal Information:
                            </h1>
                            <div className='row'>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12 text-uppercase'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='First Name'
                                  name='first_name'
                                  label='First Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Middle Name'
                                  name='middle_name'
                                  label='Middle Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Last Name'
                                  name='last_name'
                                  label='Last Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  uppercase={true}
                                  maxLength={50}
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Identification'
                                  name='id_type_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.id_types?.map(
                                    (identityType: any) => ({
                                      value: identityType?.id || identityType?.value,
                                      label: identityType?.title || identityType?.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                  disabled={
                                    allMasterDataOptions?.account_types?.find(
                                      (type: any) => type.id === values.account_type_id
                                    )?.title === 'Minor'
                                  }
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='ID Number'
                                  name='identification_number'
                                  label='Identification Number'
                                  onChange={(e: any) => {
                                    handleChange(e)
                                    const selectedAccountType =
                                      allMasterDataOptions?.account_types?.find(
                                        (account_type: any) =>
                                          account_type.id === values?.account_type_id
                                      )

                                    if (selectedAccountType?.title?.toLowerCase() === 'minor') {
                                      setFieldValue('minor_certificate_number', e?.target?.value)
                                    }
                                  }}
                                  value={values?.identification_number}
                                  errors={errors}
                                  touched={touched}
                                  maxLength={15}
                                  required
                                />
                              </div>
                              {/* <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Account Type'
                                  name='account_category_id'
                                  onChange={(w: any) => {
                                    handleChange(w)
                                    // getAccountTypeId(w, values)
                                  }}
                                  errors={errors}
                                  values={values}
                                  touched={touched}
                                  options={allMasterDataOptions?.account_categories?.map(
                                    (accat: any) => ({
                                      value: accat.id || accat.value,
                                      label: accat.title || accat.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  disabled
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label='Account Scheme'
                                  name='account_scheme_id'
                                  onChange={handleChange}
                                  errors={errors}
                                  values={values}
                                  touched={touched}
                                  options={
                                    masterFilterData?.account_schemas.length > 0
                                      ? masterFilterData?.account_schemas?.map((accur: any) => ({
                                          value: accur.id || accur.value,
                                          label: accur.title || accur.label,
                                        }))
                                      : allMasterDataOptions?.account_schemas?.map(
                                          (AccSchema: any) => ({
                                            value: AccSchema?.id || AccSchema?.value,
                                            label: AccSchema?.title || AccSchema?.label,
                                          })
                                        )
                                  }
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>*/}
                              <FieldArray
                                name='form_addresses'
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.form_addresses && values.form_addresses.length > 0
                                      ? values.form_addresses.map((address, index: number) => (
                                          <div key={index}>
                                            <div className='row'>
                                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                                <FormTextBox
                                                  labelClassName='col-lg-12'
                                                  containerClassName='col-lg-12'
                                                  type='text'
                                                  placeholder='Mobile Number'
                                                  name={`form_addresses[${index}].permanent_mobile_number`}
                                                  label='Mobile Number'
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                  required
                                                  maxLength={10}
                                                />
                                              </div>
                                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                                <FormTextBox
                                                  labelClassName='col-lg-12'
                                                  containerClassName='col-lg-12'
                                                  type='text'
                                                  placeholder='Email'
                                                  name={`form_addresses[${index}].permanent_email`}
                                                  label='Email'
                                                  onChange={handleChange}
                                                  errors={errors}
                                                  touched={touched}
                                                  // // uppercase={true}
                                                  maxLength={50}
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ))
                                      : null}
                                  </div>
                                )}
                              />
                            </div>
                          </section>
                          <hr />
                          {/* Guardians In Case of Minor */}
                          {/* {allMasterDataOptions?.account_types
                            ?.find(
                              (account_types: any) => account_types.id === values?.account_type_id
                            )
                            ?.title.toLowerCase() === 'minor' && (
                            <>
                              <section className='py-3'>
                                <div className='row'>
                                  <h2 style={{color: 'brown'}}>
                                    Guardian's Details <i>(in case of minor)</i>{' '}
                                  </h2>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      // uppercase={true}
                                      placeholder='Guardian CIF Number'
                                      name='guardian_cif_number'
                                      label='Guardian CIF Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={9}
                                      required
                                    />
                                    {checkAllDataMinor ? (
                                      <Button
                                        className='mb-5 ms-3'
                                        onClick={() => {
                                          handleOpenPdf2()
                                        }}
                                      >
                                        View PDF
                                      </Button>
                                    ) : pdfLoading ? (
                                      <Spinner animation='border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                      </Spinner>
                                    ) : (
                                      <Button
                                        className='mb-5'
                                        onClick={() => {
                                          handleEnquiry(values.guardian_cif_number)
                                        }}
                                      >
                                        Enquiry
                                      </Button>
                                    )}
                                    <div>
                                      <Button
                                        type='button'
                                        onClick={() => handleReset(setFieldValue)}
                                        className='btn btn-secondary'
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      // uppercase={true}
                                      placeholder='Guardian Identification Number'
                                      name='guardian_id_number'
                                      label='Guardian Identification Number'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      required
                                      maxLength={15}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian First Name'
                                      name='guardian_first_name'
                                      label='Guardian First Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                      required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Middle Name'
                                      name='guardian_middle_name'
                                      label='Guardian Middle Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Last Name'
                                      name='guardian_last_name'
                                      label='Guardian Last Name'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      maxLength={50}
                                      // required
                                    />
                                  </div>
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-md-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Select'
                                      label='Guardian Relationship'
                                      name='guardian_relationship_id'
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      options={allMasterDataOptions?.guardian_relationships?.map(
                                        (guardian_relationship_id: any) => ({
                                          value:
                                            guardian_relationship_id?.id ||
                                            guardian_relationship_id?.value,
                                          label:
                                            guardian_relationship_id?.title ||
                                            guardian_relationship_id?.label,
                                        })
                                      )}
                                      setFieldValue={setFieldValue}
                                      required
                                    />
                                  </div>
                                  {allMasterDataOptions?.relationships
                                    ?.find(
                                      (relationships: any) =>
                                        relationships.id === values.guardian_relationship_id
                                    )
                                    ?.title.toLowerCase() === 'others' && (
                                    <>
                                      <div className='col-md-4 col-sm-4 col-xs-12'>
                                        <FormTextBox
                                          // uppercase={true}
                                          labelClassName='col-lg-12'
                                          containerClassName='col-lg-12'
                                          type='text'
                                          name='guardian_relationship_other'
                                          label='Guardian Relationship Other Name'
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          required
                                          maxLength={50}
                                          placeholder={'Guardian Relationship Other Name'}
                                        />
                                      </div>
                                    </>
                                  )}
                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormTextBox
                                      // uppercase={true}
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      type='text'
                                      placeholder='Guardian Address'
                                      name='guardian_address'
                                      label='Guardian Address'
                                      onChange={handleChange}
                                      errors={errors}
                                      maxLength={50}
                                      touched={touched}
                                      required
                                    />
                                  </div>

                                  <div className='col-md-4 col-sm-4 col-xs-12'>
                                    <FormSelect
                                      labelClassName='col-lg-12'
                                      containerClassName='col-lg-12'
                                      placeholder='Certificate Issued By'
                                      label='Certificate Issued By'
                                      name={`certificate_issued_by`}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      options={allMasterDataOptions?.individual_unique_ids?.map(
                                        (certificateIssue: any) => ({
                                          value: certificateIssue?.id || certificateIssue?.value,
                                          label: certificateIssue?.title || certificateIssue?.label,
                                        })
                                      )}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <input
                                    type='hidden'
                                    name='minor_certificate_number'
                                    value={values.minor_certificate_number}
                                  />
                                  <input
                                    type='hidden'
                                    name='minor_certificate_issue_date_ad'
                                    value={values.minor_certificate_issue_date_ad}
                                  />
                                  <input
                                    type='hidden'
                                    name='minor_certificate_issue_date_bs'
                                    value={values.minor_certificate_issue_date_bs}
                                  />
                                </div>
                              </section>
                              <hr />
                            </>
                          )} */}
                          {/* <div className='row'>
                          {allMasterDataOptions?.account_types
                            ?.find(
                              (account_types: any) => account_types.id === values?.account_type_id
                            )
                            ?.title.toLowerCase() === 'joint' && (
                            <>
                              <div className='row'>
                                <div>
                                  <h2 style={{color: 'brown'}}>
                                    Partner's Details Section <i>(In case of Joint Account)</i>
                                  </h2>
                                </div>
                                <PartnersDetailsFormJoint
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  enquiryData={enquiryData}
                                  customerEnquiry={customerEnquiry}
                                  mutateCheckAll={mutateCheckAll}
                                  handlePdf={handleOpenPdf2}
                                  update={update}
                                  checkAllData={checkAllDataJoint}
                                  setScreeningPerformed={setScreeningPerformed}
                                  pdfLoading={pdfLoading}
                                />
                              </div>
                            </>
                          )}
                        </div> */}
                          <>
                            {(values.form_addresses[0].permanent_email ||
                              values?.form_addresses[0].permanent_mobile_number) && (
                              <div className='row mb-4 pb-4 border-bottom align-items-center'>
                                {values.form_addresses[0].permanent_email && !isBothVerified && (
                                  <div className='col-md-4 mb-2'>
                                    {!isEmailVerified ? (
                                      <button
                                        type='button'
                                        onClick={() => {
                                          setVerificationDetails((prev) => ({
                                            ...prev,
                                            email: values.form_addresses[0].permanent_email,
                                          }))
                                          otpVerification('email_verification')
                                        }}
                                        className='btn btn-primary'
                                      >
                                        Verify Email
                                      </button>
                                    ) : (
                                      <div className='heading__component__title fw-bolder mb-5'>
                                        Email Address Verified
                                      </div>
                                    )}
                                  </div>
                                )}
                                {values.form_addresses[0].permanent_mobile_number &&
                                  !isBothVerified && (
                                    <div className='col-md-4 mb-2'>
                                      {!isNumberVerified ? (
                                        <button
                                          type='button'
                                          onClick={() => {
                                            setVerificationDetails((prev) => ({
                                              ...prev,
                                              mobile_number:
                                                values.form_addresses[0].permanent_mobile_number,
                                            }))
                                            otpVerification('mobile_verification')
                                          }}
                                          className='btn btn-primary'
                                        >
                                          Verify Mobile Number
                                        </button>
                                      ) : (
                                        <div className='heading__component__title fw-bolder mb-5'>
                                          Mobile Number Verified
                                        </div>
                                      )}
                                    </div>
                                  )}
                                {values.form_addresses[0].permanent_email &&
                                  values.form_addresses[0].permanent_mobile_number &&
                                  !isBothVerified && (
                                    <div className='col-md-4 mb-2'>
                                      <button
                                        type='button'
                                        onClick={() => {
                                          setVerificationDetails((prev) => ({
                                            ...prev,
                                            email: values.form_addresses[0].permanent_email,
                                            mobile_number:
                                              values.form_addresses[0].permanent_mobile_number,
                                          }))
                                          otpVerification('both')
                                        }}
                                        className='btn btn-primary'
                                      >
                                        Verify Both
                                      </button>
                                    </div>
                                  )}
                                {isBothVerified && (
                                  <div className='col-12 text-center'>
                                    <div className='heading__component__title fw-bolder mb-5'>
                                      Both Email and Mobile Number Verified
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </>

                          <div className='card-footer text-end'>
                            <button
                              type='button'
                              className='btn btn-secondary me-2'
                              onClick={handleBack}
                            >
                              Back
                            </button>

                            <button
                              type='submit'
                              className='btn btn-warning me-2'
                              disabled={isSubmitting || screeningPerformed === false || postLoading}
                              onClick={() => {
                                handleButtonClick('save_and_continue')
                              }}
                            >
                              {isSubmitting && btnFlag === 'save_and_continue'
                                ? 'Submitting...'
                                : 'Submit'}
                            </button>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={isSubmitting || postLoading}
                              onClick={() => handleButtonClick('save')}
                              style={{backgroundColor: `${COLOR?.PRIMARY_COLOR}`}}
                            >
                              {isSubmitting && btnFlag === 'save'
                                ? 'Saving as Draft...'
                                : 'Save as Draft'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Spin>
                )
              }}
            </Formik>
            <>
              <>
                {errormessage && errormessage.includes('deduplication') ? (
                  <div className='shadow p-3 bg-white rounded my-8'>
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}
                    >
                      <div className='heading__component__title fs-2 fw-bolder mb-2'>
                        Duplicate Account Found
                      </div>
                    </div>
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}
                    >
                      <div className='heading__component__title fs-6 fw-bolder mb-2'>
                        Found errors ({errors.length})
                      </div>
                    </div>
                    <div className='datatable'>
                      <Table data={errors} autoHeight height={400} wordWrap='break-word'>
                        <Column align='center' width={50}>
                          <HeaderCell>#</HeaderCell>
                          <Cell>{(_, rowIndex: any) => <span>{rowIndex + 1}</span>}</Cell>
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={100}>
                          <HeaderCell>Customer ID</HeaderCell>
                          <Cell dataKey='CUSTID' />
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={150}>
                          <HeaderCell>Customer Name</HeaderCell>
                          <Cell dataKey='CUSTNAME' />
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={150}>
                          <HeaderCell>Citizenship Number</HeaderCell>
                          <Cell dataKey='CTZNUM' />
                        </Column>
                      </Table>
                    </div>
                  </div>
                ) : errormessage && errormessage.includes('blacklist') ? (
                  <div className='shadow p-3 bg-white rounded my-8'>
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}
                    >
                      <div className='heading__component__title fs-2 fw-bolder mb-2'>
                        Blackisting Account Found
                      </div>
                    </div>
                    <div
                      style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}
                    >
                      <div className='heading__component__title fs-6 fw-bolder mb-2'>
                        Found errors ({errors.length})
                      </div>
                    </div>
                    <div className='datatable'>
                      <Table data={errors} autoHeight height={400} wordWrap='break-word'>
                        <Column align='center' width={50}>
                          <HeaderCell>#</HeaderCell>
                          <Cell>{(_, rowIndex: any) => <span>{rowIndex + 1}</span>}</Cell>
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={100}>
                          <HeaderCell>Customer Id Number</HeaderCell>
                          <Cell dataKey='CitizenshipNumber' />
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={150}>
                          <HeaderCell>Customer Name</HeaderCell>
                          <Cell dataKey='Name' />
                        </Column>

                        <Column align='left' flexGrow={1} minWidth={150}>
                          <HeaderCell>Blacklist Number</HeaderCell>
                          <Cell dataKey='BlacklistNumber' />
                        </Column>
                        <Column align='left' flexGrow={1} minWidth={150}>
                          <HeaderCell>Blacklist Types</HeaderCell>
                          <Cell dataKey='BlacklistType' />
                        </Column>
                      </Table>
                    </div>
                  </div>
                ) : null}
              </>
            </>
          </>
        )}
      </div>
    </>
  )
}

export default NormalIndividualJointMinorFormCIF
