import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from 'src/app/modules/auth'
import * as profile from 'src/app/modules/profileComponent'
import {globalErrorReducer} from 'src/app/modules/errors/redux/reducer'
import * as siteModule from 'src/app/modules/siteSettings/index'
// import * as siteModule from 'src/app/modules/siteSettings'

import * as userManagement from 'src/app/modules/userMgmt'
import * as userRole from 'src/app/modules/userMgmt/components/userRoles'
import * as departments from 'src/app/modules/userMgmt/components/departments'
import * as rolePermission from 'src/app/modules/common'
import * as cms from 'src/app/modules/cms'
import * as banner from 'src/app/modules/cms/components/banner/redux'
import * as network from 'src/app/modules/network'

import * as textPopup from 'src/app/modules/cms/components/textPopup/redux'
import * as roadBlockPopup from 'src/app/modules/cms/components/roadblockPopup/redux'
import * as mediaManager from 'src/app/modules/common/components/fileManager'
import * as faqCategory from 'src/app/modules/cms/components/faqCategory/redux'
import * as faqManager from 'src/app/modules/cms/components/faqManager/redux'
import * as faqIconType from 'src/app/modules/cms/components/faqCategory/faqIconType/redux'

import * as emailTemplate from 'src/app/modules/cms/components/emailTemplate/redux'
import * as formsDownload from 'src/app/modules/formsAndDownload'
import AccountTypeIDSlice from './SliceAccount'
import MasterDataSlice from './MasterDataOption'
import MasterDataAllSlice from './MasterAllSlice'
import ModalSlice from './modalSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  modalOpenSlice: ModalSlice,
  masterOptionDataZDPM: MasterDataAllSlice,
  accountTypeIDMaster: AccountTypeIDSlice,
  allMasterDataOptions: MasterDataSlice,
  globalError: globalErrorReducer,
  smtp: siteModule.smtp.reducer,
  key: siteModule.key.reducer,
  groupSetting: siteModule.groupSetting.reducer,
  settingType: siteModule.settings.reducer,
  userManagement: userManagement.reducer,
  module: siteModule.module.reducer,
  permission: rolePermission.reducer,
  place: rolePermission.placeReducer,
  categoryType: rolePermission.categoryTypeReducer,
  customerTestimonialMediaType: rolePermission.mediaTypeReducer,
  role: userManagement.roles.reducer,
  userRoles: userRole.reducer,
  departments: departments.reducer,
  profile: profile.reducer,
  menuManager: cms.menu.reducer,
  content: cms.content.reducer,

  //Branch
  branch: network.branch.reducer,
  manager: network.branchManager.reducer,

  //CMS
  banner: banner.reducer,

  emailTemplate: emailTemplate.reducer,
  textPopup: textPopup.reducer,
  roadBlockPopup: roadBlockPopup.reducer,
  faqCategory: faqCategory.reducer,
  faqManager: faqManager.reducer,

  mediaManager: mediaManager.reducer,
  faqIconType: faqIconType.reducer,

  //Forms and Download
  formsAndDownload: formsDownload.formAndDownload.reducer,
  formsAndDownloadCategory: formsDownload.formAndDownload.reducer1,

  //Contact Person
  contactPerson: siteModule.contactPerson.reducer,

  //Homepage Setting
  homepageSetting: siteModule.homepageSetting.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    siteModule.smtp.saga(),
    siteModule.key.saga(),
    siteModule.settings.saga(),
    siteModule.groupSetting.saga(),
    siteModule.module.saga(),
    userRole.saga(),
    departments.saga(),
    rolePermission.saga(),
    userManagement.saga(),
    userManagement.roles.saga(),
    profile.saga(),
    cms.menu.saga(),
    cms.content.saga(),

    banner.saga(),

    textPopup.saga(),
    roadBlockPopup.saga(),

    mediaManager.saga(),
    faqCategory.saga(),
    faqManager.saga(),
    faqIconType.saga(),

    //Loan
    emailTemplate.saga(),
    formsDownload.formAndDownload.saga(),

    //Network
    network.branch.saga(),
    network.branchManager.saga(),

    //Contact Person
    siteModule.contactPerson.saga(),

    //Homepage Setting
    siteModule.homepageSetting.saga(),
  ])
}
