import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {FiMenu} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {ISettingTypeState} from 'src/app/modules/siteSettings/components/settings'
import {useSelector} from 'react-redux'
import {isEmpty} from 'lodash'
import {imageBaseUrl} from 'src/cms/helpers/constants'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const [logo, setLogo] = useState('')

  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Theme settings']?.map((item: any) => {
          if (item?.name === 'mainLogo') {
            setLogo(item?.value)
          }
        })
    }
  }, [settingTypeData])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {/* {aside.display && ( */}
        <div className='d-flex align-items-center d-lg-nones ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_aside_mobile_toggle'
          >
            <FiMenu color='white' />
          </div>
        </div>
        {/* )} */}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}

        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                className='h-50px logo'
                src={
                  imageBaseUrl
                    ? `${imageBaseUrl}/${logo}`
                    : `https://offline.ebl.com.np/framework/${logo}`
                }
              />
            </Link>
          </div>
        )}
        {/* )} */}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                className='h-50px logo'
                src={
                  imageBaseUrl
                    ? `${imageBaseUrl}/${logo}`
                    : `https://offline.ebl.com.np/framework/${logo}`
                }
              />
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
