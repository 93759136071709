import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {NORMAL_NEW_CIF_NEW_ACCOUNT_CPU_CHECKER_REVIEW} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

const postCustomerEnquiryInfo = async (payload: any) => {
  try {
    const transformedPayload = {
      customer_id: payload.values ? payload.values : payload.cifNumber,
    }
    const response = await axios.post(`${BASE_API_URL}/customer-enquiry-info`, transformedPayload)
    return response
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostCustomerEnquiryInfo = (setEnquiryData: any) => {
  return useMutation(postCustomerEnquiryInfo, {
    onSuccess: (response) => {
      if (response) {
        setEnquiryData(response.data)
        toast.success(response?.data?.resDesc)
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostCustomerEnquiryInfo
