import moment from 'moment'
import {tuple} from 'rsuite/esm/@types/utils'
import * as Yup from 'yup'

// Function to check if a string is a valid UUID
const isValidUUID = (value: any) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(value)
}
const numericRegex = /^[,A-Za-z0-9./-\s]+$/

const isCountryNepal = (value: string) => value === '48691d0c-2489-4718-8596-10833123fce9'
const isAnyFieldFilled = (obj: any) =>
  !!(
    obj.saving_ac ||
    obj.current_ac ||
    obj.fixed_ac ||
    obj.working_capital ||
    obj.term_loan ||
    obj.others
  )

// const account_type_id = (values: any) => ''

export const CPUMAKER_SAVE_AND_CONTINUE = (allMasterDataOptions: any, notNeeded = false) =>
  Yup.object().shape({
    // const isNepalYes = allMasterDataOptions?.countries?.find(
    //   (country: any) => country?.title === "NEPAL")?.id
    first_name: Yup.string()
      .required('The first name is required.')
      .matches(
        /^[,A-Za-z0-9./-\s]+$/,
        'The first name should not contain any numbers or special characters.'
      ),
    middle_name: Yup.string().matches(
      /^[,A-Za-z0-9./-\s]+$/,
      'The middle name should not contain any numbers or special characters.'
    ),
    last_name: Yup.string().matches(
      /^[A-Za-z.\s]+$/,
      'The last name should only contain alphabets and dots.'
    ),
    salutation: Yup.string().required('Salutation is required.'),
    total_annual_income: Yup.string().required('Total annual income is required'),
    // expected_annual_income: Yup.number()
    //   .required('Expected Annual Turnover is required')
    //   .min(0, 'Expected Annual Turnover cannot be negative')
    //   .typeError('Must be a numeric label.'),
    // spouse_name: Yup.string().when('marital_status', {
    //   is: (value: string) => {
    //     const selectedMarriage = allMasterDataOptions?.marital_statuses?.find(
    //       (mariage: any) => mariage?.id === value
    //     )
    //     return selectedMarriage?.title === 'MARRIED'
    //   },
    //   then: Yup.string()
    //     .required('Spouse name is required when married.')
    //     .matches(/^[,A-Za-z0-9./-\s]+$/, 'Should not contain any numbers or special characters.'),
    //   otherwise: Yup.string().nullable(),
    // }),
    // //   is: (value: string) => {
    // //     const selectedCountry = allMasterDataOptions?.countries?.find(
    // //       (country: any) => country?.id === value
    // //     )
    // //     return selectedCountry?.title === 'NEPAL'
    // //   },
    // //   then: Yup.string().required('Province is required.'),
    // //   otherwise: Yup.string().nullable(),
    // // }),
    // father_in_law: Yup.string().when('marital_status', {
    //   is: (value: string) => {
    //     const selectedMarriage = allMasterDataOptions?.marital_statuses?.find(
    //       (mariage: any) => mariage?.id === value
    //     )
    //     return selectedMarriage?.title === 'MARRIED'
    //   },
    //   then: Yup.string().matches(
    //     /^[,A-Za-z0-9./-\s]+$/,
    //     'Should not contain any numbers or special characters.'
    //   ),
    //   otherwise: Yup.string().nullable(),
    // }),
    other_occupation: Yup.string().when('occupation_id', {
      is: (occupation_id: string) => {
        const otherOccupation = allMasterDataOptions?.occupations?.find(
          (income: any) => income?.code === '5'
        )
        return occupation_id === otherOccupation?.id
      },
      then: Yup.string().required('Other occupation details is required.'),
      otherwise: Yup.string().nullable(),
    }),
    other_income_source: Yup.string().when('income_source_id', {
      is: (income_source_id: string) => {
        const incomeSource = allMasterDataOptions?.income_sources?.find(
          (income: any) => income?.code === 'O'
        )
        return income_source_id === incomeSource?.id
      },
      then: Yup.string().required('Other income source details is required.'),
      otherwise: Yup.string().nullable(),
    }),

    id_issued_date: Yup.string().required('The ID Issue date (BS) is required.'),
    id_issued_date_ad: Yup.string()
      .required('The ID Issue date (AD) is required.')
      .test('is-past-date', 'The ID Issue date must be a past date.', (value) => {
        return value ? moment(value, 'YYYY-MM-DD').isBefore(moment(), 'day') : true
      }),
    // id_expiry_date: Yup.string().when('id_type_id', {
    //   is: (value: any) => {
    //     const selectedId = allMasterDataOptions?.id_types?.find(
    //       (idType: any) => idType?.id === value
    //     )
    //     return !(
    //       selectedId?.title.toLowerCase() == 'citizenship' ||
    //       selectedId?.title.toLowerCase() == 'pan card' ||
    //       selectedId?.title.toLowerCase() == 'birth certificate'
    //     )
    //   },
    //   then: Yup.string().required('ID Expiry Date is required'),
    //   otherwise: Yup.string().nullable(),
    // }),
    id_expiry_date_ad: Yup.string().when('id_type_id', {
      is: (value: any) => {
        const selectedId = allMasterDataOptions?.id_types?.find(
          (idType: any) => idType?.id === value
        )
        const title = selectedId?.title?.toLowerCase()

        if (title === 'citizenship' || title === 'pan card' || title === 'birth certificate') {
          return false // Expiry date should not be shown or required
        }

        if (title === 'driving license' || title === 'passport') {
          return true // Expiry date should be required
        }
      },
      then: Yup.string().required('Expiry Date (AD) is required.'),
      otherwise: Yup.string().nullable(),
    }),

    id_expiry_date: Yup.string().when('id_type_id', {
      is: (value: any) => {
        const selectedId = allMasterDataOptions?.id_types?.find(
          (idType: any) => idType?.id === value
        )
        const title = selectedId?.title?.toLowerCase()

        if (title === 'citizenship' || title === 'pan card' || title === 'birth certificate') {
          return false // Expiry date should not be shown or required
        }

        if (title === 'driving license' || title === 'passport') {
          return true // Expiry date should be required
        }
      },
      then: Yup.string().required('Expiry Date (BS) is required.'),
      otherwise: Yup.string().nullable(),
    }),
    issuing_district: Yup.string().required('The Issued District is required'),

    // id_expiry_date_ad: Yup.string().when('id_type_id', {
    //   is: (value: any) => {
    //     const selectedId = allMasterDataOptions?.id_types?.find(
    //       (idType: any) => idType?.id === value
    //     )
    //     return !(
    //       selectedId?.title.toLowerCase() == 'citizenship' ||
    //       selectedId?.title.toLowerCase() == 'pan card' ||
    //       selectedId?.title.toLowerCase() == 'birth certificate'
    //     )
    //   },
    //   then: Yup.string().required('ID Expiry Date (AD) is required'),
    //   otherwise: Yup.string().nullable(),
    // }),

    form_addresses: Yup.array().of(
      Yup.object().shape({
        permanent_country: Yup.string().required('The country is required.'),
        permanent_province: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Province is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_zone: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Zone is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_district: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('District is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_municipality: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Municipality is required.'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_ward_number: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string()
            .required('Ward Number is required.')
            .matches(/^[0-9]+$/, 'Ward Number must contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_house_number: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().matches(numericRegex, 'House Number must contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_street_name: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Street Name is required.'),
          // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Street Name should not contain numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_city: Yup.string().when('permanent_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('City is required.'),
          otherwise: Yup.string().nullable(),
        }),

        permanent_outside_country_post_box: Yup.string().when('permanent_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title !== 'NEPAL'
          },
          then: Yup.string()
            .required('Post Box No. is required')
            .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        permanent_outside_country_full_address: Yup.string().when('permanent_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title !== 'NEPAL'
          },
          then: Yup.string()
            .required('Foreign Address is required')
            .matches(
              /^[.,a-zA-Z /\\_-]+$/,
              'Foreign Address name should contain only letters and spaces'
            ),
          otherwise: Yup.string().nullable(),
        }),
        permanent_email: Yup.string().required('Email is required.'),

        // permanent_mobile_number: Yup.string().when('permanent_country', {
        //   is: (value: string) => {
        //     let selectedCountry = allMasterDataOptions?.countries?.find(
        //       (country: any) => country?.id === value
        //     )
        //     return selectedCountry?.title === 'NEPAL'
        //   },
        //   then: Yup.string()
        //     .required('The mobile number is required.')
        //     .typeError('Must be a numeric value.')
        //     .matches(
        //       /^(98|97)\d{8}$/,
        //       'Mobile number must start with 98 or 97 and must be 10 digits'
        //     ),
        //     otherwise: Yup.string().nullable(),
        // }),
        // permanent_mobile_number: Yup.string().when('permanent_country', {
        //   is: (value: string) => {
        //     let selectedCountry = allMasterDataOptions?.countries?.find(
        //       (country: any) => country?.id === value
        //     )
        //     return selectedCountry?.title === 'NEPAL'
        //   },
        //   then: Yup.string()
        //     .required('The mobile number is required.')
        //     .typeError('Must be a numeric value or dots.')
        //     .matches(
        //       /^((98|97)\d{8}|\.)$/,
        //       'Mobile number must start with 98 or 97 and be 10 digits, or it can be dots (.)'
        //     ),
        //   otherwise: Yup.string().nullable(),
        // }),
        permanent_mobile_number: Yup.string()
          .required('The mobile number is required.')
          .test(
            'dots-or-mobile',
            'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
            (value) => {
              if (!value) return false

              if (/^\.+$/.test(value) && value.length > 0) {
                return true
              }

              const mobileNumberRegex = /^(98|97)\d{8}$/
              return mobileNumberRegex.test(value)
            }
          ),
        present_country: Yup.string().required('The permanent country field is required.'),
        present_province: Yup.string().when('present_country', {
          is: isCountryNepal,
          then: Yup.string().required('Province is required.'),
          otherwise: Yup.string().nullable(),
        }),
        address_type: Yup.string().required('Address Type is Required'),
        present_zone: Yup.string().when('present_country', {
          is: isCountryNepal,
          then: Yup.string().required('Zone is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_district: Yup.string().when('present_country', {
          is: isCountryNepal,
          then: Yup.string().required('District is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_municipality: Yup.string().when('present_country', {
          is: isCountryNepal,
          then: Yup.string().required('Municipality is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_city: Yup.string().when('present_country', {
          is: isCountryNepal,
          then: Yup.string().required('Permanent City is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_ward_number: Yup.string().required('Ward is required.'),
        // .required('The permanent ward number is required.'),
        present_email: Yup.string().required('Email is required.'),
        // present_outside_country_full_address: Yup.string()
        //   .required('The full address is required.')
        //   .nullable(),
        // present_outside_country_post_box: Yup.string()
        //   .required('The post box No. is required.')
        //   .nullable(),
        present_outside_country_full_address: Yup.string().when('present_country', {
          is: !isCountryNepal,
          then: Yup.string().required('The full address is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_outside_country_post_box: Yup.string().when('present_country', {
          is: !isCountryNepal,
          then: Yup.string().required('The post box No. is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_house_number: Yup.string(),
        // .required('The permanent house number is required.'),
        // present_mobile_number: Yup.string()
        //   // .required('The permanent mobile number is required.')
        //   .matches(/^(98|97)\d{8}$/, 'Mobile number must start with 98 or 97 and must be 10 digits')
        //   .typeError('Must be a numeric value.'),
        present_mobile_number: Yup.string()
          .required('The mobile number is required.')
          .test(
            'dots-or-mobile',
            'Mobile number must start with 98 or 97 and be 10 digits, or it must consist dot (.)',
            (value) => {
              if (!value) return false

              if (/^\.+$/.test(value) && value.length > 0) {
                return true
              }

              const mobileNumberRegex = /^(98|97)\d{8}$/
              return mobileNumberRegex.test(value)
            }
          ),
        present_street_name: Yup.string()
          .when('present_country', {
            is: isCountryNepal,
            then: Yup.string().required('Street Name is required.'),
            otherwise: Yup.string().nullable(),
          })
          .max(50, 'The Present Street Name must not exceed 50 characters.')
          .matches(/^[.,a-zA-Z0-9 /\\_-]+$/, 'This Field should only contain alphabets,[./,-]'),
        // present_street_name: Yup.string()
        //   .required('The Present Street Name is required.')
        //   .matches(
        //     /^[A-Za-z\s\-\/]+$/,
        //     'This Field should only contain alphabets,[./,-]'
        //   )
        //   .max(50, 'The Present Street Name must not exceed 50 characters.'),
        // present_fax_number: Yup.string()
        //   .matches(/^\d+$/, 'The Present Fax Number should only contain digits.')
        //   .max(10, 'The Present Fax Number must not exceed 50 digits.'),
        present_fax_number: Yup.string()
          .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
          .max(10, 'The Permanent Fax Number must not exceed 50 digits.'),
      })
    ),
    branch_code: Yup.string()
      .max(6, 'Branch code must be 6 digits long.')
      .min(3, 'Branch code must be atleast 3 digits'),
    gender: Yup.string().required('The gender is required.'),
    marital_status: Yup.string().required('The marital status is required.'),
    date_of_birth_ad: Yup.date().when('nationality_id', {
      is: (nationality_id: string) => {
        const nepaliNationality = allMasterDataOptions?.nationalities?.find(
          (national: any) => national?.code === 'NP'
        )
        return nationality_id === nepaliNationality?.id
      },
      then: Yup.date().required('Date of Birth (AD) is required when nationality is Nepal'),
      otherwise: Yup.date().nullable(),
    }),
    date_of_birth_bs: Yup.date().when('nationality_id', {
      is: (nationality_id: string) => {
        const nepaliNationality = allMasterDataOptions?.nationalities?.find(
          (national: any) => national?.code === 'NP'
        )
        return nationality_id === nepaliNationality?.id
      },
      then: Yup.date().required('Date of Birth (BS) is required when nationality is Nepal'),
      otherwise: Yup.date().nullable(),
    }),
    id_type_id: Yup.string().required('The ID type  is required.'),
    identification_number: Yup.string().required('The identification number is required.'),
    national_id_number: Yup.string().matches(
      /^[a-zA-Z0-9\-\/\\_]+$/,
      'The National ID number must be a number.'
    ),
    // father_name: Yup.string()
    //   .required('The father name is required.')
    //   .matches(
    //     /^[,A-Za-z0-9./-\s]+$/,
    //     'The father name should not contain any numbers or special characters.'
    //   ),
    // grandfather_name: Yup.string()
    //   .required('The grandfather name is required.')
    //   .matches(
    //     /^[,A-Za-z0-9./-\s]+$/,
    //     'The grandfather name should not contain any numbers or special characters.'
    //   ),
    expected_annual_transaction_amount: Yup.number()
      .required('Expected Annual Transaction In Amount is required')
      .min(0, 'Expected Annual Transaction In Amount cannot be negative')
      .typeError('Must be a numeric label.'),
    occupation_id: Yup.string().required('The occupation is required.'),
    income_source_id: Yup.string().required('The income source  is required.'),

    sub_risk_category_id: Yup.string().required('Risk Sub Rating is required'),
    account_scheme_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Scheme is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),

    account_category_id: Yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: Yup.string().required('Account Category is required'),
      otherwise: Yup.string().nullable(), // Skip validation when notNeeded is true
    }),
    // account_type_id: Yup.string().required('The account type is required.'),

    account_type_id: Yup.string().required('The account type is required.'),
    account_holders: Yup.array().of(
      Yup.object().shape({
        cif_number: Yup.string().when('$account_type_id', (account_type_id, schema) => {
          const selectedAccType = allMasterDataOptions?.account_types?.find(
            (accType: any) => accType?.id === account_type_id
          )
          if (selectedAccType?.title === 'Joint') {
            return schema
              .required('CIF Number is required for Joint Account')
              .matches(/^\d{9}$/, 'CIF Number must contain exactly 9 digits')
          }
          return schema.nullable()
        }),
        first_name: Yup.string().when('$account_type_id', (account_type_id, schema) => {
          const selectedAccType = allMasterDataOptions?.account_types?.find(
            (accType: any) => accType?.id === account_type_id
          )
          if (selectedAccType?.title === 'Joint') {
            return schema
              .required('First Name is required for Joint Account')
              .matches(/^[,A-Za-z0-9./-\s]+$/, 'First Name cannot contain numbers')
          }
          return schema.nullable()
        }),

        last_name: Yup.string().when('$account_type_id', (account_type_id, schema) => {
          const selectedAccType = allMasterDataOptions?.account_types?.find(
            (accType: any) => accType?.id === account_type_id
          )
          if (selectedAccType?.title === 'Joint') {
            return schema
              .required('Last Name is required for Joint Account')
              .matches(/^[,A-Za-z0-9./-\s]+$/, 'Last Name cannot contain numbers')
          }
          return schema.nullable()
        }),
        id_type_id: Yup.string().when('$account_type_id', (account_type_id, schema) => {
          const selectedAccType = allMasterDataOptions?.account_types?.find(
            (accType: any) => accType?.id === account_type_id
          )
          if (selectedAccType?.title === 'Joint') {
            return schema.required('Partner Identification is required for Joint Account')
          }
          return schema.nullable()
        }),
        id_number: Yup.string().when('$account_type_id', (account_type_id, schema) => {
          const selectedAccType = allMasterDataOptions?.account_types?.find(
            (accType: any) => accType?.id === account_type_id
          )
          if (selectedAccType?.title === 'Joint') {
            return schema.required('Identification Number is required for Joint Account')
          }
          return schema.nullable()
        }),
      })
    ),

    businesses: Yup.array()
      .of(
        Yup.object().shape({
          name_of_institution: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Name should not contain any numbers')
            .required('Name of institution is required.'),
          address: Yup.string().required('Address is required.'),
          telephone_number: Yup.string().matches(/^[0-9,]+$/, 'Telephone number must be a number'),
          designation: Yup.string().required('Designation is required.'),
          email: Yup.string().email('Invalid email address'),
          expected_annual_income: Yup.string().required('Expected annual income is required.'),
        })
      )
      .when('income_source_id', {
        is: (income_source_id: any) =>
          allMasterDataOptions?.income_sources?.some((source: {id: string; title: string}) => {
            console.log(source.title)
            console.log(income_source_id)
            return (
              source.id === income_source_id?.id &&
              (source.title.toLowerCase() === 'salary' ||
                source.title.toLowerCase() === 'business income')
            )
          }),
        then: Yup.array()
          .of(
            Yup.object().shape({
              name_of_institution: Yup.string()
                .matches(/^[A-Za-z\s]+$/, 'Name should not contain any numbers')
                .required('Name of institution is required.'),
              address: Yup.string().required('Address is required.'),
              telephone_number: Yup.string().matches(
                /^[0-9,]+$/,
                'Telephone number must be a number'
              ),
              designation: Yup.string().required('Designation is required.'),
              email: Yup.string().email('Invalid email address'),
              expected_annual_income: Yup.string().required('Expected annual income is required.'),
            })
          )
          .required('Businesses are required.'),
        otherwise: Yup.array().notRequired(),
      }),

    issuing_office: Yup.string().required('The issuing office is required.'),

    family_details: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
          .max(50, 'Name must not exceed 50 characters.')
          .required('Family name are required.'),

        relationship_id: Yup.string().required('Family name are required.'),

        // profession: Yup.string().required('Other Account Purpose is required.'),
      })
    ),

    other_banks: Yup.array().of(
      Yup.object().shape({
        bank_name: Yup.string()
          .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
          .max(50, 'Name must not exceed 50 characters.')
          .required('Bank Name is required.'),
        saving_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Saving Account should contain only letters and spaces')
          .max(50, 'Saving Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {current_ac, fixed_ac, working_capital, term_loan, others} = this.parent
            return value || current_ac || fixed_ac || working_capital || term_loan || others
          }),
        current_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Current Account should contain only letters and spaces')
          .max(50, 'Current Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, fixed_ac, working_capital, term_loan, others} = this.parent
            return value || saving_ac || fixed_ac || working_capital || term_loan || others
          }),
        fixed_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Fixed Account should contain only letters and spaces')
          .max(50, 'Fixed Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, working_capital, term_loan, others} = this.parent
            return value || saving_ac || current_ac || working_capital || term_loan || others
          }),
        working_capital: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Working Capital should contain only letters and spaces')
          .max(50, 'Working Capital cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, term_loan, others} = this.parent
            return value || saving_ac || current_ac || fixed_ac || term_loan || others
          }),
        term_loan: Yup.string()
          .matches(/^[0-9,a-zA-Z _-]+$/, 'Term Loan should contain only letters and spaces')
          .max(50, 'Term Loan cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, working_capital, others} = this.parent
            return value || saving_ac || current_ac || fixed_ac || working_capital || others
          }),
        others: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Other should contain only letters and spaces')
          .max(50, 'Other cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, working_capital, term_loan} = this.parent
            return value || saving_ac || current_ac || fixed_ac || working_capital || term_loan
          }),
      })
    ),
    other_account_purpose: Yup.string().when('account_purpose_id', {
      is: '9682d56b-9d08-4ea5-a0b9-e72a5df0397a',
      then: Yup.string()
        .required('Other Account Purpose is required.')
        .matches(/^[^\d]*$/, 'Purposes must not contain any numbers.'),
      otherwise: Yup.string().notRequired(),
    }),
    expected_annual_transaction_number: Yup.string().required(
      'Expected Annual Transaction Number is required.'
    ),
    remarks: Yup.string().required('Remarks is required.'),
    pep_status: Yup.string().required('Pep Status is required.'),
    pep_relationship: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Relationship is required'),
    }),
    pep_position: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Postion is required.'),
    }),
    pep_retirement_date: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP retirement date is required.'),
    }),
    beneficial_owner: Yup.string().required('Beneficial Owner is required.'),
    beneficial_owner_name: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string()
        .required('Beneficial Owner Name is required.')
        .matches(
          /^[A-Za-z\s]+$/,
          'Beneficial Owner Name must contain only alphabetic characters and spaces.'
        ),
      otherwise: Yup.string().notRequired(),
    }),
    beneficial_owner_occupation: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string().required('Beneficial Occupation is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    beneficial_owner_relation: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string().required('Beneficial Owner Relation is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    pan_number: Yup.string()
      // .required('The PAN number is required.')
      .matches(/^\d{9}$/, 'PAN number must be exactly 9 digits and should not have characters.')
      .max(9, 'PAN number must be 9 characters long.'),
    // pan_registration_date: Yup.string().required('The PAN registration date is required.'),
    visa_issue_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Issue Date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    visa_expiry_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Expiry Date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    visa_arrival_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Arrival Date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    work_permit_id: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit ID is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    work_permit_issued_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit issued date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    work_permit_expiry_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit expiry date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    landlord_mobile_number: Yup.string(), // Ensures only numeric characters
    crime_details: Yup.string().when('crime_conviction', {
      is: true,
      then: Yup.string().required('Crime Details are required.'),
      otherwise: Yup.string().notRequired(),
    }),
    permit_details: Yup.string().when('residential_permit_foreign_country', {
      is: true,
      then: Yup.string().required('Permit Details are required.'),
      otherwise: Yup.string().notRequired(),
    }),
    blacklist_release_date: Yup.string().when('blacklisting_inclusion', {
      is: true,
      then: Yup.string().required('Blacklist Release Date is required.'),
      otherwise: Yup.string().notRequired(),
    }),
    aml_risk_rating: Yup.string().required('AML Risk Rating is required.'),
    facta_declarations: Yup.array().of(
      Yup.object().shape({
        us_person: Yup.string()
          .trim()
          .required('US Person is required')
          .notOneOf([''], 'US Person is required'), // Ensures empty string triggers validation
        us_indicia: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string()
            .trim()
            .required('US Indicia is required')
            .notOneOf([''], 'US Indicia is required'),
          otherwise: Yup.string().notRequired(),
        }),
        us_citizenship: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Citizenship is required'),
          otherwise: Yup.string().notRequired(),
        }),
        us_birth_country: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Birth Country is required'),
          otherwise: Yup.string().notRequired(),
        }),
        us_residence: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Residence is required'),
          otherwise: Yup.string().notRequired(),
        }),
        tin_number: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('TIN Number is required'),
          otherwise: Yup.string().notRequired(),
        }),
        w9_form_submitted: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('W9 Form Submitted is required'),
          otherwise: Yup.string().notRequired(),
        }),
        us_telephone_number: Yup.string()
          .matches(/^[0-9,]+$/, 'US telephone number must only be numbers.')
          .max(10, 'US telephone number must not exceed 10 digits.')
          .when('us_person', {
            is: 'Yes',
            then: Yup.string().required('US telephone number is required.'),
            otherwise: Yup.string().notRequired(),
          }),
        joint_us_person: Yup.string().when('account_type_id', {
          is: 'Joint',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_us_indicia: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_us_citizenship: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_us_birth_country: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_us_residence: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_tin_number: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_w9_form_submitted: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().notRequired(),
        }),
        joint_us_telephone_number: Yup.string()
          .matches(/^[0-9,]+$/, 'Joint US telephone number must only be numbers.')
          .max(10, 'Joint US telephone number must not exceed 10 digits.')
          .when(['account_type_id', 'joint_us_person'], {
            is: (account_type_id: any, joint_us_person: any) =>
              account_type_id === 'Joint' && joint_us_person === 'Yes',
            then: Yup.string().required('Required for joint account.'),
            otherwise: Yup.string().notRequired(),
          }),
      })
    ),

    // total_annual_income: Yup.string()
    //   .required('The total annual income is required.')
    //   .matches(/^\d+$/, 'Total annual income must be a number.')
    //   .test(
    //     'max',
    //     'Total annual income must not exceed 1,000,000,000',
    //     (value) => !value || parseInt(value) <= 100000000000000
    //   ),

    // expected_monthly_income: Yup.string()
    //   .required('The expected annual turnover is required.')
    //   .matches(/^\d+$/, 'Expected annual turnover must be a number.')
    //   .test(
    //     'max',
    //     'Expected annual turnover must not exceed 1,000,000,000',
    //     (value) => !value || parseInt(value) <= 10000000000000000
    //   ),
    landlord_address: Yup.string(),
    landlord_name: Yup.string().matches(
      /^[,a-zA-Z _-]+$/,
      'Landlord Name Should only contain Characters'
    ),
    religion_id: Yup.string().required('The religion field is required.'),
    education_id: Yup.string().required('The education field is required.'),
    account_purpose_id: Yup.string().required('The account purpose field is required.'),
    // customer_category_id: Yup.string().required('The customer category field is required.'),
    has_existing_account: Yup.boolean().required('This field is required'),

    existing_account_number: Yup.string().when('has_existing_account', {
      is: true,
      then: Yup.string()
        .matches(/^\d+$/, 'Account number can only contain numbers')
        .required('Account number is required')
        .length(14, 'Account number must be exactly 14 digits long'),
      otherwise: Yup.string().nullable(),
    }),

    relation_with_existing_account: Yup.string().when('has_existing_account', {
      is: true,
      then: Yup.string().required('Relation with existing account is required'),
      otherwise: Yup.string().nullable(),
    }),
    constitution_id: Yup.string().required('Consititution Id is required'),

    bank_code_id: Yup.string().required('Bank Code is required'),

    customer_group_id: Yup.string().required('Customer Group is required'),

    customer_status_id: Yup.string().required('Customer Status is required'),

    // customer_free_text: Yup.string()
    //   .required('Customer Status Details are required')
    //   .max(20, 'Customer Status Details cannot be longer than 20 characters'),
    customer_free_text: Yup.string().required('Customer Status Details is required'),
    cdd_edd_date: Yup.date()
      .required('CDD & EDD date is required')
      .typeError('Invalid date format'),

    annual_turn_over_as_on: Yup.date()
      .required('Annual Turnover As On date is required')
      .typeError('Invalid date format'),
  })

export const CPUMAKER_SAVE = Yup.object().shape({
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_province: Yup.string().required('The permanent province is required.'),
      present_zone: Yup.string().required('The permanent zone is required.'),
      present_district: Yup.string().required('The permanent district is required.'),
      present_municipality: Yup.string().required('The permanent municipality is required.'),
      present_street_name: Yup.string()
        .required('The Present Street Name is required.')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'This Field should only contain alphabets,[./,-]')
        .max(50, 'The Present Street Name must not exceed 50 characters.'),
      // present_street_name: Yup.string()
      //   .required('The Present Street Name is required.')
      //   .matches(/^[.,a-zA-Z /\\_-]+$/, 'This Field should only contain alphabets,[./,-]')
      //   .max(50, 'The Present Street Name must not exceed 50 characters.'),
      present_fax_number: Yup.string()
        .matches(/^\d+$/, 'The Present Fax Number should only contain digits.')
        .max(50, 'The Present Fax Number must not exceed 50 digits.'),
      // present_fax_number: Yup.string()
      //   .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
      //   .max(50, 'The Permanent Fax Number must not exceed 50 digits.'),
    })
  ),
})
const alphabeticRegex = /^[,a-zA-Z _-]+$/

export const ACCOUNT_OPENING_FORM_VALIDATION_SAVE_AND_CONTINUE = Yup.object().shape({
  // certificate_issued_by:Yup.string().required('The certificate issued by must be provided.'),
  // minor_proof_of_guardianship:Yup.string().required('The minor proof of guardianship must be provided.'),
  // minor_certificate_number:Yup.string().required('The minor certificate number must be provided.'),
  account_holders: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string()
        .required('First Name is required')
        .max(50, 'First Name cannot exceed 50 characters')
        .nullable(),
      middle_name: Yup.string().max(50, 'Middle Name cannot exceed 50 characters').nullable(),
      last_name: Yup.string()
        // .required('Last Name is required')
        .max(50, 'Last Name cannot exceed 50 characters')
        .nullable(),
      // cif_number: Yup.string()
      //   .required('CIF Number is required')
      //   .matches(/^\d+$/, 'CIF Number must be numeric'),
      // id_number: Yup.string().required('ID Number is required'),
      // id_type_id: Yup.string().required('ID Type is required'),
    })
  ),
  ancillary_services: Yup.array().of(
    Yup.object().shape({
      status: Yup.string().required('Ancillaries Status is required.'),
      service_id: Yup.string().when('status', {
        is: 'Yes',
        then: Yup.string().required('Required.'),
      }),
      reason: Yup.string()
        .when('status', {
          is: 'No',
          then: Yup.string().required('Reason is required.'),
        })
        .nullable(),
    })
  ),
  // account_title: Yup.string()
  //   .required('The account title field is required.'),
  // account_type_id: Yup.string().required('The account title must be selected.'),
  nominee_first_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      .required('Nominee first name must be provided.')
      .matches(alphabeticRegex, 'Nominee first name must contain only alphabetic characters.'),
    otherwise: Yup.string().notRequired(),
  }),

  nominee_middle_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().matches(
      alphabeticRegex,
      'Nominee middle name must contain only alphabetic characters.'
    ),
    otherwise: Yup.string().notRequired(),
  }),

  nominee_last_name: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      // .required('Nominee last name must be provided.')
      .matches(alphabeticRegex, 'Nominee last name must contain only alphabetic characters.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_citizenship_number: Yup.string().when('nomination', {
    is: true,
    then: Yup.string()
      .required('Nominee identification number must be provided.')
      .matches(/^[0-9\/-]+$/, 'Nominee Citizenship number must be number.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_address: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee address must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_relationship_id: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee relation must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  nominee_place_of_issue: Yup.string().when('nomination', {
    is: true,
    then: Yup.string().required('Nominee place of issue must be provided.'),
    otherwise: Yup.string().notRequired(),
  }),
  introducer_first_name: Yup.string()
    // .required('Introducer first name must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'First name should only contain characters.'),
  introducer_last_name: Yup.string()
    // .required('Introducer last name must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'Last name should only contain characters.'),
  introducer_middle_name: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Middle name should only contain characters.'
  ),

  introducer_customer_id: Yup.string()
    // .required('Introducer customer ID must be provided.')
    .matches(/^\d{1,15}$/, 'Introducer customer ID must be exactly 15 digits and a number.'),
  introducer_address: Yup.string(),
  // .required('Introducer customer address must be provided.')
  introducer_confirmation_period: Yup.string()
    // .required('Introducer confirmation period must be provided.')
    .matches(/^\d{1,2}$/, 'Introducer confirmation period must be exactly 2 digits and a number.'),

  cheque_book_name: Yup.string()
    .required('Cheque book name must be provided.')
    .matches(
      /^[a-zA-Z _.\-\/]+$/,
      'Cheque Book Name should only contain letters, spaces, underscores, hyphens, or slashes.'
    )
    .max(70, 'Only up to 70 characters are allowed'),
  mode_of_operation: Yup.string().required('Mode of operation must be provided.'),
  loan_detail_id: Yup.string().required('Free Code 1 is required'),
  deposit_detail_id: Yup.string().required('Free Code 8 is required'),
  schedule_code_id: Yup.string().required('Free Code 9 is required'),
  account_scheme_id: Yup.string().required('Account Scheme is required'),
  blacklist_screening: Yup.boolean().required('Required'),
  sanction_screening: Yup.boolean().required('Required'),
  adverse_media: Yup.boolean(),
  duplication_screening: Yup.boolean().required('Required'),
  regulatory_block: Yup.boolean(),
  initial_deposit: Yup.string()
    .required('Initial deposit must be provided.')
    .matches(/^[0-9,.]+$/, 'Initial deposit must be a number.'),
  account_currency_id: Yup.string().required('The account currency must be selected.'),
  account_category_id: Yup.string().required('The account category must be provided.'),
})
