import React, {Suspense, useEffect, useState} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {GlobalStyles} from 'src/cms/layout/core/theme/globalStyles'
import {useTheme} from 'src/cms/layout/core/theme/useTheme'
import {ThemeProvider} from 'styled-components'
import {I18nProvider} from '../cms/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../cms/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {Helmet} from 'react-helmet-async'
import {ISettingTypeState} from './modules/siteSettings/components/settings'
import {useSelector} from 'react-redux'
import {isEmpty} from 'lodash'
import {imageBaseUrl} from 'src/cms/helpers/constants'
import IdleTimerContainer from 'src/cms/helpers/IdleTimerContainer'
import axios from 'axios'
import {IAuthState} from './modules/auth'
import {QueryClient, QueryClientProvider} from 'react-query'
import 'nepali-datepicker-reactjs/dist/index.css'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Worker} from '@react-pdf-viewer/core'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

type Props = {
  basename: string
}

const App: React.FC<Props> = () => {
  const {theme, themeLoaded = true} = useTheme()
  const [applicationName, setAplicationName] = useState('')
  const [favicon, setFavicon] = useState('')
  const settingTypeData: ISettingTypeState = useSelector((state: any) => state.settingType)
  const [selectedTheme, setSelectedTheme] = useState(theme)
  const authResp: IAuthState = useSelector((state: any) => state.auth)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 5,
        // staleTime: Infinity,
        retry: false,
        retryDelay: 300,
        refetchOnWindowFocus: false,
      },
    },
  })

  useEffect(() => {
    setSelectedTheme(theme)
  }, [themeLoaded])

  // useEffect(() => {
  //   //for google map api key
  //   if (!localStorage.getItem('map-key') && authResp?.accessToken) {
  //     axios.get(API_URL + '/frontend/key-setting?name=google_map_key').then((res) => {
  //       localStorage.setItem('map-key', res.data.data.key)
  //     })
  //   }
  // }, [authResp?.accessToken])

  useEffect(() => {
    if (!isEmpty(settingTypeData?.backendData)) {
      settingTypeData?.backendData &&
        settingTypeData?.backendData['Theme settings']?.map((item: any) => {
          if (item?.name === 'favicon') {
            setFavicon(item?.value)
          }
        })

      settingTypeData?.backendData &&
        settingTypeData?.backendData['Application Credentials']?.map((item: any) => {
          if (item?.name === 'applicationName') {
            setAplicationName(item?.value)
          }
        })
    }
  }, [settingTypeData])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Worker workerUrl='https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js'>
        <Helmet>
          <title>{applicationName || 'Admin Panel'}</title>
          <link rel='shortcut icon' href={`${imageBaseUrl}/${favicon}`} />
          <meta name='description' content={applicationName || 'Admin Panel'} />
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <I18nProvider>
              <LayoutProvider>
                <AuthInit>
                  {themeLoaded && (
                    <ThemeProvider theme={selectedTheme}>
                      <GlobalStyles />
                      <Routes />
                      <IdleTimerContainer />
                    </ThemeProvider>
                  )}
                </AuthInit>
              </LayoutProvider>
            </I18nProvider>
          </BrowserRouter>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Worker>
    </Suspense>
  )
}

export {App}
