import React from 'react'
import {Table} from 'rsuite'
import {IoClose} from 'react-icons/io5'

const {Column, HeaderCell, Cell} = Table

const CustomCell = ({rowData, dataKey, ...props}: any) => <Cell {...props}>{rowData[dataKey]}</Cell>

const RemarksCell = ({rowData, ...props}: any) => {
  const mainColumns = [
    'customer_Id',
    'customer_name',
    'father_name',
    'grandfather_name',
    'customer_group',
  ]
  const remarksData = Object.entries(rowData)
    .filter(([key]) => !mainColumns.includes(key))
    .map(([key, value]) => `${key}: ${value || 'None'}`)
    .join('\n')

  return (
    <Cell {...props} style={{whiteSpace: 'pre-wrap'}}>
      {remarksData}
    </Cell>
  )
}

const DuplicationCheck = ({data = []}: any) => {
  return (
    <div className='shadow p-3 bg-white rounded my-8'>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
        <div className='heading__component__title fs-2 fw-bolder mb-2'>Duplication Checklists</div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
        <div className='heading__component__title fs-6 fw-bolder mb-2'>
          {/* Found result({data?.data?.data?.duplication_lists?.length}) */}
          Found result({data?.data?.result?.duplication_lists?.length})
        </div>
      </div>
      <div className='datatable'>
        <Table
          // data={data?.data?.data?.duplication_lists}
          data={data?.data?.result?.duplication_lists || []}
          rowHeight={90}
          height={600}
          wordWrap='break-word'
        >
          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Customer Id</HeaderCell>
            <CustomCell dataKey='customer_id' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Name</HeaderCell>
            <CustomCell dataKey='customer_name' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Father Name</HeaderCell>
            <CustomCell dataKey='father_name' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Grand Father Name</HeaderCell>
            <CustomCell dataKey='grandfather_name' />
          </Column>

          <Column align='left' flexGrow={1} minWidth={100}>
            <HeaderCell>Customer Group</HeaderCell>
            <CustomCell dataKey='customer_group' />
          </Column>

          <Column align='left' flexGrow={2}>
            <HeaderCell>Remarks</HeaderCell>
            <RemarksCell dataKey='remarks' />
          </Column>
        </Table>
      </div>
    </div>
  )
}

export default DuplicationCheck
