import {FieldArray} from 'formik'
import {FaPlus} from 'react-icons/fa'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {useEffect} from 'react'

const FamilyDetailsForm = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  maritalStatus,
  gender,
}: any) => {
  const {allMasterDataOptions} = usePlaceMasterData({
    allMasterData: true,
  })

  useEffect(() => {
    // Pre-fill "Father" and "Grandfather" fields
    if (values?.family_details?.length === 0) {
      setFieldValue('family_details', [
        {
          name: '',
          profession: '',
          relationship_id: allMasterDataOptions?.relationships?.find(
            (relation: any) => relation.title === 'FATHER'
          )?.id,
        },
        {
          name: '',
          profession: '',
          relationship_id: allMasterDataOptions?.relationships?.find(
            (relation: any) => relation.title === 'GRAND FATHER'
          )?.id,
        },
      ])
    }
  
    // Add or update "Spouse" details based on marital status and gender
    if (
      allMasterDataOptions?.marital_statuses?.find(
        (maritialStatus: any) => maritialStatus.id === maritalStatus
      )?.title === 'MARRIED'
    ) {
      const existingSpouseIndex = values.family_details.findIndex(
        (detail: any) =>
          detail.relationship_id ===
          allMasterDataOptions?.relationships?.find((relation: any) =>
            gender === 'Male' ? relation.title === 'WIFE' : relation.title === 'HUSBAND'
          )?.id
      )

      const spouseRelationshipId = allMasterDataOptions?.relationships?.find((relation: any) =>
        gender === 'Male' ? relation.title === 'WIFE' : relation.title === 'HUSBAND'
      )?.id

      if (existingSpouseIndex === -1) {
        // If no "Spouse" entry exists, add one
        setFieldValue('family_details', [
          ...values.family_details,
          {
            name: '',
            profession: '',
            relationship_id: spouseRelationshipId,
          },
        ])
      }
    } else {
      // Remove "Spouse" details if marital status is not "Married"
      setFieldValue(
        'family_details',
        values.family_details.filter(
          (detail: any) =>
            detail.relationship_id !==
            allMasterDataOptions?.relationships?.find((relation: any) =>
              gender === 'Male' ? relation.title === 'WIFE' : relation.title === 'HUSBAND'
            )?.id
        )
      )
    }
  }, [maritalStatus, gender])

  return (
    <div className=''>
      <FieldArray name='family_details'>
        {({remove, push}) => (
          <div>
            {values?.family_details?.length > 0 ? (
              values?.family_details?.map((details: any, index: any) => (
                <div className='row mb-3' key={index}>
                  <div className='col-md-4'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Name'
                      uppercase={true}
                      name={`family_details.${index}.name`}
                      label='Name'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required
                      value={details?.name}
                      className='w-100 p-3 rounded-2'
                    />
                  </div>

                  <div className='col-md-4'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='Relationship'
                      name={`family_details.${index}.relationship_id`}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      arrValue={details?.relationship_id}
                      options={allMasterDataOptions?.relationships?.map((relations: any) => ({
                        value: relations.id,
                        label: relations.title || relations.label,
                      }))}
                      setFieldValue={setFieldValue}
                      required
                    />
                  </div>
                  <div className='col-md-4'>
                    <FormSelect
                      labelClassName='col-md-12'
                      containerClassName='col-lg-12'
                      placeholder='Select'
                      label='Profession'
                      name={`family_details.${index}.profession`}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                      arrValue={details?.profession}
                      options={allMasterDataOptions?.occupations?.map((occupation: any) => ({
                        value: occupation.id || occupation.value,
                        label: occupation.title || occupation.label,
                      }))}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {index >= 2 && ( // Allow removal only for additional entries
                    <div className='col-md-2 d-flex align-items-center'>
                      <button
                        type='button'
                        className='dt-btn dt-btn-primary'
                        data-cy='remove-button'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No family members added. Click 'Add Member' to start.</p>
            )}
            <button
              type='button'
              className='dt-btn dt-btn-primary'
              data-cy='add-button'
              onClick={() =>
                push({
                  name: '',
                  profession: '',
                  relationship_id: '',
                })
              }
            >
              <FaPlus className='me-2' />
              Add Member
            </button>
          </div>
        )}
      </FieldArray>
    </div>
  )
}

export default FamilyDetailsForm
