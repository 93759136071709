import * as yup from 'yup'

const numericRegex = /^[,A-Za-z0-9./-]+$/
const today: any = new Date()
today.setHours(0, 0, 0, 0)
export const CORPORATE_NORMAL_FORM_VALIDATION = (allMasterDataOptions: any, notNeeded = false) =>
  yup.object().shape({
    institution_name: yup
      .string()
      .required('The institution name field is required.')
      .matches(/^[A-Za-z\s-&./]+$/, 'The institution name should not contain any numbers.'),
    constitution_id: yup.string().required('The constitution field is required.'),

    account_scheme_id: yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: yup.string().required('Account Scheme is required'),
      otherwise: yup.string().nullable(), // Skip validation when notNeeded is true
    }),

    account_category_id: yup.string().when([], {
      is: () => !notNeeded, // If notNeeded is false, apply validation
      then: yup.string().required('Account Category is required'),
      otherwise: yup.string().nullable(), // Skip validation when notNeeded is true
    }),
    tax_type: yup.string().required('Tax type is required'),
    pan_number: yup.string().when('tax_type', {
      is: 'pan',
      then: yup
        .string()
        .required('PAN Number is required')
        .matches(/^[0-9]{9,}$/, 'PAN Number must be at least 9 digits and contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    vat_number: yup.string().when('tax_type', {
      is: 'vat',
      then: yup
        .string()
        .required('VAT Number is required')
        .matches(/^[0-9]{9,}$/, 'VAT Number must be at least 9 digits and contain only numbers'),
      otherwise: yup.string().nullable(),
    }),
    registered_office_mobile_number: yup
      .string()
      .required('The present mobile number is required.')
      .test(
        'dots-or-mobile',
        'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
        (value) => {
          if (!value) return false

          if (/^\.+$/.test(value) && value.length > 0) {
            return true
          }

          const mobileNumberRegex = /^(98|97)\d{8}$/
          return mobileNumberRegex.test(value)
        }
      ),

    registered_office_email: yup
      .string()
      .required('The registered office email field is required.'),
    registration_number: yup
      .string()
      .required('The registration Number field is required.')
      .max(30, 'Registration Number cannot exceed 20 characters')
      .matches(/^[.,0-9 /\\_-]+$/, 'Invalid format for Registration Number'),
  })

export const CORPORATE_CPU_FORM_VALIDATION = yup.object().shape({
  aml_risk_rating: yup.string().required('The AML risk rating  is required.'),
  sub_risk_category_id: yup.string().required('The AML risk rating  is required.'),
  remarks: yup.string().required('The Remark is required.'),
  bank_code_id: yup.string().required('Bank Code is required'),
  constitution_id: yup.string().required('The constitution field is required.'),

  // customer_category_id: yup.string().required('Customer Category is required'),

  customer_group_id: yup.string().required('Customer Group is required'),

  customer_status_id: yup.string().required('Customer Status is required'),

  customer_free_text: yup.string().required('Customer Status Details is required'),
  cdd_edd_date: yup.date().required('CDD & EDD date is required').typeError('Invalid date format'),
  annual_turn_over_as_on: yup
    .date()
    .required('Annual Turnover As On date is required')
    .typeError('Invalid date format'),
  share_holders: yup
    .array()
    .of(
      yup.object().shape({
        shareholder_name: yup
          .string()
          .when(
            [
              'ownership_percentage',
              'shareholder_address',
              'entity_name',
              'entity_ownership_percentage',
              'entity_address',
              'entity_registration_number',
              'entity_registration_office',
              'entity_incorporation_date',
              'entity_pan_number',
              'entity_vat_number',
              'entity_country',
              'entity_registration_address',
              'entity_business_address',
              'entity_phone_number',
              'entity_website',
            ],
            {
              is: (...fields: any) => fields.every((field: any) => !field || field === ''),
              then: yup.string().required('Fill at least one field'),
            }
          ),
        ownership_percentage: yup.number().nullable(),
        shareholder_address: yup.string().nullable(),
        entity_name: yup.string().nullable(),
        entity_ownership_percentage: yup.number().nullable(),
        entity_address: yup.string().nullable(),
        entity_registration_number: yup.string().nullable(),
        entity_registration_office: yup.string().nullable(),
        entity_incorporation_date: yup.date().nullable(),
        entity_pan_number: yup.string().nullable(),
        entity_vat_number: yup.string().nullable(),
        entity_country: yup.string().nullable(),
        entity_registration_address: yup.string().nullable(),
        entity_business_address: yup.string().nullable(),
        entity_phone_number: yup.string().nullable(),
        entity_website: yup.string().nullable(),
      })
    )
    .test('at-least-one', 'Fill at least one field for each shareholder', function (value: any) {
      return value.every((shareholder: any) =>
        Object.values(shareholder).some((field) => field !== null && field !== '')
      )
    }),
  other_banks: yup.array().of(
    yup.object().shape({
      bank_name: yup
        .string()
        .required('Bank Name is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
      saving_ac: yup
        .string()
        .required('Savings Account Number is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
      current_ac: yup
        .string()
        .required('Current Account Number is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
      od_ac: yup
        .string()
        .required('Overdraft Account Number is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
      term_loan: yup
        .string()
        .required('Term Loan Account Number is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
      others: yup
        .string()
        .required('Required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Entity name should contain only letters and spaces')
        .max(50, 'Registration Number cannot exceed 50 characters'),
    })
  ),
  directors: yup.array().of(
    yup.object().shape({
      full_name: yup
        .string()
        .required('Full Name is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Full Name should contain only letters'),
      designation: yup
        .string()
        .required('Designation is required')
        .matches(/^[.,a-zA-Z /\\_-]+$/, 'Designation should contain only letters'),
      cif_number: yup
        .string()
        // .required('Cif Number is required')
        .matches(/^[0-9]+$/, 'Cif Number should contain only number'),
    })
  ),
  facta_declarations: yup.array().of(
    yup.object().shape({
      us_person: yup.boolean().required('US Person is required'),
      us_person_remarks: yup
        .string()
        .required('Remarks is required')
        .matches(/^[,a-zA-Z_-]+$/, 'Remarks should contain only letters'),
      us_indicia_remarks: yup.string().when('us_person', {
        is: true,
        then: yup.string().required('US Indicia is required'),
      }),
      us_telephone_number: yup
        .string()
        .when('us_person', {
          is: true,
          then: yup.string().required('US Telephone Number is required'),
        })
        .matches(/^\d{10}$/, 'US Telephone Number must be 10 digits'),
      tin_number: yup.string().when('us_person', {
        is: true,
        then: yup.string().required('TIN Number is required'),
      }),
      giin_number_remarks: yup.string().when('giin_number', {
        is: true,
        then: yup.string().required('GIIN Number Remarks are required if GIIN Number is Yes'),
        otherwise: yup.string().notRequired(),
      }),
      w9_form_submitted: yup.string().when('us_person', {
        is: true,
        then: yup.string().required('W9 Form Submitted is required'),
      }),
    })
  ),

  blacklisting_inclusion: yup.boolean(),
  blacklist_release_date: yup.string().when('blacklisting_inclusion', {
    is: true,
    then: yup
      .string()
      .required('Release date is required')
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in the format YYYY-MM-DD'),
    otherwise: yup.string().notRequired(), // Optional if blacklisting_inclusion is false
  }),
})

export const ACCOUNT_OPENING_VALIDATION = yup.object().shape({
  account_category_id: yup
    .string()
    .required('The account category id field is required.')
    .uuid('Invalid UUID format for Account Category ID'),

  account_scheme_id: yup
    .string()
    .required('The Account Scheme id field is required.')
    .uuid('Invalid UUID format for Account Scheme ID'),

  initial_deposit: yup
    .string()
    .required('The Initial Deposit field is required.')
    .matches(/^[0-9 .,]+$/, 'Initial Deposit should contain only numbers'),

  account_currency_id: yup
    .string()
    .required('The account currency id field is required.')
    .uuid('Invalid UUID format for Account Currency ID'),
  account_holders: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .required('First Name is required')
        .max(50, 'First Name cannot exceed 50 characters'),
      middle_name: yup.string().max(50, 'Middle Name cannot exceed 50 characters'),
      last_name: yup
        .string()
        // .required('Last Name is required')
        .max(50, 'Last Name cannot exceed 50 characters'),
      // cif_number: yup.string()
      //   .required('CIF Number is required')
      //   .matches(/^\d+$/, 'CIF Number must be numeric'),
      // id_number: yup.string().required('ID Number is required'),
      // id_type_id: yup.string().required('ID Type is required'),
      account_relation_id: yup.string().required('Account Holder Identification is required'),
    })
  ),
  // account_title: yup
  //   .string()
  //   .required('The account title field is required.')
  //   .matches(/^[.,a-zA-Z /\\_-]+$/, 'Title should contain only letters')
  //   .max(100, 'Account title cannot exceed 100 characters'), // Adjust max length as needed

  cheque_book_name: yup
    .string()
    .required('The cheque book name field is required.')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'cheque book name should contain only letters')
    .max(100, 'Cheque book name cannot exceed 100 characters'), // Adjust max length as needed

  nomination: yup
    .boolean()
    .required('The Nomination field is required.')
    .oneOf([true, false], 'Invalid value for Nomination'),

  nominee_first_name: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The First Name field is required.')
      .max(50, 'First Name cannot exceed 50 characters')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'First name should contain only letters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_middle_name: yup
    .string()
    .nullable()
    .max(50, 'Middle Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Middle name should contain only letters'),
  nominee_last_name: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Last Name field is required.')
      .max(50, 'Last Name cannot exceed 50 characters')
      .matches(/^[.,a-zA-Z /\\_-]+$/, 'Last name should contain only letters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_citizenship_number: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Citizenship Number field is required.')
      .max(20, 'Citizenship Number cannot exceed 20 characters')
      .matches(
        /^[0-9\/-]+$/,
        'Citizenship Number can only contain numbers, hyphens (-), and slashes (/)'
      ),

    otherwise: yup.string().nullable(),
  }),

  nominee_address: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Address field is required.')
      .max(255, 'Address cannot exceed 255 characters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_place_of_issue: yup.string().when('nomination', {
    is: true,
    then: yup.string().nullable().max(255, 'Place of issue cannot exceed 255 characters'),
    otherwise: yup.string().nullable(),
  }),

  nominee_relationship_id: yup.string().when('nomination', {
    is: true,
    then: yup
      .string()
      .required('The Relation To Me field is required.')
      .max(255, 'Relation To Me cannot exceed 255 characters'),
    otherwise: yup.string().nullable(),
  }),

  introducer_first_name: yup
    .string()
    .required('The First Name field is required.')
    .max(50, 'First Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'First Name should contain only letters and spaces'),

  introducer_middle_name: yup
    .string()
    .nullable()
    .max(50, 'Middle Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Middle Name should contain only letters and spaces'),

  introducer_last_name: yup
    .string()
    .required('The Last Name field is required.')
    .max(50, 'Last Name cannot exceed 50 characters')
    .matches(/^[.,a-zA-Z /\\_-]+$/, 'Last Name should contain only letters and spaces'),

  introducer_customer_id: yup
    .string()
    .required('The Customer Id field is required.')
    .matches(/^[0-9\/-]+$/, 'Customer Id can only contain numbers, hyphens (-), and slashes (/)'),

  introducer_address: yup
    .string()
    .required('The Address field is required.')
    .max(255, 'Address cannot exceed 255 characters'),

  introducer_confirmation_period: yup
    .string()
    .nullable()
    .matches(/^\d{0,2}$/, 'Confirmation Period can only contain up to 2 digits')
    .test('maxDigits', 'Confirmation Period can only be up to 2 digits long', (value: any) => {
      return value === null || value === '' || (value && value.length <= 2)
    }),

  mode_of_operation: yup.string().required('The Operation field is required.'),
  loan_detail_id: yup.string().required('The Loan details is required.'),
  deposit_detail_id: yup.string().required('The Loan details is required.'),
  // blacklist_screening: yup
  //   .boolean()
  //   .oneOf([true], 'You must verify the blacklist screening result'),
  // sanction_screening: yup.boolean().oneOf([true], 'You must verify the sanction screening result'),
  // duplication_screening: yup
  //   .boolean()
  //   .oneOf([true], 'You must verify the duplicate screening result'),
  // adverse_media: yup.boolean().oneOf([true], 'You must verify the adverse media screening result'),
  // regulatory_block: yup.boolean().oneOf([true], 'You must verify the regulatory block result'),
})

export const CORPORATE_SAVE_DRAFT_NORMAL_FORM_VALIDATION = yup.object().shape({
  registered_office_city: yup.string().when('registered_office_country', {
    is: 'Nepal',
    then: yup
      .string()
      .required(
        'The registered office city is required when the registered office country is Nepal.'
      ),
  }),
  registered_office_district: yup
    .string()
    .required('The registered office district must be a string.'),
  registered_office_email: yup
    .string()

    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Email should be in "Username@email.com" format'
    ),
  registered_office_municipality: yup
    .string()
    .required('The registered office municipality must be a string.'),
  registered_office_province: yup
    .string()
    .required('The registered office province must be a string.'),
  registration_with_id: yup.string().required('The registration with ID is required.'),
  aml_risk_rating: yup.string().required('The AML risk rating  is required.'),
})
export const OPENING_ACCOUNT_TYPE = yup.object().shape({
  accountType: yup.string().required('Account Type is required'),
  customerType: yup.string().required('Customer Type is required'),
})
