import * as Yup from 'yup'

export const validationSchemaBranchMaker = Yup.object().shape({
  account_number: Yup.string()
    .required('The Account Number is required.')
    .matches(/^[0-9]+$/, 'Invalid Format.')
    .typeError('The Account Number must be a number.'),
    is_corporate:Yup.string().required('Required.')
})

export const validationSchemaIndividual = Yup.object().shape({
  linked_relations: Yup.array().of(
    Yup.object().shape({
      relation_cif_number: Yup.string().required('CIF number is required'),
      relationship_id: Yup.string().required('Relationship is required'),
      relation_name: Yup.string().required('Relation name is required'),
    })
  ).min(1, 'At least one linked relation is required')
});