import {adToBs} from '@sbmdkl/nepali-date-converter'
import {Form, Formik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Button, Spinner, Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostAccountPreliminaryBranchCheckeReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostIndividualBranchCheckerReview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import {REVIEW_VALIDATION_SCHEMA} from '../../../qa/utils/ValidationSchemas'
import {UserActivityLogAll} from '../../../../common/components/AllActivityLog'

interface RouteParams {
  id: string
}

const getStringValue = (value: any) => {
  if (typeof value === 'string') return value
  if (typeof value === 'number') return value.toString()
  if (value && typeof value === 'object' && 'title' in value) return value.title
  return ''
}

const NormalIndividualJointReview = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()

  const {
    mutate: branchCheckerPreliminaryReview,
    data: respData,
    isLoading: postRespLoading,
  } = usePostAccountPreliminaryBranchCheckeReview()

  const {data: preliminariesDetailsById, isLoading} = useGetPreliminariesDetailsById(id)

  const [allMasterDataOptions, setAllMasterDataOptions] = useState<any>(null)

  const {data: allMasterData} = useGetAllMasterData()
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [flag, setFlag] = useState<string>('default')
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])

  const [showPopup, setShowPopup] = useState(false) // State for popup visibility
  const [formValues, setFormValues] = useState<any>() // Store form values for submission
  const [postRespLoadings, setPostRespLoadings] = useState(false) // Simulate form submission loading

  const handleFormSubmit = (values: any) => {
    setFormValues(values) // Save form values
    setShowPopup(true) // Show popup dialog
  }

  const handleReject = () => {
    setShowPopup(false) // Close popup dialog
  }
  // const [enquiryData, setEnquiryData] = useState<any>()
  // const {mutate: customerEnquiry} = usePostCustomerEnquiryInfo(setEnquiryData)

  useEffect(() => {
    if (allMasterData) {
      setAllMasterDataOptions(allMasterData)
    }
  }, [allMasterData])

  const hadleBack = () => {
    history.go(-1)
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  const initialValues = preliminariesDetailsById?.data || {}
  const handleSubmit = () => {
    const payload = {
      ...formValues,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    branchCheckerPreliminaryReview(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }

  const handleScreening = async () => {
    setScreeningLoading(true) // Set screening loading to true

    update.current = 'default'
    const checkAllPayload: any = {
      customer_name: initialValues?.full_name,
      citizenship: initialValues?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      if (checkAllDataJoint && checkAllDataJoint[index]) {
        let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
        if (parts && parts.length > 1) {
          const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

          setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

          newPaths.forEach((path: any) => {
            window.open(path, '_blank')
          })
        }
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: any) => {
    ;('Enquiry FUnc')
    let checkAllPayload: any = {}
    if (update.current === 'minor') {
      const guardian_full_name: string = [
        initialValues?.guardian_first_name,
        initialValues?.guardian_middle_name,
        initialValues?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        date_of_birth_bs: initialValues?.date_of_birth_bs || '',
        unique_id_no: initialValues?.unique_id_no || '',
        citizenship: initialValues?.identification_number,
        gender: initialValues?.gender || '',
        model_name: 'NewCIFNewAccount',
      }

      await mutateCheckAll(checkAllPayload, {
        onSuccess: (data) => {
          setEnquiryLoading(false)
          setLoadingIndex(null)
        },
        onError: (error) => {
          console.error('Error:', error)
          setEnquiryLoading(false)
          setLoadingIndex(null)
          clickedIndex.shift()
        },
      })
    } else if (update.current === 'joint') {
      const partner = initialValues?.account_holders?.account_holders[index]

      const partners_full_name: string = [
        partner?.first_name,
        partner?.middle_name,
        partner?.last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: partners_full_name,
        date_of_birth_bs: partner?.date_of_birth_bs || '',
        unique_id_no: partner?.unique_id_no || '',
        citizenship: partner?.citizenship || '',
        gender: partner?.gender || '',
        model_name: 'NewCIFNewAccount',
      }

      await mutateCheckAll(checkAllPayload, {
        onSuccess: (data) => {
          setEnquiryLoading(false)
          setLoadingIndex(null)
        },
        onError: (error) => {
          console.error('Error:', error)
          setEnquiryLoading(false)
          setLoadingIndex(null)
          clickedIndex.shift()
        },
      })
    }
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }
  return (
    <div className='container'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <section className=''>
            <div className='row'>
              <h3 className='fw-bolder mb-4 text-capitalize'>Account Details</h3>

              {/* <h2 style={{color: 'brown'}}>Account Details</h2> */}
              <FormDetails
                key='screening_id'
                label='Screening Id'
                value={getStringValue(initialValues?.screening_id)}
              />
              <FormDetails
                key='account_type_id'
                label='Account Type'
                value={getStringValue(initialValues?.account_type_id?.cbs_code)}
              />
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={getStringValue(initialValues.branch_code)}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={getStringValue(initialValues.customer_code)}
              />
            </div>
          </section>
          <hr />
          <section className=''>
            <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
            <div className='row'>
              <FormDetails
                key='first_name'
                label='First Name'
                value={getStringValue(initialValues.first_name)}
              />
              <FormDetails
                key='middle_name'
                label='Middle Name'
                value={getStringValue(initialValues.middle_name)}
              />
              <FormDetails
                key='last_name'
                label='Last Name'
                value={getStringValue(initialValues.last_name)}
              />

              <FormDetails
                key='id_type_id'
                label='Identification Type'
                value={getStringValue(initialValues.id_type_id)}
              />
              <FormDetails
                key='identification_number'
                label='Identification Number'
                value={getStringValue(initialValues.identification_number)}
              />
              <FormDetails
                key='account_category_id'
                label='Account Category'
                value={getStringValue(initialValues?.account_opening?.account_category_id)}
              />
              <FormDetails
                key='account_Scheme_id'
                label='Account Scheme'
                value={getStringValue(initialValues?.account_opening?.account_scheme_id)}
              />
              <FormDetails
                key='permanent_mobile_number'
                label='Mobile Number'
                value={getStringValue(initialValues?.permanent_mobile_number)}
              />
              <FormDetails
                key='permanent_email'
                label='Email Address'
                value={getStringValue(initialValues?.permanent_email)}
              />
            </div>
          </section>
          <hr />
          {initialValues?.account_type_id?.title === 'Joint' && (
            <div>
              <section className=''>
                <h3 className='fw-bolder mb-4 text-capitalize'>Partner's Details</h3>
                <div className='row'>
                  {initialValues?.account_holders?.account_holders.map(
                    (holder: any, index: any) => (
                      <div key={index} className='row'>
                        <div>
                          <FormDetails
                            key={`cif_number_${index}`}
                            label='CIF Number'
                            value={holder.cif_number}
                          />

                          {clickedIndex.includes(index) ? (
                            loadingIndex === index ? (
                              <Spinner animation='border' role='status' size='sm'>
                                <span className='sr-only'>Loading...</span>
                              </Spinner>
                            ) : (
                              <Button className='mb-5 ms-3' onClick={() => handleOpenPdf2(index)}>
                                View PDF
                              </Button>
                            )
                          ) : (
                            <Button
                              className='mb-5'
                              onClick={() => handleButtonClick(holder?.cif_number, index)}
                            >
                              Enquiry
                            </Button>
                          )}
                        </div>
                        <FormDetails
                          key={`first_name_${index}`}
                          label='First Name'
                          value={holder.first_name}
                        />
                        <FormDetails
                          key={`middle_name_${index}`}
                          label='Middle Name'
                          value={holder.middle_name}
                        />
                        <FormDetails
                          key={`last_name_${index}`}
                          label='Last Name'
                          value={holder.last_name}
                        />
                        <FormDetails
                          key={`id_type_id_${index}`}
                          label='Identification Type'
                          value={holder.id_type_id?.title}
                        />
                        <FormDetails
                          key={`id_number_${index}`}
                          label='Identification Number'
                          value={holder.id_number}
                        />
                      </div>
                    )
                  )}
                </div>
              </section>
              <hr />
            </div>
          )}
          {screeningPerformed ? (
            checkAllData && (
              <Button type='button' onClick={handleOpenPdf} className='col-md-3 col-sm-6 col-xs-12'>
                View Details
              </Button>
            )
          ) : screeningLoading ? (
            <div>
              <Spinner animation='border' role='status'>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Button type='button' onClick={handleScreening} className='col-md-3 col-sm-6 col-xs-12'>
              Perform Screening
            </Button>
          )}

          <div className='my-7'>
            <h3>Screening Files</h3>
            {initialValues?.screening_files?.screening_files?.length > 0 ? (
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>File Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {initialValues?.screening_files?.screening_files?.map((file: any, index: any) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{file.file_name}</td>
                      <td>
                        <button
                          type='button'
                          onClick={() => handlePdfFileClick(file.file_path)}
                          className='btn btn-primary'
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No files available</p>
            )}
          </div>
        </div>
      </div>
      <div className='modal-container '>
        <div className='card'>
          <div className='card-body'>
            <Formik
              initialValues={{status: '', remarks: ''}}
              onSubmit={handleFormSubmit}
              validationSchema={REVIEW_VALIDATION_SCHEMA}
            >
              {({handleChange, errors, touched, values, setFieldValue}) => (
                <Form className='bg-white'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <FormSelect
                        labelClassName='col-md-12'
                        containerClassName='col-md-12'
                        label='Status Type'
                        name='status'
                        isClearable={true}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        options={[
                          {label: 'FORWARD TO BRANCH MAKER', value: 'approve'},
                          {label: 'REJECT', value: 'reject'},
                          {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
                        ]}
                        required={true}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <FormTextBox
                      containerClassName='col-md-12'
                      labelClassName='col-md-12'
                      type='text'
                      placeholder='Remarks'
                      label='Remarks'
                      name='remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      required={true}
                      as='textarea'
                    />
                  </div>
                  <div className='d-flex justify-content-end mt-3'>
                    <button type='button' onClick={hadleBack} className='btn btn-secondary mx-4'>
                      Back
                    </button>
                    <button type='submit' className='btn btn-primary' disabled={postRespLoading}>
                      {postRespLoading ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className='pt-10'>
        <AccountStatusLog data={initialValues} />
        <UserActivityLogAll
          caseId={id}
          modalName={preliminariesDetailsById?.data?.model_name}
          modalData={initialValues}
        />
      </div>

      <Modal show={showPopup} onHide={handleReject} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>I hereby declare that all the documents to be uploaded are checked.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleReject}>
            Reject
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NormalIndividualJointReview
