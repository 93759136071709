const FormDetails = ({label, value, color}: {label: string; value: string; color?: string}) => {
  return (
    <div className='col-md-4 col-12'>
      <span className='form-review-field fw-bolder fs-6 ' style={{color: color}}>
        {label}
      </span>
      <p className='' style={{color: color}}>
        {' '}
        {value || '-'}
      </p>
    </div>
  )
}

export default FormDetails
