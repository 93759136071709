import BackendSettings from '../modules/siteSettings/components/backendSettings/components'
import ContactSetting from '../modules/siteSettings/components/contactSettings/ContactSetting'
import KeySettings from '../modules/siteSettings/components/keySetting/components'
import ModuleManager from '../modules/siteSettings/components/moduleManager/components/ModuleManager'
import Settings from '../modules/siteSettings/components/settings/components'
import SmtpSettings from '../modules/siteSettings/components/smtpSetting/components'
import Departments from '../modules/userMgmt/components/departments/components'
import RoleManagementIndex from '../modules/userMgmt/components/roles/components/RoleMgmt'
import UserRoleManagementIndex from '../modules/userMgmt/components/userRoles/UserRole'
import UserManagementIndex from '../modules/userMgmt/components/users/components/users'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {RouteComponentProps} from 'react-router-dom'
import ContentManager from 'src/app/modules/cms/components/content/components/index'
import MenuManager from '../modules/cms/components/menu/components'

import Banner from '../modules/cms/components/banner/components'

import RoadBlockPopup from '../modules/cms/components/roadblockPopup/components'
import TextPopup from '../modules/cms/components/textPopup/components'

import FaqCategory from '../modules/cms/components/faqCategory/components'
import FaqManager from '../modules/cms/components/faqManager/components'

import EmailTemplate from '../modules/cms/components/emailTemplate/components'

import BranchComponent from '../modules/network/components/branch/component'
import BranchManagerComponent from '../modules/network/components/branchManager/component'
import NewCIFNewAccount from '../modules/new-cif-new-account/bankMaker/accountOpeningModules/components/NewCIFNewAccount'
import reviewAndUpdateCorporate from '../modules/new-cif-new-account/bankMaker/createNewAccountCorporate/components/newCifNewAccountCorporate/components/reviewAndUpdate'
import NewCifNewAccountNormalCorporate from '../modules/new-cif-new-account/bankMaker/createNewAccountCorporate/components/newCifNewAccountCorporateNormal/components'
import normalCorporateReviewAndUpdate from '../modules/new-cif-new-account/bankMaker/createNewAccountCorporate/components/newCifNewAccountCorporateNormal/components/reviewAndUpdate'
import NewCifNewAccountExpressCorporate from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/express/newCifNewAccountCorporate/component'
import NewCifNewAccountExpressIndividual from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/express/newCifNewAccountIndividual/components'
import NormalIndividualJointMinorForm from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/normal/individualNormal/components'
import ReviewAndUpdate from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/normal/individualNormal/components/ReviewAndUpdate'
import NormalCorporateReview from '../modules/new-cif-new-account/branchChecker/components/forms/normalCorporateReview'
import normalIndividualJointReview from '../modules/new-cif-new-account/branchChecker/components/forms/normalIndividualJointReview'
import BranchCheckerAccountLists from '../modules/new-cif-new-account/branchChecker/components/tables/BranchCheckerAccountLists'
import NewCifNewAccountCorporateCpu from '../modules/new-cif-new-account/cpuMaker/newCifNewAccountCorporateCpu'
import QANormalReviewForm from '../modules/new-cif-new-account/qa/components/forms/QANormalReviewAndUpdate'
// import NormalIndividualUploadDocs from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/normal/individualNormal/components/NormalIndividualUploadDocs'
import {lazy} from 'react'
import NormalIndividualJointMinorFormCIF from '../modules/NewCIFOnly/bankMaker/CeateNewCIF/createCIF/normal'
import NormalIndividualUploadDocsCIF from '../modules/NewCIFOnly/bankMaker/CeateNewCIF/createCIF/normal/NormalIndividualUploadDocs'
import ReviewAndUpdateCIF from '../modules/NewCIFOnly/bankMaker/CeateNewCIF/createCIF/normal/ReviewAndUpdate'
import NewCifNormalCorporate from '../modules/NewCIFOnly/bankMaker/CreateNewCiFCorporate/components/newCifCorporateNormal/components'
import NormalCorporateReviewAndUpdateCIF from '../modules/NewCIFOnly/bankMaker/CreateNewCiFCorporate/components/newCifCorporateNormal/components/reviewAndUpdate'
import UploadFileCIF from '../modules/NewCIFOnly/bankMaker/CreateNewCiFCorporate/components/uploadForm'
import NormalCorporateReviewCIF from '../modules/NewCIFOnly/branchChecker/components/forms/normalCorporateReview'
import NormalIndividualJointReviewCIF from '../modules/NewCIFOnly/branchChecker/components/forms/normalIndividualJointReview'
import CpuReviewFormCorporateCIF from '../modules/NewCIFOnly/cpuChecker/components/reviewForm/normal/cpuReviewFormCorporate'
import CPUReviewFormIndividualCIF from '../modules/NewCIFOnly/cpuChecker/components/reviewForm/normal/cpuReviewFormIndividual'
import KycUpdateFormNormalIndividualJointMinorCIF from '../modules/NewCIFOnly/cpuMaker/components/kycUpdateDetailsFormCPUMaker/normal/IndividualJointMinorKYC/kycUpdateFormNormalIndividualJointMinor'
import NewCifCorporateCpu from '../modules/NewCIFOnly/cpuMaker/newcifCorporateCpu'
import QANormalReviewAndUpdateCIF from '../modules/NewCIFOnly/qa/components/forms/QANormalReviewAndUpdate'
import ExpressCorporateReviewCIF from '../modules/NewCIFOnly/qa/components/forms/normalCorporateReview'
import ECNACpuReviewFormCorporate from '../modules/exisitingCifNewAccount/pages/cpuChecker/components/reviewForm/normal/cpuReviewFormCorporate'
import LinkedEntities from '../modules/linkedEntities'
import CreatingLinkEntitiesBranchMaker from '../modules/linkedEntities/features/branchMaker/components/createLinkedEntites'
import ReviewAndUpdateCPUCheckerLinkedEntities from '../modules/linkedEntities/features/cpuChecker/pages/reviewAndUpdateCpuChecker'
import AnnualIncomeTable from '../modules/masterSchema/annualIncome'
import AnnualTransactionTable from '../modules/masterSchema/annualTransactions'
import GenderTable from '../modules/masterSchema/gender'
import IdTypesTable from '../modules/masterSchema/idTypes'
import MaritialStatusLists from '../modules/masterSchema/maritalStatus'
import RiskCategoryListTable from '../modules/masterSchema/riskCategory'
import SalutationTable from '../modules/masterSchema/salutation'
import SectorTable from '../modules/masterSchema/sector'
import TaxCategoryLists from '../modules/masterSchema/taxCategories'
import NewCIFAccount from '../modules/new-cif-new-account'
import UploadFile from '../modules/new-cif-new-account/bankMaker/createNewAccountCorporate/components/uploadForm'
import NormalIndividualUploadDocs from '../modules/new-cif-new-account/bankMaker/createNewCIFAndAccount/createNewAccount/normal/individualNormal/components/NormalIndividualUploadDocs'
import CpuReviewFormCorporate from '../modules/new-cif-new-account/cpuChecker/components/reviewForm/normal/cpuReviewFormCorporate'
import CPUReviewFormIndividual from '../modules/new-cif-new-account/cpuChecker/components/reviewForm/normal/cpuReviewFormIndividual'
import CPUCheckerNewCIFAndAccountList from '../modules/new-cif-new-account/cpuChecker/components/tables'
import KycUpdateFormNormalIndividualJointMinor from '../modules/new-cif-new-account/cpuMaker/components/kycUpdateDetailsFormCPUMaker/normal/IndividualJointMinorKYC/kycUpdateFormNormalIndividualJointMinor'
import CPUMakerNewCIFAndAccountList from '../modules/new-cif-new-account/cpuMaker/components/tables'
import ExpressCorporateReview from '../modules/new-cif-new-account/qa/components/forms/normalCorporateReview'
import QaAccountLists from '../modules/new-cif-new-account/qa/components/tables/qaAccountLists'
import HomepageSetting from '../modules/siteSettings/components/homepageSettings/components'

import CorporateRevertedCaseForm from '../modules/linkedEntities/features/branchMaker/components/revertedCase/corporate'
import IndividualRevertedCase from '../modules/linkedEntities/features/branchMaker/components/revertedCase/individual'
import CPUCheckerCorporateReviewCase from '../modules/linkedEntities/features/cpuChecker/pages/corporate'
import CPUCheckerIndividualReviewCase from '../modules/linkedEntities/features/cpuChecker/pages/individual'
import AccountsRejectedLists from '../modules/new-cif-new-account/common/rejectedLists/components/table'
import CpuRejectedFormCorporate from '../modules/new-cif-new-account/common/rejectedLists/pages/corporate/ReviewPage'
import CPURejectedFormIndividual from '../modules/new-cif-new-account/common/rejectedLists/pages/individual/ReviewPage'
// import ExpressCorporateReview from '../modules/new-cif-new-account/qa/components/forms/normalCorporateReview'
// import UploadFile from '../modules/new-cif-new-account/bankMaker/createNewAccountCorporate/components/uploadForm'
import AccountClosue from '../modules/accountClosure/PendingAccountClosure'
import QAOnlineReviewAndUpdate from '../modules/new-cif-new-account/qa/components/forms/QAOnlineReviewAndUpdate'
import CPUCheckerOnlineReviewAndUpdate from '../modules/new-cif-new-account/cpuChecker/components/reviewForm/online/CPUCheckerOnlineReviewAndUpdate'
import CPUMakerOnlineReviewAndUpdate from '../modules/new-cif-new-account/cpuMaker/components/formcomponent/onlineReview/CPUMakerOnlineReviewAndUpdate'
import ScheduleCodeLists from '../modules/masterSchema/scheduleCode'

export interface RouteModel {
  path?: any
  component?: React.ComponentType<RouteComponentProps>
  exact?: boolean
}

export const RouteList = [
  {
    path: '/dashboard',
    component: DashboardWrapper,
    exact: true,
  },
  // {
  //   path: '/new-cif-new-accounts',
  //   component: BranchMakerNewCIFNewCIFAndAccountList,
  //   exact: true,
  // },

  {
    path: '/branch-checker-account-opening-lists',
    component: BranchCheckerAccountLists,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/account-opening-requests',
    component: NewCIFNewAccount,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/create-new-account/express-individual-preliminary-form',
    component: NewCifNewAccountExpressIndividual,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/create-new-account/normal-individual-preliminary-form',
    component: NormalIndividualJointMinorForm,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/create-new-account/normal-corporate-preliminary-form',
    component: NewCifNewAccountNormalCorporate,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/create-new-account/express-corporate-preliminary-form',
    component: NewCifNewAccountExpressCorporate,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/review-and-update/express-corporate-preliminary-form/:id',
    component: reviewAndUpdateCorporate,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/review-and-update/normal-corporate-preliminary-form/:id',
    component: normalCorporateReviewAndUpdate,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/review-and-update/normal-preliminary-form/:id',
    component: ReviewAndUpdate,
    staticName: 'New CIF New Accounts',
    exact: true,
  },
  {
    path: '/cpu-maker/account-lists',
    component: CPUMakerNewCIFAndAccountList,
    exact: true,
  },
  {
    path: '/qa/account-opening-lists',
    component: QaAccountLists,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/qa-review-and-update/normal-preliminary-form/:id',
    component: QANormalReviewForm,
    exact: true,
  },
  //Online Account opening review
  {
    path: '/new-cif-new-accounts/qa-online-review-and-update/normal-preliminary-form/:id',
    component: lazy(
      async () =>
        await import('../modules/new-cif-new-account/qa/components/forms/QAOnlineReviewAndUpdate')
    ),
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/cpu-maker-online-review-and-update/normal-preliminary-form/:id',
    component: lazy(
      async () =>
        await import(
          '../modules/new-cif-new-account/cpuMaker/components/formcomponent/onlineReview/CPUMakerOnlineReviewAndUpdate'
        )
    ),

    exact: true,
  },
  {
    path: '/new-cif-new-accounts/cpu-checker-online-review-and-update/normal-preliminary-form/:id',
    component: lazy(
      async () =>
        await import(
          '../modules/new-cif-new-account/cpuChecker/components/reviewForm/online/CPUCheckerOnlineReviewAndUpdate'
        )
    ),
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/individual-joint-minor/kyc/:id',
    component: KycUpdateFormNormalIndividualJointMinor,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/review-individual-joint-minor/kyc/:id',
    component: KycUpdateFormNormalIndividualJointMinor,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/corporate-qa-normal/review/:id',
    component: ExpressCorporateReview,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/corporate-branch-checker-normal/review/:id',
    component: NormalCorporateReview,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/branch-checker-normal/review/:id',
    component: normalIndividualJointReview,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/normal-preliminary-form/document-upload/:id',
    component: NormalIndividualUploadDocs,
    exact: true,
  },
  // NCNA Rejected
  {
    path: '/new-cif-new-accounts/rejected-account-lists',
    component: AccountsRejectedLists,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/rejected-account-by-branch-checker/:id',
    component: CPURejectedFormIndividual,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/rejected-account-by-qa/:id',
    component: CPURejectedFormIndividual,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/rejected-corporate-account-by-branch-checker/:id',
    component: CpuRejectedFormCorporate,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/rejected-corporate-account-by-qa/:id',
    component: CpuRejectedFormCorporate,
    exact: true,
  },
  {
    path: '/cpu-maker/new-cif-and-account-list',
    component: NewCifNewAccountCorporateCpu,
    exact: true,
  },
  {
    path: '/cpu-checker/account-opening-lists',
    component: CPUCheckerNewCIFAndAccountList,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/corporate/kyc/:id',
    component: NewCifNewAccountCorporateCpu,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/corporate/upload-documents/:id',
    component: UploadFile,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/cpu-checker/:id',
    component: CpuReviewFormCorporate,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/individual/cpu-checker/:id',
    component: CPUReviewFormIndividual,
    exact: true,
  },
  {
    path: '/new-cif-new-accounts/perform-screening',
    component: CPUReviewFormIndividual,
    exact: true,
  },
  {
    path: '/existing-cif/cpu-checker/review-and-update/:id',
    component: ECNACpuReviewFormCorporate,
    exact: true,
  },
  // Linked Entities
  {
    path: '/linked-entities',
    component: LinkedEntities,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-maker/create-linked-entities',
    component: CreatingLinkEntitiesBranchMaker,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-maker/individual/review-and-update/:id',
    component: IndividualRevertedCase,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-maker/corporate/review-and-update/:id',
    component: CorporateRevertedCaseForm,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-checker/individual/review-and-update/:id',
    component: CPUCheckerIndividualReviewCase,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-checker/corporate/review-and-update/:id',
    component: CPUCheckerCorporateReviewCase,
    exact: true,
  },
  {
    path: '/linked-entities/cpu-checker/review-and-update/:id',
    component: ReviewAndUpdateCPUCheckerLinkedEntities,
    exact: true,
  },

  {
    path: '/cms/content',
    component: ContentManager,
    exact: true,
  },

  {
    path: '/cms/menu-manager',
    component: MenuManager,
    exact: true,
  },
  {
    path: '/cms/banner',
    component: Banner,
    exact: true,
  },

  {
    path: '/popup/text-popup',
    component: TextPopup,
    exact: true,
  },

  {
    path: '/popup/road-block-popup',
    component: RoadBlockPopup,
    exact: true,
  },
  {
    path: '/cms/manage-faqs',
    component: FaqCategory,
    exact: true,
  },
  {
    path: '/cms/update-faqs/:id',
    component: FaqManager,
    exact: true,
  },
  {
    path: '/cms/email-template',
    component: EmailTemplate,
    exact: true,
  },

  {
    path: '/usermanagement/users',
    component: UserManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/user-role',
    component: UserRoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/role',
    component: RoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/departments',
    component: Departments,
    exact: true,
  },
  {
    path: '/usermanagement/branch-manager',
    component: BranchManagerComponent,
    exact: true,
  },
  {
    path: '/usermanagement/branch',
    component: BranchComponent,
    exact: true,
  },
  {
    path: '/sitesetting/module',
    component: ModuleManager,
    exact: true,
  },
  {
    path: '/sitesetting/contact-setting',
    component: ContactSetting,
    exact: true,
  },
  {
    path: '/sitesetting/homepage-setting',
    component: HomepageSetting,
    exact: true,
  },
  {
    path: '/sitesetting/backend-setting',
    component: BackendSettings,
    exact: true,
  },
  {
    path: '/sitesetting/setting',
    component: Settings,
    exact: true,
  },
  {
    path: '/sitesetting/smtp-setting',
    component: SmtpSettings,
    exact: true,
  },
  {
    path: '/sitesetting/key-setting',
    component: KeySettings,
    exact: true,
  },

  //ENquiry Module

  {
    path: '/account-opening-requests/new-cif-new-accountsss',
    component: NewCIFAccount,
    exact: true,
  },

  {
    path: '/new-cif-new-accounts',
    component: NewCIFAccount,
    exact: true,
  },

  {
    path: '/new-cif-new-accounts/normal-preliminary-form/document-upload/:id',
    component: NormalIndividualUploadDocs,
    exact: true,
  },

  {
    path: '/update-kyc',
    component: lazy(async () => await import('../modules/kycUpdate/MakerTable/index')),
    exact: true,
  },
  {
    path: '/update-kyc/cif-number',
    component: lazy(async () => await import('../modules/kycUpdate/KYCUpdateMaker/index')),
    exact: true,
  },
  {
    path: '/update-kyc/upload/:id',
    component: lazy(
      async () => await import('../modules/kycUpdate/KYCUpdateMaker/UploadDocument/index')
    ),
    exact: true,
  },
  {
    path: '/update-kyc/:id',
    component: lazy(async () => await import('../modules/kycUpdate/KYCUpdateMaker/ViewData/index')),
    exact: true,
  },

  {
    path: '/update-kyc/review/:id',
    component: lazy(
      async () => await import('../modules/kycUpdate/KYCUpdateMaker/ReviewBranchMaker/index')
    ),
    exact: true,
  },

  {
    path: '/update-kyc/checker-review/:id',
    component: lazy(
      async () => await import('../modules/kycUpdate/KYCUpdateChecker/CheckerList/index')
    ),
    exact: true,
  },

  {
    path: '/update-kyc/qa-review/:id',
    component: lazy(async () => await import('../modules/kycUpdate/QA/index')),
    exact: true,
  },

  {
    path: '/update-kyc/CPUMaker-review/:id',
    component: lazy(async () => await import('../modules/kycUpdate/CPUMaker/index')),
    exact: true,
  },

  {
    path: '/update-kyc/CPUChecker-review/:id',
    component: lazy(async () => await import('../modules/kycUpdate/CPUChecker/index')),
    exact: true,
  },

  {
    path: '/update-kyc/reject-list/view',
    component: lazy(async () => await import('../modules/rejectedListView/kycUpdate/index')),
    exact: true,
  },

  // New CIF Only
  {
    path: '/new-cif-only',
    component: lazy(async () => await import('../modules/NewCIFOnly/index')),
    exact: true,
  },

  {
    path: '/new-cif-only/reject-list/view',
    component: lazy(async () => await import('../modules/rejectedListView/NewCif/index')),
    exact: true,
  },

  {
    path: '/new-cif-only-detail',
    component: lazy(
      async () =>
        await import('../modules/NewCIFOnly/bankMaker/accountOpeningModules/components/NewCIF')
    ),
    exact: true,
  },
  {
    path: '/new-cif-only/create-new-cif',
    component: NormalIndividualJointMinorFormCIF,
    staticName: 'New CIF',
    exact: true,
  },

  {
    path: '/new-cif-only/create-new-cif-corporate',
    component: NewCifNormalCorporate,
    staticName: 'New CIF Corporate',
    exact: true,
  },

  {
    path: '/new-cif-only/review-and-update/normal-preliminary-form/:id',
    component: ReviewAndUpdateCIF,
    staticName: 'New CIF',
    exact: true,
  },
  {
    path: '/new-cif-only/corporate/upload-documents/:id',
    component: UploadFileCIF,
    exact: true,
  },
  {
    path: '/new-cif-only/normal-preliminary-form/document-upload/:id',
    component: NormalIndividualUploadDocsCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/review-and-update_corporate/:id',
    component: NormalCorporateReviewAndUpdateCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/normal-corporate-preliminary-form/:id',
    component: normalCorporateReviewAndUpdate,
    staticName: 'New CIF',
    exact: true,
  },

  {
    path: '/new-cif-only/branch-checker-review/:id',
    component: NormalIndividualJointReviewCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/corporate-branch-checker-review/:id',
    component: NormalCorporateReviewCIF,
    exact: true,
  },
  {
    path: '/new-cif-only/qa-review-and-update/normal-preliminary-form/:id',
    component: QANormalReviewAndUpdateCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/corporate-qa-normal/review/:id',
    component: ExpressCorporateReviewCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/corporate/kyc/:id',
    component: NewCifCorporateCpu,
    exact: true,
  },

  {
    path: '/new-cif-only/individual-joint-minor/kyc/:id',
    component: KycUpdateFormNormalIndividualJointMinorCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/review-individual-joint-minor/kyc/:id',
    component: KycUpdateFormNormalIndividualJointMinorCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/individual/cpu-checker/:id',
    component: CPUReviewFormIndividualCIF,
    exact: true,
  },

  {
    path: '/new-cif-only/cpu-checker/:id',
    component: CpuReviewFormCorporateCIF,
    exact: true,
  },
  // Account List
  {
    path: '/account-list',
    component: lazy(async () => await import('../modules/accountStatus/index')),
    exact: true,
  },
  //Existing CIF New Account

  // Account Schema

  {
    path: '/account-schemas',
    component: lazy(async () => await import('../modules/masterSchema/accountSchema/index')),
    exact: true,
  },

  {
    path: '/income-source',
    component: lazy(async () => await import('../modules/masterSchema/incomeSource/index')),
    exact: true,
  },

  {
    path: '/occupations',
    component: lazy(async () => await import('../modules/masterSchema/Occupation/index')),
  },

  // Risk Category Master Data
  {
    path: '/risk-categories',
    component: RiskCategoryListTable,
    exact: true,
  },

  {
    path: '/risk-sub-category',
    component: lazy(async () => await import('../modules/masterSchema/RiskSubCategory/index')),
    exact: true,
  },

  // Pep Category

  {
    path: '/pep-category',
    component: lazy(async () => await import('../modules/masterSchema/PepCategory/index')),
    exact: true,
  },

  {
    path: '/pep-sub-category',
    component: lazy(async () => await import('../modules/masterSchema/PepSubCategory/index')),
    exact: true,
  },

  // Marital Status
  {
    path: '/marital-status',
    component: MaritialStatusLists,
    exact: true,
  },
  //Salutations Master Data
  {
    path: '/salutations',
    component: SalutationTable,
    exact: true,
  },
  //Gender Master Data
  {
    path: '/genders',
    component: GenderTable,
    exact: true,
  },
  // Sector Master Data
  {
    path: '/sectors',
    component: SectorTable,
    exact: true,
  },
  // Sector Master Data
  {
    path: '/annual-incomes',
    component: AnnualIncomeTable,
    exact: true,
  },
  // Tax Category Master Data
  {
    path: '/tax-category',
    component: TaxCategoryLists,
    exact: true,
  },
  // Schedule Code Master Data
  {
    path: '/schedule-code',
    component: ScheduleCodeLists,
    exact: true,
  },
  // ID types Master Data
  {
    path: '/id-types',
    component: IdTypesTable,
    exact: true,
  },
  {
    path: '/annual-transactions',
    component: AnnualTransactionTable,
    exact: true,
  },
  // {
  //   path: '/existing-cif',
  //   component: ExistingCifNewAccount,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/get-details',
  //   component: cifCheck,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/create-new-account',
  //   component: IndividialData,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/document-upload/:id',
  //   component: DocumentUploadECNAIndividual,
  //   exact: true,
  // },
  // // {
  // //   path: '/existing-cif/review-and-update/:id',
  // //   component: ECNAReviewAndUpdateCase,
  // //   exact: true,
  // // },
  // {
  //   path: '/existing-cif/qa/review-and-update',
  //   component: ECNAIndividualJointQA,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/:id/branch-checker-review',
  //   component: IndividualChecker,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/qa-review-and-update/:id',
  //   component: ECNAIndividualMinorQA,
  //   exact: true,
  // },
  // {
  //   path: '/existing-cif/cpu-maker/review-and-update/:id',
  //   component: AccountOpeningFormECNA,
  //   exact: true,
  // },

  {
    path: '/existing-cif',
    component: lazy(
      async () =>
        await import('../modules/exisitingCifNewAccount/pages/branchMaker/MakerTable/index')
    ),
    exact: true,
  },
  {
    path: '/existing-cif/cif-number',
    component: lazy(
      async () => await import('../modules/exisitingCifNewAccount/pages/branchMaker/index')
    ),
    exact: true,
  },
  {
    path: '/existing-cif/upload/:id',
    component: lazy(
      async () =>
        await import('../modules/exisitingCifNewAccount/pages/branchMaker/UploadDocument/index')
    ),
    exact: true,
  },
  {
    path: '/existing-cif/:id',
    component: lazy(
      async () => await import('../modules/exisitingCifNewAccount/pages/branchMaker/ViewData/index')
    ),
    exact: true,
  },

  {
    path: '/existing-cif/review/:id',
    component: lazy(
      async () =>
        await import('../modules/exisitingCifNewAccount/pages/branchChecker/CheckerList/index')
    ),
    exact: true,
  },

  {
    path: '/existing-cif/qa-review/:id',
    component: lazy(async () => await import('../modules/exisitingCifNewAccount/pages/qa/index')),
    exact: true,
  },

  {
    path: '/existing-cif/CPUMaker-review/:id',
    component: lazy(
      async () => await import('../modules/exisitingCifNewAccount/pages/cpuMaker/index')
    ),
    exact: true,
  },
  {
    path: '/existing-cif/CPUChecker-review/:id',
    component: lazy(
      async () =>
        await import('../modules/exisitingCifNewAccount/pages/cpuChecker/components/index')
    ),
    exact: true,
  },
  {
    path: '/existing-cif/reject-list/view',
    component: lazy(async () => await import('../modules/rejectedListView/existingCif/index')),
    exact: true,
  },

  {
    path: '/religions',
    component: lazy(async () => await import('../modules/masterSchema/Religion/index')),
    exact: true,
  },

  {
    path: '/education-qualifications',
    component: lazy(async () => await import('../modules/masterSchema/Education/index')),
    exact: true,
  },

  {
    path: '/relationships',
    component: lazy(async () => await import('../modules/masterSchema/Relationship/index')),
    exact: true,
  },
  {
    path: '/account-purposes',
    component: lazy(async () => await import('../modules/masterSchema/AccountPurpose/index')),
    exact: true,
  },

  {
    path: '/account-categories',
    component: lazy(async () => await import('../modules/masterSchema/AccountCategory/index')),
    exact: true,
  },

  {
    path: '/corporate-registration-with',
    component: lazy(async () => await import('../modules/masterSchema/Registration/index')),
    exact: true,
  },

  {
    path: '/business-natures',
    component: lazy(async () => await import('../modules/masterSchema/BusinessNature/index')),
    exact: true,
  },

  {
    path: '/individual-unique-id',
    component: lazy(async () => await import('../modules/masterSchema/IndividualUniqueIDS/index')),
    exact: true,
  },
  // Account Maintenance
  {
    path: '/account-maintenance',
    component: lazy(async () => await import('../modules/accountMaintenance/index')),
    exact: true,
  },
  {
    path: '/account-maintenance/get-details',
    component: lazy(
      async () =>
        import('../modules/accountMaintenance/BranchMaker/AccountMaintainenceGetDetailsForm/index')
    ),
    exact: true,
  },
  {
    path: '/account-maintenance/:id',
    component: lazy(
      async () =>
        import('../modules/accountMaintenance/BranchMaker/AccountMaintainanceDetailPage/index')
    ),
    exact: true,
  },
  {
    path: '/account-maintenance/branchmaker-review/:id',
    component: lazy(
      async () => import('../modules/accountMaintenance/BranchMaker/ReviewForRevert/index')
    ),
    exact: true,
  },
  {
    path: '/account-maintenance/upload/:id',
    component: lazy(
      async () => import('../modules/accountMaintenance/BranchMaker/DocumentPage/uploadForm')
    ),
    exact: true,
  },
  {
    path: '/account-maintenance/branchchecker-review/:id',
    component: lazy(async () => import('../modules/accountMaintenance/BranchChecker/index')),
    exact: true,
  },
  {
    path: '/account-maintenance/qa-review/:id',
    component: lazy(async () => import('../modules/accountMaintenance/QA/index')),
    exact: true,
  },

  {
    path: '/account-maintenance/CPUMaker-review/:id',
    component: lazy(async () => import('../modules/accountMaintenance/CPUMaker/DetailPage')),
    exact: true,
  },

  {
    path: '/account-maintenance/CPUChecker-review/:id',
    component: lazy(async () => import('../modules/accountMaintenance/CPUChecker/index')),
    exact: true,
  },
  {
    path: '/account-maintenance/reject-list/view',
    component: lazy(async () => import('../modules/rejectedListView/accountMaintainance/index')),
    exact: true,
  },

  // ACCOUNT CLOSURE
  {
    path: '/accountClosure',
    component: lazy(async () => await import('../modules/accountClosure/index')),
    exact: true,
  },
  {
    path: '/accountClosure/get-details',
    component: lazy(
      async () => import('../modules/accountClosure/BranchMaker/GetDetailsForAccountClosure')
    ),
    exact: true,
  },
  {
    path: '/accountClosure/:id',
    component: lazy(
      async () => import('../modules/accountClosure/BranchMaker/DetailPageForAccountClosure')
    ),
    exact: true,
  },
  {
    path: '/accountClosure/review/:id',
    component: lazy(
      async () => import('../modules/accountClosure/BranchMaker/DetailPageForAccountClosure/index')
    ),
    exact: true,
  },
  {
    path: '/accountClosure/branchchecker-review/:id',
    component: lazy(async () => import('../modules/accountClosure/BranchChecker/index')),
    exact: true,
  },
  {
    path: '/accountClosure/upload/:id',
    component: lazy(
      async () => import('../modules/accountClosure/BranchMaker/DocumentPage/uploadForm')
    ),
    exact: true,
  },
  {
    path: '/accountClosure/qa-review/:id',
    component: lazy(async () => import('../modules/accountClosure/QA/index')),
    exact: true,
  },

  {
    path: '/accountClosure/CPUMaker-review/:id',
    component: lazy(async () => import('../modules/accountClosure/CPUMaker/index')),
    exact: true,
  },

  {
    path: '/accountClosure/CPUChecker-review/:id',
    component: lazy(async () => import('../modules/accountClosure/CPUChecker/index')),
    exact: true,
  },
  {
    path: '/accountClosure/reject-list/view',
    component: lazy(async () => import('../modules/rejectedListView/accountClosure/index')),
    exact: true,
  },
]
