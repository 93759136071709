import moment from 'moment'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from 'src/setup'
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import {QueryObserverResult, RefetchOptions, RefetchQueryFilters} from 'react-query'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {getOptionValueByKey} from '../../common/SplitFunction'
import {Form, Formik} from 'formik'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import CheckerModalAccountMaintainance from './ModalChecker'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'

interface IPayload {
  ListData: any
  isLoading: boolean
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, any>>
}

export const REVIEW_VALIDATION_SCHEMA = Yup.object().shape({
  status: Yup.string().required('Status is required'),
})
const AccountMaintainanceDocumentCheckerNewData = ({ListData, isLoading, refetch}: IPayload) => {
  const {id} = useParams<{id: string}>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [updatedRequestTypes, setUpdatedRequestTypes] = useState<string[]>([])
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll, isLoading: postLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const allStatusType = [
    {label: 'APPROVE', value: 'approve'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert_to_branchmaker'},
    {label: 'REVERT BACK TO CPU MAKER', value: 'revert_to_cpumaker'},
  ]
  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return '-'
  }
  useEffect(() => {
    const filteredMISInfo =
      ListData?.data?.updated_account_details?.maintenance_request_id?.maintenance_request_types
        .filter((item: any) => item?.cbs_code === 'mis_information')
        .map((doc: any) => doc?.cbs_code)
    const filteredAccountSchem =
      ListData?.data?.updated_account_details?.maintenance_request_id?.maintenance_request_types
        .filter((item: any) => item?.cbs_code === 'account_scheme')
        .map((doc: any) => doc?.cbs_code)
    const filteredEntity =
      ListData?.data?.updated_account_details?.maintenance_request_id?.maintenance_request_types
        .filter((item: any) => item?.cbs_code === 'linked_entity')
        .map((doc: any) => doc?.cbs_code)

    setUpdatedRequestTypes([
      ...updatedRequestTypes,
      ...filteredMISInfo,
      ...filteredAccountSchem,
      ...filteredEntity,
    ])
  }, [ListData])

  const handleBack = () => {
    history.go(-1)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = (values: {status: string}) => {
    setIsOpen(true)
    setStatus(values?.status)
  }

  const handleScreening = async () => {
    setScreeningLoading(true) // Set screening loading to true

    update.current = 'default'
    const checkAllPayload: any = {
      customer_name: ListData?.data?.updated_account_details?.account_name,
      pan_number: ListData?.data?.updated_account_details?.pan_number
        ? ListData?.data?.updated_account_details?.pan_number
        : '',
      model_name: 'AccountMaintainance',
    }
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }
  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const initialValues = {
    action: '',
    branch_code: ListData?.data?.updated_account_details?.branch_code,
    account_name: ListData?.data?.updated_account_details?.account_name || '',
    short_name: ListData?.data?.existing_account_details?.AccountDetail[0]?.ACCT_SHORT_NAME || '',
    status: 'D',
    account_opening_date: ListData?.data?.updated_account_details?.account_opening_date
      ? moment(ListData?.data?.updated_account_details?.account_opening_date).format('YYYY-MM-DD')
      : '',
    account_closing_date: ListData?.data?.updated_account_details?.ACCT_CLS_DATE
      ? moment(ListData?.data?.updated_account_details?.ACCT_CLS_DATE).format('YYYY-MM-DD')
      : '',
    account_scheme_id: ListData?.data?.updated_account_details?.account_scheme_id?.title,
    scheme_type:
      ListData?.data?.updated_account_details?.account_scheme_id?.account_category_id?.title,
    gl_sub_head_code: ListData?.data?.updated_account_details?.gl_sub_head_code || '',
    loan_detail_id: ListData?.data?.updated_account_details?.loan_detail_id?.title || '',
    deposit_detail_id: ListData?.data?.updated_account_details?.deposit_detail_id?.title,
    account_currency_id: ListData?.data?.updated_account_details?.account_currency_id?.title,
    other_account_currency: 'USD',
    remarks: '',
    linked_relations:
      ListData?.data?.updated_account_details?.relationship_data?.linked_relationships?.map(
        (party: any) => {
          return {
            relation_cif_number: party?.relation_cif_number || '',
            relation_name: party?.relation_name || '',
            relationship_id: party?.relationship_id?.title || '',
          }
        }
      ) ?? [
        {
          relation_cif_number: '',
          relation_name: '',
          relationship_id: '',
        },
      ],
    is_file_path_exists: false,
    files: [],
  }

  return (
    <>
      {isLoading ? (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body text-center '>
            <h3>
              <Spinner animation='border' variant='danger' className='mb-3' />
              <br />
              Please Wait...
            </h3>
          </div>
        </div>
      ) : (
        <div>
          <div className=''>
            <div className='mt-6'>
              <h1 className=' heading__component__title fw-bolder text-uppercase mt-2 border-bottom'>
                {updatedRequestTypes?.length > 0 ? 'Updated Information' : 'No Request For Update'}
              </h1>

              <div className='mt-4'>
                <div>
                  {/* {renderFormSections()} */}
                  {updatedRequestTypes.includes('account_scheme') && (
                    <div className=''>
                      <div className='d-flex'>
                        <h1 className=' heading__component__title fw-bolder text-uppercase mt-2'>
                          Account Scheme
                        </h1>
                      </div>
                      <div className=''>
                        <div className='row'>
                          <FormDetails
                            key='SCHM_TYPE'
                            label='Account Scheme Type'
                            value={initialValues?.scheme_type || '-'}
                          />
                          <FormDetails
                            key='SCHM_CODE'
                            label='Account Scheme'
                            value={initialValues?.account_scheme_id}
                          />
                          <FormDetails
                            key='GL_SUB_HEAD_CODE'
                            label='GL Sub Head Code'
                            value={
                              initialValues?.gl_sub_head_code
                                ? getStringValue(initialValues?.gl_sub_head_code)
                                : '-'
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {updatedRequestTypes.includes('mis_information') && (
                    <div>
                      <div className='d-flex'>
                        <h1 className=' heading__component__title fw-bolder text-uppercase'>
                          MIS Details
                        </h1>
                      </div>
                      <div className=''>
                        <div className='row'>
                          <FormDetails
                            key='NRB_DEPOSIT_LOAN_DETAIL'
                            label='NRB DEPOSIT LOAN'
                            value={initialValues?.loan_detail_id || '-'}
                          />
                          <FormDetails
                            key='NRB_DEPOSIT_DETAIL'
                            label='NRB DEPOSIT'
                            value={initialValues?.deposit_detail_id || '-'}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {updatedRequestTypes.includes('linked_entity') && (
                    <div>
                      <div className='d-flex'>
                        <h1 className=' heading__component__title fw-bolder text-uppercase'>
                          Related Party
                        </h1>
                      </div>
                      <div className=''>
                        {initialValues?.linked_relations.map((party: any, index: number) => (
                          <div key={index} className='row'>
                            <FormDetails
                              key='ACCT_POA_AS_NAME'
                              label='Account NAME'
                              value={getStringValue(party?.relation_name) || '-'}
                            />
                            <FormDetails
                              key='CUST_ID'
                              label='Customer ID'
                              value={getStringValue(party?.relation_cif_number) || '-'}
                            />
                            <FormDetails
                              key='ACCT_POA_AS_REC_TYPE'
                              label='Account TYPE'
                              value={party?.relationship_id || '-'}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className='row'>
                {screeningPerformed ? (
                  checkAllData && (
                    <Button
                      type='button'
                      onClick={handleOpenPdf}
                      className='col-md-6 col-sm-12 col-xs-12'
                    >
                      View Details
                    </Button>
                  )
                ) : screeningLoading ? (
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    type='button'
                    onClick={handleScreening}
                    className='col-md-6 col-sm-6 col-xs-12'
                  >
                    Perform Screening
                  </Button>
                )}
                <div className='my-7'>
                  <h3>Screening Files</h3>
                  {ListData?.data?.updated_account_details?.screening_files?.screening_files
                    ?.length > 0 ? (
                    ListData?.data?.updated_account_details?.screening_files?.screening_files?.map(
                      (file: any, index: any) => (
                        <button
                          key={index}
                          type='button'
                          onClick={() => handlePdfFileClick(file.file_path)}
                          style={{display: 'block', marginBottom: '10px'}}
                        >
                          {file.file_name}
                        </button>
                      )
                    )
                  ) : (
                    <p>No files available</p>
                  )}
                </div>
              </div>
              <div>
                <Formik
                  initialValues={{status: ''}}
                  onSubmit={handleSubmit}
                  validationSchema={REVIEW_VALIDATION_SCHEMA}
                >
                  {({handleChange, errors, touched, values, setFieldValue}) => (
                    <Form>
                      <div className='row mt-8'>
                        <div className='col-md-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Status Type'
                            name='status'
                            isClearable={true}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={allStatusType.map((item) => ({
                              label: item.label,
                              value: item.value,
                            }))}
                            required={true}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className=' my-2 mb-6'></div>

                        <div className='d-flex justify-content-end mt-3'>
                          <button
                            type='button'
                            onClick={handleBack}
                            className='btn btn-secondary mx-4'
                          >
                            Back
                          </button>
                          <button type='submit' className='btn btn-primary' disabled={postLoading}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <CheckerModalAccountMaintainance
              isOpen={isOpen}
              handleClose={handleClose}
              setIsOpen={setIsOpen}
              id={id}
              status={status}
              refetch={refetch}
              is_file_path_exists={screeningPerformed}
              files={accumulatedFilePaths}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AccountMaintainanceDocumentCheckerNewData
