import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table, Modal, Button} from 'rsuite'
import {isEmpty} from 'lodash'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'

const Cell = Table.Cell

const IndividualJointMinorCompleteTable = ({
  newCIFandAccountListData,
  setParams,
  params,
  loading,
  roleName,
}: any) => {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [modalRowData, setModalRowData] = useState(null)

  const handleModalOpen = (rowData: any) => {
    setModalRowData(rowData) // Set the row data for the modal
    setShowModal(true)
  }

  const handleModalClose = () => {
    setModalRowData(null) // Clear the row data when closing the modal
    setShowModal(false)
  }

  const data =
    newCIFandAccountListData?.data?.preliminaries?.map((newAccount: any, i: any) => ({
      ...newAccount,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      account_name: `${newAccount?.first_name.toUpperCase()} ${
        newAccount.middle_name ? newAccount.middle_name.toUpperCase() + ' ' : ''
      }${newAccount?.last_name.toUpperCase()}`,
      updatedOn: newAccount?.account_status?.updated_date,
      updatedBy: newAccount?.account_status?.updated_by,
      remarks: newAccount?.account_status?.remarks ?? '',
      status: newAccount?.account_status?.current_status ?? '',
      accountNumber: newAccount?.registration_detail?.account_number ?? '',
      cifNumber: newAccount?.registration_detail?.cif_number ?? '',
      btnStatus: newAccount?.btn_status ?? '',
      branchCode: newAccount?.branch_code ?? '',
      customerCode: newAccount?.customer_code ?? '',
    })) ?? []

  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    console.log('Row Data:', rowData)

    return (
      <Cell {...props} className='link-group'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Align buttons to the right
            alignItems: 'center', // Center-align buttons vertically
            gap: '10px', // Add spacing between buttons
          }}
        >
          <button
            className='btn btn-secondary btn-sm ml-2'
            style={{
              // backgroundColor: "#6c757d", // Secondary button color
              // color: "#fff",
              // border: "none",
              // padding: "5px 10px",
              // fontSize: '12px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleModalOpen(rowData)}
          >
            History
          </button>
        </div>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 80,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Action',
      width: 230,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 180,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 200,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Branch',
      dataKey: 'branchCode',
      cell: <Cell dataKey='branchCode' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Customer Code',
      dataKey: 'customerCode',
      cell: <Cell dataKey='customerCode' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Updated By',
      dataKey: 'updatedBy',
      cell: <Cell dataKey='updatedBy' />,
      sortable: false,
      width: 160,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params?.search}
          onChange={(value) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={newCIFandAccountListData?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>

      <Modal open={showModal} onClose={handleModalClose} size='lg'>
        <Modal.Body>
          <AccountStatusLog data={modalRowData} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleModalClose} appearance='primary'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default IndividualJointMinorCompleteTable
