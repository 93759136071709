import React, {useRef, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import CorporateLinkedEntitiesDetails from './corporateLinkedEntities'
import ToBeLinkedEntities from './linkedEntitiesInfos'
import {Form, Formik} from 'formik'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../setup'
import FormDetails from '../../../../../../cms/helpers/components/forms/formDetails'
import {getOptionValueByKeyCBS} from '../../../../common/SplitFunction'

const ViewCustomerDetails = ({data, customerEntitiesData, onSubmitValues}: any) => {
  const customerName = customerEntitiesData?.AccountDetail[0]?.ACCT_NAME
  const [relatedParties, setRelatedParties] = useState<any[]>(
    customerEntitiesData?.RelatedParty || []
  )

  const initialValues = {
    customer_name: customerName,
    citizenship: '',
    pan_number: '',
    model_name: 'LinkedEntities',
  }

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  // const validationSchema = Yup.object().shape({
  //   citizenship: Yup.string().when([], {
  //     is: () => !onSubmitValues?.is_corporate, // If not corporate, citizenship is required
  //     then: Yup.string()
  //       .required('Citizenship number is required')
  //       .max(30, 'Max length is 30 characters'),
  //     otherwise: Yup.string().nullable(), // If corporate, citizenship is not required
  //   }),
  //   pan_number: Yup.string().when([], {
  //     is: () => onSubmitValues?.is_corporate, // If corporate, pan_number is required
  //     then: Yup.string().required('Pan number is required').max(30, 'Max length is 30 characters'),
  //     otherwise: Yup.string().nullable(), // If not corporate, pan_number is not required
  //   }),
  // })

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const handleScreening = async (values: any) => {
    setScreeningLoading(true)
    update.current = 'default'

    const checkAllPayload: any = {
      customer_name: customerEntitiesData?.AccountDetail[0]?.ACCT_NAME,
      // pan_number: onSubmitValues?.is_corporate ? values?.pan_number : '',
      // citizenship: !onSubmitValues?.is_corporate ? '' : values?.citizenship,
      model_name: 'LinkedEntities',
    }

    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data: any) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error: any) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleSubmit = (values: any) => {
    handleScreening(values)
  }

  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return '-'
  }

  useEffect(() => {
    if (customerEntitiesData?.RelatedParty) {
      setRelatedParties(customerEntitiesData.RelatedParty)
    }
  }, [customerEntitiesData])
  const handleDeleteParty = (index: number) => {
    // Remove item from the state
    setRelatedParties((prev) => prev.filter((_, i) => i !== index))

    // Optional: If you need to sync the deletion with a backend API
    const updatedParties = relatedParties.filter((_, i) => i !== index)
    // API call here, e.g., to persist the updated relatedParties to the server
    // Example (pseudo-code):
    // await api.deleteRelatedParty(relatedParties[index].id)
    // After successful API call, update the state
    setRelatedParties(updatedParties)
  }

  return (
    <div>
      {data && customerEntitiesData && (
        <div>
          <p className='heading__component__title fw-bolder text-uppercase'>Customer Details</p>
          <div className='row align-items-center'>
            {/* Primary CIF Number */}
            <div className='col-md-3'>
              <h6>Primary CIF Number</h6>
              <p>{customerEntitiesData?.AccountDetail[0]?.CUST_ID || '-'}</p>
            </div>
            <div className='col-md-3'>
              {!onSubmitValues?.is_corporate ? (
                <>
                  <h6>Customer Full Name</h6>
                  <p>{customerEntitiesData?.AccountDetail[0]?.ACCT_NAME || '-'}</p>
                </>
              ) : (
                <>
                  <h6>Name of Institution/Company</h6>
                  <p>{customerEntitiesData?.AccountDetail[0]?.ACCT_NAME || '-'}</p>
                </>
              )}
            </div>
            <div className='col-md-3'>
              <h6>Account Opened Date</h6>
              <p>
                {customerEntitiesData?.AccountDetail[0]?.ACCT_OPN_DATE
                  ? moment(customerEntitiesData.AccountDetail[0].ACCT_OPN_DATE).format('MM/DD/YYYY')
                  : '-'}
              </p>
            </div>
            <div className='col-md-3'>
              <h6>Account Currency</h6>
              <p>{customerEntitiesData?.AccountDetail[0]?.ACCT_CRNCY_CODE || '-'}</p>
            </div>

            {/* Customer Full Name */}

            <div className='col-md-6'>
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({errors, handleChange, touched, values}) => {
                  return (
                    <Form>
                      <div className='row'>
                        {/*<div className='col-md-6'>*/}
                        {/*  {!onSubmitValues?.is_corporate ? (*/}
                        {/*    <div className='mb-3'>*/}
                        {/*      <FormTextBox*/}
                        {/*        uppercase={true}*/}
                        {/*        labelClassName='col-lg-12'*/}
                        {/*        containerClassName='col-lg-12'*/}
                        {/*        type='text'*/}
                        {/*        placeholder='Enter Citizenship Number'*/}
                        {/*        name='citizenship'*/}
                        {/*        label='Citizenship Number'*/}
                        {/*        onChange={handleChange}*/}
                        {/*        errors={errors}*/}
                        {/*        touched={touched.citizenship}*/}
                        {/*        required*/}
                        {/*        maxLength={30}*/}
                        {/*      />*/}
                        {/*    </div>*/}
                        {/*  ) : (*/}
                        {/*    <div className='mb-3'>*/}
                        {/*      <FormTextBox*/}
                        {/*        uppercase={true}*/}
                        {/*        labelClassName='col-lg-12'*/}
                        {/*        containerClassName='col-lg-12'*/}
                        {/*        type='text'*/}
                        {/*        placeholder='Enter Pan Number'*/}
                        {/*        name='pan_number'*/}
                        {/*        label='Pan Number'*/}
                        {/*        onChange={handleChange}*/}
                        {/*        errors={errors}*/}
                        {/*        touched={touched.pan_number}*/}
                        {/*        required*/}
                        {/*        maxLength={30}*/}
                        {/*      />*/}
                        {/*    </div>*/}
                        {/*  )}*/}
                        {/*</div>*/}

                        <div className='col-md-2 d-flex align-items-center'>
                          {screeningPerformed ? (
                            checkAllData && (
                              <Button
                                type='button'
                                onClick={handleOpenPdf}
                                // className='col-md-3 col-sm-6 col-xs-12'
                              >
                                View Details
                              </Button>
                            )
                          ) : screeningLoading ? (
                            <div>
                              <Spinner animation='border' role='status'>
                                <span className='sr-only'>Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <Button
                              type='submit'
                              // className='col-md-3 col-sm-6 col-xs-12'
                            >
                              Perform Screening
                            </Button>
                          )}
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
          <div className='row pt-5'>
            <p className='heading__component__title fw-bolder text-uppercase'>
              Related Party Details
            </p>
            {relatedParties.filter((party: any) => party?.ACCT_POA_AS_REC_TYPE !== 'M').length === // Filter out items with ACCT_POA_AS_REC_TYPE === 'M'
            0 ? (
              <div className='text-center my-4'>
                <p>No related parties available.</p>
              </div>
            ) : (
              relatedParties
                .filter((party: any) => party?.ACCT_POA_AS_REC_TYPE !== 'M') // Filter out items with ACCT_POA_AS_REC_TYPE === 'M'
                .map((party: any, index: number) => (
                  <div key={index} className='row align-items-center'>
                    <div className='col-md-6'>
                      <FormDetails
                        key='CUST_ID'
                        label='Account Cif Number'
                        value={getStringValue(party?.CUST_ID) || '-'}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormDetails
                        key='ACCT_POA_AS_NAME'
                        label='Account NAME'
                        value={getStringValue(party?.ACCT_POA_AS_NAME) || '-'}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormDetails
                        key='ACCT_POA_AS_REC_TYPE'
                        label='Account POA TYPE'
                        value={
                          getOptionValueByKeyCBS(
                            allMasterDataOptions?.account_relations,
                            party?.ACCT_POA_AS_REC_TYPE
                          ) || '-'
                        }
                      />
                    </div>
                    <div className='col-md-2'>
                      <Button
                        type='button'
                        variant='danger'
                        onClick={() => handleDeleteParty(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
            )}
          </div>

          {onSubmitValues.is_corporate ? (
            <CorporateLinkedEntitiesDetails
              onSubmitValues={onSubmitValues}
              screeningPerformed={screeningPerformed}
              accumulatedFilePaths={accumulatedFilePaths}
              setAccumulatedFilePaths={setAccumulatedFilePaths}
              relatedParty={relatedParties}
            />
          ) : (
            <ToBeLinkedEntities
              onSubmitValues={onSubmitValues}
              screeningPerformed={screeningPerformed}
              accumulatedFilePaths={accumulatedFilePaths}
              setAccumulatedFilePaths={setAccumulatedFilePaths}
              relatedParty={relatedParties}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ViewCustomerDetails
