import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'

import {
  UPDATE_NEW_CIF_BM,
  UPDATE_NEW_CIF_NEW_ACCOUNT_BM,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${UPDATE_NEW_CIF_NEW_ACCOUNT_BM}`

type UpdatePayloadType = {
  id: string
  data: any
}

const updateIndividualAccountPreliminary = async (payload: UpdatePayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${API_URL}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw error.response?.data || error.message
  }
}

const useUpdateIndividualAccountPreliminary = () => {
  const queryClient = useQueryClient()
  return useMutation(updateIndividualAccountPreliminary, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'Preliminary Account updated successfully!')
      }
      queryClient.invalidateQueries('branchMakerNewCIFAndAccountList')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}

export default useUpdateIndividualAccountPreliminary

const updateIndividualAccountPreliminaryCIF = async (payload: UpdatePayloadType) => {
  const {id, ...data} = payload
  try {
    const response = await axios.patch(`${UPDATE_NEW_CIF_BM}/${id}`, data)
    return response.data
  } catch (error: any) {
    throw error?.response?.data || error.message

  }
}

export const useUpdateIndividualAccountPreliminaryCIF = () => {
  const queryClient = useQueryClient()
  return useMutation(updateIndividualAccountPreliminaryCIF, {
    onSuccess: (data) => {
      // toast.success(data?.resDesc ?? 'Preliminary Account updated successfully!')
      queryClient.invalidateQueries('preliminariesDetailsByIdCIF')

      // queryClient.invalidateQueries('branchMakerAccountList')
    },
    onError: (error: any) => {
      if (error.errors) {
        for (const key in error.errors) {
          if (error.errors[key]) {
            error.errors[key].forEach((message: string) => {
              toast.error(message)
            })
          }
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error('An unknown error occurred')
      }
    },
  })
}
