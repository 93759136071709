import React from 'react'
interface IProps {
  counIndividualData: any
  individualCount: {
    model_name: string
  }
}
const OtherFilter = ({counIndividualData, individualCount}: IProps) => {
  return (
    <div className='row justify-content-md-center gy-5'>
      <div className='col-md-8 col-sm-12'>
        <div className='card text-center shadow-sm'>
          <div className='card-body'>
            <h5 className='card-title'>Overview</h5>
            <p className='text-muted'>
              Total Count:{' '}
              <span className='text-primary'>
                {counIndividualData?.data?.individual?.total_count}
              </span>
            </p>
            {/* {individualCount?.model_name === 'OnlineAccountOpening' && (
              <p className='text-muted'>
                Total Online Submitted:{' '}
                <span className='text-warning'>
                  {counIndividualData?.data?.individual?.total_online_submitted}
                </span>
              </p>
            )} */}
            <p className='text-muted'>
              Total Pending:{' '}
              <span className='text-warning'>
                {counIndividualData?.data?.individual?.total_pending}
              </span>
            </p>
            <p className='text-muted'>
              Total Completed:{' '}
              <span className='text-success'>
                {counIndividualData?.data?.individual?.total_completed}
              </span>
            </p>
            {/*<p className='text-muted'>*/}
            {/*  Total in RPA :{' '}*/}
            {/*  <span className='text-success'>*/}
            {/*    {counIndividualData?.data?.individual?.total_in_rpa}*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p className='text-muted'>
              Total Rejected:{' '}
              <span className='text-danger'>
                {counIndividualData?.data?.individual?.total_rejected}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className='row gy-4'>
        {/* Branch Maker */}
        <h3 className='mt-5 text-center text-danger border-bottom border-danger'>Branch Details</h3>
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>Branch Maker</h4>
              {individualCount?.model_name === 'OnlineAccountOpening' ? (
                <p className='card-text'>Not Available</p>
              ) : (
                <p className='card-text mb-10'>
                  Pending Count:{' '}
                  <span>{counIndividualData?.data?.individual?.total_in_branch_maker}</span>
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Branch Checker */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>Branch Checker</h4>
              {individualCount?.model_name === 'OnlineAccountOpening' ? (
                <p className='card-text'>Not Available</p>
              ) : (
                <>
                  <p className='card-text'>
                    Pending Count:{' '}
                    <span>{counIndividualData?.data?.individual?.total_in_branch_checker}</span>
                  </p>

                  <p className='card-text'>
                    Rejected Count:{' '}
                    <span className='text-danger'>
                      {counIndividualData?.data?.individual?.total_rejected_by_branch_checker}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>

        {/* QA */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>QA</h4>
              <p className='card-text'>
                Pending Count: <span>{counIndividualData?.data?.individual?.total_in_qa}</span>
              </p>

              <p className='card-text'>
                Rejected Count:{' '}
                <span className='text-danger'>
                  {counIndividualData?.data?.individual?.total_rejected_by_qa}
                </span>
              </p>
            </div>
          </div>
        </div>

        {/* CPU Maker */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>CPU Maker</h4>
              <p className='card-text'>
                Pending Count:{' '}
                <span>{counIndividualData?.data?.individual?.total_in_cpu_maker}</span>
              </p>
            </div>
          </div>
        </div>

        {/* CPU Checker */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>CPU Checker</h4>
              <p className='card-text'>
                Pending Count:{' '}
                <span>{counIndividualData?.data?.individual?.total_in_cpu_checker}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherFilter
