import React, {useEffect, useState} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'

const ShareholdersTable = ({values, handleChange, errors, touched}: any) => {
  const {setFieldValue} = useFormikContext()
  const [showShareholders, setShowShareholders] = useState(false)

  useEffect(() => {
    if (values?.basic_shareholders?.length > 0 || values?.full_shareholders?.length > 0) {
      setShowShareholders(true)
    }
  }, [values.basic_shareholders, values.full_shareholders])
  const toggleShareholdersVisibility = () => {
    setShowShareholders((prevState: any) => !prevState)
  }

  // useEffect(() => {
  //   if (values.share_holders.length === 0) {
  //     setFieldValue('share_holders', [
  //       {
  //         shareholder_name: '',
  //         ownership_percentage: '',
  //         shareholder_address: '',
  //         entity_name: '',
  //         entity_ownership_percentage: '',
  //         entity_address: '',
  //         entity_registration_number: '',
  //         entity_registration_office: '',
  //         entity_incorporation_date: '',
  //         entity_pan_number: '',
  //         entity_vat_number: '',
  //         entity_country: '',
  //         entity_registration_address: '',
  //         entity_business_address: '',
  //         entity_phone_number: '',
  //         entity_website: '',
  //       },
  //     ])
  //   }
  // }, [setFieldValue, values.share_holders])

  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const addBasicShareholder = () => {
    const newBasicShareholder = {
      shareholder_name: '',
      ownership_percentage: '',
      shareholder_address: '',
    }
    setFieldValue('basic_shareholders', [...values.basic_shareholders, newBasicShareholder])
  }

  const removeBasicShareholder = (index: number) => {
    const updated = values.basic_shareholders.filter((_: any, idx: number) => idx !== index)
    setFieldValue('basic_shareholders', updated)
  }

  const addFullShareholder = () => {
    const newFullShareholder = {
      shareholder_name: '',
      ownership_percentage: '',
      shareholder_address: '',
      entity_name: '',
      entity_ownership_percentage: '',
      entity_address: '',
      entity_registration_number: '',
      entity_registration_office: '',
      entity_incorporation_date: '',
      entity_pan_number: '',
      entity_vat_number: '',
      entity_country: '',
      entity_registration_address: '',
      entity_business_address: '',
      entity_phone_number: '',
      entity_website: '',
    }
    setFieldValue('full_shareholders', [...values.full_shareholders, newFullShareholder])
  }

  const removeFullShareholder = (index: number) => {
    const updated = values.full_shareholders.filter((_: any, idx: number) => idx !== index)
    setFieldValue('full_shareholders', updated)
  }

  return (
    <>
      <h3 className='heading__component__title fw-bolder text-uppercase'>
        G. Share Holding and Beneficial Owner Details:
      </h3>
      <button type='button' className='btn btn-primary mb-3' onClick={toggleShareholdersVisibility}>
        {showShareholders ? 'Hide Shareholders' : 'Add Shareholders'}
      </button>

      {showShareholders ? (
        <>
          <div className='row mb-3'>
            <div className='col-md-6'>
              <FormTextBox
                labelClassName='col-lg-12'
                containerClassName='col-lg-12'
                type='text'
                placeholder='M/S'
                name={`beneficial_ms`}
                label='Beneficial M/S'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className='col-md-6'>
              <FormTextBox
                labelClassName='col-lg-12'
                containerClassName='col-lg-12'
                type='text'
                placeholder='Account Number'
                name={`beneficial_account_number`}
                label='Beneficial Account Number'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className='col-md-6'>
              <FormTextBox
                labelClassName='col-lg-12'
                containerClassName='col-lg-12'
                type='text'
                placeholder='CIf Number'
                name={`beneficial_cif_number`}
                label='Beneficial Cif Number'
                onChange={handleChange}
                errors={errors}
                touched={touched}
              />
            </div>
          </div>
          {/* Basic Shareholders */}
          <h4>Shareholders</h4>
          {values?.basic_shareholders?.map((shareholder: any, index: number) => (
            <div className='row mb-3' key={index}>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Shareholder Name'
                  name={`basic_shareholders[${index}].shareholder_name`}
                  label='Shareholder Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Ownership Percentage'
                  name={`basic_shareholders[${index}].ownership_percentage`}
                  label='Ownership Percentage'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Shareholder Address'
                  name={`basic_shareholders[${index}].shareholder_address`}
                  label='Shareholder Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-2 d-flex align-items-center'>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => removeBasicShareholder(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <button
            type='button'
            className='dt-btn dt-btn-primary mb-8'
            onClick={addBasicShareholder}
          >
            Add Basic Shareholder
          </button>

          {/* Full Shareholders */}
          <h4>Shareholders Holding more than 15%</h4>
          {values?.full_shareholders?.map((shareholder: any, index: number) => (
            <div className='row mb-3' key={index}>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Shareholder Name'
                  name={`full_shareholders.${index}.shareholder_name`}
                  label='Shareholder Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Ownership Percentage'
                  name={`full_shareholders.${index}.ownership_percentage`}
                  label='Ownership Percentage'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Shareholder Address'
                  name={`full_shareholders.${index}.shareholder_address`}
                  label='Shareholder Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Name'
                  name={`full_shareholders.${index}.entity_name`}
                  label='Entity Name'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='number'
                  placeholder='Entity Ownership Percentage'
                  name={`full_shareholders.${index}.entity_ownership_percentage`}
                  label='Entity Ownership Percentage'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Address'
                  name={`full_shareholders.${index}.entity_address`}
                  label='Entity Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  
                />
              </div>
              {/* New fields start here */}
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Registration Number'
                  name={`full_shareholders.${index}.entity_registration_number`}
                  label='Entity Registration Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Registration Office'
                  name={`full_shareholders.${index}.entity_registration_office`}
                  label='Entity Registration Office'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='date'
                  placeholder='Entity Incorporation Date'
                  name={`full_shareholders.${index}.entity_incorporation_date`}
                  label='Entity Incorporation Date'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity PAN Number'
                  name={`full_shareholders.${index}.entity_pan_number`}
                  label='Entity PAN Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  maxLength={9}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity VAT Number'
                  name={`full_shareholders.${index}.entity_vat_number`}
                  label='Entity VAT Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  maxLength={9}
                />
              </div>
              <div className='col-md-6'>
                <FormSelect
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  placeholder='Select Entity Country'
                  label='Entity Country'
                  name={`full_shareholders.${index}.entity_country`}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  options={allMasterDataOptions?.countries?.map((country: any) => ({
                    value: country?.id || country?.value,
                    label: country?.title || country?.label,
                  }))}
                  setFieldValue={setFieldValue}
                  arrValue={values?.full_shareholders[0]?.entity_country?.toString()}
                  values={values}
                  // required
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Registration Address'
                  name={`full_shareholders.${index}.entity_registration_address`}
                  label='Entity Registration Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Business Address'
                  name={`full_shareholders.${index}.entity_business_address`}
                  label='Entity Business Address'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Phone Number'
                  name={`full_shareholders.${index}.entity_phone_number`}
                  label='Entity Phone Number'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-6'>
                <FormTextBox
                  labelClassName='col-lg-12'
                  containerClassName='col-lg-12'
                  type='text'
                  placeholder='Entity Website'
                  name={`full_shareholders.${index}.entity_website`}
                  label='Entity Website'
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </div>
              <div className='col-md-2 d-flex align-items-center'>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => removeFullShareholder(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <button type='button' className='dt-btn dt-btn-primary mb-8' onClick={addFullShareholder}>
            Add Full Shareholder
          </button>
        </>
      ) : (
        <div>No Shareholders added. Click 'Add Shareholders' to add.</div>
      )}
    </>
  )
}

export default ShareholdersTable
