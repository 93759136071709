import React, {useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {RcFile} from 'antd/lib/upload'
import {useGetDOcumentMasterData} from 'src/app/queries/getMasterData/documentType/documentTypeMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import FileUploadComponent from '../../../../../common/form/fileUpload'
import {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS} from '../../../../../constants/Contants'
import {Button, Spinner} from 'react-bootstrap-v5'
import axios from 'axios'
import {toast} from 'react-toastify'
import {QueryClient} from 'react-query'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {Row} from 'antd'

const DocumentUploadJoint: React.FC = () => {
  interface RouteParams {
    id: string
  }

  const history = useHistory()
  const {id: uniqueId} = useParams<RouteParams>()
  const [resetUploads, setResetUploads] = useState(false)
  const [uploadDocumentsTypeId, setUploadDocumentsTypeId] = useState<string[]>([])
  const [missingDocs, setMissingDocs] = useState<string[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<{id: string}[]>([])
  const [existingFiles, setExistingFiles] = useState<{id: string}[]>([])
  const [existingFilesIds, setExistingFilesIds] = useState<{id: string}[]>([])
  const [hasOptionalDocument, setHasOptionalDocument] = useState(false)
  const [btnFlag, setBtnFlag] = useState<string>('')
  const [loading, setLoading] = useState(false)

  // Track multiple CIF Form file uploads
  const [cifFormFiles, setCifFormFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [cifFormFilesIds, setCifFormFilesIds] = useState<Array<{id: any; doc_id: any}>>([])
  const [otherFiles, setOtherFiles] = useState<Array<{id: string; file: RcFile | null}>>([])
  const [otherFilesIds, setOtherFilesIds] = useState<any>()

  const [otherDocumentsCount, setOtherDocumentsCount] = useState(1)

  const {
    data: preliminariesDetails,
    isLoading: loadingPreliminaryData,
    invalidateQuery,
    refetch,
  } = useGetPreliminariesDetailsById(uniqueId)
  const {data: documentTypeList, isLoading: loadingDocumentTypes} = useGetDOcumentMasterData()

  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

  useEffect(() => {
    if (preliminariesDetails?.data?.account_documents?.documents) {
      const existingFilesData = preliminariesDetails.data.account_documents.documents.map(
        (doc: any) => ({id: doc.documentType.id})
      )
      setExistingFiles(existingFilesData)
      const existingFilesids = preliminariesDetails.data.account_documents.documents.map(
        (doc: any) => ({id: doc.id, doc_id: doc.documentType.id})
      )
      setExistingFilesIds(existingFilesids)
      const existingOptionalDocIds = preliminariesDetails.data.account_documents.documents
        .filter((doc: any) => optionalDocuments.includes(doc.documentType.cbs_code))
        .map((doc: any) => doc.documentType.id)
      if (existingOptionalDocIds.length > 0) {
        setHasOptionalDocument(true)
      }
    }

    if (preliminariesDetails?.data?.additional_documents?.documents) {
      const otherDocFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc.documentType.id, file: null}))

      setOtherFiles(otherDocFiles.length > 0 ? otherDocFiles : [{id: '', file: null}])
    } else {
      setOtherFiles([{id: '', file: null}])
    }
    if (preliminariesDetails?.data?.additional_documents?.documents) {
      const otherDocFiles = preliminariesDetails.data.additional_documents.documents
        ?.filter((item: any) => item?.documentType?.cbs_code === 'other_documents')
        .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

      setOtherFilesIds(otherDocFiles.length > 0 ? otherDocFiles : [{id: ''}])
    }

    // Check for CIF Form documents
    const cifFormFiles = preliminariesDetails?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc.documentType.id, file: null}))

    setCifFormFiles(cifFormFiles?.length > 0 ? cifFormFiles : [{id: '', file: null}])
    const cifFormFilesIds = preliminariesDetails?.data?.additional_documents?.documents
      ?.filter((item: any) => item?.documentType?.cbs_code === 'cif_form')
      .map((doc: any) => ({id: doc?.id, doc_id: doc.documentType.id}))

    setCifFormFilesIds(cifFormFilesIds?.length > 0 ? cifFormFilesIds : [{id: '', file: null}])
  }, [preliminariesDetails])

  const data = preliminariesDetails?.data

  let optionalDocuments = [
    'address_verifying_documents',
    'poa_signature',
    'poa_documents',
    // 'aoa_document',
    // 'moa_document',
    'map_documents',
    // 'registration_id',
  ]

  const requiredDocuments: string[] = [
    'identification_certificate',
    'upload_pp_size_photo',
    'signature',
    'upload_AOF',
  ]

  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return ''
  }

  const additionalRequiredDocs: any = []
  if (data?.id_type_id?.title === 'PAN CARD') {
    additionalRequiredDocs.push('personal_pan')
  } else if (data?.id_type_id?.title === 'VOTERS ID CARD') {
    additionalRequiredDocs.push('voters_id')
  } else if (data?.id_type_id?.title === 'DRIVING LICENSE') {
    additionalRequiredDocs.push('driving_license')
  } else if (data?.id_type_id?.title === 'PASSPORT') {
    additionalRequiredDocs.push('passport')
  }
  let finalRequiredDocuments = [...requiredDocuments, ...additionalRequiredDocs]

  const idTypeDocs = ['personal_pan', 'voters_id', 'driving_license', 'passport']
  optionalDocuments = optionalDocuments.concat(
    idTypeDocs.filter((doc) => !additionalRequiredDocs.includes(doc))
  )
  const handleCifFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setCifFormFiles((prev) => {
      const newCifFiles = [...prev]
      newCifFiles[index] = {id: documentTypeId, file}
      return newCifFiles
    })
  }

  const handleOtherFormFileUpload = (index: number, file: RcFile, documentTypeId: string) => {
    setOtherFiles((prev) => {
      const newOtherFiles = [...prev]
      newOtherFiles[index] = {id: documentTypeId, file}
      return newOtherFiles
    })
  }
  const handleFileUpload = (file: RcFile, documentTypeId: string) => {
    setUploadedFiles((prevState) => {
      const existingFileIndex = prevState.findIndex((file) => file.id === documentTypeId)
      if (existingFileIndex !== -1) {
        const newState = [...prevState]
        newState[existingFileIndex] = {id: documentTypeId}
        return newState
      } else {
        return [...prevState, {id: documentTypeId}]
      }
    })
  }

  const validateRequiredDocuments = () => {
    const requiredDocIds = documentTypeList?.data?.document_types
      .filter((doc: any) => finalRequiredDocuments.includes(doc.cbs_code))
      .map((doc: any) => doc.id)

    const uploadedDocIds = uploadedFiles.map((file) => file?.id)
    const existingDocIds = existingFiles.map((file) => file?.id)

    const missingRequiredDocs = requiredDocIds.every(
      (docId: any) => uploadedDocIds.includes(docId) || existingDocIds.includes(docId)
    )

    // setMissingDocs(missingRequiredDocs)

    const hasCifFormUploaded = cifFormFilesIds.some((file) => file.id !== '')
    // Error handling for missing CIF Form files
    if (!hasCifFormUploaded) {
      toast.error('Please upload at least one CIF Form as it is mandatory.')
      return false
    }
    if (!missingRequiredDocs) {
      setMissingDocs((prev) => [...new Set([...prev, ...requiredDocIds])])
    } else {
      setMissingDocs((prev) => prev.filter((id) => !requiredDocIds.includes(id)))
    }

    return missingRequiredDocs && hasCifFormUploaded
  }

  useEffect(() => {
    if (btnFlag === 'save_and_continue') {
      const isValid = validateRequiredDocuments()
      if (isValid) {
        setMissingDocs([])
      }
    }
  }, [uploadedFiles, existingFiles, otherFiles])

  const handleSubmit = async (isSubmit: boolean) => {
    isSubmit && setBtnFlag('save_and_continue')
    !isSubmit && setBtnFlag('save')

    // Check if at least one document is uploaded
    const isAnyDocumentUploaded =
      uploadedFiles.length > 0 ||
      existingFiles.length > 0 ||
      cifFormFiles.some((file) => file.file !== null) ||
      otherFiles.some((file) => file.file !== null)

    // Ensure at least one CIF form is uploaded
    const isCifFormUploaded = cifFormFiles.some((file) => {
      console.log(file)
      return file.id !== ''
    })

    console.log(isCifFormUploaded)
    if (!isCifFormUploaded) {
      toast.error('Please upload at least one CIF Form as it is mandatory.')
      return
    }

    if (!isSubmit && !isAnyDocumentUploaded) {
      toast.error('Please upload at least one document to save as draft.')
      return
    }

    // Validate required documents for submit action
    if (isSubmit && !validateRequiredDocuments()) {
      toast.error('Please upload all required documents.')
      return
    }

    const documentIdsToSubmit = [
      ...new Set([
        ...uploadedFiles?.map((file) => file.id),
        ...existingFiles?.map((file) => file.id),
        ...cifFormFiles?.map((file) => file.id),
        ...otherFiles?.map((file) => file.id),
      ]),
    ]

    const payload = {
      action: isSubmit ? 'save_and_continue' : 'save',
      documentIds: documentIdsToSubmit,
    }

    try {
      setLoading(true)
      const queryClient = new QueryClient()
      const response = await axios.post(
        `${API_URL}/new-cif-new-account/${uniqueId}/branch-maker-document-form`,
        payload
      )

      if (response) {
        toast.success(response?.data?.resDesc)
      }

      invalidateQuery()
      refetch()
      setLoading(false)
      history.push('/new-cif-new-accounts')
    } catch (error: any) {
      setLoading(false)
      setBtnFlag('')
      toast.error('Failed to upload documents.')
      console.error('Error uploading documents:', error)
    }
  }

  if (loadingDocumentTypes || loadingPreliminaryData) {
    return (
      <div>
        Loading... <Spinner animation={'border'} />
      </div>
    )
  }

  const getExistingFile = (documentTypeId: string, index?: number) => {
    // If CIF Form or Other Documents, fetch from additional_documents
    // const additionalDoc = preliminariesDetails?.data?.additional_documents?.documents?.find(
    //   (doc: any) => doc.documentType.id === documentTypeId
    // )[index]
    const additionalDoc = index
      ? preliminariesDetails?.data?.additional_documents?.documents?.filter(
          (doc: any) => doc.documentType.id === documentTypeId
        )[index]
      : preliminariesDetails?.data?.additional_documents?.documents?.find(
          (doc: any) => doc.documentType.id === documentTypeId
        )

    if (additionalDoc) {
      return [
        {
          name: additionalDoc?.file_name,
          url: additionalDoc?.file_url,
          id: additionalDoc?.id,
          docId: additionalDoc.documentType.id,
        },
      ]
    }
    // if (!isEmpty(additionalDoc)) {
    //   const allOtherDoc = additionalDoc?.map((doc: any) => ({
    //     name: doc?.file_name,
    //     url: doc?.file_url,
    //   }))
    //   return allOtherDoc
    // }

    // For all other documents, fetch from account_documents
    const document = preliminariesDetails?.data?.account_documents?.documents.find(
      (doc: any) => doc.documentType.id === documentTypeId
    )

    return document
      ? [
          {
            name: document?.file_name,
            status: 'done',
            url: document?.file_url,
            id: document?.id,
            docId: document?.documentType?.id,
          },
        ]
      : []
  }

  const individualDocs = documentTypeList?.data?.document_types.filter(
    (documentType: any) =>
      finalRequiredDocuments.includes(documentType.cbs_code) ||
      optionalDocuments.includes(documentType.cbs_code)
  )

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            <section className=''>
              <h3 className='fw-bolder mb-4 text-capitalize'>Account Details</h3>

              {/* <h2 style={{color: 'brown'}}>Account Details</h2> */}
              <FormDetails
                key='screening_id'
                label='Screening Id'
                value={getStringValue(data?.screening_id)}
              />
              <FormDetails
                key='account_type_id'
                label='Account Type'
                value={getStringValue(data?.account_type_id?.cbs_code)}
              />
              <FormDetails
                key='branch_code'
                label='Branch Code'
                value={getStringValue(data.branch_code)}
              />
              <FormDetails
                key='customer_code'
                label='Customer Code'
                value={getStringValue(data.customer_code)}
              />
            </section>
            <hr />
            <section className=''>
              <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
              <div className='row'>
                <FormDetails
                  key='first_name'
                  label='First Name'
                  value={getStringValue(data.first_name)}
                />
                <FormDetails
                  key='middle_name'
                  label='Middle Name'
                  value={getStringValue(data.middle_name)}
                />
                <FormDetails
                  key='last_name'
                  label='Last Name'
                  value={getStringValue(data.last_name)}
                />

                <FormDetails
                  key='id_type_id'
                  label='Identification Type'
                  value={getStringValue(data.id_type_id)}
                />
                <FormDetails
                  key='identification_number'
                  label='Identification Number'
                  value={getStringValue(data.identification_number)}
                />
                <FormDetails
                  key='account_category_id'
                  label='Account Category'
                  value={getStringValue(data?.account_opening?.account_category_id)}
                />
                <FormDetails
                  key='account_Scheme_id'
                  label='Account Scheme'
                  value={getStringValue(data?.account_opening?.account_scheme_id)}
                />
                <FormDetails
                  key='permanent_mobile_number'
                  label='Mobile Number'
                  value={getStringValue(data?.permanent_mobile_number)}
                />
                <FormDetails
                  key=''
                  label='Email Address'
                  value={getStringValue(data?.permanent_email)}
                />
              </div>
            </section>
            {data?.account_type_id?.title === 'Joint' && (
              <div>
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Partner's Details</h3>
                  <div className='row'>
                    {data?.account_holders?.account_holders.map((holder: any, index: any) => (
                      <div key={index} className='row'>
                        <FormDetails
                          key={`cif_number_${index}`}
                          label='CIF Number'
                          value={holder.cif_number}
                        />
                        <FormDetails
                          key={`first_name_${index}`}
                          label='First Name'
                          value={holder.first_name}
                        />
                        <FormDetails
                          key={`middle_name_${index}`}
                          label='Middle Name'
                          value={holder.middle_name}
                        />
                        <FormDetails
                          key={`last_name_${index}`}
                          label='Last Name'
                          value={holder.last_name}
                        />
                        <FormDetails
                          key={`id_type_id_${index}`}
                          label='Identification Type'
                          value={holder.id_type_id?.title}
                        />
                        <FormDetails
                          key={`id_number_${index}`}
                          label='Identification Number'
                          value={holder.id_number}
                        />
                        <hr />
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div>
            <div className='heading'>
              <h2 style={{color: 'brown'}}>Document Requirements - Joint Account</h2>
              <i>Please upload file of size mentioned here:</i>
              <ul>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAOF}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleCIFFORM}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleIdentificationCertificate}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePP}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>
                  {OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleSignature}{' '}
                  <span style={{color: 'red', fontSize: '20px'}}> *</span>
                </li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePOASignature}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titlePOADocument}</li>
                {/* <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAOADocument}</li> */}
                {/* <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleMOADocument}</li> */}
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleAccountVerify}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleOtherDocuments}</li>
                <li>{OFFLINE_DOCUMENT_UPLOAD_REQUIREMENTS?.titleMapDocuments}</li>
              </ul>
            </div>
            {loadingPreliminaryData ? (
              <div>
                <h4>Please Wait...</h4>
                <Spinner animation={'border'} />
              </div>
            ) : (
              <div>
                <Row gutter={16}>
                  {individualDocs?.map((documentType: any) => (
                    // <div key={documentType.id}>
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={documentType.title}
                      maxSize={Number(documentType.max_size)}
                      onChange={(file: RcFile) => handleFileUpload(file, documentType.id)}
                      documentTypeId={documentType.id}
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(documentType.id)}
                      initialFile={getExistingFile(documentType.id)}
                      existingFilesid={existingFilesIds}
                      validateRequiredDocuments={validateRequiredDocuments}
                      setExistingFiles={setExistingFiles}
                      setUploadedFiles={setUploadedFiles}
                      otherFilesId={otherFilesIds}
                      setCifFilesIds={setCifFormFilesIds}
                    />
                    // </div>
                  ))}
                </Row>
                {/* Render multiple CIF Form fields */}
                {cifFormFiles?.map((_, index) => (
                  <div key={`cif_form_${index}`} style={{marginBottom: '20px', width: '30%'}}>
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={'CIF Form'}
                      maxSize={2000}
                      onChange={(file: RcFile) =>
                        handleCifFormFileUpload(
                          index,
                          file,
                          documentTypeList?.data?.document_types.find(
                            (item: any) => item?.cbs_code === 'cif_form'
                          )?.id
                        )
                      }
                      documentTypeId={
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id
                      }
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id
                      )}
                      initialFile={getExistingFile(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'cif_form'
                        )?.id,
                        index
                      )}
                      cifFilesIds={cifFormFilesIds}
                      setCifFilesIds={setCifFormFilesIds}
                      validateRequiredDocuments={validateRequiredDocuments}
                      setExistingFiles={setExistingFiles}
                      setUploadedFiles={setUploadedFiles}
                      // setCifFormFiles={setCifFormFiles}
                    />
                  </div>
                ))}

                {/* Button to add more CIF Forms */}
                <div className='col-12'>
                  <Button
                    onClick={() => setCifFormFiles((prev) => [...prev, {id: '', file: null}])}
                    variant='outline-primary'
                  >
                    Add More CIF Form
                  </Button>
                </div>

                {/* Render multiple Other Documents fields */}
                {otherFiles.map((_: any, index: number) => (
                  <div
                    key={`other_documents_${index}`}
                    style={{marginBottom: '20px', width: '30%'}}
                  >
                    <FileUploadComponent
                      accountType={data?.account_type_id?.cbs_code}
                      documentType={'Other Documents'}
                      maxSize={2000}
                      onChange={(file: RcFile) =>
                        handleOtherFormFileUpload(
                          index,
                          file,
                          documentTypeList?.data?.document_types?.find(
                            (item: any) => item?.cbs_code === 'other_documents'
                          )?.id
                        )
                      }
                      documentTypeId={
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id
                      }
                      uniqueID={uniqueId}
                      resetUploads={resetUploads}
                      setResetUploads={setResetUploads}
                      setUploadDocumentsTypeId={setUploadDocumentsTypeId}
                      isMissing={missingDocs.includes(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id
                      )}
                      initialFile={getExistingFile(
                        documentTypeList?.data?.document_types?.find(
                          (item: any) => item?.cbs_code === 'other_documents'
                        )?.id,
                        index
                      )}
                      otherFilesId={otherFilesIds}
                      validateRequiredDocuments={validateRequiredDocuments}
                      setExistingFiles={setExistingFiles}
                      setUploadedFiles={setUploadedFiles}
                      cifFilesIds={cifFormFilesIds}
                      setCifFilesIds={setCifFormFilesIds}
                    />
                  </div>
                ))}

                {/* Button to add more Other Documents */}
                <div className='col-12'>
                  <Button
                    onClick={() => setOtherFiles((prev) => [...prev, {id: '', file: null}])}
                    // onClick={() => setOtherDocumentsCount(otherDocumentsCount + 1)}
                    variant='outline-primary'
                  >
                    Add More Other Documents
                  </Button>
                </div>
              </div>
            )}
            <Button
              type='primary'
              onClick={() => handleSubmit(false)}
              style={{margin: '20px 10px'}}
            >
              {loading && btnFlag === 'save' ? 'Saving as draft...' : 'Save as draft'}
            </Button>
            <Button
              type='primary'
              onClick={() => handleSubmit(true)}
              style={{marginTop: '20px 10px'}}
            >
              {loading && btnFlag === 'save_and_continue' ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DocumentUploadJoint
