import axios from 'axios'
import {ONLINE_ACCOUNT_OPENING} from '../../api/apiEndPoints/EndPoints'
import {toast} from 'react-toastify'
import {useMutation, useQueryClient} from 'react-query'

export const usePostOnlineAccountOpeningQaReview = (customerId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: {status: string; remarks: string}) =>
      onlineAccountOpeningQaReview(customerId, payload),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['OnlineAccountOpening']}),
  })
}

const onlineAccountOpeningQaReview = async (
  customerId: string,
  payload: {status: string; remarks: string}
) => {
  try {
    return await axios.post(`${ONLINE_ACCOUNT_OPENING}/${customerId}/qa-review`, payload)
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error while Submitting Review :: ', error)
      toast.error(error?.response?.data?.message ?? 'An error occured while submitting review')
    } else {
      console.error('Error while Submitting Review :: ', error)
    }
  }
}
