import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {isEmpty} from 'lodash'
import useGetBranchCheckerCorporateAccountLists from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/useGetBranchCheckerCorporateAccountLists'

const Cell = Table.Cell

const NormalCorporateTable = () => {
  const history = useHistory()
  const [params, setParams] = useState({
    user_type: 'branch_checker',
    cif_number: '',
    opening_mode: 'normal',
    from_date: '',
    to_date: '',
    page: 1,
    limit: 10,
    keyword: '',
    orderBy: 'updated_at',
    order: 'desc',
  })

  const {data: newCorporateBranchCheckerCIFandAccountListData, isLoading: loading} =
    useGetBranchCheckerCorporateAccountLists(params)

  const data =
    newCorporateBranchCheckerCIFandAccountListData?.data?.corporate_accounts?.map(
      (newAccount: any, i: any) => ({
        ...newAccount,
        sn: (params?.page - 1) * params?.limit + (i + 1),
        account_name: `${newAccount?.institution_name}`,
        updatedOn: newAccount?.account_status?.updated_date,
        updatedBy: newAccount?.account_status?.updated_by,

        remarks: newAccount?.account_status?.remarks ?? '',
        status: newAccount?.account_status?.current_status ?? '',
        accountNumber: newAccount?.registration_detail?.account_number ?? '',
        cifNumber: newAccount?.registration_detail?.cif_number ?? '',
        btnStatus: newAccount?.btn_status ?? '',
        constitution: newAccount?.constitution_id.title ?? '',
        registeredWith: newAccount?.registration_with_id?.title ?? '',
      })
    ) ?? []
  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    const handleButtonClick = () => {
      if (rowData.btnStatus === 'Continue') {
        history.push({
          pathname: `/corporate-branch-checker-normal/review/${rowData?.id}`,
          state: rowData,
        })
      } else if (rowData.btnStatus === 'Review') {
        history.push({
          pathname: `/corporate-branch-checker-normal/review/${rowData?.id}`,
          state: rowData,
        })
      } else {
        history.push({
          pathname: `/create-new-cif-and-account/biometric-verification/${rowData?.id}`,
          state: rowData,
        })
      }
    }

    return (
      <Cell {...props} className='link-group'>
        <button
          className={
            rowData?.btnStatus == 'Continue'
              ? 'dt-btn dt-btn-outline-primary dt-btn-sm'
              : 'btn btn-primary btn-sm '
          }
          onClick={handleButtonClick}
        >
          {rowData.btnStatus}
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 80,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Account Name',
      dataKey: 'account_name',
      cell: <Cell dataKey='account_name' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Account Number',
      dataKey: 'accountNumber',
      width: 180,
      cell: <Cell dataKey='accountNumber' />,
    },
    {
      label: 'CIF Number',
      dataKey: 'cifNumber',
      cell: <Cell dataKey='cifNumber' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Registered WIth',
      dataKey: 'registeredWith',
      cell: <Cell dataKey='registeredWith' />,
      sortable: false,
      width: 250,
    },
    {
      label: 'Constitution',
      dataKey: 'constitution',
      cell: <Cell dataKey='constitution' />,
      sortable: false,
      width: 250,
    },

    {
      label: 'Updated On',
      dataKey: 'updatedOn',
      cell: <Cell dataKey='updatedOn' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Updated By',
      dataKey: 'updatedBy',
      cell: <Cell dataKey='updatedBy' />,
      sortable: false,
      width: 160,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      cell: <Cell fullText dataKey='remarks' />,
      sortable: false,
      width: 230,
    },
    {
      label: 'Status',
      dataKey: 'status',
      cell: <Cell dataKey='status' />,
      sortable: false,
      width: 200,
    },

    {
      label: 'Action',
      width: 160,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params.keyword}
          onChange={(value: string) => setParams({...params, keyword: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.keyword) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, keyword: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={newCorporateBranchCheckerCIFandAccountListData?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default NormalCorporateTable
