import React from 'react'
interface IProps {
  counIndividualData: any
  show: boolean
  linked: boolean
}
const Individual = ({counIndividualData, show, linked}: IProps) => {
  return (
    <div className='row gy-5'>
      <div className='col-md-6 col-sm-12'>
        <div className='card text-center shadow-sm'>
          <div className='card-body'>
            <h5 className='card-title'>
              {show ? 'Individual/Joint/Minor Overview' : 'Individual/Minor Overview '}
            </h5>
            <p className='text-muted'>
              Total Count:{' '}
              <span className='text-primary'>
                {counIndividualData?.data?.individual?.total_count}
              </span>
            </p>
            <p className='text-muted'>
              Total Pending:{' '}
              <span className='text-warning'>
                {counIndividualData?.data?.individual?.total_pending}
              </span>
            </p>
            <p className='text-muted'>
              Total Completed:{' '}
              <span className='text-success'>
                {counIndividualData?.data?.individual?.total_completed}
              </span>
            </p>
            {/*<p className='text-muted'>*/}
            {/*  Total in RPA :{' '}*/}
            {/*  <span className='text-success'>*/}
            {/*    {counIndividualData?.data?.individual?.total_in_rpa}*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p className='text-muted'>
              Total Rejected:{' '}
              <span className='text-danger'>
                {counIndividualData?.data?.individual?.total_rejected}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className='col-md-6 col-sm-12'>
        <div className='card text-center shadow-sm'>
          <div className='card-body'>
            <h5 className='card-title'>Corporate Overview</h5>
            <p className='text-muted'>
              Total Count: {counIndividualData?.data?.corporate?.total_count}
            </p>
            <p className='text-muted'>
              Total Pending:{' '}
              <span className='text-warning'>
                {counIndividualData?.data?.corporate?.total_pending}
              </span>
            </p>
            <p className='text-muted'>
              Total Completed:{' '}
              <span className='text-success'>
                {counIndividualData?.data?.corporate?.total_completed}
              </span>
            </p>
            {/*<p className='text-muted'>*/}
            {/*  Total in RPA :{' '}*/}
            {/*  <span className='text-success'>*/}
            {/*    {counIndividualData?.data?.corporate?.total_in_rpa}*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p className='text-muted'>
              Total Rejected:{' '}
              <span className='text-danger'>
                {counIndividualData?.data?.corporate?.total_rejected}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="col-12">

      <div className='row gy-4'>
          {/* Branch Maker */}
          <div className='col-12'>
            
        <h3 className=' mt-5  border-bottom border-danger heading__component__title fw-bolder pb-3'>Branch Details</h3>
        </div>
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>Branch Maker</h4>
              {linked ? (
                <>
                  {' '}
                  <p className='card-text'>
                    {/* Individual/Joint/Minor Count:{' '} */}
                    {show
                      ? 'Pending Individual/Joint/Minor Count '
                      : 'Pending Individual/Minor Count '}{' '}
                    : <span>{counIndividualData?.data?.individual?.total_in_branch_maker}</span>
                  </p>
                  <p className='card-text mb-20'>
                    Pending Corporate Count:{' '}
                    <span>{counIndividualData?.data?.corporate?.total_in_branch_maker}</span>
                  </p>
                </>
              ) : (
                <p>Not Available</p>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>Branch Checker</h4>
              {linked ? (
                <>
                  <p className='card-text'>
                    {show
                      ? 'Pending Individual/Joint/Minor Count'
                      : 'Pending Individual/Minor Count '}
                    {''} :{' '}
                    <span> {counIndividualData?.data?.individual?.total_in_branch_checker}</span>
                  </p>
                  <p className='card-text'>
                    Pending Corporate Count :{' '}
                    <span>{counIndividualData?.data?.corporate?.total_in_branch_checker}</span>
                  </p>
                  <p className='card-text'>
                    {show
                      ? 'Individual/Joint/Minor Rejected Count'
                      : 'Individual/Minor Rejected Count '}
                    {''} : {''}
                    <span className='text-danger'>
                      {counIndividualData?.data?.individual?.total_rejected_by_branch_checker}
                    </span>
                  </p>
                  <p className='card-text'>
                    Corporate Rejected Count :{' '}
                    <span className='text-danger'>
                      {counIndividualData?.data?.corporate?.total_rejected_by_branch_checker}
                    </span>
                  </p>
                </>
              ) : (
                <p>Not Available</p>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>QA</h4>
              {linked ? (
                <>
                  <p className='card-text'>
                    {show
                      ? 'Pending Individual/Joint/Minor Count'
                      : 'Pending Individual/Minor Count '}{' '}
                    : <span>{counIndividualData?.data?.individual?.total_in_qa}</span>
                  </p>
                  <p className='card-text'>
                    Pending Corporate Count :{' '}
                    <span>{counIndividualData?.data?.corporate?.total_in_qa}</span>
                  </p>
                  <p className='card-text'>
                    {/* Individual/Joint/Minor Rejected:{' '} */}
                    {show
                      ? 'Individual/Joint/Minor Rejected Count'
                      : 'Individual/Minor Rejected Count '}{' '}
                    :{' '}
                    <span className='text-danger'>
                      {counIndividualData?.data?.individual?.total_rejected_by_qa}
                    </span>
                  </p>
                  <p className='card-text'>
                    Corporate Rejected :{' '}
                    <span className='text-danger'>
                      {counIndividualData?.data?.corporate?.total_rejected_by_qa}
                    </span>
                  </p>
                </>
              ) : (
                <p>Not Available</p>
              )}
            </div>
          </div>
        </div>
        {/* CPU Maker */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>CPU Maker</h4>
              <p className='card-text'>
                {show ? 'Pending Individual/Joint/Minor Count' : 'Pending Individual/Minor Count '}{' '}
                :<span>{counIndividualData?.data?.individual?.total_in_cpu_maker}</span>
              </p>
              <p className='card-text'>
                Pending Corporate Count :{' '}
                <span>{counIndividualData?.data?.corporate?.total_in_cpu_maker}</span>
              </p>
            </div>
          </div>
        </div>

        {/* CPU Checker */}
        <div className='col-md-4 col-sm-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='card-title mb-3'>CPU Checker</h4>
              <p className='card-text'>
                {show ? 'Pending Individual/Joint/Minor Count' : ' Pending Individual/Minor Count '}{' '}
                : {''}
                <span>{counIndividualData?.data?.individual?.total_in_cpu_checker}</span>
              </p>
              <p className='card-text'>
                Pending Corporate Count :{' '}
                <span>{counIndividualData?.data?.corporate?.total_in_cpu_checker}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Individual
