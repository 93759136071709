import React, {useEffect} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {FaPlus} from 'react-icons/fa'

const BusinessDetailsForm = ({values, handleChange, errors, touched, setFieldValue}: any) => {
  const {setFieldValue: setFieldData} = useFormikContext()
  console.log(values?.income_source_id)

  useEffect(() => {
    // Check income_source_id and push default values if necessary
    if (
      values?.income_source_id?.title === 'Business Income' ||
      values?.income_source_id?.title === 'Salary'
    ) {
      if (!values.businesses || values.businesses.length === 0) {
        setFieldValue('businesses', [
          {
            name_of_institution: '',
            address: '',
            telephone_number: '',
            designation: '',
            email: '',
            expected_annual_income: '',
          },
        ])
      }
    }
  }, [values.income_source_id, setFieldValue, values.businesses])

  return (
    <div className=''>
      <div className=''>
        <FieldArray name='businesses'>
          {({remove, push}) => (
            <div>
              {values?.businesses?.length > 0 ? (
                values?.businesses?.map((business: any, index: any) => (
                  <div className='row mb-3' key={index}>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Name of Institution'
                        name={`businesses.${index}.name_of_institution`}
                        label='Name of Institution'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={50}
                        value={business.name_of_institution}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Address'
                        name={`businesses.${index}.address`}
                        label='Address'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={15}
                        value={business.address}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Telephone Number'
                        name={`businesses.${index}.telephone_number`}
                        label='Telephone Number'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={10}
                        value={business.telephone_number}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Designation'
                        name={`businesses.${index}.designation`}
                        label='Designation'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        value={business.designation}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='email'
                        placeholder='Email'
                        name={`businesses.${index}.email`}
                        label='Email'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={50}
                        value={business.email}
                      />
                    </div>
                    <div className='col-md-6'>
                      <FormTextBox
                        labelClassName='col-lg-12'
                        containerClassName='col-lg-12'
                        type='text'
                        placeholder='Expected Annual Income'
                        name={`businesses.${index}.expected_annual_income`}
                        label='Expected Annual Income'
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={10}
                        value={business.expected_annual_income}
                      />
                    </div>
                    <div className='col-md-12 mt-3'>
                      <button
                        type='button'
                        className='dt-btn dt-btn-primary'
                        data-cy='remove-button'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>Click the "Add Associated Business" button to add details.</p>
              )}
              <button
                type='button'
                className='dt-btn dt-btn-primary'
                data-cy='add-button'
                onClick={() =>
                  push({
                    name_of_institution: '',
                    address: '',
                    telephone_number: '',
                    designation: '',
                    email: '',
                    expected_annual_income: '',
                  })
                }
              >
                <FaPlus className='me-2' />
                Add Business
              </button>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export default BusinessDetailsForm
