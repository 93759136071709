import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostIndividualQAReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostIndividualQAReview'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {Button, Spinner} from 'react-bootstrap-v5'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useRef, useState} from 'react'
import {
  convertNepaliDateToEnglish,
  formatDateToInput,
  getOptionValueByKey,
  getOptionValueByKeyCBS,
  getOptionValueByKeyZone,
  getRelationshipNameByCode,
  splitName,
} from 'src/app/modules/common/SplitFunction'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import useGetCorporatePreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import CPUCheckerMOdal from '../../ModalCpuChecker'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import StatusLog from '../../../../branchChecker/CheckerList/Individual/StatusLog'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {UserActivityLogAll} from '../../../../../../common/components/AllActivityLog'
import {Form, Formik} from 'formik'
import {REVIEW_VALIDATION_SCHEMA} from '../../../../../../accountMaintenance/CPUChecker/NewData'
import FormSelect from '../../../../../../../../cms/helpers/components/forms/FormSelect'

interface RouteParams {
  id: string
}

interface IProps {
  ListData: any
  isLoading: boolean
}

const CorporateCPUChecker = ({ListData, isLoading}: IProps) => {
  const dispatch = useDispatch()
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  // const {data: preliminariesDatas} = useGetPreliminariesDetailsById(id)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])

  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const [replacementPan, setReplacementPan] = useState('')
  const [replacementError, setReplacementError] = useState('')

  const handleClose = () => {
    setIsOpen(false)
  }

  // const data = preliminariesDatas?.data

  const handleScreening = async () => {
    // if (ListData?.data?.cif_details?.GeneralDetails[0]?.IDTYPE !== 'CTZ') {
    //   if (!replacementPan) {
    //     setReplacementError('Please provide PAN number.')
    //     return
    //   } else {
    //     setReplacementError('')
    //   }
    // }

    let checkAllPayload: any = {
      customer_name: ListData?.data?.cif_details?.GeneralDetails[0]?.CUST_NAME,
      // pan_number: ListData?.data?.cif_details?.GeneralDetails[0]?.IDNO,
      model_name: 'ExistingCifAccountDetails',
    }

    // if (ListData?.data?.cif_details?.data?.GeneralDetails[0]?.IDTYPE !== 'CTZ') {
    //   checkAllPayload = {
    //     ...checkAllPayload,
    //     pan_number: replacementPan,
    //   }
    // }

    setScreeningLoading(true)
    update.current = 'default'
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: number) => {
    let checkAllPayload: any = {}

    if (update.current === 'minor') {
      // const guardian_full_name: string = [
      //   !isLoading &&
      //     ListData?.data?.cif_details?.GurdianDetails[0]?.MINOR_GUARD_NAME &&
      //     splitName(ListData?.data?.cif_details?.GurdianDetails[0]?.MINOR_GUARD_NAME)?.firstName,
      //   (!isLoading &&
      //     ListData?.data?.cif_details?.GurdianDetails[0]?.MINOR_GUARD_NAME &&
      //     splitName(ListData?.data?.cif_details?.GurdianDetails[0].MINOR_GUARD_NAME)?.middleName) ||
      //     '',
      //   (!isLoading &&
      //     ListData?.data?.cif_details?.GurdianDetails[0]?.MINOR_GUARD_NAME &&
      //     splitName(ListData?.data?.cif_details?.GurdianDetails[0].MINOR_GUARD_NAME)?.lastName) ||
      //     '',
      // ]
      //   .filter(Boolean) // Remove any empty or undefined values
      //   .join(' ') // Join them with a space
      // checkAllPayload = {
      //   customer_name: guardian_full_name,
      //   model_name: 'ExistingCifAccountDetails',
      // }
    } else if (update.current === 'joint') {
      // const partner = data?.account_holders?.account_holders.find(
      //   (holder: any) => holder.cif_number === cif_number
      // )
      // const partners_full_name: string = [
      //   partner?.first_name,
      //   partner?.middle_name,
      //   partner?.last_name,
      // ]
      //   .filter(Boolean) // Remove any empty or undefined values
      //   .join(' ') // Join them with a space
      // checkAllPayload = {
      //   customer_name: partners_full_name,
      //   date_of_birth_bs: partner?.date_of_birth_bs || '',
      //   unique_id_no: partner?.unique_id_no || '',
      //   citizenship: partner?.citizenship || '',
      //   gender: partner?.gender || '',
      //   model_name: 'ExistingCifAccountDetails',
      // }
    }

    setEnquiryLoading(true)
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setEnquiryLoading(false) // Reset loading state on success
        setLoadingIndex(null)
      },
      onError: (error) => {
        console.error('Error:', error)
        setEnquiryLoading(false) // Reset loading state on error
        setLoadingIndex(null)
        clickedIndex.shift()
      },
    })
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = async (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      await handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  const {
    allMasterDataOptions,
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    // isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: true,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const handleSubmit = (values: {status: string}) => {
    setIsOpen(true)
    setStatus(values?.status)
  }

  const handleBack = () => {
    history.go(-1)
  }
  const allStatusType = [
    {label: 'APPROVE', value: 'approve'},
    {label: 'REVERT BACK TO CPU MAKER', value: 'revert'},
  ]
  const getStringValue = (value: any) => {
    if (typeof value === 'string') return value
    if (typeof value === 'number') return value.toString()
    if (value && typeof value === 'object' && 'title' in value) return value.title
    return '-'
  }
  const initialValues = {
    customer_group_id: ListData?.data?.cif_details?.MisInformation[0]?.CUST_GRP,
    institution_name: ListData?.data?.cif_details?.GeneralDetails[0]?.CUST_NAME,
    address_type: ListData?.data?.cif_details?.GeneralDetails[0]?.ADDRESS_TYPE || '',
    constitution_id: ListData?.data?.cif_details?.MisInformation[0]?.CONSTITUTION,
    other_constitution: ListData?.data?.cif_details?.other_constitution,
    foreign_company_name: ListData?.data?.cif_details?.foreign_company_name,
    foreign_company_address: ListData?.data?.cif_details?.foreign_company_address,
    registration_number: ListData?.data?.cif_details?.GeneralDetails[0]?.IDNO,
    registration_with_id: ListData?.data?.cif_details?.GeneralDetails[0]?.IDREGISTEREDIN,
    registration_date_ad: ListData?.data?.cif_details?.GeneralDetails[0]?.IDREGISTEREDDATE,
    registration_date_bs: ListData?.data?.cif_details?.IDREGISTEREDDATE,
    registration_valid_upto: ListData?.data?.cif_details?.registration_expiry_date_ad,
    registration_expiry_date_bs: ListData?.data?.cif_details?.registration_expiry_date_bs || '',
    registered_office_country: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.CNTRY_CODE || '',
    registered_office_zone: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.ZONEE || '',
    registered_office_province:
      ListData?.data?.cif_details?.RetCustAddrInfo[2]?.PROVIENCE || '' || '',
    registered_office_district: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.DISTRICT_CODE,
    registered_office_city: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.CITY_CODE,
    registered_office_municipality:
      ListData?.data?.cif_details?.RetCustAddrInfo[2]?.MUNICIPALITY_VDC_NAME || '',
    registered_office_ward_number: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.WARD_NO,
    registered_office_street_name: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.STREET_NAME,
    registered_office_house_number: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.HOUSE_NO,
    registered_office_landline: ListData?.data?.cif_details?.registered_office_landline,
    registered_office_mobile_number: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.PHONE_NUM2,
    registered_office_email: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.EMAIL_ID,
    registered_office_fax_number: ListData?.data?.cif_details?.RetCustAddrInfo[2]?.FAX_NUM_ID,
    registered_office_website: ListData?.data?.cif_details?.registered_office_website,
    registered_office_outside_country_post_box:
      ListData?.data?.cif_details?.RetCustAddrInfo[2]?.POST_BOX,
    registered_office_outside_country_full_address:
      ListData?.data?.cif_details?.RetCustAddrInfo[2]?.FULL_ADDRESS,
    business_office_country: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.CNTRY_CODE,
    business_office_zone: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.ZONEE,
    business_office_province: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.PROVIENCE || '',
    business_office_district: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.DISTRICT_CODE,
    business_office_city: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.CITY_CODE,
    business_office_municipality:
      ListData?.data?.cif_details?.RetCustAddrInfo[0]?.MUNICIPALITY_VDC_NAME || '',
    business_office_ward_number: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.WARD_NO,
    business_office_street_name: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.STREET_NAME,
    business_office_house_number: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.HOUSE_NO,
    business_office_landline: ListData?.data?.cif_details?.business_office_landline,
    business_office_mobile_number: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.PHONE_NUM2,
    business_office_email: ListData?.data?.cif_details?.business_office_email,
    business_office_fax_number: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.FAX_NUM_ID,
    business_office_website: ListData?.data?.cif_details?.business_office_website,
    business_office_outside_country_post_box:
      ListData?.data?.cif_details?.RetCustAddrInfo[0]?.POST_BOX,
    business_office_outside_country_full_address:
      ListData?.data?.cif_details?.RetCustAddrInfo[0]?.FULL_ADDRESS,
    working_area: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.WORK_AREA,
    business_nature_id: ListData?.data?.cif_details?.RetCustAddrInfo[0]?.BUSINESS_AREA,
    income_tax_assess: ListData?.data?.cif_details?.income_tax_assess,
    pan_number: ListData?.data?.cif_details?.pan_number,
    vat_number: ListData?.data?.cif_details?.vat_number,
    expected_annual_turnover: ListData?.data?.cif_details?.MisInformation[0]?.ANNUAL_TURN_OVER,
    expected_annual_transaction_number:
      ListData?.data?.cif_details?.MisInformation[0]?.TOTAL_NO_OF_ANNUAL_TXN || '',
    bank_code_id: ListData?.data?.cif_details?.data?.bank_code_id?.id || '',
    CDD_ECDD_DATE: ListData?.data?.cif_details?.MisInformation[0]?.CDD_ECDD_DATE
      ? formatDateToInput(ListData?.data?.cif_details?.MisInformation[0]?.CDD_ECDD_DATE)
      : '',
    customer_category_id: ListData?.data?.cif_details?.customer_category_id,
    customer_status_id: ListData?.data?.cif_details?.MisInformation[0]?.CUST_STATUS || '',
    customer_free_text: ListData?.data?.cif_details?.MisInformation[0]?.CUST_FREE_TEXT || '',
    annual_turn_over_as_on:
      ListData?.data?.cif_details?.MisInformation[0]?.ANNUAL_TURN_OVER_AS_ON || '',
    aml_risk_rating: ListData?.data?.cif_details?.MisInformation[0]?.RISK_CATEGORY,
    sub_risk_category_id: ListData?.data?.cif_details?.MisInformation[0]?.RISK_SUB_CATEGORY,
    pep_status: ListData?.data?.cif_details?.pep_status,
    pep_information: ListData?.data?.cif_details?.pep_information,
    deposit_detail_id: ListData?.data?.account_details?.deposit_detail_id?.id || '',
    loan_detail_id: ListData?.data?.account_details?.loan_detail_id?.id || '',
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div
            className='card mb-5 '
            style={{position: 'sticky', top: '80px', marginLeft: '-22px'}}
          >
            <div className='card-body '>
              <DocumentsPreview data={ListData} />
            </div>
          </div>
        </div>
        <div
          className='col-md-6'
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          <div className='modal-container '>
            <div className='card-body'>
              <div className='card p-5'>
                <section className=''>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Personal Details</h3>
                  <div className='row'>
                    <FormDetails
                      key='customer_group_id'
                      label='Customer Group ID'
                      value={getStringValue(initialValues?.customer_group_id)}
                    />
                    <FormDetails
                      key='institution_name'
                      label='Institution Name'
                      value={getStringValue(initialValues?.institution_name)}
                    />
                    <FormDetails
                      key='address_type'
                      label='Address Type'
                      value={
                        initialValues?.address_type === 'P'
                          ? getStringValue('Permanent Address')
                          : getStringValue('Communication Address')
                      }
                    />
                    <FormDetails
                      key='constitution_id'
                      label='Constitution ID'
                      value={getStringValue(initialValues?.constitution_id)}
                    />
                    <FormDetails
                      key='other_constitution'
                      label='Other Constitution'
                      value={getStringValue(initialValues?.other_constitution)}
                    />
                    <FormDetails
                      key='foreign_company_name'
                      label='Foreign Company Name'
                      value={getStringValue(initialValues?.foreign_company_name)}
                    />
                    <FormDetails
                      key='foreign_company_address'
                      label='Foreign Company Address'
                      value={getStringValue(initialValues?.foreign_company_address)}
                    />
                    <FormDetails
                      key='registration_number'
                      label='Registration Number'
                      value={getStringValue(initialValues?.registration_number)}
                    />
                    <FormDetails
                      key='registration_with_id'
                      label='Registration With ID'
                      value={getStringValue(initialValues?.registration_with_id)}
                    />
                    <FormDetails
                      key='registration_date_ad'
                      label='Registration Date (AD)'
                      value={getStringValue(initialValues?.registration_date_ad)}
                    />
                    <FormDetails
                      key='registration_date_bs'
                      label='Registration Date (BS)'
                      value={getStringValue(initialValues?.registration_date_bs)}
                    />
                    <FormDetails
                      key='registration_valid_upto'
                      label='Registration Valid Upto'
                      value={getStringValue(initialValues?.registration_valid_upto)}
                    />
                    <FormDetails
                      key='registration_expiry_date_bs'
                      label='Registration Expiry Date (BS)'
                      value={getStringValue(initialValues?.registration_expiry_date_bs)}
                    />
                    <FormDetails
                      key='registered_office_country'
                      label='Registered Office Country'
                      value={getStringValue(initialValues?.registered_office_country)}
                    />
                    <FormDetails
                      key='registered_office_zone'
                      label='Registered Office Zone'
                      value={getStringValue(initialValues?.registered_office_zone)}
                    />
                    <FormDetails
                      key='registered_office_province'
                      label='Registered Office Province'
                      value={getStringValue(initialValues?.registered_office_province)}
                    />
                    <FormDetails
                      key='registered_office_district'
                      label='Registered Office District'
                      value={getStringValue(initialValues?.registered_office_district)}
                    />
                    <FormDetails
                      key='registered_office_city'
                      label='Registered Office City'
                      value={getStringValue(initialValues?.registered_office_city)}
                    />
                    <FormDetails
                      key='registered_office_municipality'
                      label='Registered Office Municipality'
                      value={getStringValue(initialValues?.registered_office_municipality)}
                    />
                    <FormDetails
                      key='registered_office_ward_number'
                      label='Registered Office Ward Number'
                      value={getStringValue(initialValues?.registered_office_ward_number)}
                    />
                    <FormDetails
                      key='registered_office_street_name'
                      label='Registered Office Street Name'
                      value={getStringValue(initialValues?.registered_office_street_name)}
                    />
                    <FormDetails
                      key='registered_office_house_number'
                      label='Registered Office House Number'
                      value={getStringValue(initialValues?.registered_office_house_number)}
                    />
                    <FormDetails
                      key='registered_office_landline'
                      label='Registered Office Landline'
                      value={getStringValue(initialValues?.registered_office_landline)}
                    />
                    <FormDetails
                      key='registered_office_mobile_number'
                      label='Registered Office Mobile Number'
                      value={getStringValue(initialValues?.registered_office_mobile_number)}
                    />
                    <FormDetails
                      key='registered_office_email'
                      label='Registered Office Email'
                      value={getStringValue(initialValues?.registered_office_email)}
                    />
                    <FormDetails
                      key='registered_office_fax_number'
                      label='Registered Office Fax Number'
                      value={getStringValue(initialValues?.registered_office_fax_number)}
                    />
                    <FormDetails
                      key='registered_office_website'
                      label='Registered Office Website'
                      value={getStringValue(initialValues?.registered_office_website)}
                    />
                    <FormDetails
                      key='registered_office_outside_country_post_box'
                      label='Registered Office Outside Country Post Box'
                      value={getStringValue(
                        initialValues?.registered_office_outside_country_post_box
                      )}
                    />
                    <FormDetails
                      key='registered_office_outside_country_full_address'
                      label='Registered Office Outside Country Full Address'
                      value={getStringValue(
                        initialValues?.registered_office_outside_country_full_address
                      )}
                    />
                    <FormDetails
                      key='business_office_country'
                      label='Business Office Country'
                      value={getStringValue(initialValues?.business_office_country)}
                    />
                    <FormDetails
                      key='business_office_zone'
                      label='Business Office Zone'
                      value={getStringValue(initialValues?.business_office_zone)}
                    />
                    <FormDetails
                      key='business_office_province'
                      label='Business Office Province'
                      value={getStringValue(initialValues?.business_office_province)}
                    />
                    <FormDetails
                      key='business_office_district'
                      label='Business Office District'
                      value={getStringValue(initialValues?.business_office_district)}
                    />
                    <FormDetails
                      key='business_office_city'
                      label='Business Office City'
                      value={getStringValue(initialValues?.business_office_city)}
                    />
                    <FormDetails
                      key='business_office_municipality'
                      label='Business Office Municipality'
                      value={getStringValue(initialValues?.business_office_municipality)}
                    />
                    <FormDetails
                      key='business_office_ward_number'
                      label='Business Office Ward Number'
                      value={getStringValue(initialValues?.business_office_ward_number)}
                    />
                    <FormDetails
                      key='business_office_street_name'
                      label='Business Office Street Name'
                      value={getStringValue(initialValues?.business_office_street_name)}
                    />
                    <FormDetails
                      key='business_office_house_number'
                      label='Business Office House Number'
                      value={getStringValue(initialValues?.business_office_house_number)}
                    />
                    <FormDetails
                      key='business_office_landline'
                      label='Business Office Landline'
                      value={getStringValue(initialValues?.business_office_landline)}
                    />
                    <FormDetails
                      key='business_office_mobile_number'
                      label='Business Office Mobile Number'
                      value={getStringValue(initialValues?.business_office_mobile_number)}
                    />
                    <FormDetails
                      key='business_office_email'
                      label='Business Office Email'
                      value={getStringValue(initialValues?.business_office_email)}
                    />
                    <FormDetails
                      key='business_office_fax_number'
                      label='Business Office Fax Number'
                      value={getStringValue(initialValues?.business_office_fax_number)}
                    />
                    <FormDetails
                      key='business_office_website'
                      label='Business Office Website'
                      value={getStringValue(initialValues?.business_office_website)}
                    />
                    <FormDetails
                      key='business_office_outside_country_post_box'
                      label='Business Office Outside Country Post Box'
                      value={getStringValue(
                        initialValues?.business_office_outside_country_post_box
                      )}
                    />
                    <FormDetails
                      key='business_office_outside_country_full_address'
                      label='Business Office Outside Country Full Address'
                      value={getStringValue(
                        initialValues?.business_office_outside_country_full_address
                      )}
                    />
                    <FormDetails
                      key='working_area'
                      label='Working Area'
                      value={getStringValue(initialValues?.working_area)}
                    />
                    <FormDetails
                      key='business_nature_id'
                      label='Business Nature ID'
                      value={getStringValue(initialValues?.business_nature_id)}
                    />
                    <FormDetails
                      key='income_tax_assess'
                      label='Income Tax Assess'
                      value={getStringValue(initialValues?.income_tax_assess)}
                    />
                    <FormDetails
                      key='pan_number'
                      label='PAN Number'
                      value={getStringValue(initialValues?.pan_number)}
                    />
                    <FormDetails
                      key='vat_number'
                      label='VAT Number'
                      value={getStringValue(initialValues?.vat_number)}
                    />
                    <FormDetails
                      key='expected_annual_turnover'
                      label='Expected Annual Turnover'
                      value={getStringValue(initialValues?.expected_annual_turnover)}
                    />
                    <FormDetails
                      key='expected_annual_transaction_number'
                      label='Expected Annual Transaction Number'
                      value={getStringValue(initialValues?.expected_annual_transaction_number)}
                    />
                    <FormDetails
                      key='bank_code_id'
                      label='Bank Code ID'
                      value={getStringValue(initialValues?.bank_code_id)}
                    />
                    <FormDetails
                      key='customer_category_id'
                      label='Customer Category ID'
                      value={getStringValue(initialValues?.customer_category_id)}
                    />
                    <FormDetails
                      key='customer_status_id'
                      label='Customer Status ID'
                      value={getStringValue(initialValues?.customer_status_id)}
                    />
                    <FormDetails
                      key='customer_free_text'
                      label='Customer Free Text'
                      value={getStringValue(initialValues?.customer_free_text)}
                    />
                    <FormDetails
                      key='CDD_ECDD_DATE'
                      label='CDD and EDD Date'
                      value={getStringValue(initialValues?.CDD_ECDD_DATE) || '-'}
                    />
                    <FormDetails
                      key='annual_turn_over_as_on'
                      label='Annual Turnover As On'
                      value={getStringValue(initialValues?.annual_turn_over_as_on)}
                    />
                    <FormDetails
                      key='aml_risk_rating'
                      label='AML Risk Rating'
                      value={getStringValue(initialValues?.aml_risk_rating)}
                    />
                    <FormDetails
                      key='sub_risk_category_id'
                      label='Sub Risk Category ID'
                      value={getStringValue(initialValues?.sub_risk_category_id)}
                    />
                    <FormDetails
                      key='pep_status'
                      label='PEP Status'
                      value={getStringValue(initialValues?.pep_status)}
                    />
                    <FormDetails
                      key='pep_information'
                      label='PEP Information'
                      value={getStringValue(initialValues?.pep_information)}
                    />
                  </div>
                </section>

                <div className='row'>
                  <h3 className='fw-bolder mb-4 text-capitalize'>Account Opening Details</h3>
                  <div className='row'>
                    <FormDetails
                      key='initial_deposit'
                      label='Initial Deposit'
                      value={getStringValue(ListData?.data?.account_details?.initial_deposit)}
                    />
                    <FormDetails
                      key='account_currency_id'
                      label='Account Currency ID'
                      value={getStringValue(
                        ListData?.data?.account_details?.account_currency?.title
                      )}
                    />
                    <FormDetails
                      key='account_purpose_id'
                      label='Account Purpose ID'
                      value={getStringValue(
                        ListData?.data?.account_details?.purpose_of_account?.title
                      )}
                    />

                    <FormDetails
                      key='cheque_book_name'
                      label='Cheque Book Name'
                      value={getStringValue(ListData?.data?.account_details?.cheque_book_name)}
                    />
                    <FormDetails
                      key='mode_of_operation'
                      label='Mode of Operations'
                      value={getStringValue(ListData?.data?.account_details?.mode_of_operation)}
                    />

                    {ListData?.data?.account_details?.account_holders?.details?.length > 0 && (
                      <>
                        <section className=''>
                          <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                            Account Holder's Details
                          </h3>
                          <div className='row'>
                            {ListData?.data?.account_details?.account_holders?.details?.map(
                              (holder: any, index: any) => (
                                <div key={index} className='row'>
                                  <FormDetails
                                    key={`first_name_${index}`}
                                    label='First Name'
                                    value={holder?.first_name}
                                  />
                                  <FormDetails
                                    key={`middle_name_${index}`}
                                    label='Middle Name'
                                    value={holder?.middle_name}
                                  />
                                  <FormDetails
                                    key={`last_name_${index}`}
                                    label='Last Name'
                                    value={holder?.last_name}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </section>
                        <hr />
                      </>
                    )}

                    {ListData?.data?.account_details?.nominee_details?.first_name && (
                      <>
                        <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                          Nominee Details
                        </h3>
                        <FormDetails
                          key='nominee_first_name'
                          label='Nominee First Name'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.first_name
                          )}
                        />
                        <FormDetails
                          key='nominee_middle_name'
                          label='Nominee Middle Name'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.middle_name
                          )}
                        />
                        <FormDetails
                          key='nominee_last_name'
                          label='Nominee Last Name'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.last_name
                          )}
                        />
                        <FormDetails
                          key='nominee_citizenship_number'
                          label='Nominee Citizenship Number'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.citizenship_number
                          )}
                        />
                        <FormDetails
                          key='nominee_address'
                          label='Nominee Address'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.address
                          )}
                        />
                        <FormDetails
                          key='nominee_relationship_id'
                          label='Nominee Relationship ID'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.relationship
                          )}
                        />
                        <FormDetails
                          key='nominee_place_of_issue'
                          label='Nominee Place of Issue'
                          value={getStringValue(
                            ListData?.data?.account_details?.nominee_details?.issuance_place?.title
                          )}
                        />
                      </>
                    )}
                  </div>
                  <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                    Mis Details
                  </h3>
                  <FormDetails
                    key='loan_detail_id'
                    label='Loan Detail ID'
                    value={getStringValue(ListData?.data?.account_details?.loan_detail_id)}
                  />
                  <FormDetails
                    key='deposit_detail_id'
                    label='Deposit Detail ID'
                    value={getStringValue(ListData?.data?.account_details?.deposit_detail_id)}
                  />

                  {ListData?.data?.account_details?.mis_information && (
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Introducer's Details </h2>
                      <div className='row'>
                        <>
                          <FormDetails
                            label='Introducer First Name'
                            value={getStringValue(
                              ListData?.data?.account_details?.introducer_details?.first_name
                            )}
                          />
                          <FormDetails
                            label='Introducer Middle Name'
                            value={getStringValue(
                              ListData?.data?.account_details?.introducer_details?.middle_name
                            )}
                          />
                          <FormDetails
                            label='Introducer Last Name'
                            value={getStringValue(
                              ListData?.data?.account_details?.introducer_details?.last_name
                            )}
                          />
                          <FormDetails
                            label='Introducer Citizenship Number'
                            value={getStringValue(
                              ListData?.data?.account_details?.introducer_details
                                ?.customer_id_account_no
                            )}
                          />
                          <FormDetails
                            label='Introducer Address'
                            value={getStringValue(
                              ListData?.data?.account_details?.introducer_details?.address
                            )}
                          />
                        </>
                      </div>
                    </div>
                  )}
                  {ListData?.data?.account_details?.ancillary_services?.details?.length > 0 && (
                    <>
                      <section className=''>
                        <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                          Ancillaries Details
                        </h3>
                        <div className='row'>
                          {ListData?.data?.account_details?.ancillary_services?.details?.map(
                            (services: any, index: any) => (
                              <div key={index} className='row'>
                                <FormDetails
                                  key={`first_name_${index}`}
                                  label='Service Selected'
                                  value={services?.service?.title}
                                />
                                {services.reason !== null && (
                                  <FormDetails
                                    key={`middle_name_${index}`}
                                    label='Reason'
                                    value={services?.reason}
                                  />
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </section>
                      <hr />
                    </>
                  )}
                </div>
                {screeningPerformed ? (
                  checkAllData && (
                    <Button
                      type='button'
                      onClick={handleOpenPdf}
                      className='col-md-3 col-sm-6 col-xs-12'
                    >
                      View Details
                    </Button>
                  )
                ) : screeningLoading ? (
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    type='button'
                    onClick={handleScreening}
                    className='col-md-3 col-sm-6 col-xs-12'
                  >
                    Perform Screening
                  </Button>
                )}
                <div className='my-7'>
                  <h3>Screening Files</h3>
                  {ListData?.data?.account_details?.screening_files?.screening_files?.length > 0 ? (
                    ListData?.data?.account_details?.screening_files?.screening_files?.map(
                      (file: any, index: any) => (
                        <>
                          <button
                            key={index}
                            type='button'
                            onClick={() => handlePdfFileClick(file.file_path)}
                            style={{display: 'block', marginBottom: '10px'}}
                          >
                            {file.file_name}
                          </button>
                        </>
                      )
                    )
                  ) : (
                    <p>No files available</p>
                  )}
                </div>
                <div>
                  <Formik
                    initialValues={{status: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={REVIEW_VALIDATION_SCHEMA}
                  >
                    {({handleChange, errors, touched, values, setFieldValue}) => (
                      <Form>
                        <div className='row mt-8'>
                          <div className='col-md-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Status Type'
                              name='status'
                              isClearable={true}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allStatusType?.map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                              required={true}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className=' my-2 mb-6'></div>

                          <div className='d-flex justify-content-end mt-3'>
                            <button
                              type='button'
                              onClick={handleBack}
                              className='btn btn-secondary mx-4'
                            >
                              Back
                            </button>
                            <button type='submit' className='btn btn-primary'>
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <CPUCheckerMOdal
            isOpen={isOpen}
            handleClose={handleClose}
            setIsOpen={setIsOpen}
            id={id}
            screeningPerformed={screeningPerformed}
            accumulatedFilePaths={accumulatedFilePaths}
            status={status}
          />
        </div>
        <div className='pt-10'>
          <StatusLog data={ListData?.data?.account_details?.logs?.details} />
          <UserActivityLogAll caseId={id} modalName={ListData?.data?.account_details?.model_name} />
        </div>
      </div>
    </>
  )
}

export default CorporateCPUChecker
