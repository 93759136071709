import React from 'react'
import {Form, Formik} from 'formik'

import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import {RadioGroup} from 'rsuite'
import {Button} from 'react-bootstrap-v5'

import {useHistory} from 'react-router-dom'
import {
  ACCOUNT_OPENING_HEADING_NEW_CIF_NEW_ACCOUNT,
  ACCOUNT_OPENING_INFROMATION,
} from '../../createNewCIFAndAccount/utils/Messages'
import {FormValues} from '../../createNewCIFAndAccount/types/CommonTypes'
import {OPENING_ACCOUNT_TYPE} from '../../createNewCIFAndAccount/utils/validations/ValidationSchemas'

const NewCIFNewAccount = () => {
  const history = useHistory()
  const initialValues: FormValues = {
    accountType: 'normal',
    customerType: '',
  }

  const handleSubmit = (values: FormValues) => {
    if (values.accountType === 'express' && values.customerType === 'individual') {
      history.push('/new-cif-new-accounts/create-new-account/express-individual-preliminary-form')
    } else if (values.accountType === 'express' && values.customerType === 'corporate') {
      history.push('/new-cif-new-accounts/create-new-account/express-corporate-preliminary-form')
    } else if (values.accountType === 'normal' && values.customerType === 'individual') {
      history.push('/new-cif-new-accounts/create-new-account/normal-individual-preliminary-form')
    } else if (values.accountType === 'normal' && values.customerType === 'corporate') {
      history.push('/new-cif-new-accounts/create-new-account/normal-corporate-preliminary-form')
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-9'>
        <h3 className='heading__component__title fw-bolder'>
          {ACCOUNT_OPENING_HEADING_NEW_CIF_NEW_ACCOUNT}
        </h3>
        <span>
          <i>{ACCOUNT_OPENING_INFROMATION}</i>
        </span>
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={OPENING_ACCOUNT_TYPE}
            enableReinitialize
          >
            {({values, errors, handleChange, dirty, isSubmitting}) => (
              <Form>
                <div className='d-flex flex-column mb-3 mt-5'>
                  <section className='mb-5'>
                    <div className='row '>
                      <h6>
                        <span> please select type of account opening</span>
                      </h6>
                    </div>
                    <RadioGroup
                      name='accountType'
                      onChange={handleChange}
                      value={values?.accountType}
                    >
                      <div className='radio-buttons-container d-flex'>
                        <FormRadio
                          containerClassName='col-lg-2'
                          name='accountType'
                          value='express'
                          checkBoxText='Express'
                          onChange={handleChange}
                          checked={values.accountType === 'express'}
                          errors={errors}
                          touched={undefined}
                          disabled
                        />
                        <FormRadio
                          containerClassName='col-lg-2'
                          name='accountType'
                          value='normal'
                          checkBoxText='Normal'
                          onChange={handleChange}
                          checked={values.accountType === 'normal'}
                          errors={errors}
                          touched={undefined}
                        />
                      </div>
                    </RadioGroup>
                  </section>
                  <section className='mb-5'>
                    <div className='row '>
                      <h6>
                        <span>Please Select Account Type</span>
                      </h6>
                    </div>
                    <RadioGroup
                      name='customerType'
                      onChange={handleChange}
                      value={values.customerType}
                    >
                      <div className='radio-buttons-container d-flex'>
                        <FormRadio
                          containerClassName='col-lg-2'
                          name='customerType'
                          value='individual'
                          checkBoxText='Individual/Joint'
                          onChange={handleChange}
                          checked={values.customerType === 'individual'}
                          errors={errors}
                          touched={undefined}
                        />
                        <FormRadio
                          containerClassName='col-lg-2'
                          name='customerType'
                          value='corporate'
                          checkBoxText='Corporate'
                          onChange={handleChange}
                          checked={values.customerType === 'corporate'}
                          errors={errors}
                          touched={undefined}
                        />
                      </div>
                    </RadioGroup>
                  </section>
                </div>
                {/* Enable button only when the form is dirty and there are no errors */}
                <hr />
                <div className='text-end'>
                  <Button
                    type='submit'
                    className='btn btn-danger btn-sm'
                    disabled={!dirty || Object.keys(errors).length > 0 || isSubmitting}
                  >
                    Open
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default NewCIFNewAccount
