import {any} from 'prop-types'
import {tuple} from 'rsuite/esm/@types/utils'
import * as Yup from 'yup'

// Function to check if a string is a valid UUID
const isValidUUID = (value: any) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidRegex.test(value)
}
const numericRegex = /^[,A-Za-z0-9./-]+$/

// const account_type_id = (values: any) => ''
export const CPUMAKER_SAVE_AND_CONTINUE = (allMasterDataOptions: any) =>
  Yup.object().shape({
    issuing_district: Yup.string().required('The issuing district is required.'),
    // ancillary_services: Yup.array().of(
    //   Yup.object().shape({
    //     status: Yup.string().required('Ancillaries Status is required.'),
    //     service_id: Yup.string().when('status', {
    //       is: 'Yes',
    //       then: Yup.string().required('Required.'),
    //     }),
    //     reason: Yup.string()
    //       .when('status', {
    //         is: 'No',
    //         then: Yup.string().required('Reason is required.'),
    //       })
    //       .nullable(),
    //   })
    // ),
    remarks: Yup.string().required('The Remark is required.'),
    businesses: Yup.array()
      .of(
        Yup.object().shape({
          name_of_institution: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Name should not contain any numbers')
            .required('if Businesses is present Name is required'),
          address: Yup.string(),
          telephone_number: Yup.string().matches(/^[0-9,]+$/, 'Mobile number must be a number'),
          designation: Yup.string(),
          email: Yup.string(),
          expected_annual_income: Yup.string(),
        })
      )
      .when('income_source_id', {
        is: (income_source_id: any) =>
          allMasterDataOptions?.income_sources?.some((source: {id: string; title: string}) => {
            console.log(source.title)
            console.log(income_source_id)
            return (
              source.id === income_source_id?.id &&
              (source.title.toLowerCase() === 'salary' ||
                source.title.toLowerCase() === 'business income')
            )
          }),
        then: Yup.array()
          .of(
            Yup.object().shape({
              name_of_institution: Yup.string()
                .matches(/^[A-Za-z\s]+$/, 'Name should not contain any numbers')
                .required('Name of institution is required.'),
              address: Yup.string().required('Address is required.'),
              telephone_number: Yup.string()
                .matches(/^[0-9,]+$/, 'Telephone number must be a number')
                .required('Telephone number is required.'),
              designation: Yup.string().required('Designation is required.'),
              email: Yup.string().email('Invalid email address').required('Email is required.'),
              expected_annual_income: Yup.string().required('Expected annual income is required.'),
            })
          )
          .required('Businesses are required.'),
        otherwise: Yup.array().notRequired(),
      }),

    issuing_office: Yup.string().required('The issuing office is required.'),
    id_issued_date: Yup.string(),
    form_addresses: Yup.array().of(
      Yup.object().shape({
        present_country: Yup.string().required('The permanent country field is required.'),
        present_province: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Province is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_zone: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Zone is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_district: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('District is required.'),
          otherwise: Yup.string().nullable(),
        }),
        present_municipality: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Municipality is required.'),
          otherwise: Yup.string().nullable(),
        }),

        present_city: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Permanent City is required.'),
          otherwise: Yup.string().nullable(),
        }),

        present_street_name: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().required('Permaent Street Name is required.'),
          // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Permaent Street Name should not contain numbers'),
          otherwise: Yup.string().nullable(),
        }),
        present_ward_number: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string()
            .required('Ward Number is required.')
            .matches(/^[0-9]+$/, 'Ward Number must contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        present_house_number: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string().matches(numericRegex, 'House Number must contain numbers and Slash'),
          otherwise: Yup.string().nullable(),
        }),
        present_email: Yup.string().required('The permanent email is required.'),
        present_mobile_number: Yup.string().when('present_country', {
          is: (value: string) => {
            const selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return selectedCountry?.title === 'NEPAL'
          },
          then: Yup.string()
            .required('The mobile number is required.')
            .test(
              'dots-or-mobile',
              'Mobile number must asdfasdf start with 98 or 97 and be 10 digits, or it must consist dot (.)',
              (value) => {
                if (!value) return false

                if (/^\.+$/.test(value) && value.length > 0) {
                  return true
                }

                const mobileNumberRegex = /^(98|97)\d{8}$/
                return mobileNumberRegex.test(value)
              }
            ),
          otherwise: Yup.string().nullable(),
        }),

        // present_fax_number: Yup.string()
        //   .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
        //   .max(10, 'The Permanent Fax Number must not exceed 50 digits.'),
        present_outside_country_post_box: Yup.string().when('present_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return false
          },
          then: Yup.string()
            .required('Post Box No. is required')
            .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
          otherwise: Yup.string().nullable(),
        }),
        present_outside_country_full_address: Yup.string().when('present_country', {
          is: (value: string) => {
            let selectedCountry = allMasterDataOptions?.countries?.find(
              (country: any) => country?.id === value
            )
            return false
          },
          then: Yup.string().required('Foreign Address is required'),
          otherwise: Yup.string().nullable(),
        }),
        address_type: Yup.string().required('Address Type is required'),
      })
    ),
    family_details: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
          .max(50, 'Name must not exceed 50 characters.'),

        relationship_id: Yup.string().required('Relationship is required'),
        profession: Yup.string(),
      })
    ),
    other_banks: Yup.array().of(
      Yup.object().shape({
        bank_name: Yup.string()
          .matches(/^[^\d]*$/, 'Name must not contain any numbers.')
          .max(50, 'Name must not exceed 50 characters.')
          .required('if Other bank is present name is required.'),
        saving_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Saving Account should contain only letters and spaces')
          .max(50, 'Saving Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {current_ac, fixed_ac, working_capital, term_loan, others} = this.parent
            return value || current_ac || fixed_ac || working_capital || term_loan || others
          }),
        current_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Current Account should contain only letters and spaces')
          .max(50, 'Current Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, fixed_ac, working_capital, term_loan, others} = this.parent
            return value || saving_ac || fixed_ac || working_capital || term_loan || others
          }),
        fixed_ac: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Fixed Account should contain only letters and spaces')
          .max(50, 'Fixed Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, working_capital, term_loan, others} = this.parent
            return value || saving_ac || current_ac || working_capital || term_loan || others
          }),
        working_capital: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Working Capital should contain only letters and spaces')
          .max(50, 'Working Capital cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, term_loan, others} = this.parent
            return value || saving_ac || current_ac || fixed_ac || term_loan || others
          }),
        term_loan: Yup.string()
          .matches(/^[0-9,a-zA-Z _-]+$/, 'Term Loan should contain only letters and spaces')
          .max(50, 'Term Loan cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, working_capital, others} = this.parent
            return value || saving_ac || current_ac || fixed_ac || working_capital || others
          }),
        others: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Other should contain only letters and spaces')
          .max(50, 'Other cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, fixed_ac, working_capital, term_loan} = this.parent
            return value || saving_ac || current_ac || fixed_ac || working_capital || term_loan
          }),
      })
    ),
    other_account_purpose: Yup.string().when('account_purpose_id', {
      is: '9682d56b-9d08-4ea5-a0b9-e72a5df0397a',
      then: Yup.string()
        .required('Other Account Purpose is required.')
        .matches(/^[^\d]*$/, 'Purposes must not contain any numbers.'),
      otherwise: Yup.string().nullable(),
    }),

    expected_annual_transaction_amount: Yup.string()
      .required('Expected Annual Transaction Amount is required.')
      .matches(/^[0-9,.]+$/, 'Amount must only be numbers.'),
    expected_annual_transaction_number: Yup.string().required(
      'Expected Annual Transaction Number is required.'
    ),

    pep_status: Yup.string().required('Pep Status is required.'),
    pep_relationship: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Relationship is required'),
      otherwise: Yup.string().nullable(),
    }),
    pep_position: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Postion is required.'),
      otherwise: Yup.string().nullable(),
    }),
    pep_retirement_date: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP retirement date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    pep_category_id: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Category is required.'),
      otherwise: Yup.string().nullable(),
    }),
    pep_sub_category_id: Yup.string().when('pep_status', {
      is: 'Yes',
      then: Yup.string().required('PEP Sub Category is required.'),
      otherwise: Yup.string().nullable(),
    }),
    beneficial_owner: Yup.string().required('Beneficial Owner is required.'),
    beneficial_owner_name: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string().required('Beneficial Owner Name is required.'),
      otherwise: Yup.string().nullable(),
    }),
    beneficial_owner_occupation: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string().required('Beneficial Occupation is required.'),
      otherwise: Yup.string().nullable(),
    }),
    beneficial_owner_relation: Yup.string().when('beneficial_owner', {
      is: 'Yes',
      then: Yup.string().required('Beneficial Owner Relation is required.'),
      otherwise: Yup.string().nullable(),
    }),
    pan_number: Yup.string()
      // .required('The PAN number is required.')
      .matches(/^\d{9}$/, 'PAN number must be exactly 9 digits and should not have characters.')
      .max(9, 'PAN number must be 9 characters long.'),
    // pan_registration_date: Yup.string().required('The PAN registration date is required.'),
    visa_issue_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Issue Date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    visa_expiry_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Expiry Date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    visa_arrival_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Arrival Date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    work_permit_id: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit ID is required.'),
      otherwise: Yup.string().nullable(),
    }),
    work_permit_issued_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit issued date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    work_permit_expiry_date: Yup.string().when('present_country', {
      is: !'48691d0c-2489-4718-8596-10833123fce9 ',
      then: Yup.string().required('Visa Permit expiry date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    landlord_mobile_number: Yup.string(),
    // .matches(/^\d+$/, 'Landlord mobile number must contain only numbers'), // Ensures only numeric characters
    crime_details: Yup.string().when('crime_conviction', {
      is: true,
      then: Yup.string().required('Crime Details are required.'),
      otherwise: Yup.string().nullable(),
    }),
    permit_details: Yup.string().when('residential_permit_foreign_country', {
      is: true,
      then: Yup.string().required('Permit Details are required.'),
      otherwise: Yup.string().nullable(),
    }),
    blacklist_release_date: Yup.string().when('blacklisting_inclusion', {
      is: true,
      then: Yup.string().required('Blacklist Release Date is required.'),
      otherwise: Yup.string().nullable(),
    }),
    aml_risk_rating: Yup.string().required('AML Risk Rating is required.'),
    facta_declarations: Yup.array().of(
      Yup.object().shape({
        us_person: Yup.string().required('US Person is required'),
        us_indicia: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Indicia is required'),
          otherwise: Yup.string().nullable(),
        }),
        us_citizenship: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Citizenship is required'),
          otherwise: Yup.string().nullable(),
        }),
        us_birth_country: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Birth Country is required'),
          otherwise: Yup.string().nullable(),
        }),
        us_residence: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('US Residence is required'),
          otherwise: Yup.string().nullable(),
        }),
        tin_number: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('TIN Number is required'),
          otherwise: Yup.string().nullable(),
        }),
        w9_form_submitted: Yup.string().when('us_person', {
          is: 'Yes',
          then: Yup.string().required('W9 Form Submitted is required'),
          otherwise: Yup.string().nullable(),
        }),
        us_telephone_number: Yup.string()
          .matches(/^[0-9,]+$/, 'US f number must only be numbers.')
          .max(10, 'US telephone number must not exceed 10 digits.')
          .when('us_person', {
            is: 'Yes',
            then: Yup.string().required('US telephone number is required.'),
            otherwise: Yup.string().nullable(),
          }),

        joint_us_person: Yup.string().when('account_type_id', {
          is: 'Joint',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_us_indicia: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_us_citizenship: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_us_birth_country: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_us_residence: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_tin_number: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_w9_form_submitted: Yup.string().when(['account_type_id', 'joint_us_person'], {
          is: (account_type_id: any, joint_us_person: any) =>
            account_type_id === 'Joint' && joint_us_person === 'Yes',
          then: Yup.string().required('Required for joint account.'),
          otherwise: Yup.string().nullable(),
        }),
        joint_us_telephone_number: Yup.string()
          .matches(/^[0-9,]+$/, 'Joint US telephone number must only be numbers.')
          .max(10, 'Joint US telephone number must not exceed 10 digits.')
          .when(['account_type_id', 'joint_us_person'], {
            is: (account_type_id: any, joint_us_person: any) =>
              account_type_id === 'Joint' && joint_us_person === 'Yes',
            then: Yup.string().required('Required for joint account.'),
            otherwise: Yup.string().nullable(),
          }),
      })
    ),

    // total_annual_income: Yup.string()
    //   .required('The total annual income is required.')
    //   .matches(/^\d+$/, 'Total annual income must be a number.')
    //   .test(
    //     'max',
    //     'Total annual income must not exceed 1,000,000,000',
    //     (value) => !value || parseInt(value) <= 100000000000000
    //   ),

    // expected_monthly_income: Yup.string()
    //   .required('The expected annual turnover is required.')
    //   .matches(/^\d+$/, 'Expected annual turnover must be a number.')
    //   .test(
    //     'max',
    //     'Expected annual turnover must not exceed 1,000,000,000',
    //     (value) => !value || parseInt(value) <= 10000000000000000
    //   ),
    landlord_address: Yup.string(),
    landlord_name: Yup.string(),
    religion_id: Yup.string().required('The religion field is required.'),

    other_religion: Yup.string().when('religion_id', {
      is: (value: string) => {
        const selectedReligion = allMasterDataOptions?.religions?.find(
          (religion: any) => religion?.id === value
        )
        return selectedReligion?.title === 'OTHERS'
      },
      then: Yup.string().required('Other religion is required'),
      otherwise: Yup.string().nullable(),
    }),
    education_id: Yup.string().required('The education field is required.'),
    account_purpose_id: Yup.string().required('The account purpose field is required.'),
    // customer_category_id: Yup.string().required('The customer category field is required.'),
    has_existing_account: Yup.boolean().required('This field is required'),

    existing_account_number: Yup.string().when('has_existing_account', {
      is: true,
      then: Yup.string()
        .matches(/^\d+$/, 'Account number can only contain numbers')
        .required('Account number is required')
        .length(14, 'Account number must be exactly 14 digits long'),
      otherwise: Yup.string().nullable(),
    }),

    relation_with_existing_account: Yup.string().when('has_existing_account', {
      is: true,
      then: Yup.string().required('Relation with existing account is required'),
      otherwise: Yup.string().nullable(),
    }),
    constitution_id: Yup.string().required('Constitution is required'),
    bank_code_id: Yup.string().required('Bank Code is required'),

    customer_group_id: Yup.string().required('Customer Group is required'),

    customer_status_id: Yup.string().required('Customer Status is required'),

    customer_free_text: Yup.string().required('Customer Status Details is required'),

    cdd_edd_date: Yup.date()
      .required('CDD & EDD date is required')
      .typeError('Invalid date format'),

    annual_turn_over_as_on: Yup.date()
      .required('Annual Turnover As On date is required')
      .typeError('Invalid date format'),
  })

export const CPUMAKER_SAVE = Yup.object().shape({
  form_addresses: Yup.array().of(
    Yup.object().shape({
      present_province: Yup.string().required('The permanent province is required.'),
      present_zone: Yup.string().required('The permanent zone is required.'),
      present_district: Yup.string().required('The permanent district is required.'),
      present_municipality: Yup.string().required('The permanent municipality is required.'),
      present_street_name: Yup.string()
        .required('The Present Street Name is required.')
        .max(50, 'The Present Street Name must not exceed 50 characters.'),
      // .matches(
      //   /^[.,a-zA-Z /\\_-]+$/,
      //   'This Field should only contain alphabets, spaces, dashes, or slashes.'
      // )
      // present_street_name: Yup.string()
      //   .required('The Present Street Name is required.')
      //   .max(50, 'The Present Street Name must not exceed 50 characters.'),
      // present_fax_number: Yup.string()
      //   .matches(/^\d+$/, 'The Present Fax Number should only contain digits.')
      //   .max(50, 'The Present Fax Number must not exceed 50 digits.'),
      // present_fax_number: Yup.string()
      //   .matches(/^\d+$/, 'The Permanent Fax Number should only contain digits.')
      //   .max(50, 'The Permanent Fax Number must not exceed 50 digits.'),
    })
  ),
})
const alphabeticRegex = /^[,a-zA-Z _-]+$/

export const ACCOUNT_OPENING_FORM_VALIDATION_SAVE_AND_CONTINUE = Yup.object().shape({
  // certificate_issued_by:Yup.string().required('The certificate issued by must be provided.'),
  // minor_proof_of_guardianship:Yup.string().required('The minor proof of guardianship must be provided.'),
  // minor_certificate_number:Yup.string().required('The minor certificate number must be provided.'),
  // account_title: Yup.string().required('The account title must be selected.'),
  account_type_id: Yup.string().required('The account type must be selected.'),
  nomination: Yup.boolean(),

  introducer_first_name: Yup.string()
    .required('Introducer first name must be provided.')
    .matches(/^[,a-zA-Z _-]+$/, 'First name should only contain characters.'),
  introducer_last_name: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Last name should only contain characters.'
  ),
  introducer_middle_name: Yup.string().matches(
    /^[,a-zA-Z _-]+$/,
    'Middle name should only contain characters.'
  ),

  introducer_customer_id: Yup.string()
    .required('Introducer customer ID must be provided.')
    .matches(/^\d{1,15}$/, 'Introducer customer ID must be exactly 15 digits and a number.'),
  introducer_address: Yup.string().required('Introducer customer address must be provided.'),
  introducer_confirmation_period: Yup.string()
    .required('Introducer confirmation period must be provided.')
    .matches(/^\d{1,2}$/, 'Introducer confirmation period must be exactly 2 digits and a number.'),
  tax_category_id: Yup.string().required('Tax category must be provided.'),
  tax_group_id: Yup.string().required('Tax group must be provided.'),
  cheque_book_name: Yup.string()
    .required('Cheque book name must be provided.')
    .matches(/^[,a-zA-Z ._-]+$/, 'Cheque BookName should only contain characters.'),
  mode_of_operation: Yup.string().required('Mode of operation must be provided.'),
  loan_detail_id: Yup.string().required('Loan Details is required'),
  deposit_detail_id: Yup.string().required('Loan Details is required'),
  // blacklist_screening: Yup.boolean().required('Required'),
  sanction_screening: Yup.boolean().required('Required'),
  // adverse_media: Yup.boolean().required('Required'),
  duplication_screening: Yup.boolean().required('Required'),
  // regulatory_block: Yup.boolean().required('Required'),
  initial_deposit: Yup.string()
    .required('Initial deposit must be provided.')
    .matches(/^[0-9 .,]+$/, 'Initial deposit must be a number.'),
  account_currency_id: Yup.string().required('The account currency must be selected.'),
  account_category_id: Yup.string().required('The account category must be provided.'),
})

export const CORPORATE_CPU_FORM_VALIDATION_CIF = (allMasterDataOptions: any) =>
  Yup.object().shape({
    aml_risk_rating: Yup.string().required('The AML risk rating  is required.'),
    remarks: Yup.string().required('The Remark is required.'),
    bank_code_id: Yup.string().required('Bank Code is required'),
    no_of_branches: Yup.string()
      .matches(/^\d+$/, 'Branch Number must be a valid number')
      .nullable(),
    // customer_category_id: Yup.string().required('Customer Category is required'),
    address_type: Yup.string().required('Address Type is required'),

    customer_group_id: Yup.string().required('Customer Group is required'),

    customer_status_id: Yup.string().required('Customer Status is required'),

    business_office_country: Yup.string().required('Country is required'),
    business_office_province: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('Province is required.'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_zone: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('Zone is required.'),
      otherwise: Yup.string().nullable(),
    }),

    business_office_district: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('District is required.'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_municipality: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('Municipality is required.'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_ward_number: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string()
        .required('Ward Number is required.')
        .matches(/^[0-9]+$/, 'Ward Number must contain only numbers'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_city: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('Business Office City is required.'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_street_name: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().required('Street Name is required.'),
      // .matches(/^[.,a-zA-Z /\\_-]+$/, 'Street Name should contain only letters and spaces'),
      otherwise: Yup.string().nullable(),
    }),

    business_office_house_number: Yup.string().when('business_office_country', {
      is: (value: string) => {
        const selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return selectedCountry?.title === 'NEPAL'
      },
      then: Yup.string().matches(numericRegex, 'House Number must contain numbers and Slash'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_outside_country_post_box: Yup.string().when('business_office_country', {
      is: (value: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return false
      },
      then: Yup.string()
        .required('Post Box No. is required')
        .matches(/^[0-9]+$/, 'Post Box No. should contain only numbers'),
      otherwise: Yup.string().nullable(),
    }),
    business_office_outside_country_full_address: Yup.string().when('business_office_country', {
      is: (value: string) => {
        let selectedCountry = allMasterDataOptions?.countries?.find(
          (country: any) => country?.id === value
        )
        return false
      },
      then: Yup.string().required('Foreign Address is required'),
      otherwise: Yup.string().nullable(),
    }),
    // business_office_mobile_number: Yup.string()
    //   .required('Mobile Number is required')
    //   .matches(/^(98|97)\d{8}$/, 'Mobile Number must start with 98 or 97 and contain 10 digits'),
    business_office_mobile_number: Yup.string()
      .required('The mobile number is required.')
      .test(
        'dots-or-mobile',
        'Mobile number must start with 98 or 97 and be 10 digits, or it must consist of more than 0 dot (.)',
        (value) => {
          if (!value) return false

          if (/^\.+$/.test(value) && value.length > 0) {
            return true
          }

          const mobileNumberRegex = /^(98|97)\d{8}$/
          return mobileNumberRegex.test(value)
        }
      ),

    business_office_email: Yup.string().required('The registered office email field is required.'),

    customer_free_text: Yup.string().required('Customer Status Details is required'),

    cdd_edd_date: Yup.date()
      .required('CDD & EDD date is required')
      .typeError('Invalid date format'),
    annual_turn_over_as_on: Yup.date()
      .required('Annual Turnover As On date is required')
      .typeError('Invalid date format'),
    // share_holders: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       shareholder_name: Yup.string().when(
    //         [
    //           'ownership_percentage',
    //           'shareholder_address',
    //           'entity_name',
    //           'entity_ownership_percentage',
    //           'entity_address',
    //           'entity_registration_number',
    //           'entity_registration_office',
    //           'entity_incorporation_date',
    //           'entity_pan_number',
    //           'entity_vat_number',
    //           'entity_country',
    //           'entity_registration_address',
    //           'entity_business_address',
    //           'entity_phone_number',
    //           'entity_website',
    //         ],
    //         {
    //           is: (...fields: any) => fields.every((field: any) => !field || field === ''),
    //           then: Yup.string().required('Fill at least one field'),
    //         }
    //       ),
    //       ownership_percentage: Yup.number().nullable(),
    //       shareholder_address: Yup.string().nullable(),
    //       entity_name: Yup.string().nullable(),
    //       entity_ownership_percentage: Yup.number().nullable(),
    //       entity_address: Yup.string().nullable(),
    //       entity_registration_number: Yup.string().nullable(),
    //       entity_registration_office: Yup.string().nullable(),
    //       entity_incorporation_date: Yup.date().nullable(),
    //       entity_pan_number: Yup.string().nullable(),
    //       entity_vat_number: Yup.string().nullable(),
    //       entity_country: Yup.string().nullable(),
    //       entity_registration_address: Yup.string().nullable(),
    //       entity_business_address: Yup.string().nullable(),
    //       entity_phone_number: Yup.string().nullable(),
    //       entity_website: Yup.string().nullable(),
    //     })
    //   )
    //   .test('at-least-one', 'Fill at least one field for each shareholder', function (value: any) {
    //     return value.every((shareholder: any) =>
    //       Object.values(shareholder).some((field) => field !== null && field !== '')
    //     )
    //   }),

    other_banks: Yup.array().of(
      Yup.object().shape({
        bank_name: Yup.string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Bank name should contain only letters, numbers, and spaces'
          )
          .max(50, 'Bank Name cannot exceed 50 characters')
          .required('Bank Name is Required')
          .nullable(),
        saving_ac: Yup.string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Saving Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Saving Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {current_ac, od_ac, term_loan, others} = this.parent
            return value || current_ac || od_ac || term_loan || others
          }),
        current_ac: Yup.string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Current Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Current Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, od_ac, term_loan, others} = this.parent
            return value || saving_ac || od_ac || term_loan || others
          }),
        od_ac: Yup.string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Overdraft Account should contain only letters, numbers, and spaces'
          )
          .max(50, 'Overdraft Account cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, term_loan, others} = this.parent
            return value || saving_ac || current_ac || term_loan || others
          }),
        term_loan: Yup.string()
          .matches(
            /^[,0-9a-zA-Z _-]+$/,
            'Term Loan should contain only letters, numbers, and spaces'
          )
          .max(50, 'Term Loan cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, od_ac, others} = this.parent
            return value || saving_ac || current_ac || od_ac || others
          }),
        others: Yup.string()
          .matches(/^[,0-9a-zA-Z _-]+$/, 'Other should contain only letters, numbers, and spaces')
          .max(50, 'Other cannot exceed 50 characters')
          .nullable()
          .test('at-least-one', 'At least one account must be filled.', function (value) {
            const {saving_ac, current_ac, od_ac, term_loan} = this.parent
            return value || saving_ac || current_ac || od_ac || term_loan
          }),
      })
    ),

    directors: Yup.array().of(
      Yup.object().shape({
        full_name: Yup.string()
          .matches(/^[.,a-zA-Z /\\_-]+$/, 'Full Name should contain only letters')
          .required('Full Name is required'),
        designation: Yup.string()
          .matches(/^[.,a-zA-Z /\\_-]+$/, 'Designation should contain only letters')
          .required('Designation is required'),
        cif_number: Yup.string().matches(
          /^\d{9}$/,
          'CIF number must be exactly 9 digits and contain only numbers.'
        ),
      })
    ),
    facta_declarations: Yup.array().of(
      Yup.object().shape({
        us_person: Yup.boolean().required('US Person is required'),
        us_person_remarks: Yup.string(),
        us_indicia_remarks: Yup.string().when('us_person', {
          is: true,
          then: Yup.string().required('US Indicia is required'),
        }),
        us_telephone_number: Yup.string()
          .when('us_person', {
            is: true,
            then: Yup.string().required('US Telephone Number is required'),
          })
          .matches(/^\d{10}$/, 'US Telephone Number must be 10 digits'),
        tin_number: Yup.string().when('us_person', {
          is: true,
          then: Yup.string().required('TIN Number is required'),
        }),
        giin_number_remarks: Yup.string().when('giin_number', {
          is: true,
          then: Yup.string().required('GIIN Number Remarks are required if GIIN Number is Yes'),
          otherwise: Yup.string().notRequired(),
        }),
        w9_form_submitted: Yup.string().when('us_person', {
          is: true,
          then: Yup.string().required('W9 Form Submitted is required'),
        }),
      })
    ),

    blacklisting_inclusion: Yup.boolean(),
    blacklist_release_date: Yup.string().when('blacklisting_inclusion', {
      is: true,
      then: Yup.string()
        .required('Release date is required')
        .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in the format YYYY-MM-DD'),
      otherwise: Yup.string().nullable(), // Optional if blacklisting_inclusion is false
    }),
  })
