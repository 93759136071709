import React, {useState} from 'react'
import {ErrorMessage, FieldArray} from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {FormControlLabel, Checkbox} from '@material-ui/core'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'

const yesNoOptions = [
  {value: 'true', label: 'Yes'},
  {value: 'false', label: 'No'},
]
const FactaDeclarationTable = ({setFieldValue, values, handleChange, errors, touched}: any) => (
  <div className='card mb-5 mb-xl-10'>
    <div className='border-top pt-9 pb-9'>
      <h3 className=' heading__component__title fw-bolder text-uppercase'>L. Fatca Declaration</h3>{' '}
      <FieldArray name='facta_declarations'>
        {({remove}) => {
          const isUSPerson = values.facta_declarations[0]?.us_person === true
          return (
            <div>
              <div>
                <div className='row mb-3'>
                  {/* Row 1: US Person and Remarks */}
                  <div className='col-md-6'>
                    <FormSelect
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      placeholder='US Person'
                      name={`facta_declarations.0.us_person`}
                      label='US Person'
                      onChange={(e: any) => {
                        // Convert 'true'/'false' strings to boolean values
                        const value = e === 'true' ? true : false
                        setFieldValue(`facta_declarations.0.us_person`, value)
                      }}
                      errors={errors}
                      touched={touched}
                      arrValue={values?.facta_declarations[0]?.us_person?.toString()}
                      options={yesNoOptions}
                      setFieldValue={setFieldValue}
                      required
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='Remarks'
                      name={`facta_declarations.0.us_person_remarks`}
                      label='Remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.us_person_remarks}
                      // ?.facta_declarations[0]?.us_person_remarks
                      // required
                    />
                  </div>
                </div>

                {/* Row 2: US Indicia and US Telephone Number */}
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='US Indicia Remarks'
                      name={`facta_declarations.0.us_indicia_remarks`}
                      label='US Indicia Remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.us_indicia_remarks}
                      required={isUSPerson}
                      maxLength={50}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='US Telephone Number'
                      name={`facta_declarations.0.us_telephone_number`}
                      label='US Telephone Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.us_telephone_number}
                      required={isUSPerson}
                      maxLength={10}
                    />
                  </div>
                </div>

                {/* Row 3: TIN Number and GIIN Number */}
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='TIN Number'
                      name={`facta_declarations.0.tin_number`}
                      label='TIN Number'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.tin_number}
                      required={isUSPerson}
                      maxLength={20}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`facta_declarations.0.giin_number`}
                          checked={values?.facta_declarations[0]?.giin_number}
                          onChange={() => {
                            const isChecked = values?.facta_declarations[0]?.giin_number
                            handleChange({
                              target: {
                                name: `facta_declarations.0.giin_number`,
                                value: !isChecked,
                              },
                            })  
                          }}
                        />
                      }
                      label='GIIN Number (In case of financial institution only)'
                    />
                  </div>
                </div>

                {/* Row 4: GIIN Number Remarks and W9 Form Submitted */}
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='GIIN Number Remarks'
                      name={`facta_declarations.0.giin_number_remarks`}
                      label='GIIN Number Remarks'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.giin_number_remarks}
                    />
                  </div>
                  <div className='col-md-6'>
                    <FormTextBox
                      labelClassName='col-lg-12'
                      containerClassName='col-lg-12'
                      type='text'
                      placeholder='W9 Form Submitted'
                      name={`facta_declarations.0.w9_form_submitted`}
                      label='W9 Form Submitted'
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      value={values?.facta_declarations[0]?.w9_form_submitted}
                      required={isUSPerson}
                    />
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
          )
        }}
      </FieldArray>
    </div>
  </div>
)

export default FactaDeclarationTable
