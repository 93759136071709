import {Form, Formik} from 'formik'
import {useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetCorporatePreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/get/getCorporateDetailsById'
import {usePostCorporateQaReviewCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostCorporateQaReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {REVIEW_VALIDATION_SCHEMA} from '../../utils/ValidationSchemas'
import {UserActivityLogAll} from 'src/app/modules/common/components/AllActivityLog'

interface RouteParams {
  id: string
}

const ExpressCorporateReviewCIF = () => {
  const {id} = useParams<RouteParams>()
  const history = useHistory()
  const {data: corporateDataById, isLoading} = useGetCorporatePreliminariesDetailsByIdCIF(id)
  const {mutate: qaCorporateReview, isLoading: postRespLoading} = usePostCorporateQaReviewCIF()

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')

  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    qaCorporateReview(payload, {
      onSuccess: (data) => {
        history.go(-1)
      },
    })
  }

  const getFieldValue = (field: any, key: string) => {
    return field && field[key] ? field[key].toString() : ''
  }

  const allStatusType = [
    {label: 'FORWARD TO CPU MAKER', value: 'approve'},
    {label: 'REJECT', value: 'reject'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert'},
  ]

  const handleScreening = () => {
    setScreeningLoading(true) // Set screening loading to true

    const checkAllPayload: any = {
      customer_name: corporateDataById?.data?.institution_name,
      pan_number: corporateDataById?.data?.pan_number,
      model_name: 'NewCIFNewAccount',
    }

    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }

  const handleBack = () => {
    history.go(-1)
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  if (isLoading) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body border-top pt-9 pb-9'>
          <h3>
            Please Wait... <Spinner animation='border' variant='danger' />
          </h3>
        </div>
      </div>
    )
  }

  const data = corporateDataById?.data
  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              {' '}
              <DocumentsPreview data={data} />
            </div>
          </div>
        </div>
        <div
          className='col-md-6'
          style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}
        >
          <div className='modal-container '>
            <div className='card-body'>
              <div className='card p-5'>
                <div className='row'>
                  <section>
                    <div className='row'>
                      {' '}
                      <FormDetails
                        key='branch_code'
                        label='Branch Code'
                        value={corporateDataById?.data?.branch_code}
                      />
                      <FormDetails
                        key='screening_id'
                        label='Screening Id'
                        value={corporateDataById?.data?.screening_id}
                      />
                      <FormDetails
                        key='customer_code'
                        label='Customer Code'
                        value={corporateDataById?.data?.customer_code}
                      />
                      <h3 className='fw-bolder mb-4 text-capitalize'>Company Information</h3>
                      <FormDetails
                        key='institution_name'
                        label='Name of Institution'
                        value={corporateDataById?.data?.institution_name}
                      />
                      <FormDetails
                        key='constitution_id'
                        label='Constitution'
                        value={corporateDataById?.data?.constitution_id?.title}
                      />
                      <FormDetails
                        key='registration_number'
                        label='Registration Number'
                        value={corporateDataById?.data?.registration_number}
                      />
                      <FormDetails
                        key='account_category_id'
                        label='Account Category'
                        value={corporateDataById?.data?.account_opening?.account_category_id?.title}
                      />
                      <FormDetails
                        key='account_Scheme_id'
                        label='Account Scheme'
                        value={corporateDataById?.data?.account_opening?.account_scheme_id?.title}
                      />
                      {corporateDataById?.data?.pan_number && (
                        <>
                          <FormDetails
                            key='pan_number'
                            label='Pan Number'
                            value={corporateDataById?.data?.pan_number}
                          />
                        </>
                      )}
                      {corporateDataById?.data?.vat_number && (
                        <>
                          <FormDetails
                            key='vat_number'
                            label='Vat Number'
                            value={corporateDataById?.data?.vat_number}
                          />
                        </>
                      )}
                    </div>
                  </section>
                  <hr />
                  <div className='row'>
                    {screeningPerformed ? (
                      checkAllData && (
                        <Button
                          type='button'
                          onClick={handleOpenPdf}
                          className='col-md-3 col-sm-6 col-xs-12'
                        >
                          View Details
                        </Button>
                      )
                    ) : screeningLoading ? (
                      <Spinner animation='border' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    ) : (
                      <Button
                        type='button'
                        onClick={handleScreening}
                        className='col-md-3 col-sm-6 col-xs-12'
                      >
                        Perform Screening
                      </Button>
                    )}
                    <div className='my-7'>
                      <h3>Screening Files</h3>
                      {corporateDataById?.data?.screening_files?.screening_files?.length > 0 ? (
                        corporateDataById?.data?.screening_files?.screening_files?.map(
                          (file: any, index: any) => (
                            <button
                              key={index}
                              type='button'
                              onClick={() => handlePdfFileClick(file.file_path)}
                              style={{display: 'block', marginBottom: '10px'}}
                            >
                              {file.file_name}
                            </button>
                          )
                        )
                      ) : (
                        <p>No files available</p>
                      )}
                    </div>
                  </div>
                  <Formik
                    initialValues={{status: '', remarks: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={REVIEW_VALIDATION_SCHEMA}
                  >
                    {({handleChange, errors, touched, values, setFieldValue}) => (
                      <Form>
                        <div className='row '>
                          <div className='col-md-8'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Status Type'
                              name='status'
                              isClearable={true}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allStatusType.map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                              required={true}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className='border my-2 mb-6'></div>
                          <FormTextBox
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            type='text'
                            placeholder='Remarks'
                            label='Remarks'
                            name='remarks'
                            disabled={false}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-id-number-text-field'
                            as='textarea'
                          />
                          <div className='d-flex justify-content-end mt-3'>
                            <button
                              type='button'
                              onClick={handleBack}
                              className='btn btn-secondary mx-4'
                            >
                              Back
                            </button>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={postRespLoading}
                            >
                              {postRespLoading ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccountStatusLog data={corporateDataById?.data} />
        <UserActivityLogAll
          caseId={id}
          modalName={corporateDataById?.data?.model_name}
          modalData={corporateDataById?.data}
        />
      </div>
    </>
  )
}

export default ExpressCorporateReviewCIF
