import axios from 'axios'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQueryClient,
} from 'react-query'
import {toast} from 'react-toastify'
import {POST_SCHEDULECODE} from 'src/app/queries/api/apiEndPoints/EndPoints'
export interface IScheduleCode {
  id?: string
  title: string
  cbs_code: string
  status: boolean
  order?: number
}

const postScheduleCode = async (payload: IScheduleCode) => {
  try {
    const response = await axios.post(`${POST_SCHEDULECODE}`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(`${error.response.data.message}`)
  }
}

const usePostScheduleCode = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
) => {
  const queryClient = useQueryClient()
  return useMutation((payload: IScheduleCode) => postScheduleCode(payload), {
    onSuccess: async (response) => {
      if (response?.resCod == 200) {
        toast.success(response?.resDesc)
        setOpen(false)
        queryClient.invalidateQueries('getScheduleCodeList')
        refetch()
      }
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error?.message ?? 'An error occurred'}`)
    },
  })
}

export default usePostScheduleCode
