import {adToBs} from '@sbmdkl/nepali-date-converter'
import {FieldArray, Form, Formik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {useGetPreliminariesDetailsByIdCIF} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import {usePostKYCAdditionalDetailsCIF} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostAdditionalForm'
import {usePatchKycDetailsIndividualCIF} from 'src/app/queries/new-cif-new-account-queries/cpuMaker/kycIndividual/patch/usePatchKycDetailsIndividual'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {CPUMAKER_SAVE_AND_CONTINUE} from '../../../../utils/ValidationSchema'
import FamilyDetailsForm from './additionalFormFields/FamilyTable'
import FATCADeclarationsForm from './additionalFormFields/fatcaDeclarationForm'
import BusinessDetailsForm from './additionalFormFields/professionTable'
import {useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import FormDatepicker, {FormDatepickerNew} from 'src/cms/helpers/components/forms/FormDatepicker'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import useGetMasterDataFilter from 'src/app/queries/getMasterData/getAllMasterData'
import OtherBanksTable from 'src/app/modules/new-cif-new-account/cpuMaker/components/kycUpdateDetailsFormCPUMaker/normal/additionalForms/dealingWIthOtherBank'
import moment from 'moment'

const KycFormNormalIndividualJointMinorCIF = ({
  id: keyID,
  data: keyData,
  setActiveKey,
  setKycFormSubmitted,
}: any) => {
  interface IIDPROPS {
    id: string
  }
  const {id} = useParams<IIDPROPS>()
  const history = useHistory()
  const [btnFlag, setBtnFlag] = useState('')
  const [kycFormSubmission, setKycFormSubmission] = useState(false)
  const {data: preliminaryDetailsByID, isLoading: detailsLoading} =
    useGetPreliminariesDetailsByIdCIF(id)
  const {mutate: addAddtionalDetails} = usePostKYCAdditionalDetailsCIF()
  const {mutate: updateKycDetails, isLoading: updateLoading} = usePatchKycDetailsIndividualCIF()
  const [sameAsPermanentAddress, setSameAsPermanentAddress] = useState(false)
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<any>([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)

  const [masterFilterData, setMasterFilterData] = useState<any>(null)

  const {mutate: masterDataFilter, isLoading: masterFilterLoading} =
    useGetMasterDataFilter(setMasterFilterData)

  const update = useRef<any>('default')
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )
  const {zoneOptions, provinceOptions, districtOptions, isLoading, municipalitiesOptions} =
    usePlaceMasterData({
      allMasterData: false,
      constitutions: true,
      corporateRegistrations: true,
      countries: true,
      zones: true,
      provinces: true,
      districts: true,
      municipalities: true,
    })

  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)
  const PEPOPTIONS = [
    {value: 'Yes', label: 'Yes'},
    {value: 'No', label: 'No'},
  ]
  const getSaluationData = (id: string) => {
    masterDataFilter(id)
  }
  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('form_addresses[0].present_outside_country_post_box', '')
      setFieldValue('form_addresses[0].present_outside_country_full_address', '')
      setFieldValue('form_addresses[0].present_mobile_number', '')
      setFieldValue('form_addresses[0].present_email', '')
      setTouched({
        'form_addresses[0].present_outside_country_post_box': false,
        'form_addresses[0].present_outside_country_full_address': false,
        'form_addresses[0].present_mobile_number': false,
        'form_addresses[0].present_email': false,
      })
    } else {
      setFieldValue('form_addresses[0].present_outside_country_post_box', '')
      setFieldValue('form_addresses[0].present_outside_country_full_address', '')
      setFieldValue('form_addresses[0].present_province', '')
      setFieldValue('form_addresses[0].present_zone', '')
      setFieldValue('form_addresses[0].present_district', '')
      setFieldValue('form_addresses[0].present_municipality', '')
      setFieldValue('form_addresses[0].present_ward_number', '')
      setFieldValue('form_addresses[0].present_city', '')
      setFieldValue('form_addresses[0].present_street_name', '')
      setFieldValue('form_addresses[0].present_house_number', '')
      setFieldValue('form_addresses[0].present_mobile_number', '')
      setFieldValue('form_addresses[0].present_email', '')
      setTouched({
        'form_addresses[0].present_province': false,
        'form_addresses[0].present_zone': false,
        'form_addresses[0].present_district': false,
        'form_addresses[0].present_municipality': false,
        'form_addresses[0].present_city': false,
        'form_addresses[0].present_ward_number': false,
        'form_addresses[0].present_street_name': false,
        'form_addresses[0].present_house_number': false,
        'form_addresses[0].present_mobile_number': false,
        'form_addresses[0].present_email': false,
      })
    }
  }

  const AddressType = [
    {
      value: 'P',
      label: 'Permanent Address',
    },
    {
      value: 'C',
      label: 'Communication Address',
    },
  ]

  useEffect(() => {
    let individualData = preliminaryDetailsByID?.data?.account_type_id?.id
    if (individualData) {
      masterDataFilter(individualData)
    }
  }, [])

  useEffect(() => {
    // Set the initial state based on the API value
    // if (preliminaryDetailsByID?.data?.is_present_address_different !== undefined) {
    setSameAsPermanentAddress(preliminaryDetailsByID?.data?.is_present_address_different)
    // }
  }, [preliminaryDetailsByID])

  const initialValues = {
    account_type_id: preliminaryDetailsByID?.data?.account_type_id?.id || '',
    salutation: preliminaryDetailsByID?.data?.salutation?.id || '',
    first_name: preliminaryDetailsByID?.data?.first_name || '',
    middle_name: preliminaryDetailsByID?.data?.middle_name || '',
    last_name: preliminaryDetailsByID?.data?.last_name || '',
    gender: preliminaryDetailsByID?.data?.gender || '',
    date_of_birth_bs: preliminaryDetailsByID?.data?.date_of_birth_bs || '',
    date_of_birth_ad: preliminaryDetailsByID?.data?.date_of_birth_ad || '',
    marital_status: preliminaryDetailsByID?.data?.marital_status || '',
    father_name: preliminaryDetailsByID?.data?.father_name || '',
    grandfather_name: preliminaryDetailsByID?.data?.grandfather_name || '',
    spouse_name: preliminaryDetailsByID?.data?.spouse_name || '',
    father_in_law: preliminaryDetailsByID?.data?.father_in_law || '',
    id_type_id: preliminaryDetailsByID?.data?.id_type_id?.id || '',
    identification_number: preliminaryDetailsByID?.data?.identification_number || '',
    nationality_id: preliminaryDetailsByID?.data?.nationality_id?.title || '',
    refugee_details: preliminaryDetailsByID?.data?.refugee_details || '',
    id_expiry_date: preliminaryDetailsByID?.data?.id_expiry_date || '',
    id_expiry_date_ad: preliminaryDetailsByID?.data?.id_expiry_date_ad || '',
    income_source_id: preliminaryDetailsByID?.data?.income_source_id?.id || '',
    other_income_source: preliminaryDetailsByID?.data?.other_income_source || '',
    total_annual_income: preliminaryDetailsByID?.data?.total_annual_income?.id || '',
    occupation_id: preliminaryDetailsByID?.data?.occupation_id?.title || '',
    other_occupation: preliminaryDetailsByID?.data?.other_occupation || '',
    guardian_address: preliminaryDetailsByID?.data?.guardian_address || '',
    guardian_cif_number: preliminaryDetailsByID?.data?.guardian_cif_number || '',
    guardian_first_name: preliminaryDetailsByID?.data?.guardian_first_name || '',
    guardian_id_number: preliminaryDetailsByID?.data?.guardian_id_number || '',
    guardian_last_name: preliminaryDetailsByID?.data?.guardian_last_name || '',
    guardian_middle_name: preliminaryDetailsByID?.data?.guardian_middle_name || '',
    guardian_relationship_id: preliminaryDetailsByID?.data?.guardian_relationship_id?.id || '',
    customer_group_id: preliminaryDetailsByID?.data?.customer_group_id?.id || '',
    constitution_id: preliminaryDetailsByID?.data?.constitution_id?.id || '',
    //TO BE SENT IN PAYLOAD
    form_addresses: [
      {
        permanent_country: preliminaryDetailsByID?.data?.permanent_country?.id
          ? preliminaryDetailsByID?.data?.permanent_country?.id
          : allMasterDataOptions?.countries?.find(
              (item: any) => item?.cbs_code === preliminaryDetailsByID?.data?.nationality_id?.code
            )?.id,
        permanent_province: preliminaryDetailsByID?.data?.permanent_province?.id || '',
        permanent_zone: preliminaryDetailsByID?.data?.permanent_zone?.id || '',
        permanent_district: preliminaryDetailsByID?.data?.permanent_district?.id || '',
        permanent_municipality: preliminaryDetailsByID?.data?.permanent_municipality?.id || '',
        permanent_ward_number: preliminaryDetailsByID?.data?.permanent_ward_number || '',
        permanent_city: preliminaryDetailsByID?.data?.permanent_city?.id || '',
        permanent_street_name: preliminaryDetailsByID?.data?.permanent_street_name || '',
        permanent_house_number: preliminaryDetailsByID?.data?.permanent_house_number || '',
        permanent_fax_number: preliminaryDetailsByID?.data?.permanent_fax_number || '',
        permanent_mobile_number: preliminaryDetailsByID?.data?.permanent_mobile_number || '',
        permanent_email: preliminaryDetailsByID?.data?.permanent_email || '',
        permanent_google_plus_code: '',
        permanent_outside_country_post_box:
          preliminaryDetailsByID?.data?.permanent_outside_country_post_box || null,
        permanent_outside_country_full_address:
          preliminaryDetailsByID?.data?.permanent_outside_country_full_address || null,
        is_present_address_different: preliminaryDetailsByID?.data?.is_present_address_different,
        present_country:
          preliminaryDetailsByID?.data?.present_country?.id ||
          allMasterDataOptions?.countries?.find((item: any) => item?.cbs_code === 'NP')?.id,
        present_province: preliminaryDetailsByID?.data?.present_province?.id || '',
        present_zone: preliminaryDetailsByID?.data?.present_zone?.id || '',
        present_city: preliminaryDetailsByID?.data?.present_city?.id || '',
        present_district: preliminaryDetailsByID?.data?.present_district?.id || '',
        present_municipality: preliminaryDetailsByID?.data?.present_municipality?.id || '',
        present_ward_number: preliminaryDetailsByID?.data?.present_ward_number || '',
        present_street_name: preliminaryDetailsByID?.data?.present_street_name || '',
        present_house_number: preliminaryDetailsByID?.data?.present_house_number || '',
        present_fax_number: preliminaryDetailsByID?.data?.present_fax_number || '',
        present_mobile_number: preliminaryDetailsByID?.data?.present_mobile_number || '',
        present_email: preliminaryDetailsByID?.data?.present_email || '',
        present_google_plus_code: '',
        present_outside_country_post_box:
          preliminaryDetailsByID?.data?.present_outside_country_post_box || null,
        present_outside_country_full_address:
          preliminaryDetailsByID?.data?.present_outside_country_full_address || null,
        address_type: preliminaryDetailsByID?.data?.address_type || '',
      },
    ],

    action: '',
    issuing_office: preliminaryDetailsByID?.data?.issuing_office?.id || '',
    id_issued_date: preliminaryDetailsByID?.data?.id_issued_date || '',
    id_issued_date_ad: preliminaryDetailsByID?.data?.id_issued_date_ad || '',
    issuing_district: preliminaryDetailsByID?.data?.issuing_district?.id || '',
    account_scheme_id: preliminaryDetailsByID?.data?.account_scheme_id || '',
    bank_code_id: preliminaryDetailsByID?.data?.bank_code_id?.id || '',
    customer_category_id: preliminaryDetailsByID?.data?.customer_category_id?.id || '',
    customer_status_id: preliminaryDetailsByID?.data?.customer_status_id?.id || '',
    customer_free_text: preliminaryDetailsByID?.data?.customer_free_text || '',
    cdd_edd_date: preliminaryDetailsByID?.data?.cdd_edd_date || '',
    annual_turn_over_as_on: preliminaryDetailsByID?.data?.annual_turn_over_as_on || '',
    aml_risk_rating: preliminaryDetailsByID?.data?.aml_risk_rating?.id || '',
    sub_risk_category_id: preliminaryDetailsByID?.data?.sub_risk_category_id?.id || '',
    has_existing_account: preliminaryDetailsByID?.data?.has_existing_account,
    existing_account_number: preliminaryDetailsByID?.data?.existing_account_number || '',
    relation_with_existing_account:
      preliminaryDetailsByID?.data?.relation_with_existing_account || '',
    other_relation_with_account: preliminaryDetailsByID?.data?.other_relation_with_account || '',
    relationship_id: preliminaryDetailsByID?.data?.relationship_id || '',
    religion_id: preliminaryDetailsByID?.data?.religion_id?.id || '',
    other_religion: preliminaryDetailsByID?.data?.other_religion || '',
    education_id: preliminaryDetailsByID?.data?.education_id?.id || '',
    other_education_qualification:
      preliminaryDetailsByID?.data?.other_education_qualification || '',
    account_purpose_id: preliminaryDetailsByID?.data?.account_purpose_id?.id || '',
    other_account_purpose: preliminaryDetailsByID?.data?.other_account_purpose || '',
    expected_annual_transaction_amount:
      preliminaryDetailsByID?.data?.expected_annual_turnover || '',
    expected_annual_transaction_number:
      preliminaryDetailsByID?.data?.expected_annual_transaction_number || '',
    pep_status: preliminaryDetailsByID?.data?.pep_status || '',
    pep_relationship: preliminaryDetailsByID?.data?.pep_relationship?.id || '',
    pep_position: preliminaryDetailsByID?.data?.pep_position?.id || '',
    pep_retirement_date: preliminaryDetailsByID?.data?.pep_retirement_date || '',
    pep_category_id: preliminaryDetailsByID?.data?.pep_category_id?.id || '',
    pep_sub_category_id: preliminaryDetailsByID?.data?.pep_sub_category_id?.id || '',
    beneficial_owner: preliminaryDetailsByID?.data?.beneficial_owner || 'No',
    beneficial_owner_name: preliminaryDetailsByID?.data?.beneficial_owner_name || '',
    beneficial_owner_occupation:
      preliminaryDetailsByID?.data?.beneficial_owner_occupation?.id || '',
    beneficial_owner_relation: preliminaryDetailsByID?.data?.beneficial_owner_relation?.id || '',
    pan_number: preliminaryDetailsByID?.data?.pan_number || '',
    pan_registration_date: preliminaryDetailsByID?.data?.pan_registration_date || '',
    visa_issue_date: preliminaryDetailsByID?.data?.visa_issue_date || '',
    visa_issue_date_ad: preliminaryDetailsByID?.data?.visa_issue_date_ad || '',
    visa_expiry_date: preliminaryDetailsByID?.data?.visa_expiry_date || '',
    visa_expiry_date_ad: preliminaryDetailsByID?.data?.visa_expiry_date_ad || '',
    visa_arrival_date: preliminaryDetailsByID?.data?.visa_arrival_date || '',
    visa_arrival_date_ad: preliminaryDetailsByID?.data?.visa_arrival_date_ad || '',
    work_permit_id: preliminaryDetailsByID?.data?.work_permit_id || '',
    work_permit_issued_date: preliminaryDetailsByID?.data?.work_permit_issued_date || '',
    work_permit_issued_date_ad: preliminaryDetailsByID?.data?.work_permit_issued_date_ad || '',
    work_permit_expiry_date_ad: preliminaryDetailsByID?.data?.work_permit_expiry_date_ad || '',
    work_permit_expiry_date: preliminaryDetailsByID?.data?.work_permit_expiry_date || '',
    issuing_authority: preliminaryDetailsByID?.data?.issuing_authority || '',
    landlord_name: preliminaryDetailsByID?.data?.landlord_name || '',
    landlord_address: preliminaryDetailsByID?.data?.landlord_address || '',
    landlord_mobile_number: preliminaryDetailsByID?.data?.landlord_mobile_number || '',
    location_map_residential_address:
      preliminaryDetailsByID?.data?.location_map_residential_address || '',
    crime_conviction: preliminaryDetailsByID?.data?.crime_conviction || false,
    crime_details: preliminaryDetailsByID?.data?.crime_details || '',
    residential_permit_foreign_country:
      preliminaryDetailsByID?.data?.residential_permit_foreign_country,
    permit_details: preliminaryDetailsByID?.data?.permit_details || '',
    blacklisting_inclusion: preliminaryDetailsByID?.data?.blacklisting_inclusion,
    blacklist_release_date: preliminaryDetailsByID?.data?.blacklist_release_date || '',
    remarks: preliminaryDetailsByID?.data?.remarks || '',
    national_id_number: preliminaryDetailsByID?.data?.national_id_number || '',
    minor_certificate_number: preliminaryDetailsByID?.data?.minor_certificate_number || '',
    minor_certificate_issue_date_ad:
      preliminaryDetailsByID?.data?.minor_certificate_issue_date_ad || '',
    minor_certificate_issue_date_bs:
      preliminaryDetailsByID?.data?.minor_certificate_issue_date_bs || '',
    certificate_issued_by: preliminaryDetailsByID?.data?.certificate_issued_by?.id || '',
    screening_id: preliminaryDetailsByID?.data?.screening_id,
    branch_code: preliminaryDetailsByID?.data?.branch_code,
    customer_code: preliminaryDetailsByID?.data?.customer_code,
    family_details: preliminaryDetailsByID?.data?.family_details?.family_details?.map(
      (detail: any) => ({
        name: detail?.name || '',
        profession: detail?.profession?.id || '',
        relationship_id: detail?.relationship_id?.id || '',
      })
    ) || [
      {
        name: '',
        profession: '',
        relationship_id: '',
      },
    ],

    facta_declarations: preliminaryDetailsByID?.data?.facta_declarations?.facta_declarations?.map(
      (declaration: any) => ({
        us_person: declaration?.us_person || 'No',
        us_indicia: declaration?.us_indicia || '',
        us_telephone_number: declaration?.us_telephone_number || '',
        us_citizenship: declaration?.us_citizenship || '',
        us_birth_country: declaration?.us_birth_country || '',
        us_residence: declaration?.us_residence || '',
        tin_number: declaration?.tin_number || '',
        w9_form_submitted: declaration?.w9_form_submitted || '',
        joint_us_person: declaration?.joint_us_person || 'No',
        joint_us_indicia: declaration?.joint_us_indicia || '',
        joint_us_telephone_number: declaration?.joint_us_telephone_number || '',
        joint_us_citizenship: declaration?.joint_us_citizenship || '',
        joint_us_birth_country: declaration?.joint_us_birth_country || '',
        joint_us_residence: declaration?.joint_us_residence || '',
        joint_tin_number: declaration?.joint_tin_number || '',
        joint_w9_form_submitted: declaration?.joint_w9_form_submitted || '',
      })
    ) || [
      {
        us_person: 'No',
        us_indicia: '',
        us_telephone_number: '',
        us_citizenship: '',
        us_birth_country: '',
        us_residence: '',
        tin_number: '',
        w9_form_submitted: '',
        joint_us_person: '',
        joint_us_indicia: '',
        joint_us_telephone_number: '',
        joint_us_citizenship: '',
        joint_us_birth_country: '',
        joint_us_residence: '',
        joint_tin_number: '',
        joint_w9_form_submitted: '',
      },
    ],
    businesses: preliminaryDetailsByID?.data?.businesses?.businesses?.map((business: any) => ({
      name_of_institution: business?.name_of_institution || '',
      address: business?.address || '',
      telephone_number: business?.telephone_number || '',
      designation: business?.designation || '',
      email: business?.email || '',
      expected_annual_income: String((business?.expected_annual_income as string) || ''),
    })) || [
      {
        name_of_institution: '',
        address: '',
        telephone_number: '',
        designation: '',
        email: '',
        expected_annual_income: '',
      },
    ],
    other_banks: preliminaryDetailsByID?.data?.other_banks?.other_banks?.map((otherbank: any) => ({
      bank_name: otherbank?.bank_name,
      branch_name: otherbank?.branch_name,
      saving_ac: otherbank?.saving_ac,
      current_ac: otherbank?.current_ac,
      fixed_ac: otherbank?.fixed_ac,
      working_capital: otherbank?.working_capital,
      term_loan: otherbank?.term_loan,
      others: otherbank?.others,
    })) || [
      {
        bank_name: '',
        saving_ac: '',
        current_ac: '',
        fixed_ac: '',
        working_capital: '',
        term_loan: '',
        others: '',
      },
    ],
  }
  const handleSaveButtonClick = (flag: string) => {
    setBtnFlag(flag)
  }

  const a = preliminaryDetailsByID?.data?.facta_declarations?.facta_declarations?.map(
    (declaration: any) => declaration
  )

  const handleBack = () => {
    history.go(-1)
  }
  const transformValues = (values: any) => {
    return {
      family_details: values?.family_details,
      facta_declarations: values?.facta_declarations,
      businesses: values?.businesses,
      other_banks: values.other_banks,
    }
  }
  console.log(sameAsPermanentAddress, 'out')
  const handleSubmit = (values: any, actions: any) => {
    console.log(sameAsPermanentAddress, 'int')
    const payload = {
      ...values,
      id: id,
      is_present_address_different: sameAsPermanentAddress,

      form_addresses: {...values?.form_addresses?.[0]},
      action: btnFlag === 'save' ? 'save' : 'save_and_continue',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
      visa_issue_date_ad: values?.visa_issue_date_ad
        ? moment(values?.visa_issue_date_ad).format('YYYY-MM-DD')
        : '',
      visa_expiry_date_ad: values?.visa_expiry_date_ad
        ? moment(values?.visa_expiry_date_ad).format('YYYY-MM-DD')
        : '',
      visa_arrival_date_ad: values?.visa_arrival_date_ad
        ? moment(values?.visa_arrival_date_ad).format('YYYY-MM-DD')
        : '',
      work_permit_issued_date_ad: values?.work_permit_issued_date_ad
        ? moment(values?.work_permit_issued_date_ad).format('YYYY-MM-DD')
        : '',
    }
    const additionalFormPayload: any = transformValues(values)
    addAddtionalDetails(
      {id, additionalFormPayload},
      {
        onSuccess: (res: any) => {
          updateKycDetails(payload, {
            onSuccess: (response: any) => {
              if (btnFlag === 'save_and_continue') {
                setKycFormSubmitted(true)
              }
              actions.setSubmitting(false)
              // history.push('/new-cif-new-accounts')
              toast.success(response?.data?.resDesc)

              history.go(-1)
            },
            onError: (error) => {
              actions.setSubmitting(false)
              toast.error('Failed')
            },
          })
        },
        onError: (error) => {
          actions.setSubmitting(false)
          toast.error('Failed to add additional details')
        },
      }
    )
  }
  const handleCheckboxChange = (setFieldValue: any, values: any) => {
    if (!sameAsPermanentAddress) {
      setFieldValue('form_addresses[0].present_country', values.form_addresses[0].permanent_country)
      setFieldValue(
        'form_addresses[0].present_province',
        values.form_addresses[0].permanent_province
      )
      setFieldValue('form_addresses[0].present_zone', values.form_addresses[0].permanent_zone)
      setFieldValue(
        'form_addresses[0].present_district',
        values.form_addresses[0].permanent_district
      )
      setFieldValue(
        'form_addresses[0].present_municipality',
        values.form_addresses[0].permanent_municipality
      )
      setFieldValue(
        'form_addresses[0].present_ward_number',
        values.form_addresses[0].permanent_ward_number
      )
      setFieldValue('form_addresses[0].present_city', values.form_addresses[0].permanent_city)
      setFieldValue(
        'form_addresses[0].present_street_name',
        values.form_addresses[0].permanent_street_name
      )
      setFieldValue(
        'form_addresses[0].present_house_number',
        values.form_addresses[0].permanent_house_number
      )
      setFieldValue(
        'form_addresses[0].present_fax_number',
        values.form_addresses[0].permanent_fax_number
      )
      setFieldValue(
        'form_addresses[0].present_mobile_number',
        values.form_addresses[0].permanent_mobile_number
      )
      setFieldValue(
        'form_addresses[0].present_outside_country_full_address',
        values.form_addresses[0].permanent_outside_country_full_address
      )
      setFieldValue(
        'form_addresses[0].present_outside_country_post_box',
        values.form_addresses[0].permanent_outside_country_post_box
      )
      setFieldValue('form_addresses[0].present_email', values.form_addresses[0].permanent_email)
    } else {
      // setFieldValue('form_addresses[0].present_country', '')
      setFieldValue('form_addresses[0].present_province', '')
      setFieldValue('form_addresses[0].present_zone', '')
      setFieldValue('form_addresses[0].present_district', '')
      setFieldValue('form_addresses[0].present_municipality', '')
      setFieldValue('form_addresses[0].present_ward_number', '')
      setFieldValue('form_addresses[0].present_city', '')
      setFieldValue('form_addresses[0].present_street_name', '')
      setFieldValue('form_addresses[0].present_house_number', '')
      setFieldValue('form_addresses[0].present_fax_number', '')
      setFieldValue('form_addresses[0].present_mobile_number', '')
      setFieldValue('form_addresses[0].present_email', '')
      setFieldValue('form_addresses[0].present_outside_country_full_address', '')
      setFieldValue('form_addresses[0].present_outside_country_post_box', '')
    }
    setSameAsPermanentAddress(!sameAsPermanentAddress)
  }

  const handleScreening = () => {
    setScreeningLoading(true)
    const checkAllPayload: any = {
      customer_name: data?.full_name,
      citizenship: data?.identification_number,
      model_name: 'NewCIF',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setScreeningLoading(false)
        setScreeningPerformed(true)
      },
      onError: (error) => {
        console.error('Error:', error)
        setScreeningLoading(false)
      },
    })
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: any) => {
    let checkAllPayload: any = {}
    if (update.current === 'minor') {
      const guardian_full_name: string = [
        initialValues?.guardian_first_name,
        initialValues?.guardian_middle_name,
        initialValues?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        date_of_birth_bs: initialValues?.date_of_birth_bs || '',
        unique_id_no: '',
        citizenship: initialValues?.identification_number,
        gender: initialValues?.gender || '',
        model_name: 'NewCIF',
      }

      await mutateCheckAll(checkAllPayload, {
        onSuccess: (data) => {
          setEnquiryLoading(false)
          setLoadingIndex(null)
        },
        onError: (error) => {
          console.error('Error:', error)
          setEnquiryLoading(false)
          setLoadingIndex(null)
          clickedIndex.shift()
        },
      })
    }
    setEnquiryLoading(true)
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  const getMunicipilities = (id: string, setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue('form_addresses[0].present_district', response?.data?.district?.id)
        setFieldValue('form_addresses[0].present_zone', response?.data?.district?.zone?.id)
        setFieldValue('form_addresses[0].present_province', response?.data?.district?.province?.id)
      },
    })
  }

  const data = preliminaryDetailsByID?.data
  const [filteredSubCategories, setFilteredSubCategories] = useState([])

  const handlePEPCategoryChange = (selectedCategoryId: any, setFieldValue: any) => {
    const filtered = allMasterDataOptions?.pep_sub_categories?.find(
      (pepsubcat: any) => pepsubcat?.pep_category?.id === selectedCategoryId
    )

    setFieldValue('pep_category_id', selectedCategoryId)

    setFieldValue('pep_sub_category_id', filtered?.id)
  }

  return (
    <>
      {detailsLoading ? (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body text-center '>
            <h3>
              <Spinner animation='border' variant='danger' className='mb-3' />
              <br />
              Please Wait...
            </h3>
          </div>
        </div>
      ) : (
        <div className=''>
          <div className=''>
            <div className=''>
              <div className=''>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  validateOnChange={true}
                  validateOnBlur={true}
                  validationSchema={
                    // btnFlag == 'save_and_continue' ? CPUMAKER_SAVE_AND_CONTINUE : CPUMAKER_SAVE
                    btnFlag == 'save_and_continue'
                      ? CPUMAKER_SAVE_AND_CONTINUE(allMasterDataOptions)
                      : undefined
                  }
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    touched,
                    setFieldValue,
                    isSubmitting,
                    setTouched,
                  }) => (
                    <Form className=''>
                      <div className='row'>
                        {console.log(errors, 'Errors')}
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            // uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Screening_id'
                            name='screening_id'
                            label='Screening Id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            maxLength={6}
                            disabled
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            // uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Branch Code'
                            name='branch_code'
                            label='Branch Code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            maxLength={6}
                            disabled
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            // uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Customer Code'
                            name='customer_code'
                            label='Customer Code'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required
                            maxLength={6}
                            disabled
                          />
                        </div>
                        <div className='col-md-4 col-xs-12 mb-5'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>
                            Select Account Type
                          </label>
                          <div className='d-flex'>
                            {isLoading ? (
                              <Spinner animation={'border'} />
                            ) : (
                              allMasterDataOptions?.account_types
                                ?.filter((type: {title: string}) =>
                                  ['Individual']?.includes(type.title)
                                )
                                ?.map(({id, title}: any) => (
                                  <FormRadio
                                    key={id}
                                    containerClassName=''
                                    label='Account Type'
                                    name='account_type_id'
                                    type='radio'
                                    onChange={(e) => {
                                      handleChange(e)
                                      getSaluationData(e.target.value)
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    required
                                    value={id}
                                    checkBoxText={title}
                                    checked={values.account_type_id === id}
                                    //  className={'bg-warning w-100 p-3 rounded-2'}
                                    disabled={true}
                                  />
                                ))
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>A. Customer Name:</h3>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='First Name'
                            name='first_name'
                            label='First Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            uppercase={true}
                            required={true}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                            value={values?.first_name}
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Middle Name'
                            name='middle_name'
                            label='Middle Name'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            uppercase={true}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Last Name'
                            name='last_name'
                            label='Last Name'
                            onChange={handleChange}
                            errors={errors}
                            // required
                            touched={touched}
                            uppercase={true}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>B. Gender</h3>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Gender'
                            name='gender'
                            label='Gender'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            required
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>C. Marital Status</h3>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Marital Status'
                            name='marital_status'
                            onChange={handleChange}
                            arrValue={values?.marital_status?.id}
                            errors={errors}
                            touched={touched}
                            options={allMasterDataOptions?.marital_statuses?.map((status: any) => ({
                              value: status.id || status.value,
                              label: status.title || status.label,
                            }))}
                            setFieldValue={setFieldValue}
                            required
                            // // colors={true}
                            disabled={true}
                          />
                        </div>
                        {allMasterDataOptions?.marital_statuses?.find(
                          (status: any) => status.title === values.marital_status.title
                        )?.title === 'MARRIED' && (
                          <>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                name='spouse_name'
                                label='Spouse Name'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                placeholder={'Enter spouse Name'}
                                maxLength={50}
                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                disabled
                              />
                            </div>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                // uppercase={true}
                                type='text'
                                name='father_in_law'
                                label='Father in Law Name'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                placeholder={'Enter Father In Law Name'}
                                maxLength={50}
                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                disabled
                              />
                            </div>
                          </>
                        )}{' '}
                      </div>
                      {/* Customer Name */}
                      {/* Gender */}
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>D. Date of Birth</h3>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Date of Birth (BS)'
                            name='date_of_birth_bs'
                            label='Date of Birth(BS)'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            required
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Date of Birth (AD)'
                            name='date_of_birth_ad'
                            label='Date of Birth (AD)'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            required
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>E. Nationality</h3>
                      </div>

                      <div className='row'>
                        <div className='col-md-4 col-sm-6'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Nationality '
                            name='nationality_id'
                            label='Nationality'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={values?.nationality_id}
                            // uppercase={true}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Others'
                            name='refugee_details'
                            values={values}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={[
                              {label: 'REFUGEES', value: 'REFUGEES'},
                              {
                                label: 'NON CITIZENSHIP HOLDER',
                                value: 'NON_CITIZENSHIP_HOLDER',
                              },
                            ]}
                            setFieldValue={setFieldValue}
                            // colors={true}
                            disabled
                          />
                        </div>

                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Identification'
                            name='id_type_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.id_type_id}
                            options={allMasterDataOptions?.id_types?.map((identityType: any) => ({
                              value: identityType.id || identityType.value,
                              label: identityType.title || identityType.label,
                            }))}
                            setFieldValue={setFieldValue}
                            required
                            // colors={true}
                            disabled={true}
                          />
                        </div>
                        <div className='col-md-4 col-sm-6'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Identification Number'
                            name='identification_number'
                            label='Identification Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            required
                            value={values.identification_number}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled={true}
                          />
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Issuing Office'
                            name='issuing_office'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            arrValue={values?.issuing_office}
                            options={allMasterDataOptions?.individual_unique_ids?.map(
                              (individual_unique_ids: any) => ({
                                value: individual_unique_ids?.id || individual_unique_ids.value,
                                label: individual_unique_ids?.title || individual_unique_ids.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-lg-12'
                            placeholder='Select'
                            label='Issuing District'
                            name='issuing_district'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            values={values}
                            options={districtOptions}
                            setFieldValue={setFieldValue}
                            // required
                            disabled
                            arrValue={values?.issuing_district}
                          />
                        </div>
                        <div className='col-md-4 col-sm-4 col-xs-12'>
                          <FormTextBox
                            // uppercase={true}
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='National Id'
                            name='national_id_number'
                            label='National Id Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            maxLength={10}
                            value={values?.national_id_number}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled
                          />
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='ID Issued Date'
                            name='id_issued_date_ad'
                            label='ID Issued Date (AD)'
                            onChange={(e: any) => {
                              setFieldValue(
                                'id_issued_date',
                                e.target.value && adToBs(e.target.value)
                              )
                              handleChange(e)
                            }}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            preventFutureDate={true}
                            required
                            value={values.id_issued_date_ad}
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled
                          />
                        </div>
                        <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormNepaliCalendarDatePicker
                            englishFormName='id_issued_date_ad'
                            nepaliFormName='id_issued_date'
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            label='Id Issued Date (BS)'
                            required
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled
                          />
                        </div>
                        <div className='col-md-12 col-sm-6 col-xs-12'>
                          {/* Conditional Rendering of ID Expiry Date */}
                          {!(
                            allMasterDataOptions?.id_types
                              ?.find((account_types: any) => account_types.id === values.id_type_id)
                              ?.title.toLowerCase() === 'citizenship' ||
                            allMasterDataOptions?.id_types
                              ?.find((account_type: any) => account_type.id === values.id_type_id)
                              ?.title.toLowerCase() === 'pan card' ||
                            allMasterDataOptions?.id_types
                              ?.find((account_type: any) => account_type.id === values.id_type_id)
                              ?.title.toLowerCase() === 'birth certificate' ||
                            allMasterDataOptions?.id_types
                              ?.find((account_type: any) => account_type.id === values.id_type_id)
                              ?.title.toLowerCase() === 'passport'
                          ) && (
                            <div className='row'>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='id_expiry_date_ad'
                                  nepaliFormName='id_expiry_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Expiry Date (BS)'
                                  required
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  disabled
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  // uppercase={true}
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  name='id_expiry_date_ad'
                                  label='Expiry Date (AD)'
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      'id_expiry_date',
                                      e.target.value && adToBs(e.target.value)
                                    )
                                    handleChange(e)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  disabled
                                />
                              </div>
                            </div>
                          )}
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            {values?.form_addresses?.length > 0 &&
                              values?.form_addresses?.map((address: any, index: number) => (
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Address Type'
                                  // name={`form_addresses[${index}]?.address_type`}
                                  name={`form_addresses.[${index}].address_type`}
                                  label='Address Type'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  options={AddressType}
                                  setFieldValue={setFieldValue}
                                  arrValue={values?.form_addresses[0]?.address_type}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                      {/* nationality */}
                      {/* IncomeSOURCE */}

                      <div className='row'>
                        {allMasterDataOptions?.account_types
                          ?.filter((type: {title: string}) => ['Individual'].includes(type.title))
                          .map(({id, title}: any) => (
                            <>
                              {values?.account_type_id === id && title === 'Minor' && (
                                <>
                                  <h3 className='fw-bolder mb-4 text-capitalize'>
                                    Guardian Details
                                  </h3>
                                  <div className='row'>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian CIF Number'
                                        name='guardian_cif_number'
                                        label='Guardian CIF Number'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        // uppercase={true}
                                        required
                                        value={values.guardian_cif_number}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                        maxLength={9}
                                      />
                                      {checkAllDataMinor ? (
                                        <Button
                                          className='mb-5 ms-3'
                                          onClick={() => {
                                            handleOpenPdf2()
                                          }}
                                        >
                                          View PDF
                                        </Button>
                                      ) : enquiryLoading ? (
                                        <Spinner animation='border' role='status'>
                                          <span className='sr-only'>Loading...</span>
                                        </Spinner>
                                      ) : (
                                        <Button
                                          className='mb-5'
                                          onClick={() => {
                                            handleGuardianScreening(values?.guardian_cif_number)
                                          }}
                                        >
                                          Enquiry
                                        </Button>
                                      )}
                                    </div>

                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian First Name'
                                        name='guardian_first_name'
                                        label='Guardian First Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        // uppercase={true}
                                        required
                                        value={values.guardian_first_name}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian Middle Name'
                                        name='guardian_middle_name'
                                        label='Guardian Middle Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        // uppercase={true}
                                        // required
                                        value={values.guardian_middle_name}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian Last Name'
                                        name='guardian_last_name'
                                        label='Guardian Last Name'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        // uppercase={true}
                                        // required
                                        value={values.guardian_last_name}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian ID Number'
                                        name='guardian_id_number'
                                        label='Guardian ID Number'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        // uppercase={true}
                                        required
                                        value={values.guardian_id_number}
                                        className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormTextBox
                                        // uppercase={true}
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Guardian Address'
                                        name='guardian_address'
                                        label='Guardian Address'
                                        onChange={handleChange}
                                        errors={errors}
                                        maxLength={50}
                                        touched={touched}
                                        required
                                        className={'bg-warning w-100 p-3 rounded-2'}
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-6 col-xs-12'>
                                      <FormSelect
                                        labelClassName='col-md-12'
                                        containerClassName='col-lg-12'
                                        placeholder='Select'
                                        label='Guardian Relationship'
                                        name='guardian_relationship_id'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        arrValue={values?.guardian_relationship_id}
                                        values={values?.guardian_relationship_id}
                                        options={allMasterDataOptions?.guardian_relationships?.map(
                                          (guardian_relationship_id: any) => ({
                                            value:
                                              guardian_relationship_id?.id ||
                                              guardian_relationship_id?.value,
                                            label:
                                              guardian_relationship_id?.title ||
                                              guardian_relationship_id?.label,
                                          })
                                        )}
                                        setFieldValue={setFieldValue}
                                        required
                                        // colors={true}
                                        disabled
                                      />
                                    </div>
                                    {/* <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormTextBox
                                        // uppercase={true}
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Minor Birth Certificate Number'
                                        name='minor_certificate_number'
                                        label='Minor Birth Certificate Number'
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        required
                                        maxLength={10}
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div> */}
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormSelect
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        placeholder='Certificatge Issued By'
                                        label='Certificate Issued By'
                                        name={`certificate_issued_by`}
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        options={allMasterDataOptions?.individual_unique_ids?.map(
                                          (certificateIssue: any) => ({
                                            value: certificateIssue.id || certificateIssue.value,
                                            label: certificateIssue.title || certificateIssue.label,
                                          })
                                        )}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        arrValue={values.certificate_issued_by}
                                        required
                                        // colors={true}
                                        disabled
                                      />
                                    </div>

                                    {/* <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormNepaliCalendarDatePicker
                                        englishFormName='minor_certificate_issue_date_ad'
                                        nepaliFormName='minor_certificate_issue_date_bs'
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        values={values}
                                        label='Minor Certificate Issued on (BS)'
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled={true}
                                      />
                                    </div>
                                    <div className='col-md-4 col-sm-4 col-xs-12'>
                                      <FormTextBox
                                        // uppercase={true}
                                        labelClassName='col-lg-12'
                                        containerClassName='col-lg-12'
                                        type='text'
                                        placeholder='Minor Certificate Issued on (AD)'
                                        name='minor_certificate_issue_date_ad'
                                        label='Minor Certificate Issued on (AD)'
                                        onChange={(e: any) => {
                                          setFieldValue(
                                            'minor_certificate_issue_date_bs',
                                            e.target.value && adToBs(e.target.value)
                                          )
                                          handleChange(e)
                                        }}
                                        errors={errors}
                                        touched={touched}
                                        required
                                        //  className={'bg-warning w-100 p-3 rounded-2'}
                                        disabled
                                      />
                                    </div> */}
                                  </div>
                                </>
                              )}
                            </>
                          ))}
                      </div>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>F. Communication Address</h3>
                        <FieldArray
                          name='form_addresses'
                          render={(arrayHelpers) => (
                            <div>
                              {values.form_addresses && values.form_addresses.length > 0
                                ? values.form_addresses.map((friend: any, index) => (
                                    <div key={index}>
                                      <div className='row'>
                                        <div className='col-md-4 col-sm-6'>
                                          <FormSelect
                                            labelClassName='col-lg-12'
                                            containerClassName='col-lg-12'
                                            type='text'
                                            placeholder='Permanent Country'
                                            name={`form_addresses.[${index}].permanent_country`}
                                            label='Permanent Country'
                                            // colors={true}
                                            disabled={true}
                                            onChange={(e: any) => {
                                              handleChange(e)
                                              onCountryChange(e, setFieldValue, setTouched)
                                            }}
                                            errors={errors}
                                            touched={touched}
                                            required
                                            arrValue={
                                              values?.form_addresses[index]?.permanent_country
                                            }
                                            options={allMasterDataOptions?.countries?.map(
                                              (country: any) => ({
                                                value: country.id || country.value,
                                                label: country.title || country.label,
                                              })
                                            )}
                                            setFieldValue={setFieldValue}
                                          />
                                        </div>
                                        {allMasterDataOptions?.countries?.find(
                                          (item: any) =>
                                            item.id === values?.form_addresses[0]?.permanent_country
                                        )?.title === 'NEPAL' ? (
                                          <>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Province'
                                                name={`form_addresses.[${index}].permanent_province`}
                                                label='Permanent Province'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                required
                                                arrValue={
                                                  values?.form_addresses[index]?.permanent_province
                                                }
                                                options={provinceOptions}
                                                setFieldValue={setFieldValue}
                                                // colors={true}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Zone'
                                                name={`form_addresses.[${index}].permanent_zone`}
                                                label='Permanent Zone'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={values?.form_addresses[0]?.permanent_zone}
                                                options={zoneOptions}
                                                setFieldValue={setFieldValue}
                                                required
                                                // colors={true}
                                                disabled={true}
                                              />
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent District'
                                                name={`form_addresses.[${index}].permanent_district`}
                                                label='Permanent District'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[0]?.permanent_district
                                                }
                                                options={districtOptions}
                                                setFieldValue={setFieldValue}
                                                required
                                                // colors={true}
                                                disabled={true}
                                              />
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Municipality'
                                                name={`form_addresses.[${index}].permanent_municipality`}
                                                label='Permanent Municipality'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]
                                                    ?.permanent_municipality
                                                }
                                                required
                                                options={municipalitiesOptions}
                                                setFieldValue={setFieldValue}
                                                // colors={true}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent  City'
                                                name={`form_addresses.[${index}].permanent_city`}
                                                label='Permanent City'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]?.permanent_city
                                                }
                                                options={allMasterDataOptions?.cities?.map(
                                                  (city: any) => ({
                                                    value: city?.id || city.value,
                                                    label: city?.title || city.label,
                                                  })
                                                )}
                                                // colors={true}
                                                disabled
                                                setFieldValue={setFieldValue}
                                                required
                                              />
                                            </div>
                                            {/* <div className='row'> */}
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Street'
                                                name={`form_addresses.[${index}].permanent_street_name`}
                                                label='Permanent Street'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_street_name
                                                }
                                                // uppercase={true}
                                                required
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent House Number'
                                                name={`form_addresses.[${index}].permanent_house_number`}
                                                label='Permanent House Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_house_number
                                                }
                                                // uppercase={true}
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                            {/* </div> */}
                                            {/* <div className='row'> */}
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Mobile Number'
                                                name={`form_addresses.[${index}].permanent_mobile_number'}`}
                                                label='Permanent Mobile Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_mobile_number
                                                }
                                                // uppercase={true}
                                                required
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Fax Number'
                                                name={`form_addresses.[${index}].permanent_fax_number`}
                                                label='Permanent Fax Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_fax_number
                                                }
                                                // uppercase={true}
                                                maxLength={10}
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled
                                              />
                                              {/* </div> */}
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Ward Number'
                                                name={`form_addresses.[${index}].permanent_ward_number'`}
                                                label='Permanent Ward Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_ward_number
                                                }
                                                // uppercase={true}
                                                required
                                                maxLength={2}
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Email'
                                                name={`form_addresses.[${index}].permanent_email`}
                                                label='Permanent Email'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={values?.form_addresses[0]?.permanent_email}
                                                // uppercase={true}
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Post Box No.'
                                                name={`form_addresses.[${index}].permanent_outside_country_post_box`}
                                                label='Post Box No.'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.permanent_outside_country_post_box
                                                }
                                                // uppercase={true}
                                                required
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Full Address'
                                                name={`form_addresses.[${index}].permanent_outside_country_full_address`}
                                                label='Full Address'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.permanent_outside_country_full_address
                                                }
                                                // uppercase={true}
                                                required
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Permanent Mobile Number'
                                                name={`form_addresses.[${index}].permanent_mobile_number'}`}
                                                label='Permanent Mobile Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[0]?.permanent_mobile_number
                                                }
                                                // uppercase={true}
                                                required
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Email'
                                                name={`form_addresses.[${index}].permanent_email`}
                                                label='Permanent Email'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={values?.form_addresses[0]?.permanent_email}
                                                // uppercase={true}
                                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                                disabled={true}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className='row pb-5 pt-5'>
                                        <div className='col-md-12'>
                                          <div className='form-check'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              value=''
                                              id='sameAsPermanentAddress'
                                              checked={sameAsPermanentAddress}
                                              onChange={() =>
                                                handleCheckboxChange(setFieldValue, values)
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='sameAsPermanentAddress'
                                            >
                                              Same as Permanent Address
                                            </label>
                                          </div>
                                        </div>
                                      </div>

                                      {allMasterDataOptions?.countries?.find(
                                        (item: any) =>
                                          item.id === values?.form_addresses[0]?.present_country
                                      )?.title === 'NEPAL' ? (
                                        <>
                                          <div className='row'>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Country'
                                                name={`form_addresses.[${index}].present_country`}
                                                label='Present Country'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                required
                                                arrValue={
                                                  values?.form_addresses[index]?.present_country
                                                }
                                                disabled={sameAsPermanentAddress}
                                                options={allMasterDataOptions?.countries?.map(
                                                  (country: any) => ({
                                                    value: country.id || country.value,
                                                    label: country.title || country.label,
                                                  })
                                                )}
                                                setFieldValue={setFieldValue}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Province'
                                                name={`form_addresses.[${index}].present_province`}
                                                label='Present Province'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]?.present_province
                                                }
                                                required
                                                options={provinceOptions.map((prov: any) => ({
                                                  value: prov?.id || prov?.value,
                                                  label: prov?.title || prov?.label,
                                                }))}
                                                setFieldValue={setFieldValue}
                                                disabled={sameAsPermanentAddress}
                                              />
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Zone'
                                                name={`form_addresses.[${index}].present_zone`}
                                                label='Present Zone'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]?.present_zone
                                                }
                                                options={zoneOptions.map((zone: any) => ({
                                                  value: zone?.id || zone?.value,
                                                  label: zone?.title || zone?.label,
                                                }))}
                                                required
                                                setFieldValue={setFieldValue}
                                                disabled={sameAsPermanentAddress}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present District'
                                                name={`form_addresses.[${index}].present_district`}
                                                label='Present District'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]?.present_district
                                                }
                                                required
                                                options={districtOptions.map((district: any) => ({
                                                  value: district?.id || district?.value,
                                                  label: district?.title || district?.label,
                                                }))}
                                                disabled={sameAsPermanentAddress}
                                                setFieldValue={setFieldValue}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Municipality'
                                                name={`form_addresses.[${index}].present_municipality`}
                                                label='Present Municipality'
                                                onChange={(e: any) => {
                                                  handleChange(e)
                                                  getMunicipilities(e, setFieldValue)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]
                                                    ?.present_municipality
                                                }
                                                required
                                                options={municipalitiesOptions}
                                                disabled={sameAsPermanentAddress}
                                                setFieldValue={setFieldValue}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present  City'
                                                name={`form_addresses.[${index}].present_city`}
                                                label='Present City'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                arrValue={
                                                  values?.form_addresses[index]?.present_city
                                                }
                                                options={allMasterDataOptions?.cities?.map(
                                                  (city: any) => ({
                                                    value: city?.id || city.value,
                                                    label: city?.title || city.label,
                                                  })
                                                )}
                                                disabled={sameAsPermanentAddress}
                                                setFieldValue={setFieldValue}
                                                required
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Street'
                                                name={`form_addresses.[${index}].present_street_name`}
                                                label='Present Street'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]?.present_street_name
                                                }
                                                // uppercase={true}
                                                required
                                                disabled={sameAsPermanentAddress}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present House Number'
                                                name={`form_addresses.[${index}].present_house_number`}
                                                label='Present House Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                disabled={sameAsPermanentAddress}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.present_house_number
                                                }
                                                // uppercase={true}
                                                // required
                                                maxLength={10}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Mobile Number'
                                                name={`form_addresses.[${index}].present_mobile_number`}
                                                label='Present Mobile Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                disabled={sameAsPermanentAddress}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.present_mobile_number
                                                }
                                                // uppercase={true}
                                                required
                                                maxLength={10}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Fax Number'
                                                name={`form_addresses.[${index}].present_fax_number`}
                                                label='Present Fax Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]?.present_fax_number
                                                }
                                                // uppercase={true}
                                                maxLength={10}
                                                disabled={sameAsPermanentAddress}
                                                // required
                                              />
                                            </div>

                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Ward Number'
                                                name={`form_addresses.[${index}].present_ward_number`}
                                                label='Present Ward Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                disabled={sameAsPermanentAddress}
                                                value={
                                                  values?.form_addresses[index]?.present_ward_number
                                                }
                                                // uppercase={true}
                                                required
                                                maxLength={2}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Email'
                                                name={`form_addresses.[${index}].present_email`}
                                                label='Present Email'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                disabled={sameAsPermanentAddress}
                                                value={values?.form_addresses[index]?.present_email}
                                                // uppercase={true}
                                                required
                                                maxLength={50}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className='row'>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormSelect
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Country'
                                                name={`form_addresses.[${index}].present_country`}
                                                label='Present Country'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                required
                                                arrValue={
                                                  values?.form_addresses[index]?.present_country
                                                }
                                                disabled={sameAsPermanentAddress}
                                                options={allMasterDataOptions?.countries?.map(
                                                  (country: any) => ({
                                                    value: country.id || country.value,
                                                    label: country.title || country.label,
                                                  })
                                                )}
                                                setFieldValue={setFieldValue}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Post Box No.'
                                                name={`form_addresses.[${index}].present_outside_country_post_box`}
                                                label='Post Box No.'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.present_outside_country_post_box
                                                }
                                                // uppercase={true}
                                                required

                                                // disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Full Address'
                                                name={`form_addresses.[${index}].present_outside_country_full_address`}
                                                label='Full Address'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.present_outside_country_full_address
                                                }
                                                // uppercase={true}
                                                required

                                                // disabled={true}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Present Mobile Number'
                                                name={`form_addresses.[${index}].present_mobile_number`}
                                                label='Present Mobile Number'
                                                onChange={handleChange}
                                                errors={errors}
                                                disabled={sameAsPermanentAddress}
                                                touched={touched}
                                                value={
                                                  values?.form_addresses[index]
                                                    ?.present_mobile_number
                                                }
                                                // uppercase={true}
                                                required
                                                maxLength={10}
                                              />
                                            </div>
                                            <div className='col-md-4 col-sm-6'>
                                              <FormTextBox
                                                labelClassName='col-lg-12'
                                                containerClassName='col-lg-12'
                                                type='text'
                                                placeholder='Email'
                                                name={`form_addresses.[${index}].present_email`}
                                                label='Present Email'
                                                onChange={handleChange}
                                                errors={errors}
                                                touched={touched}
                                                value={values?.form_addresses[index]?.present_email}
                                                disabled={sameAsPermanentAddress}
                                                // uppercase={true}
                                                required
                                                maxLength={50}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ))
                                : null}
                            </div>
                          )}
                        />
                      </section>
                      <hr />
                      <div className='row'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>
                          G. Relation with the account
                        </h3>
                        <div className='col-md-4 col-sm-6 col-xs-12 pb-10'>
                          <FormRadio
                            containerClassName=''
                            label='Do you have any account at EBL?'
                            name='has_existing_account'
                            type='checkbox'
                            onChange={(e) => {
                              setFieldValue('has_existing_account', e.target.checked)
                              if (!e.target.checked) {
                                setFieldValue('existing_account_number', '')
                                setFieldValue('relation_with_existing_account', '')
                                setFieldValue('other_relation_with_account', '')
                                setFieldValue('relationship_id', '')
                              }
                            }}
                            errors={errors}
                            touched={touched}
                            checked={values?.has_existing_account}
                            checkBoxText='Has Existing Account'
                          />
                        </div>

                        {values.has_existing_account && (
                          <>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Existing Account Number'
                                name='existing_account_number'
                                label='Existing Account Number'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                maxLength={14}
                                required={values.has_existing_account}
                              />
                            </div>
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Relation with Existing Account'
                                name='relation_with_existing_account'
                                label='Relation with Existing Account'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                values={values}
                                // arrValue={values?.relation_with_existing_account}
                                required={values.has_existing_account}
                                options={allMasterDataOptions?.relationships?.map(
                                  (relations: any) => ({
                                    value: relations.id || relations.value,
                                    label: relations.title || relations.label,
                                  })
                                )}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                            {allMasterDataOptions?.relationships
                              ?.find(
                                (relation: any) =>
                                  relation.id === values?.relation_with_existing_account
                              )
                              ?.title.toLowerCase() === 'others' && (
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Other Relation with Account'
                                  name='other_relation_with_account'
                                  label='Other Relation with Account'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {values.form_addresses &&
                        values.form_addresses[0] &&
                        values.form_addresses[0].present_country !==
                          allMasterDataOptions?.countries?.find(
                            (item: any) => item?.cbs_code === 'NP'
                          ).id && (
                          <>
                            <div className='row'>
                              <h3 className='fw-bolder mb-4 text-capitalize'>
                                G. Immigration Details
                              </h3>

                              {/* Visa Issue Date */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='visa_issue_date_ad'
                                  nepaliFormName='visa_issue_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Visa Issue Date (BS)'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='visa_issue_date_ad'
                                  label='Visa Issue Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.visa_issue_date_ad}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  data-cy='end-date-drop-down'
                                  data={'visa_issue_date'}
                                />
                              </div>

                              {/* Visa Expiry Date */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='visa_expiry_date_ad'
                                  nepaliFormName='visa_expiry_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Visa Expiry Date (BS)'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='visa_expiry_date_ad'
                                  label='Visa Expiry Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.visa_expiry_date_ad || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  // disabled={values.start_date ? false : true}
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'visa_expiry_date'}
                                />
                              </div>

                              {/* Visa Arrival Date */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='visa_arrival_date_ad'
                                  nepaliFormName='visa_arrival_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Visa Arrival Date (BS)'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='visa_arrival_date_ad'
                                  label='Visa Arrival Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.visa_arrival_date_ad || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  // disabled={values.start_date ? false : true}
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'visa_arrival_date'}
                                />
                              </div>
                            </div>

                            {/* Work Permit Details */}
                            <div className='row'>
                              {/* Work Permit Issue Date */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='work_permit_issued_date_ad'
                                  nepaliFormName='work_permit_issued_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Work Permit Issued Date (BS)'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                {/* <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='date'
                                  placeholder='Work Permit Issued Date (AD)'
                                  name='work_permit_issued_date_ad'
                                  label='Work Permit Issued Date (AD)'
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      'work_permit_issued_date_bs',
                                      e.target.value && adToBs(e.target.value)
                                    )
                                    handleChange(e)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                 // uppercase={true}
                                /> */}
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='work_permit_issued_date_ad'
                                  label='Work Permit Issued Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.work_permit_issued_date_ad || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  // disabled={values.start_date ? false : true}
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'work_permit_issued_date'}
                                />
                              </div>

                              {/* Work Permit Expiry Date */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormNepaliCalendarDatePicker
                                  englishFormName='work_permit_expiry_date_ad'
                                  nepaliFormName='work_permit_expiry_date'
                                  setFieldValue={setFieldValue}
                                  touched={touched}
                                  values={values}
                                  label='Work Permit Expiry Date (BS)'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                {/* <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='date'
                                  placeholder='Work Permit Expiry Date (AD)'
                                  name='work_permit_expiry_date_ad'
                                  label='Work Permit Expiry Date (AD)'
                                  onChange={(e: any) => {
                                    setFieldValue(
                                      'work_permit_expiry_date_bs',
                                      e.target.value && adToBs(e.target.value)
                                    )
                                    handleChange(e)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                 // uppercase={true}
                                /> */}
                                <FormDatepicker
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='work_permit_expiry_date_ad'
                                  label='Work Permit Expiry Date (AD)'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.work_permit_expiry_date_ad || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  // disabled={values.start_date ? false : true}
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                  data={'work_permit_expiry_date'}
                                />
                              </div>

                              {/* Work Permit ID and Issuing Authority */}
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Work Permit ID'
                                  name='work_permit_id'
                                  label='Work Permit ID'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Issuing Authority'
                                  name='issuing_authority'
                                  label='Issuing Authority'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                  required
                                />
                              </div>
                            </div>
                          </>
                        )}
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>
                          H. Details of Landlord/Landlady (if any)
                        </h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Landlord Name'
                              name='landlord_name'
                              label='Landlord Name'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              // uppercase={true}
                              maxLength={20}
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Landlord Address'
                              name='landlord_address'
                              label='Landlord Address'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              // uppercase={true}
                              maxLength={50}
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Landline No./Mobile No.'
                              name='landlord_mobile_number'
                              label='Landline No./Mobile No.'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              // uppercase={true}
                            />
                          </div>
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>I. Family Details</h3>
                        <FamilyDetailsForm
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>J. Religion</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='religion_id'
                              label='Religion'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.religions?.map((religion: any) => ({
                                value: religion.id || religion.value,
                                label: religion.title || religion.label,
                              }))}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          {allMasterDataOptions?.religions
                            ?.find((religion: any) => religion.id === values?.religion_id)
                            ?.title.toLowerCase() === 'others' && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Other Religion'
                                name='other_religion'
                                label='Other Religion'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                // uppercase={true}
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>K. Education</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='education_id'
                              label='Education Qualification'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              // arrValue={values?.education_id}
                              options={allMasterDataOptions?.educations?.map((religion: any) => ({
                                value: religion.id || religion.value,
                                label: religion.title || religion.label,
                              }))}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          {allMasterDataOptions?.educations
                            ?.find((edu: any) => edu?.id === values?.education_id)
                            ?.title.toLowerCase() === 'others' && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Other Education Qualification'
                                name='other_education_qualification'
                                label='Other Education Qualification'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                // uppercase={true}
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>L. Occupation Type</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Occupation'
                              name='occupation_id'
                              label='Occupation'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              value={values.occupation_id}
                              // uppercase={true}
                              required
                              //  className={'bg-warning w-100 p-3 rounded-2'}
                              disabled={true}
                            />
                          </div>
                          {(values.occupation_id.toLowerCase() === 'others' ||
                            allMasterDataOptions?.occupations
                              ?.find((occupation: any) => occupation?.id === values?.occupation_id)
                              ?.title.toLowerCase() === 'others') && (
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Other Occupation'
                                name='other_occupation'
                                label='Other Occupation'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                value={values?.other_occupation}
                                maxLength={30}
                                required={
                                  values.occupation_id === 'others' ||
                                  allMasterDataOptions?.occupations
                                    ?.find(
                                      (occupation: any) => occupation.id === values.occupation_id
                                    )
                                    ?.title.toLowerCase() === 'others'
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>M. Purpose of Account </h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='account_purpose_id'
                              label='Account Purpose'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.purposes?.map((purposes: any) => ({
                                value: purposes.id || purposes.value,
                                label: purposes.title || purposes.label,
                              }))}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          {allMasterDataOptions?.purposes
                            ?.find((purposes: any) => purposes.id === values?.account_purpose_id)
                            ?.title.toLowerCase() === 'others' && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Other Account Purpose'
                                name='other_account_purpose'
                                label='Other Account Purpose'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                required
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>N. Income Source </h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='income_source_id'
                              label='Income Source'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              arrValue={values?.income_source_id}
                              options={allMasterDataOptions?.income_sources?.map(
                                (incomeSource: any) => ({
                                  value: incomeSource.id || incomeSource.value,
                                  label: incomeSource.title || incomeSource.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              required
                              // colors={true}
                              disabled
                            />
                          </div>
                          {(values.income_source_id === 'others' ||
                            allMasterDataOptions?.income_sources
                              ?.find((source: any) => source?.id === values?.income_source_id)
                              ?.title.toLowerCase() === 'others') && (
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormTextBox
                                // uppercase={true}
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Other Income Source'
                                name='other_income_source'
                                label='Other Income Source'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                maxLength={50}
                                disabled
                                // value={values?.other_income_source}
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>O. Total Annual Income </h3>
                        <div className='col-md-4 col-sm-6'>
                          <FormSelect
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            placeholder='Select Annual Income'
                            label='Annual Income'
                            values={values}
                            name='total_annual_income'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={allMasterDataOptions?.annual_turn_overs?.map(
                              (turnOver: any) => ({
                                value: turnOver.id || turnOver.value,
                                label: turnOver.title || turnOver.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                            // colors={true}
                            disabled
                          />
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>
                          P. Expected Annual Transaction in amount{' '}
                        </h3>
                        <div className='col-md-4 col-sm-6'>
                          <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='text'
                            placeholder='Annual transaction Amount'
                            name='expected_annual_transaction_amount'
                            label='Annual Transaction Amount'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // uppercase={true}
                            maxLength={15}
                            required
                            //  className={'bg-warning w-100 p-3 rounded-2'}
                            disabled
                          />
                        </div>
                      </section>
                      <hr />

                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>
                          Q. Expected Annual Transaction Number{' '}
                        </h3>
                        <div className='col-md-4 col-sm-6'>
                          <FormSelect
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            placeholder='Expected Annual Transaction Number'
                            name='expected_annual_transaction_number'
                            label='Expected Annual Transaction Number'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            // arrValue={values}
                            values={values}
                            options={allMasterDataOptions?.annual_transactions?.map(
                              (transaction: any) => ({
                                value: transaction?.id || transaction.value,
                                label: transaction?.title || transaction.label,
                              })
                            )}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>R. PEP Check</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='pep_status'
                              label='PEP Status'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={PEPOPTIONS}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          {values.pep_status === 'Yes' && (
                            <>
                              <div className='col-md-4'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label=' PEP Relationship'
                                  name='pep_relationship'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  options={allMasterDataOptions?.relationships?.map(
                                    (relations: any) => ({
                                      value: relations.id,
                                      label: relations.title || relations.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label=' PEP Position'
                                  name={`pep_position`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  // arrValue={values?.pep_position}
                                  options={allMasterDataOptions?.occupations?.map(
                                    (occupation: any) => ({
                                      value: occupation.id || occupation.value,
                                      label: occupation.title || occupation.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                {/* <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='date'
                                placeholder='PEP Retirement Date'
                                name='pep_retirement_date'
                                label='PEP Retirement Date'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                uppercase={true}
                                required
                              /> */}
                                <FormDatepickerNew
                                  labelClassName='col-12'
                                  containerClassName='col-12 '
                                  name='pep_retirement_date'
                                  label='PEP Retirement Date'
                                  placeholderText='YYYY-MM-DD'
                                  setFieldValue={setFieldValue}
                                  value={values?.pep_retirement_date || false}
                                  // minDate={values?.start_date}
                                  errors={errors}
                                  touched={touched}
                                  showIcon={true}
                                  //  className={'bg-warning w-100 p-3 rounded-2'}
                                  // disabled={values.start_date ? false : true}
                                  required
                                  // isClearable={isEndDateNotRequired}
                                  data-cy='end-date-drop-down'
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label=' PEP Category'
                                  name={`pep_category_id`}
                                  // onChange={handleChange}
                                  onChange={(e: any) => {
                                    handlePEPCategoryChange(e, setFieldValue)
                                    handleChange(e)
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  // arrValue={values?.pep_position}
                                  options={allMasterDataOptions?.pep_categories?.map(
                                    (pepcat: any) => ({
                                      value: pepcat.id || pepcat.value,
                                      label: pepcat.title || pepcat.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-md-12'
                                  containerClassName='col-lg-12'
                                  placeholder='Select'
                                  label=' PEP Sub Category'
                                  name={`pep_sub_category_id`}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  // arrValue={values?.pep_position}
                                  options={allMasterDataOptions?.pep_sub_categories?.map(
                                    (pepsubcat: any) => ({
                                      value: pepsubcat.id || pepsubcat.value,
                                      label: pepsubcat.title || pepsubcat.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  required
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>S. Beneficial Owner</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12 pb-10'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              label='Beneficial Owner'
                              name='beneficial_owner'
                              placeholder='Select'
                              options={[
                                {value: 'Yes', label: 'Yes'},
                                {value: 'No', label: 'No'},
                              ]}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              required
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          {values.beneficial_owner === 'Yes' && (
                            <>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Beneficial Owner Name'
                                  name='beneficial_owner_name'
                                  label='Beneficial Owner Name'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  // uppercase={true}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Beneficial Owner Occupation'
                                  name='beneficial_owner_occupation'
                                  label='Beneficial Owner Occupation'
                                  values={values}
                                  options={allMasterDataOptions?.occupations?.map(
                                    (occupation: any) => ({
                                      value: occupation.id || occupation.value,
                                      label: occupation.title || occupation.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                />
                              </div>
                              <div className='col-md-4 col-sm-6 col-xs-12 pb-10'>
                                <FormSelect
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='Beneficial Owner Relation'
                                  name='beneficial_owner_relation'
                                  label='Beneficial Owner Relation'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  required
                                  options={allMasterDataOptions?.relationships?.map(
                                    (relation: any) => ({
                                      value: relation.id || relation.value,
                                      label: relation.title || relation.label,
                                    })
                                  )}
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>T. Pan Details</h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='PAN Number'
                              name='pan_number'
                              label='PAN Number'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              // uppercase={true}
                              maxLength={9}
                            />
                          </div>
                          {values?.pan_number && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              {/* <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='date'
                            placeholder='PAN Registration Date'
                            name='pan_registration_date'
                            label='PAN Registration Date'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                           // uppercase={true}
                          /> */}
                              <FormDatepickerNew
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                label='PAN Registration Date'
                                name='pan_registration_date'
                                placeholderText='YYYY-MM-DD'
                                setFieldValue={setFieldValue}
                                value={values?.pan_registration_date || false}
                                // minDate={values?.start_date}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                //  className={'bg-warning w-100 p-3 rounded-2'}
                                required
                              />
                            </div>
                          )}
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>
                          U. Associated Business/Profession, if any{' '}
                        </h3>
                        <div className='row'>
                          <BusinessDetailsForm
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </section>
                      <hr />
                      <section className='py-3'>
                        <div className='col-12'>
                          <OtherBanksTable
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </section>
                      <hr />

                      {/* <section className='py-3'>
                      <h3 className='fw-bolder mb-4 text-capitalize'>V. Google Map </h3>
                      <div
                        className='location-map'
                        style={{width: '500px', height: '200px', border: '1px solid black'}}
                      ></div>
                    </section>
                    <hr /> */}
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>W. Self Declaration </h3>
                        <div className='row py-2'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormRadio
                              containerClassName=''
                              label='Crime Conviction'
                              name='crime_conviction'
                              type='checkbox'
                              onChange={(e) => {
                                setFieldValue('crime_conviction', e.target.checked)
                                if (!e.target.checked) {
                                  setFieldValue('crime_details', '')
                                }
                              }}
                              errors={errors}
                              touched={touched}
                              checked={values?.crime_conviction}
                              checkBoxText='Crime Conviction'
                            />
                          </div>
                          {values.crime_conviction && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Crime Details'
                                name='crime_details'
                                label='Crime Details'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                // uppercase={true}
                                maxLength={50}
                                required
                              />
                            </div>
                          )}
                        </div>
                        <div className='row py-2'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormRadio
                              containerClassName=''
                              label='Residential Permit Foreign Country'
                              name='residential_permit_foreign_country'
                              type='checkbox'
                              onChange={(e) => {
                                setFieldValue(
                                  'residential_permit_foreign_country',
                                  e.target.checked
                                )
                                if (!e.target.checked) {
                                  setFieldValue('permit_details', '')
                                }
                              }}
                              errors={errors}
                              touched={touched}
                              checked={values.residential_permit_foreign_country}
                              value={values.residential_permit_foreign_country}
                              checkBoxText='Residential Permit Foreign Country'
                            />
                          </div>
                          {values.residential_permit_foreign_country && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormTextBox
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                type='text'
                                placeholder='Permit Details'
                                name='permit_details'
                                label='Permit Details'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                required
                                // uppercase={true}
                              />
                            </div>
                          )}
                        </div>
                        <div className='row py-2'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormRadio
                              containerClassName=''
                              label='Blacklisting Inclusion'
                              name='blacklisting_inclusion'
                              type='checkbox'
                              onChange={(e) => {
                                setFieldValue('blacklisting_inclusion', e.target.checked)
                                if (!e.target.checked) {
                                  setFieldValue('blacklist_release_date', '')
                                }
                              }}
                              errors={errors}
                              touched={touched}
                              checked={values?.blacklisting_inclusion}
                              value={values?.blacklisting_inclusion}
                              checkBoxText='Blacklisting Inclusion'
                            />
                          </div>
                          {values.blacklisting_inclusion && (
                            <div className='col-md-4 col-sm-6 col-xs-12'>
                              <FormDatepickerNew
                                labelClassName='col-12'
                                containerClassName='col-12 '
                                name='blacklist_release_date'
                                label='Blacklist Release Date'
                                placeholderText='YYYY-MM-DD'
                                setFieldValue={setFieldValue}
                                value={values?.blacklist_release_date || false}
                                errors={errors}
                                touched={touched}
                                showIcon={true}
                                required
                                data-cy='end-date-drop-down'
                              />
                            </div>
                          )}
                        </div>
                        <hr />
                      </section>
                      <hr />
                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>X. Fatca Declaration </h3>
                        <FATCADeclarationsForm
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      </section>
                      <hr />

                      <section className='py-3'>
                        <h3 className='fw-bolder mb-4 text-capitalize'>Y. MIS Details </h3>
                        <div className='row'>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Constitution'
                              name='constitution_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allMasterDataOptions?.constitutions?.map((item: any) => ({
                                label: item.title?.toLocaleUpperCase() || '',
                                value: item.id,
                              }))}
                              setFieldValue={setFieldValue}
                              values={values}
                              required
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Bank Code'
                              name='bank_code_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.bank_codes?.map((bankCode: any) => ({
                                value: bankCode.id || bankCode.value,
                                label: bankCode.title || bankCode.label,
                              }))}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Customer Group '
                              name='customer_group_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.customer_groups?.map(
                                (customer_groups: any) => ({
                                  value: customer_groups.id || customer_groups.value,
                                  label: customer_groups.title || customer_groups.label,
                                })
                              )}
                              disabled
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Customer Status '
                              name='customer_status_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.customer_statuses?.map(
                                (customer_statuses: any) => ({
                                  value: customer_statuses?.id || customer_statuses.value,
                                  label: customer_statuses?.title || customer_statuses.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Details'
                              name='customer_free_text'
                              label='Customer Status Details'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              // uppercase={true}
                              required
                              maxLength={50}
                            />
                          </div>

                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            {/* <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='date'
                            placeholder='CDD & EDD date'
                            name='cdd_edd_date'
                            label='CDD & EDD date'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            uppercase={true}
                            required
                            value={values?.cdd_edd_date}
                          /> */}
                            <FormDatepickerNew
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              name='cdd_edd_date'
                              label='CDD & EDD date'
                              placeholderText='YYYY-MM-DD'
                              setFieldValue={setFieldValue}
                              value={values?.cdd_edd_date || false}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                              required
                              data-cy='end-date-drop-down'
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            {/* <FormTextBox
                            labelClassName='col-lg-12'
                            containerClassName='col-lg-12'
                            type='date'
                            placeholder='Annual Turnover As On'
                            name='annual_turn_over_as_on'
                            label='Annual Turnover As On'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            uppercase={true}
                            required
                            preventFutureDate={true}
                            value={values?.annual_turn_over_as_on}
                          /> */}
                            <FormDatepickerNew
                              labelClassName='col-12'
                              containerClassName='col-12 '
                              name='annual_turn_over_as_on'
                              label='Annual Turnover As On'
                              placeholderText='YYYY-MM-DD'
                              setFieldValue={setFieldValue}
                              value={values?.annual_turn_over_as_on || false}
                              errors={errors}
                              touched={touched}
                              showIcon={true}
                              required
                              data-cy='end-date-drop-down'
                            />
                          </div>
                        </div>
                      </section>
                      <hr />
                      <section>
                        <div className='row'>
                          <h3 className='fw-bolder mb-4 text-capitalize'>Z. Other Details:</h3>
                          {/* <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Customer Category'
                              name='customer_category_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.customer_categories?.map(
                                (categories: any) => ({
                                  value: categories.id || categories.value,
                                  label: categories.title || categories.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div> */}
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              name='aml_risk_rating'
                              label='AML Risk Rating'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              values={values}
                              options={allMasterDataOptions?.risk_categories?.map(
                                (categories: any) => ({
                                  value: categories?.id || categories.value,
                                  label: categories?.title || categories.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              required
                              // colors={true}
                              disabled
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select Risk Rating SubCategory'
                              label='Risk Rating SubCategory'
                              name='sub_risk_category_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allMasterDataOptions?.risk_sub_categories?.map(
                                (option: any) => ({
                                  value: option?.id || option.value,
                                  label: option?.title || option.label,
                                })
                              )}
                              values={values}
                              setFieldValue={setFieldValue}
                              required
                              // colors={true}
                              disabled
                            />
                          </div>
                        </div>
                      </section>

                      <hr />

                      <div className='col-md-12 col-sm-6 col-xs-12'>
                        <FormTextBox
                          labelClassName='col-lg-12'
                          containerClassName='col-lg-12'
                          type='text'
                          placeholder='Remarks'
                          name='remarks'
                          label='Remarks'
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          // uppercase={true}
                          required
                        />
                      </div>

                      <div className='row'>
                        {screeningPerformed ? (
                          checkAllData && (
                            <Button
                              type='button'
                              onClick={handleOpenPdf}
                              className='col-md-3 col-sm-6 col-xs-12'
                            >
                              View Details
                            </Button>
                          )
                        ) : screeningLoading ? (
                          <Spinner animation='border' role='status'>
                            <span className='sr-only'>Loading...</span>
                          </Spinner>
                        ) : (
                          <Button
                            type='button'
                            onClick={handleScreening}
                            className='col-md-3 col-sm-6 col-xs-12'
                          >
                            Perform Screening
                          </Button>
                        )}
                        <div className='my-7'>
                          <h3>Screening Files</h3>
                          {data?.screening_files?.screening_files?.length > 0 ? (
                            data?.screening_files?.screening_files?.map((file: any, index: any) => (
                              <button
                                key={index}
                                type='button'
                                onClick={() => handlePdfFileClick(file.file_path)}
                                style={{display: 'block', marginBottom: '10px'}}
                              >
                                {file.file_name}
                              </button>
                            ))
                          ) : (
                            <p>No files available</p>
                          )}
                        </div>
                      </div>
                      <div className='d-flex justify-content-end my-5 px-5'>
                        <div className='me-3'>
                          <button className='btn btn-primary' type='button' onClick={handleBack}>
                            Back
                          </button>
                        </div>
                        <div className='me-3'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            // disabled={isSubmitting}
                            onClick={() => handleSaveButtonClick('save_and_continue')}
                          >
                            {isSubmitting && btnFlag === 'save_and_continue'
                              ? 'Submitting...'
                              : 'Submit'}
                          </button>
                        </div>
                        <div>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={isSubmitting}
                            onClick={() => handleSaveButtonClick('save')}
                            style={{backgroundColor: `#d9214e`}}
                          >
                            {isSubmitting && btnFlag === 'save'
                              ? 'Saving as Draft...'
                              : 'Save as Draft'}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default KycFormNormalIndividualJointMinorCIF
