import axios from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {UPDATEKYC} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from '../../../cms/helpers/constants/common'

interface PostRemoveParams {
  document_id: string
  id: string | undefined
  // title: string
}

const postRemove = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${UPDATEKYC}/update-kyc/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentAPI = () => {
  return useMutation(postRemove, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

interface PostRemoveParams {
  document_id: string
  id: string | undefined
  // title: string
}

const postRemoveExisting = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${BASE_API_URL}/existing-cif/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentAPIExisting = () => {
  return useMutation(postRemoveExisting, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

const postRemoveNewCifNewAccount = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${BASE_API_URL}/new-cif-new-account/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentNewCifNewAccount = () => {
  return useMutation(postRemoveNewCifNewAccount, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

const postRemoveNewCifNewAccountCorporate = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${BASE_API_URL}/corporate-new-cif-new-account/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentNewCifNewAccountCorporate = () => {
  return useMutation(postRemoveNewCifNewAccountCorporate, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

const postRemoveNewCif = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${BASE_API_URL}/new-cif/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentNewCif = () => {
  return useMutation(postRemoveNewCif, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

const postRemoveNewCifCorporate = async ({document_id, id}: PostRemoveParams) => {
  const formData = new FormData()
  formData.append('document_id', document_id)
  try {
    const response = await axios.post(
      `${BASE_API_URL}/corporate-new-cif/${id}/remove-documents/${document_id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const useRemoveDocumentNewCifCorporate = () => {
  return useMutation(postRemoveNewCifCorporate, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}
