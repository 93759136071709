import {Form, Formik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import FormCheckbox from 'src/cms/helpers/components/forms/FormCheckbox'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import useGetMasterDataFilter, {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import usePlaceMasterData from 'src/app/queries/getMasterData/mappedMasterData/PlaceMasterData'
import {usePostCorporatePreliminariesDetailsCIF} from 'src/app/queries/new-cif-new-account-queries/corporateAccountPreliminary/post/usePostPreliminariesCorporateDetails'
import FormNepaliCalendarDatePicker from 'src/cms/helpers/components/forms/FormNepaliCalendarDatePicker'
import {CORPORATE_NORMAL_FORM_VALIDATION} from '../../../utils/validations/ValidationSchemas'
import moment from 'moment'
import {Spinner} from 'react-bootstrap-v5'
import CIFCheck from 'src/app/pages/dashboard/component.tsx/CIFCheck'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllZoneData} from 'src/app/queries/getMasterData/zones/useGetAllZone'
import {BASE_API_URL, BASE_APP_URL} from 'src/cms/helpers/constants/common'
import axios from 'axios'
import {useGetAllRiskCategoriesList} from 'src/app/queries/getMasterData/riskCategories/get/useGetAllRiskCategoriesList'
import {setAccountTypeId} from 'src/setup/redux/SliceAccount'
import {RootState} from 'src/setup'
import FormDatepicker from 'src/cms/helpers/components/forms/FormDatepicker'
import useGetMunicipilitiesById from 'src/hooks/getMunicipilities/useGetMunicipilitiesData'
import {nanoid} from '@reduxjs/toolkit'
import {usePostToGenerateCodeCorporate} from 'src/app/queries/new-cif-new-account-queries/usePostGenerateOtp'
import {toast} from 'react-toastify'
import {Modal, Table} from 'rsuite'
import VerificationForm from 'src/app/modules/new-cif-new-account/common/optVerification'

const {Column, HeaderCell, Cell} = Table

const NewCifNormalCorporate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const authResp = useSelector((state: any) => state.auth)
  const {user} = authResp
  const userBranchCode = user?.data?.branch?.code

  const {data: allMasterData} = useGetAllMasterData()
  const {mutate: postCorporateNormaldetails, isLoading: postdataloading} =
    usePostCorporatePreliminariesDetailsCIF()

  const [isSave, setIsSave] = useState(true) // Adjust based on your logic
  const [masterData, setMasterData] = useState<any>()

  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setcheckAllDataJoint] = useState<any>([])
  const [flag, setFlag] = useState<string>('default')
  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])

  const update = useRef<any>('default')
  const {mutate: mutateCheckAll, isLoading: pdfLoading} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setcheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )

  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false)
  const [verificationType, setVerificationType] = useState(null)
  const [verificationStatus, setVerificationStatus] = useState<any>(false)
  const [verificationDetails, setVerificationDetails] = useState({
    email: '',
    mobile: '',
  })
  const [uniqueID, setUniqueID] = useState('')
  const [regenrateID, setRegenrateID] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [isNumberVerified, setIsNumberVerified] = useState(false)
  const [isBothVerified, setIsBothVerified] = useState(false)
  const [isDisabled, setISDisabled] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>()
  const [errormessage, setErrormessage] = useState<any>()
  const [datas, setDatas] = useState<any>()
  const [isSubmitting, setSubmitting] = useState<any>()
  const [screeningId, setScreeningId] = useState<any>()
  const [myLoading, setMyLoading] = useState<any>()

  const generateScreeningId = () => {
    const ScreeningId = Math.floor(10000 + Math.random() * 90000)
    setScreeningId(ScreeningId)
  }
  // const [forcedContinue, setForcedContinue] = useState<any>(false)

  // const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false)

  const [masterFilterData, setMasterFilterData] = useState<any>(null)

  const {mutate: masterDataFilter, isLoading: masterFilterLoading} =
    useGetMasterDataFilter(setMasterFilterData)

  useEffect(() => {
    let individualData = allMasterDataOptions?.account_types?.find(
      (type: any) => type?.cbs_code === 'Corporate'
    )?.id
    if (individualData) {
      masterDataFilter(individualData)
    }
  }, [])

  useEffect(() => {
    if (allMasterData) {
      setMasterData(allMasterData)
    }
  }, [allMasterData])
  const allMasterDataOptions = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const {
    constutionDatas,
    corporateRegistrations,
    countriesData,
    zoneOptions,
    provinceOptions,
    districtOptions,
    isLoading,
    municipalitiesOptions,
  } = usePlaceMasterData({
    allMasterData: false,
    constitutions: true,
    corporateRegistrations: true,
    countries: true,
    zones: true,
    provinces: true,
    districts: true,
    municipalities: true,
  })

  const handleSubmit = (values: any, setSubmitting?: any) => {
    const Email = values?.registered_office_email
    const Mobile = values?.registered_office_mobile_number
    setDatas(values)
    setMyLoading(true)

    // if (isSave) {
    //   if (
    //     !verificationStatus &&
    //     (!(Email && Email.includes('.')) || !(Mobile && Mobile.includes('.')))
    //   ) {
    //     toast.error('Please verify the account before submitting.')
    //     setSubmitting(false)
    //     return // Stop the function from continuing
    //   }
    // }

    const generatedShortName = values?.institution_name?.split(' ')[0]

    const capitalizeName = (name: string) => {
      return name.replace(/\b\w/g, (char: string) => char.toUpperCase())
    }

    const payload = {
      ...values,
      // forced_continue: forcedContinue,
      screening_id: screeningId?.toString(),
      verification_type: verificationType,
      verification_status: verificationStatus,
      institution_name: values?.institution_name?.toUpperCase(),
      registration_date_ad: values?.registration_date_ad
        ? moment(values?.registration_date_ad).format('YYYY-MM-DD')
        : '',
      registration_expiry_date_ad: values?.registration_expiry_date_ad
        ? moment(values?.registration_expiry_date_ad).format('YYYY-MM-DD')
        : '',
      // other_constitution: values?.other_constitution
      //   ? capitalizeName(values?.other_constitution)
      //   : '',
      // foreign_company_name: values?.foreign_company_name
      //   ? capitalizeName(values?.foreign_company_name)
      //   : '',
      // foreign_company_address: values?.foreign_company_address
      //   ? capitalizeName(values?.foreign_company_address)
      //   : '',
      // registered_office_street_name: capitalizeName(values?.registered_office_street_name),
      // pep_information: values?.pep_information ? capitalizeName(values?.pep_information) : '',
      short_name: generatedShortName || '',
      action: isSave ? 'save_and_continue' : 'save',
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    postCorporateNormaldetails(payload, {
      onSuccess: () => {
        // if (isSave) {
        //   toast.success('Submitted to Branch Checker.')
        // } else {
        //   toast.success('Saved successfully.')
        // }
        setSubmitting(false)
        setMyLoading(false)
        history.push('/new-cif-only')
      },
      onError: (error: any) => {
        setErrors(error?.errors?.flat())
        setErrormessage(error?.message)
        setSubmitting(false)
        toast.error(error?.message)
        // {
        //   error?.message?.includes('deduplication')
        //     ? setIsDuplicationModalOpen(true)
        //     : setIsDuplicationModalOpen(false)
        // }
      },
    })
  }

  const {data: zoneData} = useGetAllZoneData()
  const [zoneCode, setZoneCode] = useState<string | null>(null)
  const [districtData, setDistrictData] = useState<any>(null)
  const [municipalitiesData, setMunicipalitiesData] = useState<any>(null)

  const {mutate: MutateMunicipilities} = useGetMunicipilitiesById(setMunicipalitiesData)

  const onCountryChange = (
    id: string,
    setFieldValue: (field: string, value: any) => void,
    setTouched: any
  ) => {
    const selectedCountry = allMasterDataOptions?.countries?.find(
      (item: any) => item?.id === id
    )?.title

    if (selectedCountry === 'NEPAL') {
      setFieldValue('registered_office_outside_country_post_box', '')
      setFieldValue('registered_office_outside_country_full_address', '')
      setFieldValue('registered_office_mobile_number', '')
      setFieldValue('registered_office_email', '')
      setTouched({
        registered_office_outside_country_post_box: false,
        registered_office_outside_country_full_address: false,
        registered_office_mobile_number: false,
        registered_office_email: false,
      })
    } else {
      setFieldValue('registered_office_province', '')
      setFieldValue('registered_office_zone', '')
      setFieldValue('registered_office_district', '')
      setFieldValue('registered_office_municipality', '')
      setFieldValue('registered_office_ward_number', '')
      setFieldValue('registered_office_city', '')
      setFieldValue('registered_office_street_name', '')
      setFieldValue('registered_office_house_number', '')
      setFieldValue('registered_office_mobile_number', '')
      setFieldValue('registered_office_email', '')
      setTouched({
        registered_office_province: false,
        registered_office_zone: false,
        registered_office_district: false,
        registered_office_municipality: false,
        registered_office_city: false,
        registered_office_ward_number: false,
        registered_office_street_name: false,
        registered_office_house_number: false,
        registered_office_mobile_number: false,
        registered_office_email: false,
      })
    }
  }

  useEffect(() => {
    if (zoneCode) {
      const fetchDistrictData = async () => {
        try {
          await axios
            .get(`${BASE_API_URL}/district`, {
              params: {zoneCode},
            })
            .then((res: any) => {
              setDistrictData(res?.data)
            })
          // setDistrictData(data) // Store the district data in state
        } catch (error) {
          console.error('Error fetching district data:', error)
        }
      }

      fetchDistrictData()
    }
  }, [zoneCode])
  const accountTypeID = useSelector((state: RootState) => state?.accountTypeIDMaster?.accountTypeID)
  useEffect(() => {
    let accountType = allMasterDataOptions?.account_types?.find(
      (item: any) => item.cbs_code === 'Corporate'
    )?.id
    dispatch(setAccountTypeId(accountType?.id))
  }, [])
  useEffect(() => {
    const generatedID = nanoid()
    setUniqueID(generatedID)
  }, [regenrateID])

  const {mutate: generateOtp, isLoading: generateOtpLoading} = usePostToGenerateCodeCorporate()

  const {data: riskData} = useGetAllRiskCategoriesList()
  const [riskCode, setRiskCode] = useState<string | null>(null)
  const [riskSubCat, setRiskSubCat] = useState<any>(null)
  useEffect(() => {
    if (riskCode) {
      fetchRiskData()
    }
  }, [riskCode])

  const fetchRiskData = async () => {
    try {
      await axios
        .get(`${BASE_API_URL}/risk-sub-categories`, {
          params: {risk_category_id: riskCode},
        })
        .then((res: any) => {
          setRiskSubCat(res?.data)
        })
    } catch (error) {
      console.error('Error fetching district data:', error)
    }
  }
  const handleBack = () => {
    history.go(-1)
  }

  const getMunicipilities = (id: string, setFieldValue: any) => {
    MutateMunicipilities(id, {
      onSuccess: (response: any) => {
        setFieldValue('registered_office_province', response?.data?.district?.province?.id)
        setFieldValue('registered_office_zone', response?.data?.district?.zone?.id)
        setFieldValue('registered_office_district', response?.data?.district?.id)
      },
    })
  }

  const initialValues = {
    action: '',
    opening_mode: 'normal',
    account_type_id:
      (!isLoading &&
        allMasterDataOptions?.account_types?.find((item: any) => item?.cbs_code === 'Corporate')
          ?.id) ||
      accountTypeID,
    branch_code: userBranchCode,
    institution_name: '',
    constitution_id: '',
    other_constitution: '',
    registration_number: '',
    registered_office_mobile_number: '',
    registered_office_email: '',
    pan_number: '',
    vat_number: '',
    tax_type: '',
    screening_id: '',
    forced_continue: false,

    // account_scheme_id: '',
    // account_category_id: allMasterDataOptions?.account_categories?.find(
    //   (accCat: any) => accCat?.cbs_code === 'CAA'
    // )?.id,
  }

  const [showCIFCheck, setShowCIFCheck] = useState(false)

  const handleScreeningClick = () => {
    setShowCIFCheck((prevState) => !prevState)
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const getZoneCode = (e: any, values: any) => {
    const presentZone = e
    const zoneList = zoneData?.data?.zone
    const matchingZone = zoneList?.find((zone: any) => zone?.id === presentZone)
    if (matchingZone) {
      setZoneCode(matchingZone.code)
    } else {
      console.log('No matching zone found.')
    }
  }
  useEffect(() => {
    let categoryId = allMasterDataOptions?.risk_categories?.find(
      (option: any) => option?.title === 'LOW RISK'
    )?.id
    setRiskCode(categoryId)
    fetchRiskData()
  }, [])

  const getSubRiskCode = (id: string, setFieldValue: any) => {
    const selectedSubCategory = allMasterDataOptions?.risk_sub_categories?.find(
      (option: any) => option?.id === id
    )
    const riskCategoryId = selectedSubCategory?.risk_category?.id || ''
    // const riskCategoryTitle = selectedSubCategory?.risk_category?.title || 'null'
    const riskCategoryTitle = selectedSubCategory?.title || 'null'

    setFieldValue('aml_risk_rating', riskCategoryId)
    if (riskCategoryTitle == 'Others') {
      setISDisabled(false)
    } else if (riskCategoryTitle == 'null') {
      setISDisabled(false)
    } else {
      setISDisabled(true)
    }
  }

  const openVerificationModal = (type: any) => {
    setVerificationType(type)
    setIsVerificationModalOpen(true)
    if (type === 'email_verification') {
      generateOtp({
        payload: {
          type: 'email_verification',
          case: 'new_case',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: '',
        },
      })
    } else if (type === 'mobile_verification') {
      generateOtp({
        payload: {
          type: 'mobile_verification',
          case: 'new_case',
          temp_customer_id: uniqueID,
          email: '',
          mobile_number: verificationDetails?.mobile,
        },
      })
    } else {
      generateOtp({
        payload: {
          type: 'both',
          case: 'new_case',
          temp_customer_id: uniqueID,
          email: verificationDetails?.email,
          mobile_number: verificationDetails?.mobile,
        },
      })
    }
  }

  return (
    <>
      <div>
        {isLoading ? (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body text-center '>
              <h3>
                <Spinner animation='border' variant='danger' className='mb-3' />
                <br />
                Please Wait...
              </h3>
            </div>
          </div>
        ) : (
          <>
            <div className=''>
              {/* <div className='text-end'>
                <Button onClick={handleScreeningClick} className='mb-3'>
                  {showCIFCheck ? 'Hide CIF Check' : 'Show CIF Check'}
                </Button>
              </div> */}

              {/* {showCIFCheck && ( */}
              <CIFCheck
                is_corporate={true}
                mutateCheckAll={mutateCheckAll}
                model_name='NewCIF'
                checkAllData={checkAllData}
                setCheckAllData={setCheckAllData}
                handleOpenPdf={handleOpenPdf}
                setScreeningPerformed={setScreeningPerformed}
                generateScreeningId={generateScreeningId}
              />
              {/* )} */}
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={
                isSave ? CORPORATE_NORMAL_FORM_VALIDATION(allMasterDataOptions, true) : undefined
              }
              // validationSchema={CORPORATE_NORMAL_FORM_VALIDATION}
              onSubmit={(values) => {
                handleSubmit(values, setSubmitting)
              }}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
            >
              {({
                values,
                touched,
                handleChange,
                // handleSubmit,
                errors,
                setFieldValue,
                setTouched,
              }) => {
                const otpVerification = (type: any) => {
                  setVerificationType(type)
                  setIsVerificationModalOpen(true)
                  if (type === 'email_verification') {
                    generateOtp({
                      payload: {
                        type: 'email_verification',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: values?.registered_office_email,
                        mobile_number: '',
                      },
                    })
                  } else if (type === 'mobile_verification') {
                    generateOtp({
                      payload: {
                        type: 'mobile_verification',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: '',
                        mobile_number: values?.registered_office_mobile_number,
                      },
                    })
                  } else {
                    generateOtp({
                      payload: {
                        type: 'both',
                        case: 'new_case',
                        temp_customer_id: uniqueID,
                        email: values?.registered_office_email,
                        mobile_number: values?.registered_office_mobile_number,
                      },
                    })
                  }
                }
                const prevEmailRef = useRef(values?.registered_office_email)
                const prevMobileRef = useRef(values?.registered_office_mobile_number)

                useEffect(() => {
                  // Check if email or mobile number actually changed
                  if (
                    values?.registered_office_email !== prevEmailRef.current ||
                    values?.registered_office_mobile_number !== prevMobileRef.current
                  ) {
                    setVerificationStatus(false)
                    setIsEmailVerified(false)
                    setIsNumberVerified(false)
                    setIsBothVerified(false)

                    // Update refs with the new values
                    prevEmailRef.current = values?.registered_office_email
                    prevMobileRef.current = values?.registered_office_mobile_number
                  }
                }, [values?.registered_office_email, values?.registered_office_mobile_number])

                return (
                  <Form className='bg-white'>
                    <div className='card mb-5 mb-xl-10'>
                      <div className='card-body border-top '>
                        <div className='d-flex border-bottom mb-4 pb-2'>
                          <h1 className=' heading__component__title fw-bolder text-uppercase'>{`CORPORATE PRELIMINARY Form`}</h1>
                        </div>
                        <div className='row'>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              // uppercase={true}
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Branch Code'
                              name='branch_code'
                              label='Branch Code'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                              maxLength={6}
                              disabled
                            />
                          </div>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              // uppercase={true}
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Screening Id'
                              name='screening_id'
                              label='Screening Id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              value={screeningId}
                              required
                              maxLength={6}
                              disabled
                            />
                          </div>

                          <h1 className=' heading__component__title fw-bolder text-uppercase'>
                            Registration Details
                          </h1>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Institution Name'
                              name='institution_name'
                              label='Name of Institution'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                              uppercase={true}
                            />
                          </div>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Constitution'
                              name='constitution_id'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allMasterDataOptions?.constitutions?.map((item: any) => ({
                                label: item?.title?.toLocaleUpperCase() || '',
                                value: item?.id,
                              }))}
                              setFieldValue={setFieldValue}
                              values={values}
                              required
                            />
                          </div>

                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Registration'
                              name='registration_number'
                              label='i. Registration Number'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required={true}
                              maxLength={30}
                              // uppercase={true}
                            />
                          </div>

                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Account Type'
                              name='account_category_id'
                              onChange={(w: any) => {
                                handleChange(w)
                                // getAccountTypeId(w, values)
                              }}
                              errors={errors}
                              values={values}
                              touched={touched}
                              options={allMasterDataOptions?.account_categories?.map(
                                (accat: any) => ({
                                  value: accat.id || accat.value,
                                  label: accat.title || accat.label,
                                })
                              )}
                              setFieldValue={setFieldValue}
                              disabled
                              required
                            />
                          </div>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormSelect
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              placeholder='Select'
                              label='Account Scheme'
                              name='account_scheme_id'
                              onChange={handleChange}
                              errors={errors}
                              values={values}
                              touched={touched}
                              options={
                                masterFilterData?.account_schemas.length > 0
                                  ? masterFilterData?.account_schemas?.map((accur: any) => ({
                                      value: accur.id || accur.value,
                                      label: accur.title || accur.label,
                                    }))
                                  : allMasterDataOptions?.account_schemas?.map(
                                      (AccSchema: any) => ({
                                        value: AccSchema?.id || AccSchema?.value,
                                        label: AccSchema?.title || AccSchema?.label,
                                      })
                                    )
                              }
                              setFieldValue={setFieldValue}
                              required
                            />
                          </div>

                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Mobile Number'
                              name={'registered_office_mobile_number'}
                              label='Mobile Number'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                              maxLength={10}
                              // uppercase={true}
                            />
                          </div>
                          <div className='col-md-4 col-sm-4 col-xs-12'>
                            <FormTextBox
                              labelClassName='col-lg-12'
                              containerClassName='col-lg-12'
                              type='text'
                              placeholder='Email'
                              name={'registered_office_email'}
                              label='Email'
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              maxLength={50}
                              // uppercase={true}
                              required
                            />
                          </div>
                        </div>

                        <section className='py-3'>
                          <h3 className=' heading__component__title fw-bolder text-uppercase required'>
                            E. PAN/VAT Details:
                          </h3>{' '}
                          <div className='row'>
                            <div className='col-md-4 col-sm-4 col-xs-12'>
                              <FormSelect
                                labelClassName='col-lg-12'
                                containerClassName='col-lg-12'
                                placeholder='Select Type'
                                label='Select Type'
                                name='tax_type'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                options={[
                                  {value: 'pan', label: 'PAN'},
                                  {value: 'vat', label: 'VAT'},
                                ]}
                                setFieldValue={setFieldValue}
                                values={values}
                                required
                              />
                            </div>
                            {values.tax_type === 'pan' && (
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='PAN Number'
                                  name='pan_number'
                                  label='PAN Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={9}
                                  // uppercase={true}
                                />
                              </div>
                            )}

                            {values.tax_type === 'vat' && (
                              <div className='col-md-4 col-sm-4 col-xs-12'>
                                <FormTextBox
                                  labelClassName='col-lg-12'
                                  containerClassName='col-lg-12'
                                  type='text'
                                  placeholder='VAT Number'
                                  name='vat_number'
                                  label='VAT Number'
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  required
                                  maxLength={9}
                                  // uppercase={true}
                                />
                              </div>
                            )}
                          </div>
                        </section>
                        <hr />
                      </div>
                      <>
                        {(values?.registered_office_email ||
                          values?.registered_office_mobile_number) && (
                          <div className='row mb-4 pb-4 border-bottom align-items-center'>
                            {values?.registered_office_email && !isBothVerified && (
                              <div className='col-md-4 mb-2'>
                                {!isEmailVerified ? (
                                  <button
                                    type='button'
                                    onClick={() => {
                                      setVerificationDetails((prev) => ({
                                        ...prev,
                                        email: values?.registered_office_email,
                                      }))
                                      otpVerification('email_verification')
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Verify Email
                                  </button>
                                ) : (
                                  <div className='heading__component__title fw-bolder mb-5'>
                                    Email Address Verified
                                  </div>
                                )}
                              </div>
                            )}
                            {values?.registered_office_mobile_number && !isBothVerified && (
                              <div className='col-md-4 mb-2'>
                                {!isNumberVerified ? (
                                  <button
                                    type='button'
                                    onClick={() => {
                                      setVerificationDetails((prev) => ({
                                        ...prev,
                                        mobile_number: values?.registered_office_mobile_number,
                                      }))
                                      otpVerification('mobile_verification')
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Verify Mobile Number
                                  </button>
                                ) : (
                                  <div className='heading__component__title fw-bolder mb-5'>
                                    Mobile Number Verified
                                  </div>
                                )}
                              </div>
                            )}
                            {values?.registered_office_email &&
                              values?.registered_office_mobile_number &&
                              !isBothVerified && (
                                <div className='col-md-4 mb-2'>
                                  <button
                                    type='button'
                                    onClick={() => {
                                      setVerificationDetails((prev) => ({
                                        ...prev,
                                        email: values?.registered_office_email,
                                        mobile_number: values?.registered_office_mobile_number,
                                      }))
                                      otpVerification('both')
                                    }}
                                    className='btn btn-primary'
                                  >
                                    Verify Both
                                  </button>
                                </div>
                              )}
                            {isBothVerified && (
                              <div className='col-12 text-center'>
                                <div className='heading__component__title fw-bolder mb-5'>
                                  Both Email and Mobile Number Verified
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>

                      <div className='card-footer text-end'>
                        <button
                          type='button'
                          className='btn btn-secondary me-2'
                          onClick={handleBack}
                        >
                          Back
                        </button>

                        {isSave && myLoading ? (
                          <button
                            type='submit'
                            className='btn btn-primary me-2'
                            disabled={myLoading}
                          >
                            Submitting...
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-primary me-2'
                            onClick={() => {
                              setIsSave(true)
                            }}
                            disabled={isSubmitting || screeningPerformed === false}
                          >
                            Submit
                          </button>
                        )}

                        {!isSave && myLoading ? (
                          <button
                            type='submit'
                            className='btn btn-warning me-2'
                            disabled={myLoading}
                          >
                            Saving as Draft...
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-warning me-2'
                            onClick={() => {
                              setIsSave(false)
                            }}
                          >
                            Save as Draft
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </>
        )}
      </div>
      <>
        {errormessage && errormessage.includes('deduplication') ? (
          <div className='shadow p-3 bg-white rounded my-8'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
              <div className='heading__component__title fs-2 fw-bolder mb-2'>
                Duplicate Account Found
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
              <div className='heading__component__title fs-6 fw-bolder mb-2'>
                Found errors ({errors.length})
              </div>
            </div>
            <div className='datatable'>
              <Table data={errors} autoHeight height={400} wordWrap='break-word'>
                <Column align='center' width={50}>
                  <HeaderCell>#</HeaderCell>
                  <Cell>{(_, rowIndex: any) => <span>{rowIndex + 1}</span>}</Cell>
                </Column>

                <Column align='left' flexGrow={1} minWidth={100}>
                  <HeaderCell>Customer ID</HeaderCell>
                  <Cell dataKey='CUSTID' />
                </Column>

                <Column align='left' flexGrow={1} minWidth={150}>
                  <HeaderCell>Customer Name</HeaderCell>
                  <Cell dataKey='CUSTNAME' />
                </Column>

                <Column align='left' flexGrow={1} minWidth={150}>
                  <HeaderCell>Citizenship Number</HeaderCell>
                  <Cell dataKey='CTZNUM' />
                </Column>
              </Table>
            </div>
          </div>
        ) : errormessage && errormessage.includes('blacklist') ? (
          <div className='shadow p-3 bg-white rounded my-8'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
              <div className='heading__component__title fs-2 fw-bolder mb-2'>
                Blackisting Account Found
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'left'}}>
              <div className='heading__component__title fs-6 fw-bolder mb-2'>
                Found errors ({errors.length})
              </div>
            </div>
            <div className='datatable'>
              <Table data={errors} autoHeight height={400} wordWrap='break-word'>
                <Column align='center' width={50}>
                  <HeaderCell>#</HeaderCell>
                  <Cell>{(_, rowIndex: any) => <span>{rowIndex + 1}</span>}</Cell>
                </Column>

                <Column align='left' flexGrow={1} minWidth={100}>
                  <HeaderCell>Customer Id Number</HeaderCell>
                  <Cell dataKey='CitizenshipNumber' />
                </Column>

                <Column align='left' flexGrow={1} minWidth={150}>
                  <HeaderCell>Customer Name</HeaderCell>
                  <Cell dataKey='Name' />
                </Column>

                <Column align='left' flexGrow={1} minWidth={150}>
                  <HeaderCell>Blacklist Number</HeaderCell>
                  <Cell dataKey='BlacklistNumber' />
                </Column>
                <Column align='left' flexGrow={1} minWidth={150}>
                  <HeaderCell>Blacklist Types</HeaderCell>
                  <Cell dataKey='BlacklistType' />
                </Column>
              </Table>
            </div>
          </div>
        ) : null}
      </>
      <Modal
        className='w-55'
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
      >
        <Modal.Body>
          {verificationType && (
            <VerificationForm
              accountType={
                allMasterDataOptions?.account_types?.find(
                  (item: any) => item?.cbs_code === 'Corporate'
                )?.cbs_code
              }
              setIsVerificationModalOpen={setIsVerificationModalOpen}
              uniqueId={uniqueID}
              loading={generateOtpLoading}
              variant={verificationType === 'both' ? 'both' : verificationType}
              openVerificationModal={openVerificationModal}
              setIsEmailVerified={setIsEmailVerified}
              setIsNumberVerified={setIsNumberVerified}
              setIsBothVerified={setIsBothVerified}
              caseType={'new_case'}
              setVerificationStatus={setVerificationStatus}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setIsVerificationModalOpen(false)} className='btn btn-secondary'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewCifNormalCorporate
