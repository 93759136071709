import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {
  GET_NEW_CIF_Corporate,
  POST_CORPORATE_NEW_CIF_NEW_ACCOUNT,
} from 'src/app/queries/api/apiEndPoints/EndPoints'

const API_URL = `${POST_CORPORATE_NEW_CIF_NEW_ACCOUNT}`
type PayloadType = {
  data: any
}

const postCorporatePreliminariesDetails = async (payload: PayloadType) => {
  try {
    const response = await axios.post(API_URL, payload)
    return response.data
  } catch (error: any) {
    throw error?.response?.data || error.message

    // throw new Error(`${error.response?.data?.message || error.message}`)
  }
}
const usePostCorporatePreliminariesDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(postCorporatePreliminariesDetails, {
    onSuccess: (data) => {
      if (data?.resCod == 200) {
        toast.success(data?.resDesc ?? 'success!')
      }
      queryClient.invalidateQueries('corporateAccountList')
      queryClient.invalidateQueries('getCorporateBranchMakerAccountLists')
    },
    onError: (error: Error) => {
      console.log(error)
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}

export default usePostCorporatePreliminariesDetails

const postCorporatePreliminariesDetailsCIF = async (payload: PayloadType) => {
  try {
    const response = await axios.post(GET_NEW_CIF_Corporate, payload)
    return response.data
  } catch (error: any) {
    throw error?.response?.data || error.message

    // throw new Error(`${error.response?.data || error.message}`)
  }
}
export const usePostCorporatePreliminariesDetailsCIF = () => {
  const queryClient = useQueryClient()

  return useMutation(postCorporatePreliminariesDetailsCIF, {
    onSuccess: (data) => {
      toast.success(data?.resDesc ?? 'success!')
      queryClient.invalidateQueries('corporatePeliminariesDetailsByIdCIF')
    },
    onError: (error: Error) => {
      console.log(error)
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}
