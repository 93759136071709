import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Input, InputGroup, Pagination, Table} from 'rsuite'
import {isEmpty} from 'lodash'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import {Close, Search} from '@rsuite/icons'
import {useGetBranchMakerNewCIFandAccountList} from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/getPliminariesAccountList'

const Cell = Table.Cell

const IndividualOnlineRejectedQA = ({
  onlineRejectListData,
  setParams,
  params,
  loading,
  roleName,
}: any) => {
  const history = useHistory()

  // const [params, setParams] = useState({
  //   user_type: 'branch_maker',
  //   cif_number: '',
  //   opening_mode: 'normal',
  //   from_date: '',
  //   to_date: '',
  //   page: 1,
  //   limit: 10,
  //   keyword: '',
  //   orderBy: 'updated_at',
  //   order: 'desc',
  // })

  // const {data: newCIFandAccountListData, isLoading: loading} =
  //   useGetBranchMakerNewCIFandAccountList(params)
  const data =
    onlineRejectListData?.data?.OnlineAccountOpening?.map((item: any, i: number) => ({
      ...item,
      branchCode: item?.personal_info?.personalInformationBasicDetailsData?.branchId ?? '',
      sn: (params?.page - 1) * params?.limit + (i + 1),
      updateOn: item?.account_status?.updated_date,
      updatedBy: item?.account_status?.updated_by,
      remarks: item?.account_status?.remarks,

      customerId: item?.preliminary_info?.customerId ?? '',
      accountNo: item?.accountNo ? item?.accountNo : '-',
      fullName: item?.personal_info?.personalInformationBasicDetailsData?.fullName,
      email: item?.preliminary_info?.email ?? '',
      mobileNumber: item?.preliminary_info?.mobileNumber ?? '',
    })) ?? []

  const handleChangeLimit = (dataKey: any) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const ActionCell = ({rowData, dataKey, ...props}: any) => {
    const handleButtonClick = () => {
      switch (roleName) {
        case 'cpu_checker':
          history.push({
            pathname: `/new-cif-new-accounts/cpu-checker-online-review-and-update/normal-preliminary-form/${rowData?.preliminary_info?.customerId}`,
            state: {roleName},
          })
          break
        case 'cpu_maker':
          history.push({
            pathname: `/new-cif-new-accounts/cpu-maker-online-review-and-update/normal-preliminary-form/${rowData?.preliminary_info?.customerId}`,
            state: {roleName},
          })
          break
        case 'qa':
          history.push({
            pathname: `/new-cif-new-accounts/qa-online-review-and-update/normal-preliminary-form/${rowData?.preliminary_info?.customerId}`,
            state: {roleName},
          })
          break
        default:
          break
      }
    }

    return (
      <Cell {...props} className='link-group'>
        <button
          className={
            rowData?.btnStatus == 'Continue'
              ? 'dt-btn dt-btn-outline-primary dt-btn-sm'
              : 'btn btn-primary btn-sm '
          }
          onClick={handleButtonClick}
        >
          Review
        </button>
      </Cell>
    )
  }

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Customer Id',
      dataKey: 'customerId',
      cell: <Cell dataKey='customerId' />,
      sortable: false,
      width: 230,
    },
    // {
    //   label: 'Account No',
    //   dataKey: 'accountNo',
    //   cell: <Cell dataKey='accountNo' />,
    //   sortable: false,
    //   width: 230,
    // },

    {
      label: 'Branch',
      dataKey: 'branchData',
      cell: <Cell dataKey='branchCode' />,
      width: 230,
    },

    {
      label: 'Name',
      dataKey: 'firstName',
      cell: <Cell dataKey='fullName' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Email',
      dataKey: 'email',
      cell: <Cell dataKey='email' />,
      sortable: true,
      width: 250,
    },

    {
      label: 'Mobile Number',
      dataKey: 'mobileNumber',
      width: 230,
      cell: <Cell dataKey='mobileNumber' />,
      sortable: true,
    },
    {
      label: 'Updated On',
      dataKey: 'updateOn',
      width: 230,
      cell: <Cell dataKey='updateOn' />,
      sortable: true,
    },

    {
      label: 'Updated By',
      dataKey: 'updatedBy',
      width: 230,
      cell: <Cell dataKey='updatedBy' />,
      sortable: true,
    },

    {
      label: 'Remarks',
      dataKey: 'remarks',
      width: 230,
      cell: <Cell dataKey='remarks' />,
      sortable: true,
    },

    {
      label: 'Action',
      width: 100,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  return (
    <>
      <InputGroup inside style={{width: '30%', margin: '1rem 0'}}>
        <Input
          placeholder='Search'
          value={params?.search}
          onChange={(value) => setParams({...params, search: value})}
          data-cy='search-field'
        />
        <InputGroup.Addon>
          {!isEmpty(params?.search) ? (
            <Close
              style={{cursor: 'pointer'}}
              onClick={() => setParams({...params, search: ''})}
              data-cy='search-close-button'
            />
          ) : (
            <Search />
          )}
        </InputGroup.Addon>
      </InputGroup>
      <div className='datatable'>
        <RSuiteTable columns={columns} data={data} showLoading={loading} />
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size='sm'
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={onlineRejectListData?.data?.meta?.total ?? 0}
          limitOptions={[10, 20, 50, 100]}
          limit={params?.limit}
          activePage={params?.page}
          onChangePage={(value) => setParams({...params, page: value})}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  )
}

export default IndividualOnlineRejectedQA
