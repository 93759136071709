import {Form, Formik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import 'rsuite/dist/rsuite.min.css'
import {AccountStatusLog} from 'src/app/modules/common/components/AccountStatusLog'
import {REVIEW_VALIDATION_SCHEMA} from 'src/app/modules/new-cif-new-account/qa/utils/ValidationSchemas'
import usePostCheckAll from 'src/app/queries/apiMS/usePostCheckAllAndGeneratePdf'
import {useGetAllMasterData} from 'src/app/queries/getMasterData/getAllMasterData'
import useGetPreliminariesDetailsById from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/get/useGetPreliminariesDetailsById'
import usePostAccountPreliminaryCPUCheckerReview from 'src/app/queries/new-cif-new-account-queries/accountPreliminaries/post/usePostCPUCheckerReview'
import DocumentsPreview from 'src/cms/helpers/components/forms/DocumentPreview'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormDetails from 'src/cms/helpers/components/forms/formDetails'
import {Table} from 'rsuite'
import {adToBs} from '@sbmdkl/nepali-date-converter'
import {RootState} from 'src/setup'
import {formatDateToInput} from 'src/app/modules/common/SplitFunction'

const {Column, HeaderCell, Cell} = Table
import {BASE_APP_URL} from 'src/cms/helpers/constants/common'
import {Modal} from 'antd'
import {UserActivityLogAll} from '../../../../../common/components/AllActivityLog'

interface RouteParams {
  id: string
}

const getStringValue = (value: any) => {
  if (typeof value === 'string' && value !== '') return value
  if (typeof value === 'number') return value.toString()
  if (typeof value === 'boolean') return value ? 'Yes' : 'No'
  if (value && typeof value === 'object' && 'title' in value) return value.title
  return '-'
}

const CPUReviewFormIndividual = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams<RouteParams>()

  const {
    mutate: cpuCheckerReviewPost,

    isLoading: postRespLoading,
  } = usePostAccountPreliminaryCPUCheckerReview()

  const {data: preliminariesDetailsById, isLoading} = useGetPreliminariesDetailsById(id)

  const [allMasterDataOptions, setAllMasterDataOptions] = useState<any>(null)

  const {data: allMasterData} = useGetAllMasterData()
  const [checkAllData, setCheckAllData] = useState<any>()
  const [checkAllDataMinor, setcheckAllDataMinor] = useState<any>()
  const [checkAllDataJoint, setCheckAllDataJoint] = useState<any>([])
  const [screeningPerformed, setScreeningPerformed] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [accumulatedFilePaths, setAccumulatedFilePaths] = useState<
    {file_name: string; file_path: string}[]
  >([])
  const [enquiryLoading, setEnquiryLoading] = useState(false)
  const [screeningLoading, setScreeningLoading] = useState(false)
  const update = useRef<any>('default')
  const {mutate: mutateCheckAll} = usePostCheckAll(
    setCheckAllData,
    setcheckAllDataMinor,
    setCheckAllDataJoint,
    setAccumulatedFilePaths,
    update
  )
  const allMasterDataOption = useSelector(
    (state: RootState) => state?.allMasterDataOptions?.allMasterDataOptions
  )

  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([])
  const [loadingIndex, setLoadingIndex] = useState<number | null>()
  const [clickedIndex, setClickedIndex] = useState<number[]>([])

  useEffect(() => {
    if (allMasterData) {
      setAllMasterDataOptions(allMasterData)
    }
  }, [allMasterData])

  if (isLoading) {
    return (
      <div className='card'>
        <div className='card-body'>
          <h2>
            Please Wait...
            <Spinner animation={'border'} />
          </h2>
        </div>
      </div>
    )
  }
  const allStatusType = [
    {label: 'APPROVE', value: 'approve'},
    {label: 'REVERT TO CPU MAKER', value: 'revert_to_cpumaker'},
    {label: 'REVERT BACK TO BRANCH MAKER', value: 'revert_to_branchmaker'},
  ]
  const initialValues = preliminariesDetailsById?.data || {}
  const handleSubmit = (values: any) => {
    // let modifiedFilePath = ''
    // let accumulatedFilePaths: {file_name: any; file_path: any}[] = []
    // if (checkAllData?.data?.data?.file_path) {
    //   const parts = checkAllData.data.data.file_path.split('/public/')
    //   modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`

    //   // Add the current file to the accumulated file paths
    //   accumulatedFilePaths.push({
    //     file_name: modifiedFilePath.split('/').pop(),
    //     file_path: modifiedFilePath,
    //   })
    // }
    const payload = {
      ...values,
      id,
      is_file_path_exists: screeningPerformed,
      files: accumulatedFilePaths,
    }
    cpuCheckerReviewPost(payload, {
      onSuccess: (data) => {
        // history.go(-1)
        if (values?.status === 'approve') {
          setIsModalOpen(true)
        } else {
          history.go(-1)
        }
      },
      onError: (error) => {
        console.error('Error:', error)
      },
    })
  }
  // const fun = () => {
  //   const businesses = initialValues?.businesses?.businesses?.map(
  //     (business: any) => business?.email
  //   )
  // }
  // fun()

  const handleBack = () => {
    history.go(-1)
  }

  const handleScreening = () => {
    setScreeningLoading(true) // Set screening loading to true
    const checkAllPayload: any = {
      customer_name: initialValues?.full_name,
      citizenship: initialValues?.identification_number,
      model_name: 'NewCIFNewAccount',
    }
    mutateCheckAll(checkAllPayload, {
      onSuccess: (data: any) => {
        setScreeningPerformed(true)
        setScreeningLoading(false)
      },
      onError: (error: any) => {
        console.error('Error:', error)
        setScreeningLoading(false)
        setScreeningPerformed(false)
      },
    })
  }

  const handlePdfFileClick = (file: any) => {
    if (file) {
      let parts = file?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }

  const handleOpenPdf = () => {
    if (checkAllData) {
      let parts = checkAllData?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    } else {
      console.error('PDF file path is not available.')
    }
  }
  // const handlePdfFileClick = (file: any) => {
  //   if (file) {
  //     window.open(file, '_blank')
  //   } else {
  //     console.error('PDF file path is not available.')
  //   }
  // }

  const handleOpenPdf2 = (index?: any) => {
    if (checkAllDataJoint.length > 0) {
      {
        if (checkAllDataJoint && checkAllDataJoint[index]) {
          let parts = checkAllDataJoint[index]?.data?.file_path?.split('/public/')
          if (parts && parts.length > 1) {
            const newPaths = parts.slice(1).map((part: any) => `${BASE_APP_URL}/framework/${part}`)

            // Add new paths to the opened file paths state
            setOpenedFilePaths((prevPaths) => [...prevPaths, ...newPaths])

            // Open all accumulated file paths
            newPaths.forEach((path: any) => {
              window.open(path, '_blank')
            })
          }
        }
        // let parts = checkAllDataJoint[index as number]?.data?.file_path?.split('/public/')
        // const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
        // window.open(modifiedFilePath, '_blank')
      }
    } else if (checkAllDataMinor) {
      let parts = checkAllDataMinor?.data?.file_path?.split('/public/')
      const modifiedFilePath = `${BASE_APP_URL}/framework/${parts[1]}`
      window.open(modifiedFilePath, '_blank')
    }
  }
  const additionalScreening = async (cif_number: any, index?: number) => {
    let checkAllPayload: any = {}

    if (update.current === 'minor') {
      const guardian_full_name: string = [
        initialValues?.guardian_first_name,
        initialValues?.guardian_middle_name,
        initialValues?.guardian_last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: guardian_full_name,
        citizenship: initialValues?.identification_number,
        model_name: 'NewCIFNewAccount',
      }
    } else if (update.current === 'joint') {
      const partner = initialValues?.account_holders?.account_holders.find(
        (holder: any) => holder.cif_number === cif_number
      )

      const partners_full_name: string = [
        partner?.first_name,
        partner?.middle_name,
        partner?.last_name,
      ]
        .filter(Boolean) // Remove any empty or undefined values
        .join(' ') // Join them with a space

      checkAllPayload = {
        customer_name: partners_full_name,
        date_of_birth_bs: partner?.date_of_birth_bs || '',
        unique_id_no: partner?.unique_id_no || '',
        citizenship: partner?.citizenship || '',
        gender: partner?.gender || '',
        model_name: 'NewCIFNewAccount',
      }
    }

    setEnquiryLoading(true)
    await mutateCheckAll(checkAllPayload, {
      onSuccess: (data) => {
        setEnquiryLoading(false) // Reset loading state on success
        setLoadingIndex(null)
      },
      onError: (error) => {
        console.error('Error:', error)
        setEnquiryLoading(false) // Reset loading state on error
        setLoadingIndex(null)
        clickedIndex.shift()
      },
    })
  }
  const handleGuardianScreening = (cif_number: any) => {
    update.current = 'minor'
    // setFlag(() => 'minor')
    setEnquiryLoading(true) // Set enquiry loading to true
    additionalScreening(cif_number)
  }

  const handlePartnersScreening = (cif_number: any, index: any) => {
    setEnquiryLoading(true) // Set enquiry loading to true
    update.current = 'joint'
    additionalScreening(cif_number, index)
  }

  const handleButtonClick = async (cif_number: string, index: number) => {
    setLoadingIndex(index) // Set the loading index to the clicked button's index
    setTimeout(() => {
      // Update the clicked indices to include the newly clicked index
      setClickedIndex((prevIndices) => {
        // If the index is not already in the array, add it
        if (!prevIndices.includes(index)) {
          return [...prevIndices, index]
        }
        return prevIndices
      })
    }, 1000)
    try {
      await handlePartnersScreening(cif_number, index) // Trigger the enquiry action
    } catch (error) {
      setLoadingIndex(null)
    }
  }

  const BusinessesTable = ({data}: any) => (
    <>
      <Table data={data?.businesses || []} autoHeight width={500}>
        <Column width={160} align='center'>
          <HeaderCell>Name of Institution</HeaderCell>
          <Cell dataKey='name_of_institution' fullText />
        </Column>
        <Column width={150} align='center'>
          <HeaderCell>Address</HeaderCell>
          <Cell dataKey='address' />
        </Column>
        <Column width={150} align='center'>
          <HeaderCell>Designation</HeaderCell>
          <Cell dataKey='designation' />
        </Column>
        <Column width={150} align='center'>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey='email' fullText />
        </Column>
        <Column width={150} align='center'>
          <HeaderCell fullText>Expected Annual Income</HeaderCell>
          <Cell dataKey='expected_annual_income' />
        </Column>
        <Column width={150} align='center'>
          <HeaderCell>Telephone Number</HeaderCell>
          <Cell dataKey='telephone_number' fullText />
        </Column>
      </Table>
    </>
  )

  const AncillaryServicesTable = ({data}: any) => {
    const servicesData = data?.ancillary_services
    return (
      <>
        <Table data={servicesData} autoHeight width={500}>
          <>
            <Column width={150} align='center'>
              <HeaderCell>Title</HeaderCell>
              <Cell dataKey='service.title' />
            </Column>
          </>
        </Table>
      </>
    )
  }
  const OtherBanksTable = ({data}: any) => (
    <Table data={data?.other_banks} autoHeight width={500}>
      <Column width={150} align='center'>
        <HeaderCell>Bank Name</HeaderCell>
        <Cell dataKey='bank_name' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Branch Name</HeaderCell>
        <Cell dataKey='branch_name' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Saving Account</HeaderCell>
        <Cell dataKey='saving_ac' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Current Account</HeaderCell>
        <Cell dataKey='current_ac' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Fixed Account</HeaderCell>
        <Cell dataKey='fixed_ac' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Working Capital</HeaderCell>
        <Cell dataKey='working_capital' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Term Loan</HeaderCell>
        <Cell dataKey='term_loan' />
      </Column>
      <Column width={150} align='center'>
        <HeaderCell>Others</HeaderCell>
        <Cell dataKey='others' />
      </Column>
    </Table>
  )

  const FamilyDetailsTable = ({data}: any) => {
    const familyData = data?.family_details || []

    return (
      <>
        <Table data={familyData} autoHeight width={500}>
          <Column width={180} align='center'>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column width={180} align='center'>
            <HeaderCell>Profession</HeaderCell>
            <Cell dataKey='profession.title' />
          </Column>

          <Column width={180} align='center'>
            <HeaderCell>Relationship</HeaderCell>
            <Cell dataKey='relationship_id.title' />
          </Column>
        </Table>
      </>
    )
  }
  const USDeclarationsTable = ({data}: any) => {
    const facta_declarations = data?.facta_declarations || []
    return (
      <div>
        <Table data={facta_declarations} autoHeight width={500}>
          <Column width={100} align='center'>
            <HeaderCell>US Person</HeaderCell>
            <Cell dataKey='us_person' />
          </Column>
          <Column width={100} align='center'>
            <HeaderCell>US Indicia</HeaderCell>
            <Cell dataKey='us_indicia' />
          </Column>
          <Column width={130} align='center'>
            <HeaderCell>US Telephone Number</HeaderCell>
            <Cell dataKey='us_telephone_number' />
          </Column>
          <Column width={120} align='center'>
            <HeaderCell>US Citizenship</HeaderCell>
            <Cell dataKey='us_citizenship' />
          </Column>
          <Column width={130} align='center'>
            <HeaderCell>US Birth Country</HeaderCell>
            <Cell dataKey='us_birth_country' />
          </Column>
          <Column width={120} align='center'>
            <HeaderCell>US Residence</HeaderCell>
            <Cell dataKey='us_residence' />
          </Column>

          <Column width={120} align='center'>
            <HeaderCell>TIN Number</HeaderCell>
            <Cell dataKey='tin_number' />
          </Column>
          <Column width={130} align='center'>
            <HeaderCell>W9 Form Submitted</HeaderCell>
            <Cell dataKey='w9_form_submitted' />
          </Column>
        </Table>
      </div>
    )
  }

  const JointDeclarationsTable = ({data}: any) => {
    const joint_declarations = data?.facta_declarations || []
    return (
      <>
        <Table data={joint_declarations} autoHeight width={500}>
          <Column width={150} align='center'>
            <HeaderCell>Joint US Person</HeaderCell>
            <Cell dataKey='joint_us_person' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell>Joint US Telephone Number</HeaderCell>
            <Cell dataKey='joint_us_telephone_number' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell>Joint US Citizenship</HeaderCell>
            <Cell dataKey='joint_us_citizenship' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell fullText>Joint US Birth Country</HeaderCell>
            <Cell dataKey='joint_us_birth_country' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell fullText>Joint US Residence</HeaderCell>
            <Cell dataKey='joint_us_residence' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell fullText>Joint US Indicia</HeaderCell>
            <Cell dataKey='joint_us_indicia' />
          </Column>
          <Column flexGrow={1} align='center' fixed>
            <HeaderCell fullText>Joint TIN Number</HeaderCell>
            <Cell dataKey='joint_tin_number' />
          </Column>
          <Column width={150} align='center'>
            <HeaderCell fullText>Joint W9 Form Submitted</HeaderCell>
            <Cell dataKey='joint_w9_form_submitted' />
          </Column>
        </Table>
      </>
    )
  }

  const handleOk = () => {
    setIsModalOpen(false)
    history.go(-1)
  }

  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-9 '>
              {' '}
              <DocumentsPreview data={initialValues} />
            </div>
          </div>
        </div>
        <div className='col-md-6  my-2'  style={{
            maxHeight: '700px',
            overflowY: 'auto',
          }}>
          <div className='modal-container '>
            <div className='card p-5'>
              <div className='card-body'>
                <div className='row'>
                  <div className='row'>
                    <FormDetails
                      key='screening_id'
                      label='Screening Id'
                      value={getStringValue(initialValues?.screening_id)}
                    />

                    <FormDetails
                      key='branch_code'
                      label='Branch Code'
                      value={getStringValue(initialValues?.branch_code)}
                    />
                    <FormDetails
                      key='Customer_code'
                      label='Customer Code'
                      value={initialValues?.customer_code}
                    />
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Account Details</h2>
                      <FormDetails
                        key='account_type_id'
                        label='Account Type'
                        value={getStringValue(initialValues?.account_type_id?.title)}
                      />
                      <FormDetails
                        key='account_purpose_id'
                        label='Account Purpose'
                        value={getStringValue(initialValues?.account_purpose_id?.title)}
                      />
                      {
                        <FormDetails
                          key='other_account_purpose'
                          label='Other Account Purpose'
                          value={getStringValue(initialValues?.other_account_purpose)}
                        />
                      }
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Personal Information</h2>
                      <FormDetails
                        key='salutation'
                        label='Salutation'
                        value={getStringValue(initialValues?.salutation)}
                      />
                      <FormDetails
                        key='first_name'
                        label='First Name'
                        value={getStringValue(initialValues?.first_name)}
                      />
                      <FormDetails
                        key='middle_name'
                        label='Middle Name'
                        value={getStringValue(initialValues?.middle_name)}
                      />
                      <FormDetails
                        key='last_name'
                        label='Last Name'
                        value={getStringValue(initialValues?.last_name)}
                      />
                      <FormDetails
                        key='full_name'
                        label='Full Name'
                        value={getStringValue(initialValues?.full_name)}
                      />
                      <FormDetails
                        key='short_name'
                        label='Short Name'
                        value={getStringValue(initialValues?.short_name)}
                      />
                      <FormDetails
                        key='marital_status'
                        label='Marital Status'
                        value={getStringValue(initialValues?.marital_status?.title)}
                      />
                      {initialValues?.marital_status?.title === 'MARRIED' && (
                        <>
                          <FormDetails
                            key='spouse_name'
                            label='Spouse Name'
                            value={getStringValue(initialValues?.spouse_name)}
                          />
                          <FormDetails
                            key='father_in_law'
                            label='Father-In-Law Name'
                            value={getStringValue(initialValues?.father_in_law)}
                          />
                        </>
                      )}

                      <FormDetails
                        key='father_name'
                        label='Father’s Name'
                        value={getStringValue(initialValues?.father_name)}
                      />
                      <FormDetails
                        key='grandfather_name'
                        label='Grandfather’s Name'
                        value={getStringValue(initialValues?.grandfather_name)}
                      />
                      <FormDetails
                        key='gender'
                        label='Gender'
                        value={getStringValue(initialValues?.gender)}
                      />
                      <FormDetails
                        key='date_of_birth_ad'
                        label='Date of Birth (AD)'
                        value={getStringValue(initialValues?.date_of_birth_ad)}
                      />
                      <FormDetails
                        key='date_of_birth_bs'
                        label='Date of Birth (BS)'
                        value={getStringValue(initialValues?.date_of_birth_bs)}
                      />
                      <FormDetails
                        key='national_id_number'
                        label='National ID Number'
                        value={getStringValue(initialValues?.national_id_number)}
                      />
                      <FormDetails
                        key='nationality_id'
                        label='Nationality'
                        value={getStringValue(initialValues?.nationality_id?.title)}
                      />
                      {initialValues.refugee_details && (
                        <FormDetails
                          key='refugee_details'
                          label='Others'
                          value={
                            getStringValue(
                              initialValues.refugee_details === 'REFUGEES'
                                ? 'REFUGEES'
                                : 'NON CITIZENSHIP HOLDER'
                            ) || '-'
                          }
                        />
                      )}

                      <FormDetails
                        key='religion_id'
                        label='Religion'
                        value={getStringValue(initialValues?.religion_id?.title)}
                      />
                      {initialValues?.other_religion && (
                        <FormDetails
                          key='other_religion'
                          label='Other Religion'
                          value={getStringValue(initialValues?.other_religion)}
                        />
                      )}

                      <FormDetails
                        key='education_id'
                        label='Education'
                        value={getStringValue(initialValues?.education_id?.title)}
                      />
                      {initialValues?.other_education_qualification && (
                        <FormDetails
                          key='other_education_qualification'
                          label='Other Education Qualification'
                          value={getStringValue(initialValues?.other_education_qualification)}
                        />
                      )}

                      <FormDetails
                        key={'address_type'}
                        label='Address Type'
                        value={getStringValue(
                          initialValues?.address_type === 'P'
                            ? 'Permanent Address'
                            : 'Communication Address'
                        )}
                      />
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Permanent Address Details</h2>
                      <FormDetails
                        key='permanent_country'
                        label=' Country'
                        value={getStringValue(initialValues?.permanent_country?.title)}
                      />
                      {initialValues?.permanent_country?.title === 'NEPAL' ? (
                        <>
                          <FormDetails
                            key='permanent_province'
                            label=' Province'
                            value={getStringValue(initialValues?.permanent_province?.title)}
                          />
                          <FormDetails
                            key='permanent_zone'
                            label=' Zone'
                            value={getStringValue(initialValues?.permanent_zone?.title)}
                          />
                          <FormDetails
                            key='permanent_district'
                            label='District'
                            value={getStringValue(initialValues?.permanent_district?.title)}
                          />
                          <FormDetails
                            key='permanent_municipality'
                            label='Municipality'
                            value={getStringValue(initialValues?.permanent_municipality?.title)}
                          />
                          <FormDetails
                            key='permanent_city'
                            label='Permanent City'
                            value={getStringValue(initialValues?.permanent_city?.title)}
                          />
                          <FormDetails
                            key='permanent_street_name'
                            label='Permanent Street'
                            value={getStringValue(initialValues?.permanent_street_name)}
                          />

                          {initialValues?.permanent_fax_number && (
                            <FormDetails
                              key='permanent_fax_number'
                              label='Permanent Fax Number'
                              value={getStringValue(initialValues?.permanent_fax_number)}
                            />
                          )}
                          <FormDetails
                            key='permanent_ward_number'
                            label='Ward Number'
                            value={getStringValue(initialValues?.permanent_ward_number)}
                          />

                          {/* <FormDetails
                            key='permanent_street_name'
                            label='Street Name'
                            value={getStringValue(initialValues?.permanent_street_name)}
                          /> */}
                          <FormDetails
                            key='permanent_house_number'
                            label='House Number'
                            value={getStringValue(initialValues?.permanent_house_number)}
                          />
                          <FormDetails
                            key='permanent_mobile_number'
                            label='Mobile Number'
                            value={getStringValue(initialValues?.permanent_mobile_number)}
                          />
                          <FormDetails
                            key='permanent_email'
                            label='Email'
                            value={getStringValue(initialValues?.permanent_email)}
                          />
                        </>
                      ) : (
                        <>
                          <FormDetails
                            key='permanent_outside_country_post_box'
                            label='Outside Country Post Box'
                            value={getStringValue(
                              initialValues?.permanent_outside_country_post_box
                            )}
                          />
                          <FormDetails
                            key='permanent_outside_country_full_address'
                            label='Outside Country Full Address'
                            value={getStringValue(
                              initialValues?.permanent_outside_country_full_address
                            )}
                          />
                          <FormDetails
                            key='permanent_mobile_number'
                            label='Mobile Number'
                            value={getStringValue(initialValues?.permanent_mobile_number)}
                          />
                          <FormDetails
                            key='permanent_email'
                            label='Email'
                            value={getStringValue(initialValues?.permanent_email)}
                          />
                        </>
                      )}
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Present Address Details</h2>
                      <FormDetails
                        key='present_country'
                        label='Country'
                        value={getStringValue(initialValues?.present_country?.title)}
                      />
                      {initialValues?.present_country?.title === 'NEPAL' ? (
                        <>
                          <FormDetails
                            key='present_province'
                            label='Province'
                            value={getStringValue(initialValues?.present_province?.title)}
                          />
                          <FormDetails
                            key='present_zone'
                            label='Zone'
                            value={getStringValue(initialValues?.present_zone?.title)}
                          />
                          <FormDetails
                            key='present_district'
                            label='District'
                            value={getStringValue(initialValues?.present_district?.title)}
                          />
                          <FormDetails
                            key='present_municipality'
                            label='Municipality'
                            value={getStringValue(initialValues?.present_municipality?.title)}
                          />
                          {initialValues?.present_city && (
                            <FormDetails
                              key='present_city'
                              label='Present City'
                              value={getStringValue(initialValues?.present_city?.title)}
                            />
                          )}
                          <FormDetails
                            key='present_street_name'
                            label='Present Street'
                            value={getStringValue(initialValues?.present_street_name)}
                          />

                          {initialValues?.present_fax_number && (
                            <FormDetails
                              key='present_fax_number'
                              label='Present Fax Number'
                              value={getStringValue(initialValues?.present_fax_number)}
                            />
                          )}

                          <FormDetails
                            key='present_ward_number'
                            label='Ward Number'
                            value={getStringValue(initialValues?.present_ward_number)}
                          />
                          {/* <FormDetails
                        key='present_city'
                        label='City'
                        value={getStringValue(initialValues?.present_city)}
                      />
                      <FormDetails
                        key='present_street_name'
                        label='Street Name'
                        value={getStringValue(initialValues?.present_street_name)}
                      /> */}
                          <FormDetails
                            key='present_house_number'
                            label='House Number'
                            value={getStringValue(initialValues?.present_house_number)}
                          />
                          <FormDetails
                            key='present_mobile_number'
                            label='Mobile Number'
                            value={getStringValue(initialValues?.present_mobile_number)}
                          />
                          <FormDetails
                            key='present_email'
                            label='Email'
                            value={getStringValue(initialValues?.present_email)}
                          />
                        </>
                      ) : (
                        <>
                          <FormDetails
                            key='present_outside_country_full_address'
                            label='Full Address'
                            value={getStringValue(
                              initialValues?.present_outside_country_full_address
                            )}
                          />
                          <FormDetails
                            key='present_outside_country_post_box'
                            label='Post Box No.'
                            value={getStringValue(initialValues?.present_outside_country_post_box)}
                          />
                          <FormDetails
                            key='present_mobile_number'
                            label='Mobile Number'
                            value={getStringValue(initialValues?.present_mobile_number)}
                          />
                          <FormDetails
                            key='present_email'
                            label='Email'
                            value={getStringValue(initialValues?.present_email)}
                          />
                        </>
                      )}
                    </div>
                    {initialValues?.present_country?.title !== 'NEPAL' ? (
                      <div className='row'>
                        <h2 style={{color: 'brown'}}>Immigration Details</h2>
                        <>
                          <FormDetails
                            key='visa_issue_date'
                            label=' Visa Issue Date (BS)'
                            value={getStringValue(initialValues?.visa_issue_date)}
                          />
                          <FormDetails
                            key='visa_issue_date_ad'
                            label='Visa Issue Date (AD)'
                            value={getStringValue(initialValues?.visa_issue_date_ad)}
                          />
                          <FormDetails
                            key='visa_expiry_date'
                            label='Visa Expiry Date (BS)'
                            value={getStringValue(initialValues?.visa_expiry_date)}
                          />
                          <FormDetails
                            key='visa_expiry_date_ad'
                            label='Visa Expiry Date (AD)'
                            value={getStringValue(initialValues?.visa_expiry_date_ad)}
                          />
                          <FormDetails
                            key='work_permit_id'
                            label='Work Permit '
                            value={getStringValue(initialValues?.work_permit_id)}
                          />
                          <FormDetails
                            key='work_permit_issued_date'
                            label='Work Permit Issued Date (BS)'
                            value={getStringValue(initialValues?.work_permit_issued_date)}
                          />
                          <FormDetails
                            key='work_permit_issued_date_ad'
                            label='Work Permit Issued Date (AD)'
                            value={getStringValue(initialValues?.work_permit_issued_date_ad)}
                          />
                          <FormDetails
                            key='work_permit_expiry_date'
                            label='Work Permit Expiry Date (BS)'
                            value={getStringValue(initialValues?.work_permit_expiry_date)}
                          />

                          <FormDetails
                            key='work_permit_expiry_date_ad'
                            label='Work Permit Expirt Date (AD)'
                            value={getStringValue(initialValues?.work_permit_expiry_date_ad)}
                          />
                          <FormDetails
                            key='issuing_authority'
                            label='Issuing Authority'
                            value={getStringValue(initialValues?.issuing_authority)}
                          />
                        </>
                      </div>
                    ) : null}
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Occupation and Income Details</h2>
                      <div className='row'>
                        <FormDetails
                          key='occupation_id'
                          label='Occupation'
                          value={getStringValue(initialValues?.occupation_id?.title)}
                        />
                        {initialValues?.other_occupation && (
                          <FormDetails
                            key='other_occupation'
                            label='Other Occupation'
                            value={getStringValue(initialValues?.other_occupation)}
                          />
                        )}
                        <FormDetails
                          key='income_source_id'
                          label='Income Source'
                          value={getStringValue(initialValues?.income_source_id?.title)}
                        />
                        {initialValues?.other_income_source && (
                          <FormDetails
                            key='other_income_source'
                            label='Other Income Source'
                            value={getStringValue(initialValues?.other_income_source)}
                          />
                        )}
                        {/*<FormDetails*/}
                        {/*  key='expected_annual_turnover'*/}
                        {/*  label='Annual Transaction Amount'*/}
                        {/*  value={getStringValue(initialValues?.expected_annual_turnover)}*/}
                        {/*/>*/}
                        <FormDetails
                          key='expected_annual_transaction_amount'
                          label='Expected Annual Transaction Amount'
                          value={getStringValue(initialValues?.expected_annual_transaction_amount)}
                        />
                        <FormDetails
                          key='expected_annual_transaction_number'
                          label='Expected Annual Transaction Number'
                          value={initialValues?.expected_annual_transaction_number?.title}
                        />
                        <FormDetails
                          key='total_annual_income'
                          label='Total Annual Income'
                          value={getStringValue(initialValues?.total_annual_income)}
                        />
                        <FormDetails
                          key='annual_turn_over_as_on'
                          label='Annual Turnover As On'
                          value={getStringValue(initialValues?.annual_turn_over_as_on)}
                        />
                        <FormDetails
                          key='pan_number'
                          label='PAN Number'
                          value={getStringValue(initialValues?.pan_number)}
                        />
                        <FormDetails
                          key='pan_registration_date'
                          label='PAN Registration Date'
                          value={getStringValue(initialValues?.pan_registration_date)}
                        />
                      </div>
                    </div>

                    {initialValues?.account_type_id?.title === 'Minor' && (
                      <div className='row'>
                        <h2 style={{color: 'brown'}}>
                          Guardian Information (Specific to Minor Accounts)
                        </h2>
                        <div className='row'>
                          <FormDetails
                            key='guardian_first_name'
                            label='Guardian First Name'
                            value={getStringValue(initialValues?.guardian_first_name)}
                          />
                          <FormDetails
                            key='guardian_middle_name'
                            label='Guardian Middle Name'
                            value={getStringValue(initialValues?.guardian_middle_name)}
                          />
                          <FormDetails
                            key='guardian_last_name'
                            label='Guardian Last Name'
                            value={getStringValue(initialValues?.guardian_last_name)}
                          />
                          <FormDetails
                            key='guardian_cif_number'
                            label='Guardian CIF Number'
                            value={getStringValue(initialValues?.guardian_cif_number)}
                          />
                          <FormDetails
                            key='guardian_relationship_id'
                            label='Guardian Relationship'
                            value={getStringValue(initialValues?.guardian_relationship_id)}
                          />
                          <FormDetails
                            key='guardian_address'
                            label='Guardian Address'
                            value={getStringValue(initialValues?.guardian_address)}
                          />
                          {/*<FormDetails*/}
                          {/*  key='minor_certificate_issue_date_ad'*/}
                          {/*  label='Minor Certificate Issue Date (AD)'*/}
                          {/*  value={getStringValue(initialValues?.minor_certificate_issue_date_ad)}*/}
                          {/*/>*/}
                          {/*<FormDetails*/}
                          {/*  key='minor_certificate_issue_date_bs'*/}
                          {/*  label='Minor Certificate Issue Date (BS)'*/}
                          {/*  value={getStringValue(initialValues?.minor_certificate_issue_date_bs)}*/}
                          {/*/>*/}
                          {/*<FormDetails*/}
                          {/*  key='minor_certificate_number'*/}
                          {/*  label='Minor Certificate Number'*/}
                          {/*  value={getStringValue(initialValues?.minor_certificate_number)}*/}
                          {/*/>*/}
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormDetails
                              key='minor_certificate_number'
                              label='Minor Certificate Number'
                              value={getStringValue(initialValues?.minor_certificate_number)}
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormDetails
                              key='certificate_issued_by'
                              label='Certificate Issued By'
                              value={getStringValue(initialValues?.certificate_issued_by)}
                            />
                          </div>
                          <div className='col-md-4 col-sm-6 col-xs-12'>
                            <FormDetails
                              key='minor_proof_of_guardianship'
                              label='Minor Proof of Guardianship'
                              value={getStringValue(initialValues?.minor_proof_of_guardianship)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {initialValues?.account_holders?.account_holders?.length > 0 && (
                      <>
                        <section className=''>
                          <h3 className='fw-bolder mb-4 text-capitalize' style={{color: 'brown'}}>
                            Account Holder's Details
                          </h3>
                          <div className='row'>
                            {initialValues?.account_holders?.account_holders?.map(
                              (holder: any, index: any) => (
                                <div key={index} className='row'>
                                  <FormDetails
                                    key={`first_name_${index}`}
                                    label='First Name'
                                    value={holder?.first_name}
                                  />
                                  <FormDetails
                                    key={`middle_name_${index}`}
                                    label='Middle Name'
                                    value={holder?.middle_name}
                                  />
                                  <FormDetails
                                    key={`last_name_${index}`}
                                    label='Last Name'
                                    value={holder?.last_name}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </section>
                        <hr />
                      </>
                    )}
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Relation with the account</h2>
                      <div className='row'>
                        <FormDetails
                          key='has_existing_account'
                          label='Has Existing Account'
                          value={getStringValue(initialValues?.has_existing_account)}
                        />
                        {initialValues?.has_existing_account === true && (
                          <>
                            <FormDetails
                              key='existing_account_number'
                              label='Existing account number'
                              value={getStringValue(initialValues?.existing_account_number)}
                            />
                            <FormDetails
                              key='relation_with_existing_account'
                              label='Relationship with existing account'
                              value={getStringValue(initialValues?.relation_with_existing_account)}
                            />
                            {initialValues?.relation_with_existing_account === 'other' && (
                              <FormDetails
                                key='pep_retirement_date'
                                label='PEP Retirement Date'
                                value={getStringValue(initialValues?.pep_retirement_date)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Details of Landlord/Landlady</h2>
                      <div className='row'>
                        <FormDetails
                          key='landlord_name'
                          label='Landlord Name'
                          value={getStringValue(initialValues?.landlord_name)}
                        />
                        <FormDetails
                          key='landlord_address'
                          label='Landlord Address'
                          value={getStringValue(initialValues?.landlord_address)}
                        />
                        <FormDetails
                          key='landlord_mobile_number'
                          label='Landline No./Mobile No.'
                          value={getStringValue(initialValues?.landlord_mobile_number)}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>PEP Details</h2>
                      <div className='row'>
                        <FormDetails
                          key='pep_status'
                          label='PEP Status'
                          value={getStringValue(initialValues?.pep_status)}
                        />
                        {initialValues?.pep_status === 'Yes' && (
                          <>
                            <FormDetails
                              key='pep_position'
                              label='PEP Position'
                              value={getStringValue(initialValues?.pep_position)}
                            />
                            <FormDetails
                              key='pep_relationship'
                              label='PEP Relationship'
                              value={getStringValue(initialValues?.pep_relationship)}
                            />
                            <FormDetails
                              key='pep_retirement_date'
                              label='PEP Retirement Date'
                              value={getStringValue(initialValues?.pep_retirement_date)}
                            />
                            <FormDetails
                              key='pep_category_id'
                              label='PEP Category'
                              value={getStringValue(initialValues?.pep_category_id?.title)}
                            />
                            <FormDetails
                              key='pep_sub_categories_id'
                              label='PEP Sub Category'
                              value={getStringValue(initialValues?.pep_sub_categories_id?.title)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Beneficial Ownership</h2>
                      <div className='row'>
                        <FormDetails
                          key='beneficial_owner'
                          label='Beneficial Owner'
                          value={getStringValue(initialValues?.beneficial_owner)}
                        />
                        {initialValues?.beneficial_owner === 'Yes' && (
                          <>
                            <FormDetails
                              key='beneficial_owner_name'
                              label='Beneficial Owner Name'
                              value={getStringValue(initialValues?.beneficial_owner_name)}
                            />
                            <FormDetails
                              key='beneficial_owner_occupation'
                              label='Beneficial Owner Occupation'
                              value={getStringValue(initialValues?.beneficial_owner_occupation)}
                            />
                            <FormDetails
                              key='beneficial_owner_relation'
                              label='Beneficial Owner Relation'
                              value={getStringValue(initialValues?.beneficial_owner_relation)}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className='row'>
                      <h2 style={{color: 'brown'}}>Documents</h2>
                      <div className='row'>
                        <FormDetails
                          key='id_type_id'
                          label='ID Type'
                          value={getStringValue(initialValues?.id_type_id?.title)}
                        />
                        <FormDetails
                          key='identification_number'
                          label='Identification Number'
                          value={getStringValue(initialValues?.identification_number)}
                        />
                        <FormDetails
                          key='id_issued_date'
                          label='ID Issued Date (BS)'
                          value={getStringValue(initialValues?.id_issued_date)}
                          // value={getStringValue(initialValues?.id_issued_date_ad)}
                        />
                        <FormDetails
                          key='id_issued_date_ad'
                          label='ID Issued Date (AD)'
                          value={getStringValue(initialValues?.id_issued_date_ad)}
                        />
                        {
                          <FormDetails
                            key='national_id_number'
                            label='National ID Number'
                            value={getStringValue(initialValues?.national_id_number)}
                          />
                        }

                        {initialValues?.id_type_id?.title !== 'BIRTH CERTIFICATE' &&
                          initialValues?.id_type_id?.title !== 'PAN CARD' &&
                          initialValues?.id_type_id?.title !== 'CITIZENSHIP' && (
                            <>
                              <FormDetails
                                key='id_expiry_date'
                                label='ID Expiry Date (BS)'
                                value={getStringValue(initialValues?.id_expiry_date)}
                              />
                              <FormDetails
                                key='id_expiry_date_ad'
                                label='ID Expiry Date (AD)'
                                value={getStringValue(initialValues?.id_expiry_date_ad)}
                              />
                            </>
                          )}

                        <FormDetails
                          key='issuing_authority'
                          label='Issuing Authority'
                          value={getStringValue(initialValues?.issuing_office)}
                        />
                        <FormDetails
                          key='issuing_district'
                          label='Issuing District'
                          value={getStringValue(initialValues?.issuing_district?.title)}
                        />
                        <FormDetails
                          key='issuing_office'
                          label='Issuing Office'
                          value={getStringValue(initialValues?.issuing_office?.title)}
                        />

                        {allMasterDataOptions?.id_types?.some(
                          (account_type: any) => account_type.id === initialValues?.id_type_id
                        ) &&
                          allMasterDataOptions?.id_types?.some(
                            (account_type: any) => account_type.id === initialValues?.id_type_id
                          ) &&
                          allMasterDataOptions?.id_types?.some(
                            (account_type: any) => account_type.id === initialValues?.id_type_id
                          ) && (
                            <>
                              <FormDetails
                                key='id_expiry_date_ad'
                                label='Expiry Date (AD)'
                                value={getStringValue(
                                  initialValues?.issuing_office?.id_expiry_date_ad
                                )}
                              />
                              <FormDetails
                                key='id_expiry_date'
                                label='Expiry Date (BS)'
                                value={getStringValue(
                                  initialValues?.issuing_office?.id_expiry_date
                                )}
                              />
                            </>
                          )}
                        {/* <FormDetails
                          key='work_permit_id'
                          label='Work Permit ID'
                          value={getStringValue(initialValues?.work_permit_id)}
                        />
                        <FormDetails
                          key='work_permit_issued_date'
                          label='Work Permit Issued Date'
                          value={getStringValue(initialValues?.work_permit_issued_date)}
                        />
                        <FormDetails
                          key='work_permit_expiry_date'
                          label='Work Permit Expiry Date'
                          value={getStringValue(initialValues?.work_permit_expiry_date)}
                        /> */}
                      </div>
                    </div>
                    {/* <section className='py-3'> */}
                    <div className='row'>
                      <h3 className='fw-bolder mb-4 text-capitalize'>Account Opening Details</h3>
                      <div className='row'>
                        {/* <FormDetails
                          key='account_title'
                          label='Account Title'
                          value={getStringValue(initialValues?.account_opening?.account_title)}
                        /> */}
                        <FormDetails
                          key='initial_deposit'
                          label='Initial Deposit'
                          value={getStringValue(initialValues?.account_opening?.initial_deposit)}
                        />
                        <FormDetails
                          key='account_category_id'
                          label='Account Category'
                          value={getStringValue(
                            initialValues?.account_opening?.account_category_id
                          )}
                        />
                        <FormDetails
                          key='account_Scheme_id'
                          label='Account Scheme'
                          value={getStringValue(initialValues?.account_opening?.account_scheme_id)}
                        />
                        <FormDetails
                          key='account_currency_id'
                          label='Account Currency '
                          value={getStringValue(
                            initialValues?.account_opening?.account_currency_id
                          )}
                        />
                        <FormDetails
                          key='account_purpose_id'
                          label='Account Purpose '
                          value={getStringValue(initialValues?.account_purpose_id?.title)}
                        />
                        {/* <div className='col-md-4 col-sm-6 col-xs-12'>
                          <FormDetails
                            key='other_account_currency'
                            label='Other Account Currency'
                            value={getStringValue(
                              initialValues?.account_opening?.other_account_currency
                            )}
                          />
                        </div> */}
                        {initialValues?.nomination === true && (
                          <>
                            <FormDetails
                              key='nomination'
                              label='Nomination'
                              value={getStringValue(initialValues?.nomination)}
                            />

                            <FormDetails
                              key='nominee_first_name'
                              label='Nominee First Name'
                              value={getStringValue(initialValues?.nominee_first_name)}
                            />
                            <FormDetails
                              key='nominee_middle_name'
                              label='Nominee Middle Name'
                              value={getStringValue(initialValues?.nominee_middle_name)}
                            />
                            <FormDetails
                              key='nominee_last_name'
                              label='Nominee Last Name'
                              value={getStringValue(initialValues?.nominee_last_name)}
                            />
                            <FormDetails
                              key='nominee_citizenship_number'
                              label='Nominee Citizenship Number'
                              value={getStringValue(initialValues?.nominee_citizenship_number)}
                            />
                            <FormDetails
                              key='nominee_address'
                              label='Nominee Address'
                              value={getStringValue(initialValues?.nominee_address)}
                            />
                            <FormDetails
                              key='nominee_relationship_id'
                              label='Nominee Relationship '
                              value={getStringValue(initialValues?.nominee_relationship_id)}
                            />
                            <FormDetails
                              key='nominee_place_of_issue'
                              label='Nominee Place of Issue'
                              value={getStringValue(initialValues?.nominee_place_of_issue)}
                            />
                          </>
                        )}

                        <FormDetails
                          key='cheque_book_name'
                          label='Cheque Book Name'
                          value={getStringValue(initialValues?.account_opening?.cheque_book_name)}
                        />
                        <FormDetails
                          key='mode_of_operation'
                          label='Mode of Operation'
                          value={getStringValue(initialValues?.account_opening?.mode_of_operation)}
                        />
                        {initialValues?.account_opening?.mode_of_operation === 'other' && (
                          <FormDetails
                            key='other_mode_of_operation'
                            label='Other Mode of Operation'
                            value={getStringValue(
                              initialValues?.account_opening?.other_mode_of_operation
                            )}
                          />
                        )}

                        <FormDetails
                          key='loan_detail_id'
                          label='Loan Detail '
                          value={getStringValue(initialValues?.account_opening?.loan_detail_id)}
                        />
                        <FormDetails
                          key='deposit_detail_id'
                          label='Deposit Detail'
                          value={getStringValue(initialValues?.account_opening?.deposit_detail_id)}
                        />
                      </div>
                      {/* </section> */}

                      {initialValues?.account_opening?.nomination === true && (
                        <div className='row'>
                          <h2 style={{color: 'brown'}}>Nomination Details </h2>
                          <div className='row'>
                            <>
                              <FormDetails
                                label='Nominee First Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_first_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Middle Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_middle_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Last Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_last_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Citizenship Number'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_citizenship_number
                                )}
                              />
                              <FormDetails
                                label='Nominee Address'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_address
                                )}
                              />
                              <FormDetails
                                label='Nominee Relationship'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_relationship_id?.title
                                )}
                              />
                              <FormDetails
                                label='Nominees Citizenship Place of Issuing District'
                                value={getStringValue(
                                  initialValues?.account_opening?.nominee_place_of_issue?.title
                                )}
                              />
                            </>
                          </div>
                        </div>
                      )}
                      {initialValues?.account_opening?.introducer_first_name && (
                        <div className='row'>
                          <h2 style={{color: 'brown'}}>Introducer's Details </h2>
                          <div className='row'>
                            <>
                              <FormDetails
                                label='Nominee First Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_first_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Middle Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_middle_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Last Name'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_last_name
                                )}
                              />
                              <FormDetails
                                label='Nominee Citizenship Number'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_customer_id
                                )}
                              />
                              <FormDetails
                                label='Nominee Address'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_address
                                )}
                              />

                              <FormDetails
                                label='Nominees Citizenship Place of Issuing District'
                                value={getStringValue(
                                  initialValues?.account_opening?.introducer_confirmation_period
                                )}
                              />
                            </>
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        <h2 style={{color: 'brown'}}>AML Risk and Self Declaration </h2>
                        <div className='row'>
                          <FormDetails
                            key='aml_risk_rating'
                            label='AML Risk Rating'
                            value={initialValues?.aml_risk_rating?.title}
                          />
                          <FormDetails
                            key='sub_risk_category_id'
                            label='AML Risk Sub Rating'
                            value={initialValues?.sub_risk_category_id?.title}
                          />

                          <FormDetails
                            key='residential_permit_foreign_country'
                            label='Residential Permit Foreign Country'
                            value={getStringValue(
                              initialValues?.residential_permit_foreign_country ? 'yes' : 'no'
                            )}
                          />
                          {initialValues?.permit_details && (
                            <FormDetails
                              key='permit_details'
                              label='Permit Details'
                              value={getStringValue(initialValues?.permit_details)}
                            />
                          )}
                          <FormDetails
                            key='blacklisting_inclusion'
                            label='Blacklisting Inclusion'
                            value={getStringValue(
                              initialValues?.blacklisting_inclusion ? 'Yes' : 'No'
                            )}
                          />
                          {initialValues?.blacklisting_inclusion && (
                            <FormDetails
                              key='blacklist_release_date'
                              label='Blacklist Release Date'
                              value={getStringValue(initialValues?.blacklist_release_date)}
                            />
                          )}
                          <FormDetails
                            key='crime_conviction'
                            label='Crime Conviction'
                            value={getStringValue(initialValues?.crime_conviction ? 'Yes' : 'No')}
                          />
                          {initialValues?.crime_conviction && (
                            <FormDetails
                              key='crime_details'
                              label='Crime Details'
                              value={getStringValue(initialValues?.crime_details)}
                            />
                          )}

                          {/* <FormDetails
                            key='sanction_screening'
                            label='Sanction Screening'
                            value={getStringValue(initialValues?.sanction_screening ? 'Yes' : 'No')}
                          /> */}
                        </div>
                      </div>
                      <div className='row'>
                        <h2 style={{color: 'brown'}}>MIS Details</h2>
                        <FormDetails
                          key='bank_code_id'
                          label='Bank Code'
                          value={getStringValue(initialValues?.bank_code_id)}
                        />
                        <FormDetails
                          key='customer_group_id'
                          label='Customer Group'
                          value={getStringValue(initialValues?.customer_group_id)}
                        />
                        <FormDetails
                          key='customer_status_id'
                          label='Customer Status'
                          value={getStringValue(initialValues?.customer_status_id)}
                        />
                        <FormDetails
                          key='customer_free_text'
                          label='Customer Status Details'
                          value={getStringValue(initialValues?.customer_free_text)}
                        />
                        <FormDetails
                          key='cdd_edd_date'
                          label='CDD & EDD Date'
                          value={getStringValue(initialValues?.cdd_edd_date)}
                        />
                        {/* <div className='col-md-4 col-sm-6 col-xs-12'>
                        <FormDetails
                          key='annual_turn_over_as_on'
                          label='Annual Turnover As On'
                          value={getStringValue(initialValues?.annual_turn_over_as_on)}
                        />
                      </div> */}
                      </div>

                      <div className='row'>
                        <h2 style={{color: 'brown'}}>Other Related Information</h2>
                        <div className='row'>
                          <div>
                            <h2>Associated Businesses</h2>
                            <BusinessesTable data={initialValues?.businesses} />
                            <h2>Ancillary Services</h2>
                            <AncillaryServicesTable data={initialValues?.ancillary_services} />
                            <h2>Family Details</h2>
                            <FamilyDetailsTable data={initialValues?.family_details} />
                            <h2>Fatca Details</h2>
                            <USDeclarationsTable data={initialValues?.facta_declarations} />
                            <h2>Dealing with Other Banks</h2>
                            <OtherBanksTable data={initialValues?.other_banks} />
                            {initialValues?.account_type_id?.title === 'Joint' && (
                              <>
                                <h2>Joint Fatca Details</h2>
                                <JointDeclarationsTable data={initialValues?.facta_declarations} />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    {screeningPerformed ? (
                      checkAllData && (
                        <Button
                          type='button'
                          onClick={handleOpenPdf}
                          className='col-md-3 col-sm-6 col-xs-12'
                        >
                          View Details
                        </Button>
                      )
                    ) : screeningLoading ? (
                      <Spinner animation='border' role='status'>
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    ) : (
                      <Button
                        type='button'
                        onClick={handleScreening}
                        className='col-md-3 col-sm-6 col-xs-12'
                      >
                        Perform Screening
                      </Button>
                    )}
                    <div className='my-7'>
                      <h3>Screening Files</h3>
                      {initialValues?.screening_files?.screening_files?.length > 0 ? (
                        initialValues?.screening_files?.screening_files?.map(
                          (file: any, index: any) => (
                            <button
                              key={index}
                              type='button'
                              onClick={() => handlePdfFileClick(file.file_path)}
                              style={{display: 'block', marginBottom: '10px'}}
                            >
                              {file.file_name}
                            </button>
                          )
                        )
                      ) : (
                        <p>No files available</p>
                      )}
                    </div>
                  </div>
                  <Formik
                    initialValues={{status: '', remarks: ''}}
                    onSubmit={handleSubmit}
                    validationSchema={REVIEW_VALIDATION_SCHEMA}
                  >
                    {({handleChange, errors, touched, values, setFieldValue}) => (
                      <Form>
                        <div className='row '>
                          <div className='col-lg-6 col-md-6 col-12'>
                            <FormSelect
                              labelClassName='col-md-12'
                              containerClassName='col-md-12'
                              label='Status Type'
                              name='status'
                              isClearable={true}
                              onChange={handleChange}
                              errors={errors}
                              touched={touched}
                              options={allStatusType.map((item) => ({
                                label: item.label,
                                value: item.value,
                              }))}
                              required={true}
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className='border my-2 mb-6'></div>
                          <FormTextBox
                            containerClassName='col-md-12'
                            labelClassName='col-md-12'
                            type='text'
                            placeholder='Remarks'
                            label='Remarks'
                            name='remarks'
                            disabled={false}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            required={true}
                            data-cy='add-id-number-text-field'
                            as='textarea'
                          />
                          <div className='d-flex justify-content-end mt-3'>
                            <button
                              type='button'
                              onClick={handleBack}
                              className='btn btn-secondary mx-4'
                            >
                              Back
                            </button>
                            <button
                              type='submit'
                              className='btn btn-primary'
                              disabled={postRespLoading}
                            >
                              {postRespLoading ? 'Submitting...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccountStatusLog data={preliminariesDetailsById?.data} />
        <UserActivityLogAll
          caseId={id}
          modalName={preliminariesDetailsById?.data?.model_name}
          modalData={preliminariesDetailsById?.data}
        />

        <Modal
          title='Processing Request'
          open={isModalOpen}
          onOk={handleOk}
          footer={[
            <Button key='submit' type='primary' onClick={handleOk}>
              OK
            </Button>,
          ]}
          centered
        >
          <p>Your request is being processed, thank you for your patience.</p>
        </Modal>
      </div>
    </>
  )
}

export default CPUReviewFormIndividual
