import axios from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ACCOUT_MAINTENANCE_URI, UPDATEKYC} from 'src/app/queries/api/apiEndPoints/EndPoints'
import {BASE_API_URL} from 'src/cms/helpers/constants/common'

interface PostParams {
  payload: {
    status: string
    remarks: string
    is_file_path_exists: boolean
    files?: Array<any>
  }

  id: string
}

const postChecker = async ({payload, id}: PostParams) => {
  try {
    const response = await axios.post(`${ACCOUT_MAINTENANCE_URI}/${id}/cpu-checker-review`, payload)
    return response.data
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'An error occurred')
  }
}

export const usePostMaintainanceByChecker = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const history = useHistory()

  return useMutation(postChecker, {
    onSuccess: (response) => {
      toast.success(response.resDesc)
      setIsOpen(false)
      history.go(-1)
    },
    onError: (error: Error) => {
      toast.error(`Error: ${error.message || 'An error occurred'}`)
    },
  })
}
